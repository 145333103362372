(function (exports) {
	'use strict';

	var commonjsGlobal = typeof globalThis !== 'undefined' ? globalThis : typeof window !== 'undefined' ? window : typeof global !== 'undefined' ? global : typeof self !== 'undefined' ? self : {};

	function createCommonjsModule(fn, module) {
		return module = { exports: {} }, fn(module, module.exports), module.exports;
	}

	var O = 'object';
	var check = function (it) {
	    return it && it.Math == Math && it;
	};
	var global_1 = check(typeof globalThis == O && globalThis) || check(typeof window == O && window) || check(typeof self == O && self) || check(typeof commonjsGlobal == O && commonjsGlobal) || Function('return this')();

	var fails = function (exec) {
	    try {
	        return !(!exec());
	    } catch (error) {
	        return true;
	    }
	};

	var descriptors = !fails(function () {
	    return Object.defineProperty({}, 'a', {
	        get: function () {
	            return 7;
	        }
	    }).a != 7;
	});

	var nativePropertyIsEnumerable = ({}).propertyIsEnumerable;
	var getOwnPropertyDescriptor = Object.getOwnPropertyDescriptor;
	var NASHORN_BUG = getOwnPropertyDescriptor && !nativePropertyIsEnumerable.call({
	    1: 2
	}, 1);
	var f = NASHORN_BUG ? function propertyIsEnumerable(V) {
	    var descriptor = getOwnPropertyDescriptor(this, V);
	    return !(!descriptor) && descriptor.enumerable;
	} : nativePropertyIsEnumerable;
	var objectPropertyIsEnumerable = {
	    f: f
	};

	var createPropertyDescriptor = function (bitmap, value) {
	    return {
	        enumerable: !(bitmap & 1),
	        configurable: !(bitmap & 2),
	        writable: !(bitmap & 4),
	        value: value
	    };
	};

	var toString = ({}).toString;
	var classofRaw = function (it) {
	    return toString.call(it).slice(8, -1);
	};

	var split = ''.split;
	var indexedObject = fails(function () {
	    return !Object('z').propertyIsEnumerable(0);
	}) ? function (it) {
	    return classofRaw(it) == 'String' ? split.call(it, '') : Object(it);
	} : Object;

	var requireObjectCoercible = function (it) {
	    if (it == undefined) 
	        { throw TypeError("Can't call method on " + it); }
	    return it;
	};

	var toIndexedObject = function (it) {
	    return indexedObject(requireObjectCoercible(it));
	};

	var isObject = function (it) {
	    return typeof it === 'object' ? it !== null : typeof it === 'function';
	};

	var toPrimitive = function (input, PREFERRED_STRING) {
	    if (!isObject(input)) 
	        { return input; }
	    var fn, val;
	    if (PREFERRED_STRING && typeof (fn = input.toString) == 'function' && !isObject(val = fn.call(input))) 
	        { return val; }
	    if (typeof (fn = input.valueOf) == 'function' && !isObject(val = fn.call(input))) 
	        { return val; }
	    if (!PREFERRED_STRING && typeof (fn = input.toString) == 'function' && !isObject(val = fn.call(input))) 
	        { return val; }
	    throw TypeError("Can't convert object to primitive value");
	};

	var hasOwnProperty = ({}).hasOwnProperty;
	var has = function (it, key) {
	    return hasOwnProperty.call(it, key);
	};

	var document$1 = global_1.document;
	var EXISTS = isObject(document$1) && isObject(document$1.createElement);
	var documentCreateElement = function (it) {
	    return EXISTS ? document$1.createElement(it) : {};
	};

	var ie8DomDefine = !descriptors && !fails(function () {
	    return Object.defineProperty(documentCreateElement('div'), 'a', {
	        get: function () {
	            return 7;
	        }
	    }).a != 7;
	});

	var nativeGetOwnPropertyDescriptor = Object.getOwnPropertyDescriptor;
	var f$1 = descriptors ? nativeGetOwnPropertyDescriptor : function getOwnPropertyDescriptor(O, P) {
	    O = toIndexedObject(O);
	    P = toPrimitive(P, true);
	    if (ie8DomDefine) {
	        try {
	            return nativeGetOwnPropertyDescriptor(O, P);
	        } catch (error) {}
	    }
	    if (has(O, P)) 
	        { return createPropertyDescriptor(!objectPropertyIsEnumerable.f.call(O, P), O[P]); }
	};
	var objectGetOwnPropertyDescriptor = {
	    f: f$1
	};

	var anObject = function (it) {
	    if (!isObject(it)) {
	        throw TypeError(String(it) + ' is not an object');
	    }
	    return it;
	};

	var nativeDefineProperty = Object.defineProperty;
	var f$2 = descriptors ? nativeDefineProperty : function defineProperty(O, P, Attributes) {
	    anObject(O);
	    P = toPrimitive(P, true);
	    anObject(Attributes);
	    if (ie8DomDefine) {
	        try {
	            return nativeDefineProperty(O, P, Attributes);
	        } catch (error) {}
	    }
	    if ('get' in Attributes || 'set' in Attributes) 
	        { throw TypeError('Accessors not supported'); }
	    if ('value' in Attributes) 
	        { O[P] = Attributes.value; }
	    return O;
	};
	var objectDefineProperty = {
	    f: f$2
	};

	var hide = descriptors ? function (object, key, value) {
	    return objectDefineProperty.f(object, key, createPropertyDescriptor(1, value));
	} : function (object, key, value) {
	    object[key] = value;
	    return object;
	};

	var setGlobal = function (key, value) {
	    try {
	        hide(global_1, key, value);
	    } catch (error) {
	        global_1[key] = value;
	    }
	    return value;
	};

	var isPure = false;

	var shared = createCommonjsModule(function (module) {
	    var SHARED = '__core-js_shared__';
	    var store = global_1[SHARED] || setGlobal(SHARED, {});
	    (module.exports = function (key, value) {
	        return store[key] || (store[key] = value !== undefined ? value : {});
	    })('versions', []).push({
	        version: '3.2.1',
	        mode:  'global',
	        copyright: '© 2019 Denis Pushkarev (zloirock.ru)'
	    });
	});

	var functionToString = shared('native-function-to-string', Function.toString);

	var WeakMap = global_1.WeakMap;
	var nativeWeakMap = typeof WeakMap === 'function' && /native code/.test(functionToString.call(WeakMap));

	var id = 0;
	var postfix = Math.random();
	var uid = function (key) {
	    return 'Symbol(' + String(key === undefined ? '' : key) + ')_' + (++id + postfix).toString(36);
	};

	var keys = shared('keys');
	var sharedKey = function (key) {
	    return keys[key] || (keys[key] = uid(key));
	};

	var hiddenKeys = {};

	var WeakMap$1 = global_1.WeakMap;
	var set, get, has$1;
	var enforce = function (it) {
	    return has$1(it) ? get(it) : set(it, {});
	};
	var getterFor = function (TYPE) {
	    return function (it) {
	        var state;
	        if (!isObject(it) || (state = get(it)).type !== TYPE) {
	            throw TypeError('Incompatible receiver, ' + TYPE + ' required');
	        }
	        return state;
	    };
	};
	if (nativeWeakMap) {
	    var store = new WeakMap$1();
	    var wmget = store.get;
	    var wmhas = store.has;
	    var wmset = store.set;
	    set = function (it, metadata) {
	        wmset.call(store, it, metadata);
	        return metadata;
	    };
	    get = function (it) {
	        return wmget.call(store, it) || {};
	    };
	    has$1 = function (it) {
	        return wmhas.call(store, it);
	    };
	} else {
	    var STATE = sharedKey('state');
	    hiddenKeys[STATE] = true;
	    set = function (it, metadata) {
	        hide(it, STATE, metadata);
	        return metadata;
	    };
	    get = function (it) {
	        return has(it, STATE) ? it[STATE] : {};
	    };
	    has$1 = function (it) {
	        return has(it, STATE);
	    };
	}
	var internalState = {
	    set: set,
	    get: get,
	    has: has$1,
	    enforce: enforce,
	    getterFor: getterFor
	};

	var redefine = createCommonjsModule(function (module) {
	    var getInternalState = internalState.get;
	    var enforceInternalState = internalState.enforce;
	    var TEMPLATE = String(functionToString).split('toString');
	    shared('inspectSource', function (it) {
	        return functionToString.call(it);
	    });
	    (module.exports = function (O, key, value, options) {
	        var unsafe = options ? !(!options.unsafe) : false;
	        var simple = options ? !(!options.enumerable) : false;
	        var noTargetGet = options ? !(!options.noTargetGet) : false;
	        if (typeof value == 'function') {
	            if (typeof key == 'string' && !has(value, 'name')) 
	                { hide(value, 'name', key); }
	            enforceInternalState(value).source = TEMPLATE.join(typeof key == 'string' ? key : '');
	        }
	        if (O === global_1) {
	            if (simple) 
	                { O[key] = value; }
	             else 
	                { setGlobal(key, value); }
	            return;
	        } else if (!unsafe) {
	            delete O[key];
	        } else if (!noTargetGet && O[key]) {
	            simple = true;
	        }
	        if (simple) 
	            { O[key] = value; }
	         else 
	            { hide(O, key, value); }
	    })(Function.prototype, 'toString', function toString() {
	        return typeof this == 'function' && getInternalState(this).source || functionToString.call(this);
	    });
	});

	var path = global_1;

	var aFunction = function (variable) {
	    return typeof variable == 'function' ? variable : undefined;
	};
	var getBuiltIn = function (namespace, method) {
	    return arguments.length < 2 ? aFunction(path[namespace]) || aFunction(global_1[namespace]) : path[namespace] && path[namespace][method] || global_1[namespace] && global_1[namespace][method];
	};

	var ceil = Math.ceil;
	var floor = Math.floor;
	var toInteger = function (argument) {
	    return isNaN(argument = +argument) ? 0 : (argument > 0 ? floor : ceil)(argument);
	};

	var min = Math.min;
	var toLength = function (argument) {
	    return argument > 0 ? min(toInteger(argument), 0x1FFFFFFFFFFFFF) : 0;
	};

	var max = Math.max;
	var min$1 = Math.min;
	var toAbsoluteIndex = function (index, length) {
	    var integer = toInteger(index);
	    return integer < 0 ? max(integer + length, 0) : min$1(integer, length);
	};

	var createMethod = function (IS_INCLUDES) {
	    return function ($this, el, fromIndex) {
	        var O = toIndexedObject($this);
	        var length = toLength(O.length);
	        var index = toAbsoluteIndex(fromIndex, length);
	        var value;
	        if (IS_INCLUDES && el != el) 
	            { while (length > index) {
	            value = O[index++];
	            if (value != value) 
	                { return true; }
	        } }
	         else 
	            { for (; length > index; index++) {
	            if ((IS_INCLUDES || index in O) && O[index] === el) 
	                { return IS_INCLUDES || index || 0; }
	        } }
	        return !IS_INCLUDES && -1;
	    };
	};
	var arrayIncludes = {
	    includes: createMethod(true),
	    indexOf: createMethod(false)
	};

	var indexOf = arrayIncludes.indexOf;
	var objectKeysInternal = function (object, names) {
	    var O = toIndexedObject(object);
	    var i = 0;
	    var result = [];
	    var key;
	    for (key in O) 
	        { !has(hiddenKeys, key) && has(O, key) && result.push(key); }
	    while (names.length > i) 
	        { if (has(O, key = names[i++])) {
	        ~indexOf(result, key) || result.push(key);
	    } }
	    return result;
	};

	var enumBugKeys = ['constructor','hasOwnProperty','isPrototypeOf','propertyIsEnumerable',
	    'toLocaleString','toString','valueOf'];

	var hiddenKeys$1 = enumBugKeys.concat('length', 'prototype');
	var f$3 = Object.getOwnPropertyNames || function getOwnPropertyNames(O) {
	    return objectKeysInternal(O, hiddenKeys$1);
	};
	var objectGetOwnPropertyNames = {
	    f: f$3
	};

	var f$4 = Object.getOwnPropertySymbols;
	var objectGetOwnPropertySymbols = {
	    f: f$4
	};

	var ownKeys = getBuiltIn('Reflect', 'ownKeys') || function ownKeys(it) {
	    var keys = objectGetOwnPropertyNames.f(anObject(it));
	    var getOwnPropertySymbols = objectGetOwnPropertySymbols.f;
	    return getOwnPropertySymbols ? keys.concat(getOwnPropertySymbols(it)) : keys;
	};

	var copyConstructorProperties = function (target, source) {
	    var keys = ownKeys(source);
	    var defineProperty = objectDefineProperty.f;
	    var getOwnPropertyDescriptor = objectGetOwnPropertyDescriptor.f;
	    for (var i = 0;i < keys.length; i++) {
	        var key = keys[i];
	        if (!has(target, key)) 
	            { defineProperty(target, key, getOwnPropertyDescriptor(source, key)); }
	    }
	};

	var replacement = /#|\.prototype\./;
	var isForced = function (feature, detection) {
	    var value = data[normalize(feature)];
	    return value == POLYFILL ? true : value == NATIVE ? false : typeof detection == 'function' ? fails(detection) : !(!detection);
	};
	var normalize = isForced.normalize = function (string) {
	    return String(string).replace(replacement, '.').toLowerCase();
	};
	var data = isForced.data = {};
	var NATIVE = isForced.NATIVE = 'N';
	var POLYFILL = isForced.POLYFILL = 'P';
	var isForced_1 = isForced;

	var getOwnPropertyDescriptor$1 = objectGetOwnPropertyDescriptor.f;
	var _export = function (options, source) {
	    var TARGET = options.target;
	    var GLOBAL = options.global;
	    var STATIC = options.stat;
	    var FORCED, target, key, targetProperty, sourceProperty, descriptor;
	    if (GLOBAL) {
	        target = global_1;
	    } else if (STATIC) {
	        target = global_1[TARGET] || setGlobal(TARGET, {});
	    } else {
	        target = (global_1[TARGET] || {}).prototype;
	    }
	    if (target) 
	        { for (key in source) {
	        sourceProperty = source[key];
	        if (options.noTargetGet) {
	            descriptor = getOwnPropertyDescriptor$1(target, key);
	            targetProperty = descriptor && descriptor.value;
	        } else 
	            { targetProperty = target[key]; }
	        FORCED = isForced_1(GLOBAL ? key : TARGET + (STATIC ? '.' : '#') + key, options.forced);
	        if (!FORCED && targetProperty !== undefined) {
	            if (typeof sourceProperty === typeof targetProperty) 
	                { continue; }
	            copyConstructorProperties(sourceProperty, targetProperty);
	        }
	        if (options.sham || targetProperty && targetProperty.sham) {
	            hide(sourceProperty, 'sham', true);
	        }
	        redefine(target, key, sourceProperty, options);
	    } }
	};

	var nativeSymbol = !(!Object.getOwnPropertySymbols) && !fails(function () {
	    return !String(Symbol());
	});

	var isArray = Array.isArray || function isArray(arg) {
	    return classofRaw(arg) == 'Array';
	};

	var toObject = function (argument) {
	    return Object(requireObjectCoercible(argument));
	};

	var objectKeys = Object.keys || function keys(O) {
	    return objectKeysInternal(O, enumBugKeys);
	};

	var objectDefineProperties = descriptors ? Object.defineProperties : function defineProperties(O, Properties) {
	    anObject(O);
	    var keys = objectKeys(Properties);
	    var length = keys.length;
	    var index = 0;
	    var key;
	    while (length > index) 
	        { objectDefineProperty.f(O, key = keys[index++], Properties[key]); }
	    return O;
	};

	var html = getBuiltIn('document', 'documentElement');

	var IE_PROTO = sharedKey('IE_PROTO');
	var PROTOTYPE = 'prototype';
	var Empty = function () {};
	var createDict = function () {
	    var iframe = documentCreateElement('iframe');
	    var length = enumBugKeys.length;
	    var lt = '<';
	    var script = 'script';
	    var gt = '>';
	    var js = 'java' + script + ':';
	    var iframeDocument;
	    iframe.style.display = 'none';
	    html.appendChild(iframe);
	    iframe.src = String(js);
	    iframeDocument = iframe.contentWindow.document;
	    iframeDocument.open();
	    iframeDocument.write(lt + script + gt + 'document.F=Object' + lt + '/' + script + gt);
	    iframeDocument.close();
	    createDict = iframeDocument.F;
	    while (length--) 
	        { delete createDict[PROTOTYPE][enumBugKeys[length]]; }
	    return createDict();
	};
	var objectCreate = Object.create || function create(O, Properties) {
	    var result;
	    if (O !== null) {
	        Empty[PROTOTYPE] = anObject(O);
	        result = new Empty();
	        Empty[PROTOTYPE] = null;
	        result[IE_PROTO] = O;
	    } else 
	        { result = createDict(); }
	    return Properties === undefined ? result : objectDefineProperties(result, Properties);
	};
	hiddenKeys[IE_PROTO] = true;

	var nativeGetOwnPropertyNames = objectGetOwnPropertyNames.f;
	var toString$1 = ({}).toString;
	var windowNames = typeof window == 'object' && window && Object.getOwnPropertyNames ? Object.getOwnPropertyNames(window) : [];
	var getWindowNames = function (it) {
	    try {
	        return nativeGetOwnPropertyNames(it);
	    } catch (error) {
	        return windowNames.slice();
	    }
	};
	var f$5 = function getOwnPropertyNames(it) {
	    return windowNames && toString$1.call(it) == '[object Window]' ? getWindowNames(it) : nativeGetOwnPropertyNames(toIndexedObject(it));
	};
	var objectGetOwnPropertyNamesExternal = {
	    f: f$5
	};

	var Symbol$1 = global_1.Symbol;
	var store$1 = shared('wks');
	var wellKnownSymbol = function (name) {
	    return store$1[name] || (store$1[name] = nativeSymbol && Symbol$1[name] || (nativeSymbol ? Symbol$1 : uid)('Symbol.' + name));
	};

	var f$6 = wellKnownSymbol;
	var wrappedWellKnownSymbol = {
	    f: f$6
	};

	var defineProperty = objectDefineProperty.f;
	var defineWellKnownSymbol = function (NAME) {
	    var Symbol = path.Symbol || (path.Symbol = {});
	    if (!has(Symbol, NAME)) 
	        { defineProperty(Symbol, NAME, {
	        value: wrappedWellKnownSymbol.f(NAME)
	    }); }
	};

	var defineProperty$1 = objectDefineProperty.f;
	var TO_STRING_TAG = wellKnownSymbol('toStringTag');
	var setToStringTag = function (it, TAG, STATIC) {
	    if (it && !has(it = STATIC ? it : it.prototype, TO_STRING_TAG)) {
	        defineProperty$1(it, TO_STRING_TAG, {
	            configurable: true,
	            value: TAG
	        });
	    }
	};

	var aFunction$1 = function (it) {
	    if (typeof it != 'function') {
	        throw TypeError(String(it) + ' is not a function');
	    }
	    return it;
	};

	var bindContext = function (fn, that, length) {
	    aFunction$1(fn);
	    if (that === undefined) 
	        { return fn; }
	    switch (length) {
	        case 0:
	            return function () {
	                return fn.call(that);
	            };
	        case 1:
	            return function (a) {
	                return fn.call(that, a);
	            };
	        case 2:
	            return function (a, b) {
	                return fn.call(that, a, b);
	            };
	        case 3:
	            return function (a, b, c) {
	                return fn.call(that, a, b, c);
	            };
	    }
	    return function () {
	        return fn.apply(that, arguments);
	    };
	};

	var SPECIES = wellKnownSymbol('species');
	var arraySpeciesCreate = function (originalArray, length) {
	    var C;
	    if (isArray(originalArray)) {
	        C = originalArray.constructor;
	        if (typeof C == 'function' && (C === Array || isArray(C.prototype))) 
	            { C = undefined; }
	         else if (isObject(C)) {
	            C = C[SPECIES];
	            if (C === null) 
	                { C = undefined; }
	        }
	    }
	    return new (C === undefined ? Array : C)(length === 0 ? 0 : length);
	};

	var push = [].push;
	var createMethod$1 = function (TYPE) {
	    var IS_MAP = TYPE == 1;
	    var IS_FILTER = TYPE == 2;
	    var IS_SOME = TYPE == 3;
	    var IS_EVERY = TYPE == 4;
	    var IS_FIND_INDEX = TYPE == 6;
	    var NO_HOLES = TYPE == 5 || IS_FIND_INDEX;
	    return function ($this, callbackfn, that, specificCreate) {
	        var O = toObject($this);
	        var self = indexedObject(O);
	        var boundFunction = bindContext(callbackfn, that, 3);
	        var length = toLength(self.length);
	        var index = 0;
	        var create = specificCreate || arraySpeciesCreate;
	        var target = IS_MAP ? create($this, length) : IS_FILTER ? create($this, 0) : undefined;
	        var value, result;
	        for (; length > index; index++) 
	            { if (NO_HOLES || index in self) {
	            value = self[index];
	            result = boundFunction(value, index, O);
	            if (TYPE) {
	                if (IS_MAP) 
	                    { target[index] = result; }
	                 else if (result) 
	                    { switch (TYPE) {
	                    case 3:
	                        return true;
	                    case 5:
	                        return value;
	                    case 6:
	                        return index;
	                    case 2:
	                        push.call(target, value);
	                } }
	                 else if (IS_EVERY) 
	                    { return false; }
	            }
	        } }
	        return IS_FIND_INDEX ? -1 : IS_SOME || IS_EVERY ? IS_EVERY : target;
	    };
	};
	var arrayIteration = {
	    forEach: createMethod$1(0),
	    map: createMethod$1(1),
	    filter: createMethod$1(2),
	    some: createMethod$1(3),
	    every: createMethod$1(4),
	    find: createMethod$1(5),
	    findIndex: createMethod$1(6)
	};

	var $forEach = arrayIteration.forEach;
	var HIDDEN = sharedKey('hidden');
	var SYMBOL = 'Symbol';
	var PROTOTYPE$1 = 'prototype';
	var TO_PRIMITIVE = wellKnownSymbol('toPrimitive');
	var setInternalState = internalState.set;
	var getInternalState = internalState.getterFor(SYMBOL);
	var ObjectPrototype = Object[PROTOTYPE$1];
	var $Symbol = global_1.Symbol;
	var JSON$1 = global_1.JSON;
	var nativeJSONStringify = JSON$1 && JSON$1.stringify;
	var nativeGetOwnPropertyDescriptor$1 = objectGetOwnPropertyDescriptor.f;
	var nativeDefineProperty$1 = objectDefineProperty.f;
	var nativeGetOwnPropertyNames$1 = objectGetOwnPropertyNamesExternal.f;
	var nativePropertyIsEnumerable$1 = objectPropertyIsEnumerable.f;
	var AllSymbols = shared('symbols');
	var ObjectPrototypeSymbols = shared('op-symbols');
	var StringToSymbolRegistry = shared('string-to-symbol-registry');
	var SymbolToStringRegistry = shared('symbol-to-string-registry');
	var WellKnownSymbolsStore = shared('wks');
	var QObject = global_1.QObject;
	var USE_SETTER = !QObject || !QObject[PROTOTYPE$1] || !QObject[PROTOTYPE$1].findChild;
	var setSymbolDescriptor = descriptors && fails(function () {
	    return objectCreate(nativeDefineProperty$1({}, 'a', {
	        get: function () {
	            return nativeDefineProperty$1(this, 'a', {
	                value: 7
	            }).a;
	        }
	    })).a != 7;
	}) ? function (O, P, Attributes) {
	    var ObjectPrototypeDescriptor = nativeGetOwnPropertyDescriptor$1(ObjectPrototype, P);
	    if (ObjectPrototypeDescriptor) 
	        { delete ObjectPrototype[P]; }
	    nativeDefineProperty$1(O, P, Attributes);
	    if (ObjectPrototypeDescriptor && O !== ObjectPrototype) {
	        nativeDefineProperty$1(ObjectPrototype, P, ObjectPrototypeDescriptor);
	    }
	} : nativeDefineProperty$1;
	var wrap = function (tag, description) {
	    var symbol = AllSymbols[tag] = objectCreate($Symbol[PROTOTYPE$1]);
	    setInternalState(symbol, {
	        type: SYMBOL,
	        tag: tag,
	        description: description
	    });
	    if (!descriptors) 
	        { symbol.description = description; }
	    return symbol;
	};
	var isSymbol = nativeSymbol && typeof $Symbol.iterator == 'symbol' ? function (it) {
	    return typeof it == 'symbol';
	} : function (it) {
	    return Object(it) instanceof $Symbol;
	};
	var $defineProperty = function defineProperty(O, P, Attributes) {
	    if (O === ObjectPrototype) 
	        { $defineProperty(ObjectPrototypeSymbols, P, Attributes); }
	    anObject(O);
	    var key = toPrimitive(P, true);
	    anObject(Attributes);
	    if (has(AllSymbols, key)) {
	        if (!Attributes.enumerable) {
	            if (!has(O, HIDDEN)) 
	                { nativeDefineProperty$1(O, HIDDEN, createPropertyDescriptor(1, {})); }
	            O[HIDDEN][key] = true;
	        } else {
	            if (has(O, HIDDEN) && O[HIDDEN][key]) 
	                { O[HIDDEN][key] = false; }
	            Attributes = objectCreate(Attributes, {
	                enumerable: createPropertyDescriptor(0, false)
	            });
	        }
	        return setSymbolDescriptor(O, key, Attributes);
	    }
	    return nativeDefineProperty$1(O, key, Attributes);
	};
	var $defineProperties = function defineProperties(O, Properties) {
	    anObject(O);
	    var properties = toIndexedObject(Properties);
	    var keys = objectKeys(properties).concat($getOwnPropertySymbols(properties));
	    $forEach(keys, function (key) {
	        if (!descriptors || $propertyIsEnumerable.call(properties, key)) 
	            { $defineProperty(O, key, properties[key]); }
	    });
	    return O;
	};
	var $create = function create(O, Properties) {
	    return Properties === undefined ? objectCreate(O) : $defineProperties(objectCreate(O), Properties);
	};
	var $propertyIsEnumerable = function propertyIsEnumerable(V) {
	    var P = toPrimitive(V, true);
	    var enumerable = nativePropertyIsEnumerable$1.call(this, P);
	    if (this === ObjectPrototype && has(AllSymbols, P) && !has(ObjectPrototypeSymbols, P)) 
	        { return false; }
	    return enumerable || !has(this, P) || !has(AllSymbols, P) || has(this, HIDDEN) && this[HIDDEN][P] ? enumerable : true;
	};
	var $getOwnPropertyDescriptor = function getOwnPropertyDescriptor(O, P) {
	    var it = toIndexedObject(O);
	    var key = toPrimitive(P, true);
	    if (it === ObjectPrototype && has(AllSymbols, key) && !has(ObjectPrototypeSymbols, key)) 
	        { return; }
	    var descriptor = nativeGetOwnPropertyDescriptor$1(it, key);
	    if (descriptor && has(AllSymbols, key) && !(has(it, HIDDEN) && it[HIDDEN][key])) {
	        descriptor.enumerable = true;
	    }
	    return descriptor;
	};
	var $getOwnPropertyNames = function getOwnPropertyNames(O) {
	    var names = nativeGetOwnPropertyNames$1(toIndexedObject(O));
	    var result = [];
	    $forEach(names, function (key) {
	        if (!has(AllSymbols, key) && !has(hiddenKeys, key)) 
	            { result.push(key); }
	    });
	    return result;
	};
	var $getOwnPropertySymbols = function getOwnPropertySymbols(O) {
	    var IS_OBJECT_PROTOTYPE = O === ObjectPrototype;
	    var names = nativeGetOwnPropertyNames$1(IS_OBJECT_PROTOTYPE ? ObjectPrototypeSymbols : toIndexedObject(O));
	    var result = [];
	    $forEach(names, function (key) {
	        if (has(AllSymbols, key) && (!IS_OBJECT_PROTOTYPE || has(ObjectPrototype, key))) {
	            result.push(AllSymbols[key]);
	        }
	    });
	    return result;
	};
	if (!nativeSymbol) {
	    $Symbol = function Symbol() {
	        if (this instanceof $Symbol) 
	            { throw TypeError('Symbol is not a constructor'); }
	        var description = !arguments.length || arguments[0] === undefined ? undefined : String(arguments[0]);
	        var tag = uid(description);
	        var setter = function (value) {
	            if (this === ObjectPrototype) 
	                { setter.call(ObjectPrototypeSymbols, value); }
	            if (has(this, HIDDEN) && has(this[HIDDEN], tag)) 
	                { this[HIDDEN][tag] = false; }
	            setSymbolDescriptor(this, tag, createPropertyDescriptor(1, value));
	        };
	        if (descriptors && USE_SETTER) 
	            { setSymbolDescriptor(ObjectPrototype, tag, {
	            configurable: true,
	            set: setter
	        }); }
	        return wrap(tag, description);
	    };
	    redefine($Symbol[PROTOTYPE$1], 'toString', function toString() {
	        return getInternalState(this).tag;
	    });
	    objectPropertyIsEnumerable.f = $propertyIsEnumerable;
	    objectDefineProperty.f = $defineProperty;
	    objectGetOwnPropertyDescriptor.f = $getOwnPropertyDescriptor;
	    objectGetOwnPropertyNames.f = (objectGetOwnPropertyNamesExternal.f = $getOwnPropertyNames);
	    objectGetOwnPropertySymbols.f = $getOwnPropertySymbols;
	    if (descriptors) {
	        nativeDefineProperty$1($Symbol[PROTOTYPE$1], 'description', {
	            configurable: true,
	            get: function description() {
	                return getInternalState(this).description;
	            }
	        });
	        {
	            redefine(ObjectPrototype, 'propertyIsEnumerable', $propertyIsEnumerable, {
	                unsafe: true
	            });
	        }
	    }
	    wrappedWellKnownSymbol.f = function (name) {
	        return wrap(wellKnownSymbol(name), name);
	    };
	}
	_export({
	    global: true,
	    wrap: true,
	    forced: !nativeSymbol,
	    sham: !nativeSymbol
	}, {
	    Symbol: $Symbol
	});
	$forEach(objectKeys(WellKnownSymbolsStore), function (name) {
	    defineWellKnownSymbol(name);
	});
	_export({
	    target: SYMBOL,
	    stat: true,
	    forced: !nativeSymbol
	}, {
	    'for': function (key) {
	        var string = String(key);
	        if (has(StringToSymbolRegistry, string)) 
	            { return StringToSymbolRegistry[string]; }
	        var symbol = $Symbol(string);
	        StringToSymbolRegistry[string] = symbol;
	        SymbolToStringRegistry[symbol] = string;
	        return symbol;
	    },
	    keyFor: function keyFor(sym) {
	        if (!isSymbol(sym)) 
	            { throw TypeError(sym + ' is not a symbol'); }
	        if (has(SymbolToStringRegistry, sym)) 
	            { return SymbolToStringRegistry[sym]; }
	    },
	    useSetter: function () {
	        USE_SETTER = true;
	    },
	    useSimple: function () {
	        USE_SETTER = false;
	    }
	});
	_export({
	    target: 'Object',
	    stat: true,
	    forced: !nativeSymbol,
	    sham: !descriptors
	}, {
	    create: $create,
	    defineProperty: $defineProperty,
	    defineProperties: $defineProperties,
	    getOwnPropertyDescriptor: $getOwnPropertyDescriptor
	});
	_export({
	    target: 'Object',
	    stat: true,
	    forced: !nativeSymbol
	}, {
	    getOwnPropertyNames: $getOwnPropertyNames,
	    getOwnPropertySymbols: $getOwnPropertySymbols
	});
	_export({
	    target: 'Object',
	    stat: true,
	    forced: fails(function () {
	        objectGetOwnPropertySymbols.f(1);
	    })
	}, {
	    getOwnPropertySymbols: function getOwnPropertySymbols(it) {
	        return objectGetOwnPropertySymbols.f(toObject(it));
	    }
	});
	JSON$1 && _export({
	    target: 'JSON',
	    stat: true,
	    forced: !nativeSymbol || fails(function () {
	        var symbol = $Symbol();
	        return nativeJSONStringify([symbol]) != '[null]' || nativeJSONStringify({
	            a: symbol
	        }) != '{}' || nativeJSONStringify(Object(symbol)) != '{}';
	    })
	}, {
	    stringify: function stringify(it) {
	        var arguments$1 = arguments;

	        var args = [it];
	        var index = 1;
	        var replacer, $replacer;
	        while (arguments.length > index) 
	            { args.push(arguments$1[index++]); }
	        $replacer = (replacer = args[1]);
	        if (!isObject(replacer) && it === undefined || isSymbol(it)) 
	            { return; }
	        if (!isArray(replacer)) 
	            { replacer = function (key, value) {
	            if (typeof $replacer == 'function') 
	                { value = $replacer.call(this, key, value); }
	            if (!isSymbol(value)) 
	                { return value; }
	        }; }
	        args[1] = replacer;
	        return nativeJSONStringify.apply(JSON$1, args);
	    }
	});
	if (!$Symbol[PROTOTYPE$1][TO_PRIMITIVE]) 
	    { hide($Symbol[PROTOTYPE$1], TO_PRIMITIVE, $Symbol[PROTOTYPE$1].valueOf); }
	setToStringTag($Symbol, SYMBOL);
	hiddenKeys[HIDDEN] = true;

	defineWellKnownSymbol('asyncIterator');

	var defineProperty$2 = objectDefineProperty.f;
	var NativeSymbol = global_1.Symbol;
	if (descriptors && typeof NativeSymbol == 'function' && (!('description' in NativeSymbol.prototype) || NativeSymbol().description !== undefined)) {
	    var EmptyStringDescriptionStore = {};
	    var SymbolWrapper = function Symbol() {
	        var description = arguments.length < 1 || arguments[0] === undefined ? undefined : String(arguments[0]);
	        var result = this instanceof SymbolWrapper ? new NativeSymbol(description) : description === undefined ? NativeSymbol() : NativeSymbol(description);
	        if (description === '') 
	            { EmptyStringDescriptionStore[result] = true; }
	        return result;
	    };
	    copyConstructorProperties(SymbolWrapper, NativeSymbol);
	    var symbolPrototype = SymbolWrapper.prototype = NativeSymbol.prototype;
	    symbolPrototype.constructor = SymbolWrapper;
	    var symbolToString = symbolPrototype.toString;
	    var native = String(NativeSymbol('test')) == 'Symbol(test)';
	    var regexp = /^Symbol\((.*)\)[^)]+$/;
	    defineProperty$2(symbolPrototype, 'description', {
	        configurable: true,
	        get: function description() {
	            var symbol = isObject(this) ? this.valueOf() : this;
	            var string = symbolToString.call(symbol);
	            if (has(EmptyStringDescriptionStore, symbol)) 
	                { return ''; }
	            var desc = native ? string.slice(7, -1) : string.replace(regexp, '$1');
	            return desc === '' ? undefined : desc;
	        }
	    });
	    _export({
	        global: true,
	        forced: true
	    }, {
	        Symbol: SymbolWrapper
	    });
	}

	defineWellKnownSymbol('hasInstance');

	defineWellKnownSymbol('isConcatSpreadable');

	defineWellKnownSymbol('iterator');

	defineWellKnownSymbol('match');

	defineWellKnownSymbol('matchAll');

	defineWellKnownSymbol('replace');

	defineWellKnownSymbol('search');

	defineWellKnownSymbol('species');

	defineWellKnownSymbol('split');

	defineWellKnownSymbol('toPrimitive');

	defineWellKnownSymbol('toStringTag');

	defineWellKnownSymbol('unscopables');

	var nativeAssign = Object.assign;
	var objectAssign = !nativeAssign || fails(function () {
	    var A = {};
	    var B = {};
	    var symbol = Symbol();
	    var alphabet = 'abcdefghijklmnopqrst';
	    A[symbol] = 7;
	    alphabet.split('').forEach(function (chr) {
	        B[chr] = chr;
	    });
	    return nativeAssign({}, A)[symbol] != 7 || objectKeys(nativeAssign({}, B)).join('') != alphabet;
	}) ? function assign(target, source) {
	    var arguments$1 = arguments;

	    var T = toObject(target);
	    var argumentsLength = arguments.length;
	    var index = 1;
	    var getOwnPropertySymbols = objectGetOwnPropertySymbols.f;
	    var propertyIsEnumerable = objectPropertyIsEnumerable.f;
	    while (argumentsLength > index) {
	        var S = indexedObject(arguments$1[index++]);
	        var keys = getOwnPropertySymbols ? objectKeys(S).concat(getOwnPropertySymbols(S)) : objectKeys(S);
	        var length = keys.length;
	        var j = 0;
	        var key;
	        while (length > j) {
	            key = keys[j++];
	            if (!descriptors || propertyIsEnumerable.call(S, key)) 
	                { T[key] = S[key]; }
	        }
	    }
	    return T;
	} : nativeAssign;

	_export({
	    target: 'Object',
	    stat: true,
	    forced: Object.assign !== objectAssign
	}, {
	    assign: objectAssign
	});

	_export({
	    target: 'Object',
	    stat: true,
	    sham: !descriptors
	}, {
	    create: objectCreate
	});

	_export({
	    target: 'Object',
	    stat: true,
	    forced: !descriptors,
	    sham: !descriptors
	}, {
	    defineProperty: objectDefineProperty.f
	});

	_export({
	    target: 'Object',
	    stat: true,
	    forced: !descriptors,
	    sham: !descriptors
	}, {
	    defineProperties: objectDefineProperties
	});

	var propertyIsEnumerable = objectPropertyIsEnumerable.f;
	var createMethod$2 = function (TO_ENTRIES) {
	    return function (it) {
	        var O = toIndexedObject(it);
	        var keys = objectKeys(O);
	        var length = keys.length;
	        var i = 0;
	        var result = [];
	        var key;
	        while (length > i) {
	            key = keys[i++];
	            if (!descriptors || propertyIsEnumerable.call(O, key)) {
	                result.push(TO_ENTRIES ? [key,O[key]] : O[key]);
	            }
	        }
	        return result;
	    };
	};
	var objectToArray = {
	    entries: createMethod$2(true),
	    values: createMethod$2(false)
	};

	var $entries = objectToArray.entries;
	_export({
	    target: 'Object',
	    stat: true
	}, {
	    entries: function entries(O) {
	        return $entries(O);
	    }
	});

	var freezing = !fails(function () {
	    return Object.isExtensible(Object.preventExtensions({}));
	});

	var internalMetadata = createCommonjsModule(function (module) {
	    var defineProperty = objectDefineProperty.f;
	    var METADATA = uid('meta');
	    var id = 0;
	    var isExtensible = Object.isExtensible || function () {
	        return true;
	    };
	    var setMetadata = function (it) {
	        defineProperty(it, METADATA, {
	            value: {
	                objectID: 'O' + ++id,
	                weakData: {}
	            }
	        });
	    };
	    var fastKey = function (it, create) {
	        if (!isObject(it)) 
	            { return typeof it == 'symbol' ? it : (typeof it == 'string' ? 'S' : 'P') + it; }
	        if (!has(it, METADATA)) {
	            if (!isExtensible(it)) 
	                { return 'F'; }
	            if (!create) 
	                { return 'E'; }
	            setMetadata(it);
	        }
	        return it[METADATA].objectID;
	    };
	    var getWeakData = function (it, create) {
	        if (!has(it, METADATA)) {
	            if (!isExtensible(it)) 
	                { return true; }
	            if (!create) 
	                { return false; }
	            setMetadata(it);
	        }
	        return it[METADATA].weakData;
	    };
	    var onFreeze = function (it) {
	        if (freezing && meta.REQUIRED && isExtensible(it) && !has(it, METADATA)) 
	            { setMetadata(it); }
	        return it;
	    };
	    var meta = module.exports = {
	        REQUIRED: false,
	        fastKey: fastKey,
	        getWeakData: getWeakData,
	        onFreeze: onFreeze
	    };
	    hiddenKeys[METADATA] = true;
	});
	var internalMetadata_1 = internalMetadata.REQUIRED;
	var internalMetadata_2 = internalMetadata.fastKey;
	var internalMetadata_3 = internalMetadata.getWeakData;
	var internalMetadata_4 = internalMetadata.onFreeze;

	var onFreeze = internalMetadata.onFreeze;
	var nativeFreeze = Object.freeze;
	var FAILS_ON_PRIMITIVES = fails(function () {
	    nativeFreeze(1);
	});
	_export({
	    target: 'Object',
	    stat: true,
	    forced: FAILS_ON_PRIMITIVES,
	    sham: !freezing
	}, {
	    freeze: function freeze(it) {
	        return nativeFreeze && isObject(it) ? nativeFreeze(onFreeze(it)) : it;
	    }
	});

	var iterators = {};

	var ITERATOR = wellKnownSymbol('iterator');
	var ArrayPrototype = Array.prototype;
	var isArrayIteratorMethod = function (it) {
	    return it !== undefined && (iterators.Array === it || ArrayPrototype[ITERATOR] === it);
	};

	var TO_STRING_TAG$1 = wellKnownSymbol('toStringTag');
	var CORRECT_ARGUMENTS = classofRaw((function () {
	    return arguments;
	})()) == 'Arguments';
	var tryGet = function (it, key) {
	    try {
	        return it[key];
	    } catch (error) {}
	};
	var classof = function (it) {
	    var O, tag, result;
	    return it === undefined ? 'Undefined' : it === null ? 'Null' : typeof (tag = tryGet(O = Object(it), TO_STRING_TAG$1)) == 'string' ? tag : CORRECT_ARGUMENTS ? classofRaw(O) : (result = classofRaw(O)) == 'Object' && typeof O.callee == 'function' ? 'Arguments' : result;
	};

	var ITERATOR$1 = wellKnownSymbol('iterator');
	var getIteratorMethod = function (it) {
	    if (it != undefined) 
	        { return it[ITERATOR$1] || it['@@iterator'] || iterators[classof(it)]; }
	};

	var callWithSafeIterationClosing = function (iterator, fn, value, ENTRIES) {
	    try {
	        return ENTRIES ? fn(anObject(value)[0], value[1]) : fn(value);
	    } catch (error) {
	        var returnMethod = iterator['return'];
	        if (returnMethod !== undefined) 
	            { anObject(returnMethod.call(iterator)); }
	        throw error;
	    }
	};

	var iterate_1 = createCommonjsModule(function (module) {
	    var Result = function (stopped, result) {
	        this.stopped = stopped;
	        this.result = result;
	    };
	    var iterate = module.exports = function (iterable, fn, that, AS_ENTRIES, IS_ITERATOR) {
	        var boundFunction = bindContext(fn, that, AS_ENTRIES ? 2 : 1);
	        var iterator, iterFn, index, length, result, step;
	        if (IS_ITERATOR) {
	            iterator = iterable;
	        } else {
	            iterFn = getIteratorMethod(iterable);
	            if (typeof iterFn != 'function') 
	                { throw TypeError('Target is not iterable'); }
	            if (isArrayIteratorMethod(iterFn)) {
	                for (index = 0, length = toLength(iterable.length); length > index; index++) {
	                    result = AS_ENTRIES ? boundFunction(anObject(step = iterable[index])[0], step[1]) : boundFunction(iterable[index]);
	                    if (result && result instanceof Result) 
	                        { return result; }
	                }
	                return new Result(false);
	            }
	            iterator = iterFn.call(iterable);
	        }
	        while (!(step = iterator.next()).done) {
	            result = callWithSafeIterationClosing(iterator, boundFunction, step.value, AS_ENTRIES);
	            if (result && result instanceof Result) 
	                { return result; }
	        }
	        return new Result(false);
	    };
	    iterate.stop = function (result) {
	        return new Result(true, result);
	    };
	});

	var createProperty = function (object, key, value) {
	    var propertyKey = toPrimitive(key);
	    if (propertyKey in object) 
	        { objectDefineProperty.f(object, propertyKey, createPropertyDescriptor(0, value)); }
	     else 
	        { object[propertyKey] = value; }
	};

	_export({
	    target: 'Object',
	    stat: true
	}, {
	    fromEntries: function fromEntries(iterable) {
	        var obj = {};
	        iterate_1(iterable, function (k, v) {
	            createProperty(obj, k, v);
	        }, undefined, true);
	        return obj;
	    }
	});

	var nativeGetOwnPropertyDescriptor$2 = objectGetOwnPropertyDescriptor.f;
	var FAILS_ON_PRIMITIVES$1 = fails(function () {
	    nativeGetOwnPropertyDescriptor$2(1);
	});
	var FORCED = !descriptors || FAILS_ON_PRIMITIVES$1;
	_export({
	    target: 'Object',
	    stat: true,
	    forced: FORCED,
	    sham: !descriptors
	}, {
	    getOwnPropertyDescriptor: function getOwnPropertyDescriptor(it, key) {
	        return nativeGetOwnPropertyDescriptor$2(toIndexedObject(it), key);
	    }
	});

	_export({
	    target: 'Object',
	    stat: true,
	    sham: !descriptors
	}, {
	    getOwnPropertyDescriptors: function getOwnPropertyDescriptors(object) {
	        var O = toIndexedObject(object);
	        var getOwnPropertyDescriptor = objectGetOwnPropertyDescriptor.f;
	        var keys = ownKeys(O);
	        var result = {};
	        var index = 0;
	        var key, descriptor;
	        while (keys.length > index) {
	            descriptor = getOwnPropertyDescriptor(O, key = keys[index++]);
	            if (descriptor !== undefined) 
	                { createProperty(result, key, descriptor); }
	        }
	        return result;
	    }
	});

	var nativeGetOwnPropertyNames$2 = objectGetOwnPropertyNamesExternal.f;
	var FAILS_ON_PRIMITIVES$2 = fails(function () {
	    return !Object.getOwnPropertyNames(1);
	});
	_export({
	    target: 'Object',
	    stat: true,
	    forced: FAILS_ON_PRIMITIVES$2
	}, {
	    getOwnPropertyNames: nativeGetOwnPropertyNames$2
	});

	var correctPrototypeGetter = !fails(function () {
	    function F() {}
	    
	    F.prototype.constructor = null;
	    return Object.getPrototypeOf(new F()) !== F.prototype;
	});

	var IE_PROTO$1 = sharedKey('IE_PROTO');
	var ObjectPrototype$1 = Object.prototype;
	var objectGetPrototypeOf = correctPrototypeGetter ? Object.getPrototypeOf : function (O) {
	    O = toObject(O);
	    if (has(O, IE_PROTO$1)) 
	        { return O[IE_PROTO$1]; }
	    if (typeof O.constructor == 'function' && O instanceof O.constructor) {
	        return O.constructor.prototype;
	    }
	    return O instanceof Object ? ObjectPrototype$1 : null;
	};

	var FAILS_ON_PRIMITIVES$3 = fails(function () {
	    objectGetPrototypeOf(1);
	});
	_export({
	    target: 'Object',
	    stat: true,
	    forced: FAILS_ON_PRIMITIVES$3,
	    sham: !correctPrototypeGetter
	}, {
	    getPrototypeOf: function getPrototypeOf(it) {
	        return objectGetPrototypeOf(toObject(it));
	    }
	});

	var sameValue = Object.is || function is(x, y) {
	    return x === y ? x !== 0 || 1 / x === 1 / y : x != x && y != y;
	};

	_export({
	    target: 'Object',
	    stat: true
	}, {
	    is: sameValue
	});

	var nativeIsExtensible = Object.isExtensible;
	var FAILS_ON_PRIMITIVES$4 = fails(function () {
	    nativeIsExtensible(1);
	});
	_export({
	    target: 'Object',
	    stat: true,
	    forced: FAILS_ON_PRIMITIVES$4
	}, {
	    isExtensible: function isExtensible(it) {
	        return isObject(it) ? nativeIsExtensible ? nativeIsExtensible(it) : true : false;
	    }
	});

	var nativeIsFrozen = Object.isFrozen;
	var FAILS_ON_PRIMITIVES$5 = fails(function () {
	    nativeIsFrozen(1);
	});
	_export({
	    target: 'Object',
	    stat: true,
	    forced: FAILS_ON_PRIMITIVES$5
	}, {
	    isFrozen: function isFrozen(it) {
	        return isObject(it) ? nativeIsFrozen ? nativeIsFrozen(it) : false : true;
	    }
	});

	var nativeIsSealed = Object.isSealed;
	var FAILS_ON_PRIMITIVES$6 = fails(function () {
	    nativeIsSealed(1);
	});
	_export({
	    target: 'Object',
	    stat: true,
	    forced: FAILS_ON_PRIMITIVES$6
	}, {
	    isSealed: function isSealed(it) {
	        return isObject(it) ? nativeIsSealed ? nativeIsSealed(it) : false : true;
	    }
	});

	var FAILS_ON_PRIMITIVES$7 = fails(function () {
	    objectKeys(1);
	});
	_export({
	    target: 'Object',
	    stat: true,
	    forced: FAILS_ON_PRIMITIVES$7
	}, {
	    keys: function keys(it) {
	        return objectKeys(toObject(it));
	    }
	});

	var onFreeze$1 = internalMetadata.onFreeze;
	var nativePreventExtensions = Object.preventExtensions;
	var FAILS_ON_PRIMITIVES$8 = fails(function () {
	    nativePreventExtensions(1);
	});
	_export({
	    target: 'Object',
	    stat: true,
	    forced: FAILS_ON_PRIMITIVES$8,
	    sham: !freezing
	}, {
	    preventExtensions: function preventExtensions(it) {
	        return nativePreventExtensions && isObject(it) ? nativePreventExtensions(onFreeze$1(it)) : it;
	    }
	});

	var onFreeze$2 = internalMetadata.onFreeze;
	var nativeSeal = Object.seal;
	var FAILS_ON_PRIMITIVES$9 = fails(function () {
	    nativeSeal(1);
	});
	_export({
	    target: 'Object',
	    stat: true,
	    forced: FAILS_ON_PRIMITIVES$9,
	    sham: !freezing
	}, {
	    seal: function seal(it) {
	        return nativeSeal && isObject(it) ? nativeSeal(onFreeze$2(it)) : it;
	    }
	});

	var aPossiblePrototype = function (it) {
	    if (!isObject(it) && it !== null) {
	        throw TypeError("Can't set " + String(it) + ' as a prototype');
	    }
	    return it;
	};

	var objectSetPrototypeOf = Object.setPrototypeOf || ('__proto__' in {} ? (function () {
	    var CORRECT_SETTER = false;
	    var test = {};
	    var setter;
	    try {
	        setter = Object.getOwnPropertyDescriptor(Object.prototype, '__proto__').set;
	        setter.call(test, []);
	        CORRECT_SETTER = test instanceof Array;
	    } catch (error) {}
	    return function setPrototypeOf(O, proto) {
	        anObject(O);
	        aPossiblePrototype(proto);
	        if (CORRECT_SETTER) 
	            { setter.call(O, proto); }
	         else 
	            { O.__proto__ = proto; }
	        return O;
	    };
	})() : undefined);

	_export({
	    target: 'Object',
	    stat: true
	}, {
	    setPrototypeOf: objectSetPrototypeOf
	});

	var $values = objectToArray.values;
	_export({
	    target: 'Object',
	    stat: true
	}, {
	    values: function values(O) {
	        return $values(O);
	    }
	});

	var TO_STRING_TAG$2 = wellKnownSymbol('toStringTag');
	var test = {};
	test[TO_STRING_TAG$2] = 'z';
	var objectToString = String(test) !== '[object z]' ? function toString() {
	    return '[object ' + classof(this) + ']';
	} : test.toString;

	var ObjectPrototype$2 = Object.prototype;
	if (objectToString !== ObjectPrototype$2.toString) {
	    redefine(ObjectPrototype$2, 'toString', objectToString, {
	        unsafe: true
	    });
	}

	var forcedObjectPrototypeAccessorsMethods =  !fails(function () {
	    var key = Math.random();
	    __defineSetter__.call(null, key, function () {});
	    delete global_1[key];
	});

	if (descriptors) {
	    _export({
	        target: 'Object',
	        proto: true,
	        forced: forcedObjectPrototypeAccessorsMethods
	    }, {
	        __defineGetter__: function __defineGetter__(P, getter) {
	            objectDefineProperty.f(toObject(this), P, {
	                get: aFunction$1(getter),
	                enumerable: true,
	                configurable: true
	            });
	        }
	    });
	}

	if (descriptors) {
	    _export({
	        target: 'Object',
	        proto: true,
	        forced: forcedObjectPrototypeAccessorsMethods
	    }, {
	        __defineSetter__: function __defineSetter__(P, setter) {
	            objectDefineProperty.f(toObject(this), P, {
	                set: aFunction$1(setter),
	                enumerable: true,
	                configurable: true
	            });
	        }
	    });
	}

	var getOwnPropertyDescriptor$2 = objectGetOwnPropertyDescriptor.f;
	if (descriptors) {
	    _export({
	        target: 'Object',
	        proto: true,
	        forced: forcedObjectPrototypeAccessorsMethods
	    }, {
	        __lookupGetter__: function __lookupGetter__(P) {
	            var O = toObject(this);
	            var key = toPrimitive(P, true);
	            var desc;
	            do {
	                if (desc = getOwnPropertyDescriptor$2(O, key)) 
	                    { return desc.get; }
	            } while (O = objectGetPrototypeOf(O));
	        }
	    });
	}

	var getOwnPropertyDescriptor$3 = objectGetOwnPropertyDescriptor.f;
	if (descriptors) {
	    _export({
	        target: 'Object',
	        proto: true,
	        forced: forcedObjectPrototypeAccessorsMethods
	    }, {
	        __lookupSetter__: function __lookupSetter__(P) {
	            var O = toObject(this);
	            var key = toPrimitive(P, true);
	            var desc;
	            do {
	                if (desc = getOwnPropertyDescriptor$3(O, key)) 
	                    { return desc.set; }
	            } while (O = objectGetPrototypeOf(O));
	        }
	    });
	}

	var slice = [].slice;
	var factories = {};
	var construct = function (C, argsLength, args) {
	    if (!(argsLength in factories)) {
	        for (var list = [], i = 0;i < argsLength; i++) 
	            { list[i] = 'a[' + i + ']'; }
	        factories[argsLength] = Function('C,a', 'return new C(' + list.join(',') + ')');
	    }
	    return factories[argsLength](C, args);
	};
	var functionBind = Function.bind || function bind(that) {
	    var fn = aFunction$1(this);
	    var partArgs = slice.call(arguments, 1);
	    var boundFunction = function bound() {
	        var args = partArgs.concat(slice.call(arguments));
	        return this instanceof boundFunction ? construct(fn, args.length, args) : fn.apply(that, args);
	    };
	    if (isObject(fn.prototype)) 
	        { boundFunction.prototype = fn.prototype; }
	    return boundFunction;
	};

	_export({
	    target: 'Function',
	    proto: true
	}, {
	    bind: functionBind
	});

	var defineProperty$3 = objectDefineProperty.f;
	var FunctionPrototype = Function.prototype;
	var FunctionPrototypeToString = FunctionPrototype.toString;
	var nameRE = /^\s*function ([^ (]*)/;
	var NAME = 'name';
	if (descriptors && !(NAME in FunctionPrototype)) {
	    defineProperty$3(FunctionPrototype, NAME, {
	        configurable: true,
	        get: function () {
	            try {
	                return FunctionPrototypeToString.call(this).match(nameRE)[1];
	            } catch (error) {
	                return '';
	            }
	        }
	    });
	}

	var HAS_INSTANCE = wellKnownSymbol('hasInstance');
	var FunctionPrototype$1 = Function.prototype;
	if (!(HAS_INSTANCE in FunctionPrototype$1)) {
	    objectDefineProperty.f(FunctionPrototype$1, HAS_INSTANCE, {
	        value: function (O) {
	            if (typeof this != 'function' || !isObject(O)) 
	                { return false; }
	            if (!isObject(this.prototype)) 
	                { return O instanceof this; }
	            while (O = objectGetPrototypeOf(O)) 
	                { if (this.prototype === O) 
	                { return true; } }
	            return false;
	        }
	    });
	}

	var arrayFrom = function from(arrayLike) {
	    var O = toObject(arrayLike);
	    var C = typeof this == 'function' ? this : Array;
	    var argumentsLength = arguments.length;
	    var mapfn = argumentsLength > 1 ? arguments[1] : undefined;
	    var mapping = mapfn !== undefined;
	    var index = 0;
	    var iteratorMethod = getIteratorMethod(O);
	    var length, result, step, iterator;
	    if (mapping) 
	        { mapfn = bindContext(mapfn, argumentsLength > 2 ? arguments[2] : undefined, 2); }
	    if (iteratorMethod != undefined && !(C == Array && isArrayIteratorMethod(iteratorMethod))) {
	        iterator = iteratorMethod.call(O);
	        result = new C();
	        for (; !(step = iterator.next()).done; index++) {
	            createProperty(result, index, mapping ? callWithSafeIterationClosing(iterator, mapfn, [step.value,
	                index], true) : step.value);
	        }
	    } else {
	        length = toLength(O.length);
	        result = new C(length);
	        for (; length > index; index++) {
	            createProperty(result, index, mapping ? mapfn(O[index], index) : O[index]);
	        }
	    }
	    result.length = index;
	    return result;
	};

	var ITERATOR$2 = wellKnownSymbol('iterator');
	var SAFE_CLOSING = false;
	try {
	    var called = 0;
	    var iteratorWithReturn = {
	        next: function () {
	            return {
	                done: !(!called++)
	            };
	        },
	        'return': function () {
	            SAFE_CLOSING = true;
	        }
	    };
	    iteratorWithReturn[ITERATOR$2] = function () {
	        return this;
	    };
	    Array.from(iteratorWithReturn, function () {
	        throw 2;
	    });
	} catch (error) {}
	var checkCorrectnessOfIteration = function (exec, SKIP_CLOSING) {
	    if (!SKIP_CLOSING && !SAFE_CLOSING) 
	        { return false; }
	    var ITERATION_SUPPORT = false;
	    try {
	        var object = {};
	        object[ITERATOR$2] = function () {
	            return {
	                next: function () {
	                    return {
	                        done: ITERATION_SUPPORT = true
	                    };
	                }
	            };
	        };
	        exec(object);
	    } catch (error) {}
	    return ITERATION_SUPPORT;
	};

	var INCORRECT_ITERATION = !checkCorrectnessOfIteration(function (iterable) {
	    Array.from(iterable);
	});
	_export({
	    target: 'Array',
	    stat: true,
	    forced: INCORRECT_ITERATION
	}, {
	    from: arrayFrom
	});

	_export({
	    target: 'Array',
	    stat: true
	}, {
	    isArray: isArray
	});

	var ISNT_GENERIC = fails(function () {
	    function F() {}
	    
	    return !(Array.of.call(F) instanceof F);
	});
	_export({
	    target: 'Array',
	    stat: true,
	    forced: ISNT_GENERIC
	}, {
	    of: function of() {
	        var arguments$1 = arguments;

	        var index = 0;
	        var argumentsLength = arguments.length;
	        var result = new (typeof this == 'function' ? this : Array)(argumentsLength);
	        while (argumentsLength > index) 
	            { createProperty(result, index, arguments$1[index++]); }
	        result.length = argumentsLength;
	        return result;
	    }
	});

	var SPECIES$1 = wellKnownSymbol('species');
	var arrayMethodHasSpeciesSupport = function (METHOD_NAME) {
	    return !fails(function () {
	        var array = [];
	        var constructor = array.constructor = {};
	        constructor[SPECIES$1] = function () {
	            return {
	                foo: 1
	            };
	        };
	        return array[METHOD_NAME](Boolean).foo !== 1;
	    });
	};

	var IS_CONCAT_SPREADABLE = wellKnownSymbol('isConcatSpreadable');
	var MAX_SAFE_INTEGER = 0x1FFFFFFFFFFFFF;
	var MAXIMUM_ALLOWED_INDEX_EXCEEDED = 'Maximum allowed index exceeded';
	var IS_CONCAT_SPREADABLE_SUPPORT = !fails(function () {
	    var array = [];
	    array[IS_CONCAT_SPREADABLE] = false;
	    return array.concat()[0] !== array;
	});
	var SPECIES_SUPPORT = arrayMethodHasSpeciesSupport('concat');
	var isConcatSpreadable = function (O) {
	    if (!isObject(O)) 
	        { return false; }
	    var spreadable = O[IS_CONCAT_SPREADABLE];
	    return spreadable !== undefined ? !(!spreadable) : isArray(O);
	};
	var FORCED$1 = !IS_CONCAT_SPREADABLE_SUPPORT || !SPECIES_SUPPORT;
	_export({
	    target: 'Array',
	    proto: true,
	    forced: FORCED$1
	}, {
	    concat: function concat(arg) {
	        var arguments$1 = arguments;

	        var O = toObject(this);
	        var A = arraySpeciesCreate(O, 0);
	        var n = 0;
	        var i, k, length, len, E;
	        for (i = -1, length = arguments.length; i < length; i++) {
	            E = i === -1 ? O : arguments$1[i];
	            if (isConcatSpreadable(E)) {
	                len = toLength(E.length);
	                if (n + len > MAX_SAFE_INTEGER) 
	                    { throw TypeError(MAXIMUM_ALLOWED_INDEX_EXCEEDED); }
	                for (k = 0; k < len; k++, n++) 
	                    { if (k in E) 
	                    { createProperty(A, n, E[k]); } }
	            } else {
	                if (n >= MAX_SAFE_INTEGER) 
	                    { throw TypeError(MAXIMUM_ALLOWED_INDEX_EXCEEDED); }
	                createProperty(A, n++, E);
	            }
	        }
	        A.length = n;
	        return A;
	    }
	});

	var min$2 = Math.min;
	var arrayCopyWithin = [].copyWithin || function copyWithin(target, start) {
	    var O = toObject(this);
	    var len = toLength(O.length);
	    var to = toAbsoluteIndex(target, len);
	    var from = toAbsoluteIndex(start, len);
	    var end = arguments.length > 2 ? arguments[2] : undefined;
	    var count = min$2((end === undefined ? len : toAbsoluteIndex(end, len)) - from, len - to);
	    var inc = 1;
	    if (from < to && to < from + count) {
	        inc = -1;
	        from += count - 1;
	        to += count - 1;
	    }
	    while (count-- > 0) {
	        if (from in O) 
	            { O[to] = O[from]; }
	         else 
	            { delete O[to]; }
	        to += inc;
	        from += inc;
	    }
	    return O;
	};

	var UNSCOPABLES = wellKnownSymbol('unscopables');
	var ArrayPrototype$1 = Array.prototype;
	if (ArrayPrototype$1[UNSCOPABLES] == undefined) {
	    hide(ArrayPrototype$1, UNSCOPABLES, objectCreate(null));
	}
	var addToUnscopables = function (key) {
	    ArrayPrototype$1[UNSCOPABLES][key] = true;
	};

	_export({
	    target: 'Array',
	    proto: true
	}, {
	    copyWithin: arrayCopyWithin
	});
	addToUnscopables('copyWithin');

	var sloppyArrayMethod = function (METHOD_NAME, argument) {
	    var method = [][METHOD_NAME];
	    return !method || !fails(function () {
	        method.call(null, argument || function () {
	            throw 1;
	        }, 1);
	    });
	};

	var $every = arrayIteration.every;
	_export({
	    target: 'Array',
	    proto: true,
	    forced: sloppyArrayMethod('every')
	}, {
	    every: function every(callbackfn) {
	        return $every(this, callbackfn, arguments.length > 1 ? arguments[1] : undefined);
	    }
	});

	var arrayFill = function fill(value) {
	    var O = toObject(this);
	    var length = toLength(O.length);
	    var argumentsLength = arguments.length;
	    var index = toAbsoluteIndex(argumentsLength > 1 ? arguments[1] : undefined, length);
	    var end = argumentsLength > 2 ? arguments[2] : undefined;
	    var endPos = end === undefined ? length : toAbsoluteIndex(end, length);
	    while (endPos > index) 
	        { O[index++] = value; }
	    return O;
	};

	_export({
	    target: 'Array',
	    proto: true
	}, {
	    fill: arrayFill
	});
	addToUnscopables('fill');

	var $filter = arrayIteration.filter;
	_export({
	    target: 'Array',
	    proto: true,
	    forced: !arrayMethodHasSpeciesSupport('filter')
	}, {
	    filter: function filter(callbackfn) {
	        return $filter(this, callbackfn, arguments.length > 1 ? arguments[1] : undefined);
	    }
	});

	var $find = arrayIteration.find;
	var FIND = 'find';
	var SKIPS_HOLES = true;
	if (FIND in []) 
	    { Array(1)[FIND](function () {
	    SKIPS_HOLES = false;
	}); }
	_export({
	    target: 'Array',
	    proto: true,
	    forced: SKIPS_HOLES
	}, {
	    find: function find(callbackfn) {
	        return $find(this, callbackfn, arguments.length > 1 ? arguments[1] : undefined);
	    }
	});
	addToUnscopables(FIND);

	var $findIndex = arrayIteration.findIndex;
	var FIND_INDEX = 'findIndex';
	var SKIPS_HOLES$1 = true;
	if (FIND_INDEX in []) 
	    { Array(1)[FIND_INDEX](function () {
	    SKIPS_HOLES$1 = false;
	}); }
	_export({
	    target: 'Array',
	    proto: true,
	    forced: SKIPS_HOLES$1
	}, {
	    findIndex: function findIndex(callbackfn) {
	        return $findIndex(this, callbackfn, arguments.length > 1 ? arguments[1] : undefined);
	    }
	});
	addToUnscopables(FIND_INDEX);

	var flattenIntoArray = function (target, original, source, sourceLen, start, depth, mapper, thisArg) {
	    var targetIndex = start;
	    var sourceIndex = 0;
	    var mapFn = mapper ? bindContext(mapper, thisArg, 3) : false;
	    var element;
	    while (sourceIndex < sourceLen) {
	        if (sourceIndex in source) {
	            element = mapFn ? mapFn(source[sourceIndex], sourceIndex, original) : source[sourceIndex];
	            if (depth > 0 && isArray(element)) {
	                targetIndex = flattenIntoArray(target, original, element, toLength(element.length), targetIndex, depth - 1) - 1;
	            } else {
	                if (targetIndex >= 0x1FFFFFFFFFFFFF) 
	                    { throw TypeError('Exceed the acceptable array length'); }
	                target[targetIndex] = element;
	            }
	            targetIndex++;
	        }
	        sourceIndex++;
	    }
	    return targetIndex;
	};
	var flattenIntoArray_1 = flattenIntoArray;

	_export({
	    target: 'Array',
	    proto: true
	}, {
	    flat: function flat() {
	        var depthArg = arguments.length ? arguments[0] : undefined;
	        var O = toObject(this);
	        var sourceLen = toLength(O.length);
	        var A = arraySpeciesCreate(O, 0);
	        A.length = flattenIntoArray_1(A, O, O, sourceLen, 0, depthArg === undefined ? 1 : toInteger(depthArg));
	        return A;
	    }
	});

	_export({
	    target: 'Array',
	    proto: true
	}, {
	    flatMap: function flatMap(callbackfn) {
	        var O = toObject(this);
	        var sourceLen = toLength(O.length);
	        var A;
	        aFunction$1(callbackfn);
	        A = arraySpeciesCreate(O, 0);
	        A.length = flattenIntoArray_1(A, O, O, sourceLen, 0, 1, callbackfn, arguments.length > 1 ? arguments[1] : undefined);
	        return A;
	    }
	});

	var $forEach$1 = arrayIteration.forEach;
	var arrayForEach = sloppyArrayMethod('forEach') ? function forEach(callbackfn) {
	    return $forEach$1(this, callbackfn, arguments.length > 1 ? arguments[1] : undefined);
	} : [].forEach;

	_export({
	    target: 'Array',
	    proto: true,
	    forced: [].forEach != arrayForEach
	}, {
	    forEach: arrayForEach
	});

	var $includes = arrayIncludes.includes;
	_export({
	    target: 'Array',
	    proto: true
	}, {
	    includes: function includes(el) {
	        return $includes(this, el, arguments.length > 1 ? arguments[1] : undefined);
	    }
	});
	addToUnscopables('includes');

	var $indexOf = arrayIncludes.indexOf;
	var nativeIndexOf = [].indexOf;
	var NEGATIVE_ZERO = !(!nativeIndexOf) && 1 / [1].indexOf(1, -0) < 0;
	var SLOPPY_METHOD = sloppyArrayMethod('indexOf');
	_export({
	    target: 'Array',
	    proto: true,
	    forced: NEGATIVE_ZERO || SLOPPY_METHOD
	}, {
	    indexOf: function indexOf(searchElement) {
	        return NEGATIVE_ZERO ? nativeIndexOf.apply(this, arguments) || 0 : $indexOf(this, searchElement, arguments.length > 1 ? arguments[1] : undefined);
	    }
	});

	var nativeJoin = [].join;
	var ES3_STRINGS = indexedObject != Object;
	var SLOPPY_METHOD$1 = sloppyArrayMethod('join', ',');
	_export({
	    target: 'Array',
	    proto: true,
	    forced: ES3_STRINGS || SLOPPY_METHOD$1
	}, {
	    join: function join(separator) {
	        return nativeJoin.call(toIndexedObject(this), separator === undefined ? ',' : separator);
	    }
	});

	var min$3 = Math.min;
	var nativeLastIndexOf = [].lastIndexOf;
	var NEGATIVE_ZERO$1 = !(!nativeLastIndexOf) && 1 / [1].lastIndexOf(1, -0) < 0;
	var SLOPPY_METHOD$2 = sloppyArrayMethod('lastIndexOf');
	var arrayLastIndexOf = NEGATIVE_ZERO$1 || SLOPPY_METHOD$2 ? function lastIndexOf(searchElement) {
	    if (NEGATIVE_ZERO$1) 
	        { return nativeLastIndexOf.apply(this, arguments) || 0; }
	    var O = toIndexedObject(this);
	    var length = toLength(O.length);
	    var index = length - 1;
	    if (arguments.length > 1) 
	        { index = min$3(index, toInteger(arguments[1])); }
	    if (index < 0) 
	        { index = length + index; }
	    for (; index >= 0; index--) 
	        { if (index in O && O[index] === searchElement) 
	        { return index || 0; } }
	    return -1;
	} : nativeLastIndexOf;

	_export({
	    target: 'Array',
	    proto: true,
	    forced: arrayLastIndexOf !== [].lastIndexOf
	}, {
	    lastIndexOf: arrayLastIndexOf
	});

	var $map = arrayIteration.map;
	_export({
	    target: 'Array',
	    proto: true,
	    forced: !arrayMethodHasSpeciesSupport('map')
	}, {
	    map: function map(callbackfn) {
	        return $map(this, callbackfn, arguments.length > 1 ? arguments[1] : undefined);
	    }
	});

	var createMethod$3 = function (IS_RIGHT) {
	    return function (that, callbackfn, argumentsLength, memo) {
	        aFunction$1(callbackfn);
	        var O = toObject(that);
	        var self = indexedObject(O);
	        var length = toLength(O.length);
	        var index = IS_RIGHT ? length - 1 : 0;
	        var i = IS_RIGHT ? -1 : 1;
	        if (argumentsLength < 2) 
	            { while (true) {
	            if (index in self) {
	                memo = self[index];
	                index += i;
	                break;
	            }
	            index += i;
	            if (IS_RIGHT ? index < 0 : length <= index) {
	                throw TypeError('Reduce of empty array with no initial value');
	            }
	        } }
	        for (; IS_RIGHT ? index >= 0 : length > index; index += i) 
	            { if (index in self) {
	            memo = callbackfn(memo, self[index], index, O);
	        } }
	        return memo;
	    };
	};
	var arrayReduce = {
	    left: createMethod$3(false),
	    right: createMethod$3(true)
	};

	var $reduce = arrayReduce.left;
	_export({
	    target: 'Array',
	    proto: true,
	    forced: sloppyArrayMethod('reduce')
	}, {
	    reduce: function reduce(callbackfn) {
	        return $reduce(this, callbackfn, arguments.length, arguments.length > 1 ? arguments[1] : undefined);
	    }
	});

	var $reduceRight = arrayReduce.right;
	_export({
	    target: 'Array',
	    proto: true,
	    forced: sloppyArrayMethod('reduceRight')
	}, {
	    reduceRight: function reduceRight(callbackfn) {
	        return $reduceRight(this, callbackfn, arguments.length, arguments.length > 1 ? arguments[1] : undefined);
	    }
	});

	var nativeReverse = [].reverse;
	var test$1 = [1,2];
	_export({
	    target: 'Array',
	    proto: true,
	    forced: String(test$1) === String(test$1.reverse())
	}, {
	    reverse: function reverse() {
	        if (isArray(this)) 
	            { this.length = this.length; }
	        return nativeReverse.call(this);
	    }
	});

	var SPECIES$2 = wellKnownSymbol('species');
	var nativeSlice = [].slice;
	var max$1 = Math.max;
	_export({
	    target: 'Array',
	    proto: true,
	    forced: !arrayMethodHasSpeciesSupport('slice')
	}, {
	    slice: function slice(start, end) {
	        var O = toIndexedObject(this);
	        var length = toLength(O.length);
	        var k = toAbsoluteIndex(start, length);
	        var fin = toAbsoluteIndex(end === undefined ? length : end, length);
	        var Constructor, result, n;
	        if (isArray(O)) {
	            Constructor = O.constructor;
	            if (typeof Constructor == 'function' && (Constructor === Array || isArray(Constructor.prototype))) {
	                Constructor = undefined;
	            } else if (isObject(Constructor)) {
	                Constructor = Constructor[SPECIES$2];
	                if (Constructor === null) 
	                    { Constructor = undefined; }
	            }
	            if (Constructor === Array || Constructor === undefined) {
	                return nativeSlice.call(O, k, fin);
	            }
	        }
	        result = new (Constructor === undefined ? Array : Constructor)(max$1(fin - k, 0));
	        for (n = 0; k < fin; k++, n++) 
	            { if (k in O) 
	            { createProperty(result, n, O[k]); } }
	        result.length = n;
	        return result;
	    }
	});

	var $some = arrayIteration.some;
	_export({
	    target: 'Array',
	    proto: true,
	    forced: sloppyArrayMethod('some')
	}, {
	    some: function some(callbackfn) {
	        return $some(this, callbackfn, arguments.length > 1 ? arguments[1] : undefined);
	    }
	});

	var nativeSort = [].sort;
	var test$2 = [1,2,3];
	var FAILS_ON_UNDEFINED = fails(function () {
	    test$2.sort(undefined);
	});
	var FAILS_ON_NULL = fails(function () {
	    test$2.sort(null);
	});
	var SLOPPY_METHOD$3 = sloppyArrayMethod('sort');
	var FORCED$2 = FAILS_ON_UNDEFINED || !FAILS_ON_NULL || SLOPPY_METHOD$3;
	_export({
	    target: 'Array',
	    proto: true,
	    forced: FORCED$2
	}, {
	    sort: function sort(comparefn) {
	        return comparefn === undefined ? nativeSort.call(toObject(this)) : nativeSort.call(toObject(this), aFunction$1(comparefn));
	    }
	});

	var max$2 = Math.max;
	var min$4 = Math.min;
	var MAX_SAFE_INTEGER$1 = 0x1FFFFFFFFFFFFF;
	var MAXIMUM_ALLOWED_LENGTH_EXCEEDED = 'Maximum allowed length exceeded';
	_export({
	    target: 'Array',
	    proto: true,
	    forced: !arrayMethodHasSpeciesSupport('splice')
	}, {
	    splice: function splice(start, deleteCount) {
	        var arguments$1 = arguments;

	        var O = toObject(this);
	        var len = toLength(O.length);
	        var actualStart = toAbsoluteIndex(start, len);
	        var argumentsLength = arguments.length;
	        var insertCount, actualDeleteCount, A, k, from, to;
	        if (argumentsLength === 0) {
	            insertCount = (actualDeleteCount = 0);
	        } else if (argumentsLength === 1) {
	            insertCount = 0;
	            actualDeleteCount = len - actualStart;
	        } else {
	            insertCount = argumentsLength - 2;
	            actualDeleteCount = min$4(max$2(toInteger(deleteCount), 0), len - actualStart);
	        }
	        if (len + insertCount - actualDeleteCount > MAX_SAFE_INTEGER$1) {
	            throw TypeError(MAXIMUM_ALLOWED_LENGTH_EXCEEDED);
	        }
	        A = arraySpeciesCreate(O, actualDeleteCount);
	        for (k = 0; k < actualDeleteCount; k++) {
	            from = actualStart + k;
	            if (from in O) 
	                { createProperty(A, k, O[from]); }
	        }
	        A.length = actualDeleteCount;
	        if (insertCount < actualDeleteCount) {
	            for (k = actualStart; k < len - actualDeleteCount; k++) {
	                from = k + actualDeleteCount;
	                to = k + insertCount;
	                if (from in O) 
	                    { O[to] = O[from]; }
	                 else 
	                    { delete O[to]; }
	            }
	            for (k = len; k > len - actualDeleteCount + insertCount; k--) 
	                { delete O[k - 1]; }
	        } else if (insertCount > actualDeleteCount) {
	            for (k = len - actualDeleteCount; k > actualStart; k--) {
	                from = k + actualDeleteCount - 1;
	                to = k + insertCount - 1;
	                if (from in O) 
	                    { O[to] = O[from]; }
	                 else 
	                    { delete O[to]; }
	            }
	        }
	        for (k = 0; k < insertCount; k++) {
	            O[k + actualStart] = arguments$1[k + 2];
	        }
	        O.length = len - actualDeleteCount + insertCount;
	        return A;
	    }
	});

	var SPECIES$3 = wellKnownSymbol('species');
	var setSpecies = function (CONSTRUCTOR_NAME) {
	    var Constructor = getBuiltIn(CONSTRUCTOR_NAME);
	    var defineProperty = objectDefineProperty.f;
	    if (descriptors && Constructor && !Constructor[SPECIES$3]) {
	        defineProperty(Constructor, SPECIES$3, {
	            configurable: true,
	            get: function () {
	                return this;
	            }
	        });
	    }
	};

	setSpecies('Array');

	addToUnscopables('flat');

	addToUnscopables('flatMap');

	var ITERATOR$3 = wellKnownSymbol('iterator');
	var BUGGY_SAFARI_ITERATORS = false;
	var returnThis = function () {
	    return this;
	};
	var IteratorPrototype, PrototypeOfArrayIteratorPrototype, arrayIterator;
	if ([].keys) {
	    arrayIterator = [].keys();
	    if (!('next' in arrayIterator)) 
	        { BUGGY_SAFARI_ITERATORS = true; }
	     else {
	        PrototypeOfArrayIteratorPrototype = objectGetPrototypeOf(objectGetPrototypeOf(arrayIterator));
	        if (PrototypeOfArrayIteratorPrototype !== Object.prototype) 
	            { IteratorPrototype = PrototypeOfArrayIteratorPrototype; }
	    }
	}
	if (IteratorPrototype == undefined) 
	    { IteratorPrototype = {}; }
	if ( !has(IteratorPrototype, ITERATOR$3)) 
	    { hide(IteratorPrototype, ITERATOR$3, returnThis); }
	var iteratorsCore = {
	    IteratorPrototype: IteratorPrototype,
	    BUGGY_SAFARI_ITERATORS: BUGGY_SAFARI_ITERATORS
	};

	var IteratorPrototype$1 = iteratorsCore.IteratorPrototype;
	var returnThis$1 = function () {
	    return this;
	};
	var createIteratorConstructor = function (IteratorConstructor, NAME, next) {
	    var TO_STRING_TAG = NAME + ' Iterator';
	    IteratorConstructor.prototype = objectCreate(IteratorPrototype$1, {
	        next: createPropertyDescriptor(1, next)
	    });
	    setToStringTag(IteratorConstructor, TO_STRING_TAG, false);
	    iterators[TO_STRING_TAG] = returnThis$1;
	    return IteratorConstructor;
	};

	var IteratorPrototype$2 = iteratorsCore.IteratorPrototype;
	var BUGGY_SAFARI_ITERATORS$1 = iteratorsCore.BUGGY_SAFARI_ITERATORS;
	var ITERATOR$4 = wellKnownSymbol('iterator');
	var KEYS = 'keys';
	var VALUES = 'values';
	var ENTRIES = 'entries';
	var returnThis$2 = function () {
	    return this;
	};
	var defineIterator = function (Iterable, NAME, IteratorConstructor, next, DEFAULT, IS_SET, FORCED) {
	    createIteratorConstructor(IteratorConstructor, NAME, next);
	    var getIterationMethod = function (KIND) {
	        if (KIND === DEFAULT && defaultIterator) 
	            { return defaultIterator; }
	        if (!BUGGY_SAFARI_ITERATORS$1 && KIND in IterablePrototype) 
	            { return IterablePrototype[KIND]; }
	        switch (KIND) {
	            case KEYS:
	                return function keys() {
	                    return new IteratorConstructor(this, KIND);
	                };
	            case VALUES:
	                return function values() {
	                    return new IteratorConstructor(this, KIND);
	                };
	            case ENTRIES:
	                return function entries() {
	                    return new IteratorConstructor(this, KIND);
	                };
	        }
	        return function () {
	            return new IteratorConstructor(this);
	        };
	    };
	    var TO_STRING_TAG = NAME + ' Iterator';
	    var INCORRECT_VALUES_NAME = false;
	    var IterablePrototype = Iterable.prototype;
	    var nativeIterator = IterablePrototype[ITERATOR$4] || IterablePrototype['@@iterator'] || DEFAULT && IterablePrototype[DEFAULT];
	    var defaultIterator = !BUGGY_SAFARI_ITERATORS$1 && nativeIterator || getIterationMethod(DEFAULT);
	    var anyNativeIterator = NAME == 'Array' ? IterablePrototype.entries || nativeIterator : nativeIterator;
	    var CurrentIteratorPrototype, methods, KEY;
	    if (anyNativeIterator) {
	        CurrentIteratorPrototype = objectGetPrototypeOf(anyNativeIterator.call(new Iterable()));
	        if (IteratorPrototype$2 !== Object.prototype && CurrentIteratorPrototype.next) {
	            if ( objectGetPrototypeOf(CurrentIteratorPrototype) !== IteratorPrototype$2) {
	                if (objectSetPrototypeOf) {
	                    objectSetPrototypeOf(CurrentIteratorPrototype, IteratorPrototype$2);
	                } else if (typeof CurrentIteratorPrototype[ITERATOR$4] != 'function') {
	                    hide(CurrentIteratorPrototype, ITERATOR$4, returnThis$2);
	                }
	            }
	            setToStringTag(CurrentIteratorPrototype, TO_STRING_TAG, true);
	        }
	    }
	    if (DEFAULT == VALUES && nativeIterator && nativeIterator.name !== VALUES) {
	        INCORRECT_VALUES_NAME = true;
	        defaultIterator = function values() {
	            return nativeIterator.call(this);
	        };
	    }
	    if ( IterablePrototype[ITERATOR$4] !== defaultIterator) {
	        hide(IterablePrototype, ITERATOR$4, defaultIterator);
	    }
	    iterators[NAME] = defaultIterator;
	    if (DEFAULT) {
	        methods = {
	            values: getIterationMethod(VALUES),
	            keys: IS_SET ? defaultIterator : getIterationMethod(KEYS),
	            entries: getIterationMethod(ENTRIES)
	        };
	        if (FORCED) 
	            { for (KEY in methods) {
	            if (BUGGY_SAFARI_ITERATORS$1 || INCORRECT_VALUES_NAME || !(KEY in IterablePrototype)) {
	                redefine(IterablePrototype, KEY, methods[KEY]);
	            }
	        } }
	         else 
	            { _export({
	            target: NAME,
	            proto: true,
	            forced: BUGGY_SAFARI_ITERATORS$1 || INCORRECT_VALUES_NAME
	        }, methods); }
	    }
	    return methods;
	};

	var ARRAY_ITERATOR = 'Array Iterator';
	var setInternalState$1 = internalState.set;
	var getInternalState$1 = internalState.getterFor(ARRAY_ITERATOR);
	var es_array_iterator = defineIterator(Array, 'Array', function (iterated, kind) {
	    setInternalState$1(this, {
	        type: ARRAY_ITERATOR,
	        target: toIndexedObject(iterated),
	        index: 0,
	        kind: kind
	    });
	}, function () {
	    var state = getInternalState$1(this);
	    var target = state.target;
	    var kind = state.kind;
	    var index = state.index++;
	    if (!target || index >= target.length) {
	        state.target = undefined;
	        return {
	            value: undefined,
	            done: true
	        };
	    }
	    if (kind == 'keys') 
	        { return {
	        value: index,
	        done: false
	    }; }
	    if (kind == 'values') 
	        { return {
	        value: target[index],
	        done: false
	    }; }
	    return {
	        value: [index,target[index]],
	        done: false
	    };
	}, 'values');
	iterators.Arguments = iterators.Array;
	addToUnscopables('keys');
	addToUnscopables('values');
	addToUnscopables('entries');

	var fromCharCode = String.fromCharCode;
	var nativeFromCodePoint = String.fromCodePoint;
	var INCORRECT_LENGTH = !(!nativeFromCodePoint) && nativeFromCodePoint.length != 1;
	_export({
	    target: 'String',
	    stat: true,
	    forced: INCORRECT_LENGTH
	}, {
	    fromCodePoint: function fromCodePoint(x) {
	        var arguments$1 = arguments;

	        var elements = [];
	        var length = arguments.length;
	        var i = 0;
	        var code;
	        while (length > i) {
	            code = +arguments$1[i++];
	            if (toAbsoluteIndex(code, 0x10FFFF) !== code) 
	                { throw RangeError(code + ' is not a valid code point'); }
	            elements.push(code < 0x10000 ? fromCharCode(code) : fromCharCode(((code -= 0x10000) >> 10) + 0xD800, code % 0x400 + 0xDC00));
	        }
	        return elements.join('');
	    }
	});

	_export({
	    target: 'String',
	    stat: true
	}, {
	    raw: function raw(template) {
	        var arguments$1 = arguments;

	        var rawTemplate = toIndexedObject(template.raw);
	        var literalSegments = toLength(rawTemplate.length);
	        var argumentsLength = arguments.length;
	        var elements = [];
	        var i = 0;
	        while (literalSegments > i) {
	            elements.push(String(rawTemplate[i++]));
	            if (i < argumentsLength) 
	                { elements.push(String(arguments$1[i])); }
	        }
	        return elements.join('');
	    }
	});

	var createMethod$4 = function (CONVERT_TO_STRING) {
	    return function ($this, pos) {
	        var S = String(requireObjectCoercible($this));
	        var position = toInteger(pos);
	        var size = S.length;
	        var first, second;
	        if (position < 0 || position >= size) 
	            { return CONVERT_TO_STRING ? '' : undefined; }
	        first = S.charCodeAt(position);
	        return first < 0xD800 || first > 0xDBFF || position + 1 === size || (second = S.charCodeAt(position + 1)) < 0xDC00 || second > 0xDFFF ? CONVERT_TO_STRING ? S.charAt(position) : first : CONVERT_TO_STRING ? S.slice(position, position + 2) : (first - 0xD800 << 10) + (second - 0xDC00) + 0x10000;
	    };
	};
	var stringMultibyte = {
	    codeAt: createMethod$4(false),
	    charAt: createMethod$4(true)
	};

	var codeAt = stringMultibyte.codeAt;
	_export({
	    target: 'String',
	    proto: true
	}, {
	    codePointAt: function codePointAt(pos) {
	        return codeAt(this, pos);
	    }
	});

	var MATCH = wellKnownSymbol('match');
	var isRegexp = function (it) {
	    var isRegExp;
	    return isObject(it) && ((isRegExp = it[MATCH]) !== undefined ? !(!isRegExp) : classofRaw(it) == 'RegExp');
	};

	var notARegexp = function (it) {
	    if (isRegexp(it)) {
	        throw TypeError("The method doesn't accept regular expressions");
	    }
	    return it;
	};

	var MATCH$1 = wellKnownSymbol('match');
	var correctIsRegexpLogic = function (METHOD_NAME) {
	    var regexp = /./;
	    try {
	        '/./'[METHOD_NAME](regexp);
	    } catch (e) {
	        try {
	            regexp[MATCH$1] = false;
	            return '/./'[METHOD_NAME](regexp);
	        } catch (f) {}
	    }
	    return false;
	};

	var nativeEndsWith = ''.endsWith;
	var min$5 = Math.min;
	_export({
	    target: 'String',
	    proto: true,
	    forced: !correctIsRegexpLogic('endsWith')
	}, {
	    endsWith: function endsWith(searchString) {
	        var that = String(requireObjectCoercible(this));
	        notARegexp(searchString);
	        var endPosition = arguments.length > 1 ? arguments[1] : undefined;
	        var len = toLength(that.length);
	        var end = endPosition === undefined ? len : min$5(toLength(endPosition), len);
	        var search = String(searchString);
	        return nativeEndsWith ? nativeEndsWith.call(that, search, end) : that.slice(end - search.length, end) === search;
	    }
	});

	_export({
	    target: 'String',
	    proto: true,
	    forced: !correctIsRegexpLogic('includes')
	}, {
	    includes: function includes(searchString) {
	        return !(!(~String(requireObjectCoercible(this)).indexOf(notARegexp(searchString), arguments.length > 1 ? arguments[1] : undefined)));
	    }
	});

	var regexpFlags = function () {
	    var that = anObject(this);
	    var result = '';
	    if (that.global) 
	        { result += 'g'; }
	    if (that.ignoreCase) 
	        { result += 'i'; }
	    if (that.multiline) 
	        { result += 'm'; }
	    if (that.dotAll) 
	        { result += 's'; }
	    if (that.unicode) 
	        { result += 'u'; }
	    if (that.sticky) 
	        { result += 'y'; }
	    return result;
	};

	var nativeExec = RegExp.prototype.exec;
	var nativeReplace = String.prototype.replace;
	var patchedExec = nativeExec;
	var UPDATES_LAST_INDEX_WRONG = (function () {
	    var re1 = /a/;
	    var re2 = /b*/g;
	    nativeExec.call(re1, 'a');
	    nativeExec.call(re2, 'a');
	    return re1.lastIndex !== 0 || re2.lastIndex !== 0;
	})();
	var NPCG_INCLUDED = /()??/.exec('')[1] !== undefined;
	var PATCH = UPDATES_LAST_INDEX_WRONG || NPCG_INCLUDED;
	if (PATCH) {
	    patchedExec = function exec(str) {
	        var re = this;
	        var lastIndex, reCopy, match, i;
	        if (NPCG_INCLUDED) {
	            reCopy = new RegExp('^' + re.source + '$(?!\\s)', regexpFlags.call(re));
	        }
	        if (UPDATES_LAST_INDEX_WRONG) 
	            { lastIndex = re.lastIndex; }
	        match = nativeExec.call(re, str);
	        if (UPDATES_LAST_INDEX_WRONG && match) {
	            re.lastIndex = re.global ? match.index + match[0].length : lastIndex;
	        }
	        if (NPCG_INCLUDED && match && match.length > 1) {
	            nativeReplace.call(match[0], reCopy, function () {
	                var arguments$1 = arguments;

	                for (i = 1; i < arguments.length - 2; i++) {
	                    if (arguments$1[i] === undefined) 
	                        { match[i] = undefined; }
	                }
	            });
	        }
	        return match;
	    };
	}
	var regexpExec = patchedExec;

	var SPECIES$4 = wellKnownSymbol('species');
	var REPLACE_SUPPORTS_NAMED_GROUPS = !fails(function () {
	    var re = /./;
	    re.exec = function () {
	        var result = [];
	        result.groups = {
	            a: '7'
	        };
	        return result;
	    };
	    return ''.replace(re, '$<a>') !== '7';
	});
	var SPLIT_WORKS_WITH_OVERWRITTEN_EXEC = !fails(function () {
	    var re = /(?:)/;
	    var originalExec = re.exec;
	    re.exec = function () {
	        return originalExec.apply(this, arguments);
	    };
	    var result = 'ab'.split(re);
	    return result.length !== 2 || result[0] !== 'a' || result[1] !== 'b';
	});
	var fixRegexpWellKnownSymbolLogic = function (KEY, length, exec, sham) {
	    var SYMBOL = wellKnownSymbol(KEY);
	    var DELEGATES_TO_SYMBOL = !fails(function () {
	        var O = {};
	        O[SYMBOL] = function () {
	            return 7;
	        };
	        return ''[KEY](O) != 7;
	    });
	    var DELEGATES_TO_EXEC = DELEGATES_TO_SYMBOL && !fails(function () {
	        var execCalled = false;
	        var re = /a/;
	        re.exec = function () {
	            execCalled = true;
	            return null;
	        };
	        if (KEY === 'split') {
	            re.constructor = {};
	            re.constructor[SPECIES$4] = function () {
	                return re;
	            };
	        }
	        re[SYMBOL]('');
	        return !execCalled;
	    });
	    if (!DELEGATES_TO_SYMBOL || !DELEGATES_TO_EXEC || KEY === 'replace' && !REPLACE_SUPPORTS_NAMED_GROUPS || KEY === 'split' && !SPLIT_WORKS_WITH_OVERWRITTEN_EXEC) {
	        var nativeRegExpMethod = /./[SYMBOL];
	        var methods = exec(SYMBOL, ''[KEY], function (nativeMethod, regexp, str, arg2, forceStringMethod) {
	            if (regexp.exec === regexpExec) {
	                if (DELEGATES_TO_SYMBOL && !forceStringMethod) {
	                    return {
	                        done: true,
	                        value: nativeRegExpMethod.call(regexp, str, arg2)
	                    };
	                }
	                return {
	                    done: true,
	                    value: nativeMethod.call(str, regexp, arg2)
	                };
	            }
	            return {
	                done: false
	            };
	        });
	        var stringMethod = methods[0];
	        var regexMethod = methods[1];
	        redefine(String.prototype, KEY, stringMethod);
	        redefine(RegExp.prototype, SYMBOL, length == 2 ? function (string, arg) {
	            return regexMethod.call(string, this, arg);
	        } : function (string) {
	            return regexMethod.call(string, this);
	        });
	        if (sham) 
	            { hide(RegExp.prototype[SYMBOL], 'sham', true); }
	    }
	};

	var charAt = stringMultibyte.charAt;
	var advanceStringIndex = function (S, index, unicode) {
	    return index + (unicode ? charAt(S, index).length : 1);
	};

	var regexpExecAbstract = function (R, S) {
	    var exec = R.exec;
	    if (typeof exec === 'function') {
	        var result = exec.call(R, S);
	        if (typeof result !== 'object') {
	            throw TypeError('RegExp exec method returned something other than an Object or null');
	        }
	        return result;
	    }
	    if (classofRaw(R) !== 'RegExp') {
	        throw TypeError('RegExp#exec called on incompatible receiver');
	    }
	    return regexpExec.call(R, S);
	};

	fixRegexpWellKnownSymbolLogic('match', 1, function (MATCH, nativeMatch, maybeCallNative) {
	    return [function match(regexp) {
	        var O = requireObjectCoercible(this);
	        var matcher = regexp == undefined ? undefined : regexp[MATCH];
	        return matcher !== undefined ? matcher.call(regexp, O) : new RegExp(regexp)[MATCH](String(O));
	    },function (regexp) {
	        var res = maybeCallNative(nativeMatch, regexp, this);
	        if (res.done) 
	            { return res.value; }
	        var rx = anObject(regexp);
	        var S = String(this);
	        if (!rx.global) 
	            { return regexpExecAbstract(rx, S); }
	        var fullUnicode = rx.unicode;
	        rx.lastIndex = 0;
	        var A = [];
	        var n = 0;
	        var result;
	        while ((result = regexpExecAbstract(rx, S)) !== null) {
	            var matchStr = String(result[0]);
	            A[n] = matchStr;
	            if (matchStr === '') 
	                { rx.lastIndex = advanceStringIndex(S, toLength(rx.lastIndex), fullUnicode); }
	            n++;
	        }
	        return n === 0 ? null : A;
	    }];
	});

	var SPECIES$5 = wellKnownSymbol('species');
	var speciesConstructor = function (O, defaultConstructor) {
	    var C = anObject(O).constructor;
	    var S;
	    return C === undefined || (S = anObject(C)[SPECIES$5]) == undefined ? defaultConstructor : aFunction$1(S);
	};

	var MATCH_ALL = wellKnownSymbol('matchAll');
	var REGEXP_STRING = 'RegExp String';
	var REGEXP_STRING_ITERATOR = REGEXP_STRING + ' Iterator';
	var setInternalState$2 = internalState.set;
	var getInternalState$2 = internalState.getterFor(REGEXP_STRING_ITERATOR);
	var RegExpPrototype = RegExp.prototype;
	var regExpBuiltinExec = RegExpPrototype.exec;
	var regExpExec = function (R, S) {
	    var exec = R.exec;
	    var result;
	    if (typeof exec == 'function') {
	        result = exec.call(R, S);
	        if (typeof result != 'object') 
	            { throw TypeError('Incorrect exec result'); }
	        return result;
	    }
	    return regExpBuiltinExec.call(R, S);
	};
	var $RegExpStringIterator = createIteratorConstructor(function RegExpStringIterator(regexp, string, global, fullUnicode) {
	    setInternalState$2(this, {
	        type: REGEXP_STRING_ITERATOR,
	        regexp: regexp,
	        string: string,
	        global: global,
	        unicode: fullUnicode,
	        done: false
	    });
	}, REGEXP_STRING, function next() {
	    var state = getInternalState$2(this);
	    if (state.done) 
	        { return {
	        value: undefined,
	        done: true
	    }; }
	    var R = state.regexp;
	    var S = state.string;
	    var match = regExpExec(R, S);
	    if (match === null) 
	        { return {
	        value: undefined,
	        done: state.done = true
	    }; }
	    if (state.global) {
	        if (String(match[0]) == '') 
	            { R.lastIndex = advanceStringIndex(S, toLength(R.lastIndex), state.unicode); }
	        return {
	            value: match,
	            done: false
	        };
	    }
	    state.done = true;
	    return {
	        value: match,
	        done: false
	    };
	});
	var $matchAll = function (string) {
	    var R = anObject(this);
	    var S = String(string);
	    var C, flagsValue, flags, matcher, global, fullUnicode;
	    C = speciesConstructor(R, RegExp);
	    flagsValue = R.flags;
	    if (flagsValue === undefined && R instanceof RegExp && !('flags' in RegExpPrototype)) {
	        flagsValue = regexpFlags.call(R);
	    }
	    flags = flagsValue === undefined ? '' : String(flagsValue);
	    matcher = new C(C === RegExp ? R.source : R, flags);
	    global = !(!(~flags.indexOf('g')));
	    fullUnicode = !(!(~flags.indexOf('u')));
	    matcher.lastIndex = toLength(R.lastIndex);
	    return new $RegExpStringIterator(matcher, S, global, fullUnicode);
	};
	_export({
	    target: 'String',
	    proto: true
	}, {
	    matchAll: function matchAll(regexp) {
	        var O = requireObjectCoercible(this);
	        var S, matcher, rx;
	        if (regexp != null) {
	            matcher = regexp[MATCH_ALL];
	            if (matcher === undefined && isPure && classof(regexp) == 'RegExp') 
	                { matcher = $matchAll; }
	            if (matcher != null) 
	                { return aFunction$1(matcher).call(regexp, O); }
	        }
	        S = String(O);
	        rx = new RegExp(regexp, 'g');
	        return  rx[MATCH_ALL](S);
	    }
	});
	 MATCH_ALL in RegExpPrototype || hide(RegExpPrototype, MATCH_ALL, $matchAll);

	var stringRepeat = ''.repeat || function repeat(count) {
	    var str = String(requireObjectCoercible(this));
	    var result = '';
	    var n = toInteger(count);
	    if (n < 0 || n == Infinity) 
	        { throw RangeError('Wrong number of repetitions'); }
	    for (; n > 0; (n >>>= 1) && (str += str)) 
	        { if (n & 1) 
	        { result += str; } }
	    return result;
	};

	var ceil$1 = Math.ceil;
	var createMethod$5 = function (IS_END) {
	    return function ($this, maxLength, fillString) {
	        var S = String(requireObjectCoercible($this));
	        var stringLength = S.length;
	        var fillStr = fillString === undefined ? ' ' : String(fillString);
	        var intMaxLength = toLength(maxLength);
	        var fillLen, stringFiller;
	        if (intMaxLength <= stringLength || fillStr == '') 
	            { return S; }
	        fillLen = intMaxLength - stringLength;
	        stringFiller = stringRepeat.call(fillStr, ceil$1(fillLen / fillStr.length));
	        if (stringFiller.length > fillLen) 
	            { stringFiller = stringFiller.slice(0, fillLen); }
	        return IS_END ? S + stringFiller : stringFiller + S;
	    };
	};
	var stringPad = {
	    start: createMethod$5(false),
	    end: createMethod$5(true)
	};

	var userAgent = getBuiltIn('navigator', 'userAgent') || '';

	var webkitStringPadBug = /Version\/10\.\d+(\.\d+)?( Mobile\/\w+)? Safari\//.test(userAgent);

	var $padEnd = stringPad.end;
	_export({
	    target: 'String',
	    proto: true,
	    forced: webkitStringPadBug
	}, {
	    padEnd: function padEnd(maxLength) {
	        return $padEnd(this, maxLength, arguments.length > 1 ? arguments[1] : undefined);
	    }
	});

	var $padStart = stringPad.start;
	_export({
	    target: 'String',
	    proto: true,
	    forced: webkitStringPadBug
	}, {
	    padStart: function padStart(maxLength) {
	        return $padStart(this, maxLength, arguments.length > 1 ? arguments[1] : undefined);
	    }
	});

	_export({
	    target: 'String',
	    proto: true
	}, {
	    repeat: stringRepeat
	});

	var max$3 = Math.max;
	var min$6 = Math.min;
	var floor$1 = Math.floor;
	var SUBSTITUTION_SYMBOLS = /\$([$&'`]|\d\d?|<[^>]*>)/g;
	var SUBSTITUTION_SYMBOLS_NO_NAMED = /\$([$&'`]|\d\d?)/g;
	var maybeToString = function (it) {
	    return it === undefined ? it : String(it);
	};
	fixRegexpWellKnownSymbolLogic('replace', 2, function (REPLACE, nativeReplace, maybeCallNative) {
	    return [function replace(searchValue, replaceValue) {
	        var O = requireObjectCoercible(this);
	        var replacer = searchValue == undefined ? undefined : searchValue[REPLACE];
	        return replacer !== undefined ? replacer.call(searchValue, O, replaceValue) : nativeReplace.call(String(O), searchValue, replaceValue);
	    },function (regexp, replaceValue) {
	        var res = maybeCallNative(nativeReplace, regexp, this, replaceValue);
	        if (res.done) 
	            { return res.value; }
	        var rx = anObject(regexp);
	        var S = String(this);
	        var functionalReplace = typeof replaceValue === 'function';
	        if (!functionalReplace) 
	            { replaceValue = String(replaceValue); }
	        var global = rx.global;
	        if (global) {
	            var fullUnicode = rx.unicode;
	            rx.lastIndex = 0;
	        }
	        var results = [];
	        while (true) {
	            var result = regexpExecAbstract(rx, S);
	            if (result === null) 
	                { break; }
	            results.push(result);
	            if (!global) 
	                { break; }
	            var matchStr = String(result[0]);
	            if (matchStr === '') 
	                { rx.lastIndex = advanceStringIndex(S, toLength(rx.lastIndex), fullUnicode); }
	        }
	        var accumulatedResult = '';
	        var nextSourcePosition = 0;
	        for (var i = 0;i < results.length; i++) {
	            result = results[i];
	            var matched = String(result[0]);
	            var position = max$3(min$6(toInteger(result.index), S.length), 0);
	            var captures = [];
	            for (var j = 1;j < result.length; j++) 
	                { captures.push(maybeToString(result[j])); }
	            var namedCaptures = result.groups;
	            if (functionalReplace) {
	                var replacerArgs = [matched].concat(captures, position, S);
	                if (namedCaptures !== undefined) 
	                    { replacerArgs.push(namedCaptures); }
	                var replacement = String(replaceValue.apply(undefined, replacerArgs));
	            } else {
	                replacement = getSubstitution(matched, S, position, captures, namedCaptures, replaceValue);
	            }
	            if (position >= nextSourcePosition) {
	                accumulatedResult += S.slice(nextSourcePosition, position) + replacement;
	                nextSourcePosition = position + matched.length;
	            }
	        }
	        return accumulatedResult + S.slice(nextSourcePosition);
	    }];
	    function getSubstitution(matched, str, position, captures, namedCaptures, replacement) {
	        var tailPos = position + matched.length;
	        var m = captures.length;
	        var symbols = SUBSTITUTION_SYMBOLS_NO_NAMED;
	        if (namedCaptures !== undefined) {
	            namedCaptures = toObject(namedCaptures);
	            symbols = SUBSTITUTION_SYMBOLS;
	        }
	        return nativeReplace.call(replacement, symbols, function (match, ch) {
	            var capture;
	            switch (ch.charAt(0)) {
	                case '$':
	                    return '$';
	                case '&':
	                    return matched;
	                case '`':
	                    return str.slice(0, position);
	                case "'":
	                    return str.slice(tailPos);
	                case '<':
	                    capture = namedCaptures[ch.slice(1, -1)];
	                    break;
	                default:
	                    var n = +ch;
	                    if (n === 0) 
	                        { return match; }
	                    if (n > m) {
	                        var f = floor$1(n / 10);
	                        if (f === 0) 
	                            { return match; }
	                        if (f <= m) 
	                            { return captures[f - 1] === undefined ? ch.charAt(1) : captures[f - 1] + ch.charAt(1); }
	                        return match;
	                    }
	                    capture = captures[n - 1];
	            }
	            return capture === undefined ? '' : capture;
	        });
	    }
	    
	});

	fixRegexpWellKnownSymbolLogic('search', 1, function (SEARCH, nativeSearch, maybeCallNative) {
	    return [function search(regexp) {
	        var O = requireObjectCoercible(this);
	        var searcher = regexp == undefined ? undefined : regexp[SEARCH];
	        return searcher !== undefined ? searcher.call(regexp, O) : new RegExp(regexp)[SEARCH](String(O));
	    },function (regexp) {
	        var res = maybeCallNative(nativeSearch, regexp, this);
	        if (res.done) 
	            { return res.value; }
	        var rx = anObject(regexp);
	        var S = String(this);
	        var previousLastIndex = rx.lastIndex;
	        if (!sameValue(previousLastIndex, 0)) 
	            { rx.lastIndex = 0; }
	        var result = regexpExecAbstract(rx, S);
	        if (!sameValue(rx.lastIndex, previousLastIndex)) 
	            { rx.lastIndex = previousLastIndex; }
	        return result === null ? -1 : result.index;
	    }];
	});

	var arrayPush = [].push;
	var min$7 = Math.min;
	var MAX_UINT32 = 0xFFFFFFFF;
	var SUPPORTS_Y = !fails(function () {
	    return !RegExp(MAX_UINT32, 'y');
	});
	fixRegexpWellKnownSymbolLogic('split', 2, function (SPLIT, nativeSplit, maybeCallNative) {
	    var internalSplit;
	    if ('abbc'.split(/(b)*/)[1] == 'c' || 'test'.split(/(?:)/, -1).length != 4 || 'ab'.split(/(?:ab)*/).length != 2 || '.'.split(/(.?)(.?)/).length != 4 || '.'.split(/()()/).length > 1 || ''.split(/.?/).length) {
	        internalSplit = function (separator, limit) {
	            var string = String(requireObjectCoercible(this));
	            var lim = limit === undefined ? MAX_UINT32 : limit >>> 0;
	            if (lim === 0) 
	                { return []; }
	            if (separator === undefined) 
	                { return [string]; }
	            if (!isRegexp(separator)) {
	                return nativeSplit.call(string, separator, lim);
	            }
	            var output = [];
	            var flags = (separator.ignoreCase ? 'i' : '') + (separator.multiline ? 'm' : '') + (separator.unicode ? 'u' : '') + (separator.sticky ? 'y' : '');
	            var lastLastIndex = 0;
	            var separatorCopy = new RegExp(separator.source, flags + 'g');
	            var match, lastIndex, lastLength;
	            while (match = regexpExec.call(separatorCopy, string)) {
	                lastIndex = separatorCopy.lastIndex;
	                if (lastIndex > lastLastIndex) {
	                    output.push(string.slice(lastLastIndex, match.index));
	                    if (match.length > 1 && match.index < string.length) 
	                        { arrayPush.apply(output, match.slice(1)); }
	                    lastLength = match[0].length;
	                    lastLastIndex = lastIndex;
	                    if (output.length >= lim) 
	                        { break; }
	                }
	                if (separatorCopy.lastIndex === match.index) 
	                    { separatorCopy.lastIndex++; }
	            }
	            if (lastLastIndex === string.length) {
	                if (lastLength || !separatorCopy.test('')) 
	                    { output.push(''); }
	            } else 
	                { output.push(string.slice(lastLastIndex)); }
	            return output.length > lim ? output.slice(0, lim) : output;
	        };
	    } else if ('0'.split(undefined, 0).length) {
	        internalSplit = function (separator, limit) {
	            return separator === undefined && limit === 0 ? [] : nativeSplit.call(this, separator, limit);
	        };
	    } else 
	        { internalSplit = nativeSplit; }
	    return [function split(separator, limit) {
	        var O = requireObjectCoercible(this);
	        var splitter = separator == undefined ? undefined : separator[SPLIT];
	        return splitter !== undefined ? splitter.call(separator, O, limit) : internalSplit.call(String(O), separator, limit);
	    },function (regexp, limit) {
	        var res = maybeCallNative(internalSplit, regexp, this, limit, internalSplit !== nativeSplit);
	        if (res.done) 
	            { return res.value; }
	        var rx = anObject(regexp);
	        var S = String(this);
	        var C = speciesConstructor(rx, RegExp);
	        var unicodeMatching = rx.unicode;
	        var flags = (rx.ignoreCase ? 'i' : '') + (rx.multiline ? 'm' : '') + (rx.unicode ? 'u' : '') + (SUPPORTS_Y ? 'y' : 'g');
	        var splitter = new C(SUPPORTS_Y ? rx : '^(?:' + rx.source + ')', flags);
	        var lim = limit === undefined ? MAX_UINT32 : limit >>> 0;
	        if (lim === 0) 
	            { return []; }
	        if (S.length === 0) 
	            { return regexpExecAbstract(splitter, S) === null ? [S] : []; }
	        var p = 0;
	        var q = 0;
	        var A = [];
	        while (q < S.length) {
	            splitter.lastIndex = SUPPORTS_Y ? q : 0;
	            var z = regexpExecAbstract(splitter, SUPPORTS_Y ? S : S.slice(q));
	            var e;
	            if (z === null || (e = min$7(toLength(splitter.lastIndex + (SUPPORTS_Y ? 0 : q)), S.length)) === p) {
	                q = advanceStringIndex(S, q, unicodeMatching);
	            } else {
	                A.push(S.slice(p, q));
	                if (A.length === lim) 
	                    { return A; }
	                for (var i = 1;i <= z.length - 1; i++) {
	                    A.push(z[i]);
	                    if (A.length === lim) 
	                        { return A; }
	                }
	                q = (p = e);
	            }
	        }
	        A.push(S.slice(p));
	        return A;
	    }];
	}, !SUPPORTS_Y);

	var nativeStartsWith = ''.startsWith;
	var min$8 = Math.min;
	_export({
	    target: 'String',
	    proto: true,
	    forced: !correctIsRegexpLogic('startsWith')
	}, {
	    startsWith: function startsWith(searchString) {
	        var that = String(requireObjectCoercible(this));
	        notARegexp(searchString);
	        var index = toLength(min$8(arguments.length > 1 ? arguments[1] : undefined, that.length));
	        var search = String(searchString);
	        return nativeStartsWith ? nativeStartsWith.call(that, search, index) : that.slice(index, index + search.length) === search;
	    }
	});

	var whitespaces = '\u0009\u000A\u000B\u000C\u000D\u0020\u00A0\u1680\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200A\u202F\u205F\u3000\u2028\u2029\uFEFF';

	var whitespace = '[' + whitespaces + ']';
	var ltrim = RegExp('^' + whitespace + whitespace + '*');
	var rtrim = RegExp(whitespace + whitespace + '*$');
	var createMethod$6 = function (TYPE) {
	    return function ($this) {
	        var string = String(requireObjectCoercible($this));
	        if (TYPE & 1) 
	            { string = string.replace(ltrim, ''); }
	        if (TYPE & 2) 
	            { string = string.replace(rtrim, ''); }
	        return string;
	    };
	};
	var stringTrim = {
	    start: createMethod$6(1),
	    end: createMethod$6(2),
	    trim: createMethod$6(3)
	};

	var non = '\u200B\u0085\u180E';
	var forcedStringTrimMethod = function (METHOD_NAME) {
	    return fails(function () {
	        return !(!whitespaces[METHOD_NAME]()) || non[METHOD_NAME]() != non || whitespaces[METHOD_NAME].name !== METHOD_NAME;
	    });
	};

	var $trim = stringTrim.trim;
	_export({
	    target: 'String',
	    proto: true,
	    forced: forcedStringTrimMethod('trim')
	}, {
	    trim: function trim() {
	        return $trim(this);
	    }
	});

	var $trimStart = stringTrim.start;
	var FORCED$3 = forcedStringTrimMethod('trimStart');
	var trimStart = FORCED$3 ? function trimStart() {
	    return $trimStart(this);
	} : ''.trimStart;
	_export({
	    target: 'String',
	    proto: true,
	    forced: FORCED$3
	}, {
	    trimStart: trimStart,
	    trimLeft: trimStart
	});

	var $trimEnd = stringTrim.end;
	var FORCED$4 = forcedStringTrimMethod('trimEnd');
	var trimEnd = FORCED$4 ? function trimEnd() {
	    return $trimEnd(this);
	} : ''.trimEnd;
	_export({
	    target: 'String',
	    proto: true,
	    forced: FORCED$4
	}, {
	    trimEnd: trimEnd,
	    trimRight: trimEnd
	});

	var charAt$1 = stringMultibyte.charAt;
	var STRING_ITERATOR = 'String Iterator';
	var setInternalState$3 = internalState.set;
	var getInternalState$3 = internalState.getterFor(STRING_ITERATOR);
	defineIterator(String, 'String', function (iterated) {
	    setInternalState$3(this, {
	        type: STRING_ITERATOR,
	        string: String(iterated),
	        index: 0
	    });
	}, function next() {
	    var state = getInternalState$3(this);
	    var string = state.string;
	    var index = state.index;
	    var point;
	    if (index >= string.length) 
	        { return {
	        value: undefined,
	        done: true
	    }; }
	    point = charAt$1(string, index);
	    state.index += point.length;
	    return {
	        value: point,
	        done: false
	    };
	});

	var quot = /"/g;
	var createHtml = function (string, tag, attribute, value) {
	    var S = String(requireObjectCoercible(string));
	    var p1 = '<' + tag;
	    if (attribute !== '') 
	        { p1 += ' ' + attribute + '="' + String(value).replace(quot, '&quot;') + '"'; }
	    return p1 + '>' + S + '</' + tag + '>';
	};

	var forcedStringHtmlMethod = function (METHOD_NAME) {
	    return fails(function () {
	        var test = ''[METHOD_NAME]('"');
	        return test !== test.toLowerCase() || test.split('"').length > 3;
	    });
	};

	_export({
	    target: 'String',
	    proto: true,
	    forced: forcedStringHtmlMethod('anchor')
	}, {
	    anchor: function anchor(name) {
	        return createHtml(this, 'a', 'name', name);
	    }
	});

	_export({
	    target: 'String',
	    proto: true,
	    forced: forcedStringHtmlMethod('big')
	}, {
	    big: function big() {
	        return createHtml(this, 'big', '', '');
	    }
	});

	_export({
	    target: 'String',
	    proto: true,
	    forced: forcedStringHtmlMethod('blink')
	}, {
	    blink: function blink() {
	        return createHtml(this, 'blink', '', '');
	    }
	});

	_export({
	    target: 'String',
	    proto: true,
	    forced: forcedStringHtmlMethod('bold')
	}, {
	    bold: function bold() {
	        return createHtml(this, 'b', '', '');
	    }
	});

	_export({
	    target: 'String',
	    proto: true,
	    forced: forcedStringHtmlMethod('fixed')
	}, {
	    fixed: function fixed() {
	        return createHtml(this, 'tt', '', '');
	    }
	});

	_export({
	    target: 'String',
	    proto: true,
	    forced: forcedStringHtmlMethod('fontcolor')
	}, {
	    fontcolor: function fontcolor(color) {
	        return createHtml(this, 'font', 'color', color);
	    }
	});

	_export({
	    target: 'String',
	    proto: true,
	    forced: forcedStringHtmlMethod('fontsize')
	}, {
	    fontsize: function fontsize(size) {
	        return createHtml(this, 'font', 'size', size);
	    }
	});

	_export({
	    target: 'String',
	    proto: true,
	    forced: forcedStringHtmlMethod('italics')
	}, {
	    italics: function italics() {
	        return createHtml(this, 'i', '', '');
	    }
	});

	_export({
	    target: 'String',
	    proto: true,
	    forced: forcedStringHtmlMethod('link')
	}, {
	    link: function link(url) {
	        return createHtml(this, 'a', 'href', url);
	    }
	});

	_export({
	    target: 'String',
	    proto: true,
	    forced: forcedStringHtmlMethod('small')
	}, {
	    small: function small() {
	        return createHtml(this, 'small', '', '');
	    }
	});

	_export({
	    target: 'String',
	    proto: true,
	    forced: forcedStringHtmlMethod('strike')
	}, {
	    strike: function strike() {
	        return createHtml(this, 'strike', '', '');
	    }
	});

	_export({
	    target: 'String',
	    proto: true,
	    forced: forcedStringHtmlMethod('sub')
	}, {
	    sub: function sub() {
	        return createHtml(this, 'sub', '', '');
	    }
	});

	_export({
	    target: 'String',
	    proto: true,
	    forced: forcedStringHtmlMethod('sup')
	}, {
	    sup: function sup() {
	        return createHtml(this, 'sup', '', '');
	    }
	});

	var inheritIfRequired = function ($this, dummy, Wrapper) {
	    var NewTarget, NewTargetPrototype;
	    if (objectSetPrototypeOf && typeof (NewTarget = dummy.constructor) == 'function' && NewTarget !== Wrapper && isObject(NewTargetPrototype = NewTarget.prototype) && NewTargetPrototype !== Wrapper.prototype) 
	        { objectSetPrototypeOf($this, NewTargetPrototype); }
	    return $this;
	};

	var defineProperty$4 = objectDefineProperty.f;
	var getOwnPropertyNames = objectGetOwnPropertyNames.f;
	var MATCH$2 = wellKnownSymbol('match');
	var NativeRegExp = global_1.RegExp;
	var RegExpPrototype$1 = NativeRegExp.prototype;
	var re1 = /a/g;
	var re2 = /a/g;
	var CORRECT_NEW = new NativeRegExp(re1) !== re1;
	var FORCED$5 = descriptors && isForced_1('RegExp', !CORRECT_NEW || fails(function () {
	    re2[MATCH$2] = false;
	    return NativeRegExp(re1) != re1 || NativeRegExp(re2) == re2 || NativeRegExp(re1, 'i') != '/a/i';
	}));
	if (FORCED$5) {
	    var RegExpWrapper = function RegExp(pattern, flags) {
	        var thisIsRegExp = this instanceof RegExpWrapper;
	        var patternIsRegExp = isRegexp(pattern);
	        var flagsAreUndefined = flags === undefined;
	        return !thisIsRegExp && patternIsRegExp && pattern.constructor === RegExpWrapper && flagsAreUndefined ? pattern : inheritIfRequired(CORRECT_NEW ? new NativeRegExp(patternIsRegExp && !flagsAreUndefined ? pattern.source : pattern, flags) : NativeRegExp((patternIsRegExp = pattern instanceof RegExpWrapper) ? pattern.source : pattern, patternIsRegExp && flagsAreUndefined ? regexpFlags.call(pattern) : flags), thisIsRegExp ? this : RegExpPrototype$1, RegExpWrapper);
	    };
	    var proxy = function (key) {
	        key in RegExpWrapper || defineProperty$4(RegExpWrapper, key, {
	            configurable: true,
	            get: function () {
	                return NativeRegExp[key];
	            },
	            set: function (it) {
	                NativeRegExp[key] = it;
	            }
	        });
	    };
	    var keys$1 = getOwnPropertyNames(NativeRegExp);
	    var index = 0;
	    while (keys$1.length > index) 
	        { proxy(keys$1[index++]); }
	    RegExpPrototype$1.constructor = RegExpWrapper;
	    RegExpWrapper.prototype = RegExpPrototype$1;
	    redefine(global_1, 'RegExp', RegExpWrapper);
	}
	setSpecies('RegExp');

	_export({
	    target: 'RegExp',
	    proto: true,
	    forced: /./.exec !== regexpExec
	}, {
	    exec: regexpExec
	});

	if (descriptors && /./g.flags != 'g') {
	    objectDefineProperty.f(RegExp.prototype, 'flags', {
	        configurable: true,
	        get: regexpFlags
	    });
	}

	var TO_STRING = 'toString';
	var RegExpPrototype$2 = RegExp.prototype;
	var nativeToString = RegExpPrototype$2[TO_STRING];
	var NOT_GENERIC = fails(function () {
	    return nativeToString.call({
	        source: 'a',
	        flags: 'b'
	    }) != '/a/b';
	});
	var INCORRECT_NAME = nativeToString.name != TO_STRING;
	if (NOT_GENERIC || INCORRECT_NAME) {
	    redefine(RegExp.prototype, TO_STRING, function toString() {
	        var R = anObject(this);
	        var p = String(R.source);
	        var rf = R.flags;
	        var f = String(rf === undefined && R instanceof RegExp && !('flags' in RegExpPrototype$2) ? regexpFlags.call(R) : rf);
	        return '/' + p + '/' + f;
	    }, {
	        unsafe: true
	    });
	}

	var trim = stringTrim.trim;
	var nativeParseInt = global_1.parseInt;
	var hex = /^[+-]?0[Xx]/;
	var FORCED$6 = nativeParseInt(whitespaces + '08') !== 8 || nativeParseInt(whitespaces + '0x16') !== 22;
	var _parseInt = FORCED$6 ? function parseInt(string, radix) {
	    var S = trim(String(string));
	    return nativeParseInt(S, radix >>> 0 || (hex.test(S) ? 16 : 10));
	} : nativeParseInt;

	_export({
	    global: true,
	    forced: parseInt != _parseInt
	}, {
	    parseInt: _parseInt
	});

	var trim$1 = stringTrim.trim;
	var nativeParseFloat = global_1.parseFloat;
	var FORCED$7 = 1 / nativeParseFloat(whitespaces + '-0') !== -Infinity;
	var _parseFloat = FORCED$7 ? function parseFloat(string) {
	    var trimmedString = trim$1(String(string));
	    var result = nativeParseFloat(trimmedString);
	    return result === 0 && trimmedString.charAt(0) == '-' ? -0 : result;
	} : nativeParseFloat;

	_export({
	    global: true,
	    forced: parseFloat != _parseFloat
	}, {
	    parseFloat: _parseFloat
	});

	var getOwnPropertyNames$1 = objectGetOwnPropertyNames.f;
	var getOwnPropertyDescriptor$4 = objectGetOwnPropertyDescriptor.f;
	var defineProperty$5 = objectDefineProperty.f;
	var trim$2 = stringTrim.trim;
	var NUMBER = 'Number';
	var NativeNumber = global_1[NUMBER];
	var NumberPrototype = NativeNumber.prototype;
	var BROKEN_CLASSOF = classofRaw(objectCreate(NumberPrototype)) == NUMBER;
	var toNumber = function (argument) {
	    var it = toPrimitive(argument, false);
	    var first, third, radix, maxCode, digits, length, index, code;
	    if (typeof it == 'string' && it.length > 2) {
	        it = trim$2(it);
	        first = it.charCodeAt(0);
	        if (first === 43 || first === 45) {
	            third = it.charCodeAt(2);
	            if (third === 88 || third === 120) 
	                { return NaN; }
	        } else if (first === 48) {
	            switch (it.charCodeAt(1)) {
	                case 66:
	                case 98:
	                    radix = 2;
	                    maxCode = 49;
	                    break;
	                case 79:
	                case 111:
	                    radix = 8;
	                    maxCode = 55;
	                    break;
	                default:
	                    return +it;
	            }
	            digits = it.slice(2);
	            length = digits.length;
	            for (index = 0; index < length; index++) {
	                code = digits.charCodeAt(index);
	                if (code < 48 || code > maxCode) 
	                    { return NaN; }
	            }
	            return parseInt(digits, radix);
	        }
	    }
	    return +it;
	};
	if (isForced_1(NUMBER, !NativeNumber(' 0o1') || !NativeNumber('0b1') || NativeNumber('+0x1'))) {
	    var NumberWrapper = function Number(value) {
	        var it = arguments.length < 1 ? 0 : value;
	        var dummy = this;
	        return dummy instanceof NumberWrapper && (BROKEN_CLASSOF ? fails(function () {
	            NumberPrototype.valueOf.call(dummy);
	        }) : classofRaw(dummy) != NUMBER) ? inheritIfRequired(new NativeNumber(toNumber(it)), dummy, NumberWrapper) : toNumber(it);
	    };
	    for (var keys$2 = descriptors ? getOwnPropertyNames$1(NativeNumber) : ('MAX_VALUE,MIN_VALUE,NaN,NEGATIVE_INFINITY,POSITIVE_INFINITY,' + 'EPSILON,isFinite,isInteger,isNaN,isSafeInteger,MAX_SAFE_INTEGER,' + 'MIN_SAFE_INTEGER,parseFloat,parseInt,isInteger').split(','), j = 0, key;keys$2.length > j; j++) {
	        if (has(NativeNumber, key = keys$2[j]) && !has(NumberWrapper, key)) {
	            defineProperty$5(NumberWrapper, key, getOwnPropertyDescriptor$4(NativeNumber, key));
	        }
	    }
	    NumberWrapper.prototype = NumberPrototype;
	    NumberPrototype.constructor = NumberWrapper;
	    redefine(global_1, NUMBER, NumberWrapper);
	}

	_export({
	    target: 'Number',
	    stat: true
	}, {
	    EPSILON: Math.pow(2, -52)
	});

	var globalIsFinite = global_1.isFinite;
	var numberIsFinite = Number.isFinite || function isFinite(it) {
	    return typeof it == 'number' && globalIsFinite(it);
	};

	_export({
	    target: 'Number',
	    stat: true
	}, {
	    isFinite: numberIsFinite
	});

	var floor$2 = Math.floor;
	var isInteger = function isInteger(it) {
	    return !isObject(it) && isFinite(it) && floor$2(it) === it;
	};

	_export({
	    target: 'Number',
	    stat: true
	}, {
	    isInteger: isInteger
	});

	_export({
	    target: 'Number',
	    stat: true
	}, {
	    isNaN: function isNaN(number) {
	        return number != number;
	    }
	});

	var abs = Math.abs;
	_export({
	    target: 'Number',
	    stat: true
	}, {
	    isSafeInteger: function isSafeInteger(number) {
	        return isInteger(number) && abs(number) <= 0x1FFFFFFFFFFFFF;
	    }
	});

	_export({
	    target: 'Number',
	    stat: true
	}, {
	    MAX_SAFE_INTEGER: 0x1FFFFFFFFFFFFF
	});

	_export({
	    target: 'Number',
	    stat: true
	}, {
	    MIN_SAFE_INTEGER: -0x1FFFFFFFFFFFFF
	});

	_export({
	    target: 'Number',
	    stat: true,
	    forced: Number.parseFloat != _parseFloat
	}, {
	    parseFloat: _parseFloat
	});

	_export({
	    target: 'Number',
	    stat: true,
	    forced: Number.parseInt != _parseInt
	}, {
	    parseInt: _parseInt
	});

	var thisNumberValue = function (value) {
	    if (typeof value != 'number' && classofRaw(value) != 'Number') {
	        throw TypeError('Incorrect invocation');
	    }
	    return +value;
	};

	var nativeToFixed = (1.0).toFixed;
	var floor$3 = Math.floor;
	var pow = function (x, n, acc) {
	    return n === 0 ? acc : n % 2 === 1 ? pow(x, n - 1, acc * x) : pow(x * x, n / 2, acc);
	};
	var log = function (x) {
	    var n = 0;
	    var x2 = x;
	    while (x2 >= 4096) {
	        n += 12;
	        x2 /= 4096;
	    }
	    while (x2 >= 2) {
	        n += 1;
	        x2 /= 2;
	    }
	    return n;
	};
	var FORCED$8 = nativeToFixed && ((0.00008).toFixed(3) !== '0.000' || (0.9).toFixed(0) !== '1' || (1.255).toFixed(2) !== '1.25' || (1000000000000000128.0).toFixed(0) !== '1000000000000000128') || !fails(function () {
	    nativeToFixed.call({});
	});
	_export({
	    target: 'Number',
	    proto: true,
	    forced: FORCED$8
	}, {
	    toFixed: function toFixed(fractionDigits) {
	        var number = thisNumberValue(this);
	        var fractDigits = toInteger(fractionDigits);
	        var data = [0,0,0,0,0,0];
	        var sign = '';
	        var result = '0';
	        var e, z, j, k;
	        var multiply = function (n, c) {
	            var index = -1;
	            var c2 = c;
	            while (++index < 6) {
	                c2 += n * data[index];
	                data[index] = c2 % 1e7;
	                c2 = floor$3(c2 / 1e7);
	            }
	        };
	        var divide = function (n) {
	            var index = 6;
	            var c = 0;
	            while (--index >= 0) {
	                c += data[index];
	                data[index] = floor$3(c / n);
	                c = c % n * 1e7;
	            }
	        };
	        var dataToString = function () {
	            var index = 6;
	            var s = '';
	            while (--index >= 0) {
	                if (s !== '' || index === 0 || data[index] !== 0) {
	                    var t = String(data[index]);
	                    s = s === '' ? t : s + stringRepeat.call('0', 7 - t.length) + t;
	                }
	            }
	            return s;
	        };
	        if (fractDigits < 0 || fractDigits > 20) 
	            { throw RangeError('Incorrect fraction digits'); }
	        if (number != number) 
	            { return 'NaN'; }
	        if (number <= -1e21 || number >= 1e21) 
	            { return String(number); }
	        if (number < 0) {
	            sign = '-';
	            number = -number;
	        }
	        if (number > 1e-21) {
	            e = log(number * pow(2, 69, 1)) - 69;
	            z = e < 0 ? number * pow(2, -e, 1) : number / pow(2, e, 1);
	            z *= 0x10000000000000;
	            e = 52 - e;
	            if (e > 0) {
	                multiply(0, z);
	                j = fractDigits;
	                while (j >= 7) {
	                    multiply(1e7, 0);
	                    j -= 7;
	                }
	                multiply(pow(10, j, 1), 0);
	                j = e - 1;
	                while (j >= 23) {
	                    divide(1 << 23);
	                    j -= 23;
	                }
	                divide(1 << j);
	                multiply(1, 1);
	                divide(2);
	                result = dataToString();
	            } else {
	                multiply(0, z);
	                multiply(1 << -e, 0);
	                result = dataToString() + stringRepeat.call('0', fractDigits);
	            }
	        }
	        if (fractDigits > 0) {
	            k = result.length;
	            result = sign + (k <= fractDigits ? '0.' + stringRepeat.call('0', fractDigits - k) + result : result.slice(0, k - fractDigits) + '.' + result.slice(k - fractDigits));
	        } else {
	            result = sign + result;
	        }
	        return result;
	    }
	});

	var nativeToPrecision = (1.0).toPrecision;
	var FORCED$9 = fails(function () {
	    return nativeToPrecision.call(1, undefined) !== '1';
	}) || !fails(function () {
	    nativeToPrecision.call({});
	});
	_export({
	    target: 'Number',
	    proto: true,
	    forced: FORCED$9
	}, {
	    toPrecision: function toPrecision(precision) {
	        return precision === undefined ? nativeToPrecision.call(thisNumberValue(this)) : nativeToPrecision.call(thisNumberValue(this), precision);
	    }
	});

	var log$1 = Math.log;
	var mathLog1p = Math.log1p || function log1p(x) {
	    return (x = +x) > -1e-8 && x < 1e-8 ? x - x * x / 2 : log$1(1 + x);
	};

	var nativeAcosh = Math.acosh;
	var log$2 = Math.log;
	var sqrt = Math.sqrt;
	var LN2 = Math.LN2;
	var FORCED$a = !nativeAcosh || Math.floor(nativeAcosh(Number.MAX_VALUE)) != 710 || nativeAcosh(Infinity) != Infinity;
	_export({
	    target: 'Math',
	    stat: true,
	    forced: FORCED$a
	}, {
	    acosh: function acosh(x) {
	        return (x = +x) < 1 ? NaN : x > 94906265.62425156 ? log$2(x) + LN2 : mathLog1p(x - 1 + sqrt(x - 1) * sqrt(x + 1));
	    }
	});

	var nativeAsinh = Math.asinh;
	var log$3 = Math.log;
	var sqrt$1 = Math.sqrt;
	function asinh(x) {
	    return !isFinite(x = +x) || x == 0 ? x : x < 0 ? -asinh(-x) : log$3(x + sqrt$1(x * x + 1));
	}

	_export({
	    target: 'Math',
	    stat: true,
	    forced: !(nativeAsinh && 1 / nativeAsinh(0) > 0)
	}, {
	    asinh: asinh
	});

	var nativeAtanh = Math.atanh;
	var log$4 = Math.log;
	_export({
	    target: 'Math',
	    stat: true,
	    forced: !(nativeAtanh && 1 / nativeAtanh(-0) < 0)
	}, {
	    atanh: function atanh(x) {
	        return (x = +x) == 0 ? x : log$4((1 + x) / (1 - x)) / 2;
	    }
	});

	var mathSign = Math.sign || function sign(x) {
	    return (x = +x) == 0 || x != x ? x : x < 0 ? -1 : 1;
	};

	var abs$1 = Math.abs;
	var pow$1 = Math.pow;
	_export({
	    target: 'Math',
	    stat: true
	}, {
	    cbrt: function cbrt(x) {
	        return mathSign(x = +x) * pow$1(abs$1(x), 1 / 3);
	    }
	});

	var floor$4 = Math.floor;
	var log$5 = Math.log;
	var LOG2E = Math.LOG2E;
	_export({
	    target: 'Math',
	    stat: true
	}, {
	    clz32: function clz32(x) {
	        return (x >>>= 0) ? 31 - floor$4(log$5(x + 0.5) * LOG2E) : 32;
	    }
	});

	var nativeExpm1 = Math.expm1;
	var exp = Math.exp;
	var mathExpm1 = !nativeExpm1 || nativeExpm1(10) > 22025.465794806719 || nativeExpm1(10) < 22025.4657948067165168 || nativeExpm1(-2e-17) != -2e-17 ? function expm1(x) {
	    return (x = +x) == 0 ? x : x > -1e-6 && x < 1e-6 ? x + x * x / 2 : exp(x) - 1;
	} : nativeExpm1;

	var nativeCosh = Math.cosh;
	var abs$2 = Math.abs;
	var E = Math.E;
	_export({
	    target: 'Math',
	    stat: true,
	    forced: !nativeCosh || nativeCosh(710) === Infinity
	}, {
	    cosh: function cosh(x) {
	        var t = mathExpm1(abs$2(x) - 1) + 1;
	        return (t + 1 / (t * E * E)) * (E / 2);
	    }
	});

	_export({
	    target: 'Math',
	    stat: true,
	    forced: mathExpm1 != Math.expm1
	}, {
	    expm1: mathExpm1
	});

	var abs$3 = Math.abs;
	var pow$2 = Math.pow;
	var EPSILON = pow$2(2, -52);
	var EPSILON32 = pow$2(2, -23);
	var MAX32 = pow$2(2, 127) * (2 - EPSILON32);
	var MIN32 = pow$2(2, -126);
	var roundTiesToEven = function (n) {
	    return n + 1 / EPSILON - 1 / EPSILON;
	};
	var mathFround = Math.fround || function fround(x) {
	    var $abs = abs$3(x);
	    var $sign = mathSign(x);
	    var a, result;
	    if ($abs < MIN32) 
	        { return $sign * roundTiesToEven($abs / MIN32 / EPSILON32) * MIN32 * EPSILON32; }
	    a = (1 + EPSILON32 / EPSILON) * $abs;
	    result = a - (a - $abs);
	    if (result > MAX32 || result != result) 
	        { return $sign * Infinity; }
	    return $sign * result;
	};

	_export({
	    target: 'Math',
	    stat: true
	}, {
	    fround: mathFround
	});

	var $hypot = Math.hypot;
	var abs$4 = Math.abs;
	var sqrt$2 = Math.sqrt;
	var BUGGY = !(!$hypot) && $hypot(Infinity, NaN) !== Infinity;
	_export({
	    target: 'Math',
	    stat: true,
	    forced: BUGGY
	}, {
	    hypot: function hypot(value1, value2) {
	        var arguments$1 = arguments;

	        var sum = 0;
	        var i = 0;
	        var aLen = arguments.length;
	        var larg = 0;
	        var arg, div;
	        while (i < aLen) {
	            arg = abs$4(arguments$1[i++]);
	            if (larg < arg) {
	                div = larg / arg;
	                sum = sum * div * div + 1;
	                larg = arg;
	            } else if (arg > 0) {
	                div = arg / larg;
	                sum += div * div;
	            } else 
	                { sum += arg; }
	        }
	        return larg === Infinity ? Infinity : larg * sqrt$2(sum);
	    }
	});

	var nativeImul = Math.imul;
	var FORCED$b = fails(function () {
	    return nativeImul(0xFFFFFFFF, 5) != -5 || nativeImul.length != 2;
	});
	_export({
	    target: 'Math',
	    stat: true,
	    forced: FORCED$b
	}, {
	    imul: function imul(x, y) {
	        var UINT16 = 0xFFFF;
	        var xn = +x;
	        var yn = +y;
	        var xl = UINT16 & xn;
	        var yl = UINT16 & yn;
	        return 0 | xl * yl + ((UINT16 & xn >>> 16) * yl + xl * (UINT16 & yn >>> 16) << 16 >>> 0);
	    }
	});

	var log$6 = Math.log;
	var LOG10E = Math.LOG10E;
	_export({
	    target: 'Math',
	    stat: true
	}, {
	    log10: function log10(x) {
	        return log$6(x) * LOG10E;
	    }
	});

	_export({
	    target: 'Math',
	    stat: true
	}, {
	    log1p: mathLog1p
	});

	var log$7 = Math.log;
	var LN2$1 = Math.LN2;
	_export({
	    target: 'Math',
	    stat: true
	}, {
	    log2: function log2(x) {
	        return log$7(x) / LN2$1;
	    }
	});

	_export({
	    target: 'Math',
	    stat: true
	}, {
	    sign: mathSign
	});

	var abs$5 = Math.abs;
	var exp$1 = Math.exp;
	var E$1 = Math.E;
	var FORCED$c = fails(function () {
	    return Math.sinh(-2e-17) != -2e-17;
	});
	_export({
	    target: 'Math',
	    stat: true,
	    forced: FORCED$c
	}, {
	    sinh: function sinh(x) {
	        return abs$5(x = +x) < 1 ? (mathExpm1(x) - mathExpm1(-x)) / 2 : (exp$1(x - 1) - exp$1(-x - 1)) * (E$1 / 2);
	    }
	});

	var exp$2 = Math.exp;
	_export({
	    target: 'Math',
	    stat: true
	}, {
	    tanh: function tanh(x) {
	        var a = mathExpm1(x = +x);
	        var b = mathExpm1(-x);
	        return a == Infinity ? 1 : b == Infinity ? -1 : (a - b) / (exp$2(x) + exp$2(-x));
	    }
	});

	setToStringTag(Math, 'Math', true);

	var ceil$2 = Math.ceil;
	var floor$5 = Math.floor;
	_export({
	    target: 'Math',
	    stat: true
	}, {
	    trunc: function trunc(it) {
	        return (it > 0 ? floor$5 : ceil$2)(it);
	    }
	});

	_export({
	    target: 'Date',
	    stat: true
	}, {
	    now: function now() {
	        return new Date().getTime();
	    }
	});

	var FORCED$d = fails(function () {
	    return new Date(NaN).toJSON() !== null || Date.prototype.toJSON.call({
	        toISOString: function () {
	            return 1;
	        }
	    }) !== 1;
	});
	_export({
	    target: 'Date',
	    proto: true,
	    forced: FORCED$d
	}, {
	    toJSON: function toJSON(key) {
	        var O = toObject(this);
	        var pv = toPrimitive(O);
	        return typeof pv == 'number' && !isFinite(pv) ? null : O.toISOString();
	    }
	});

	var padStart = stringPad.start;
	var abs$6 = Math.abs;
	var DatePrototype = Date.prototype;
	var getTime = DatePrototype.getTime;
	var nativeDateToISOString = DatePrototype.toISOString;
	var dateToIsoString = fails(function () {
	    return nativeDateToISOString.call(new Date(-5e13 - 1)) != '0385-07-25T07:06:39.999Z';
	}) || !fails(function () {
	    nativeDateToISOString.call(new Date(NaN));
	}) ? function toISOString() {
	    if (!isFinite(getTime.call(this))) 
	        { throw RangeError('Invalid time value'); }
	    var date = this;
	    var year = date.getUTCFullYear();
	    var milliseconds = date.getUTCMilliseconds();
	    var sign = year < 0 ? '-' : year > 9999 ? '+' : '';
	    return sign + padStart(abs$6(year), sign ? 6 : 4, 0) + '-' + padStart(date.getUTCMonth() + 1, 2, 0) + '-' + padStart(date.getUTCDate(), 2, 0) + 'T' + padStart(date.getUTCHours(), 2, 0) + ':' + padStart(date.getUTCMinutes(), 2, 0) + ':' + padStart(date.getUTCSeconds(), 2, 0) + '.' + padStart(milliseconds, 3, 0) + 'Z';
	} : nativeDateToISOString;

	_export({
	    target: 'Date',
	    proto: true,
	    forced: Date.prototype.toISOString !== dateToIsoString
	}, {
	    toISOString: dateToIsoString
	});

	var DatePrototype$1 = Date.prototype;
	var INVALID_DATE = 'Invalid Date';
	var TO_STRING$1 = 'toString';
	var nativeDateToString = DatePrototype$1[TO_STRING$1];
	var getTime$1 = DatePrototype$1.getTime;
	if (new Date(NaN) + '' != INVALID_DATE) {
	    redefine(DatePrototype$1, TO_STRING$1, function toString() {
	        var value = getTime$1.call(this);
	        return value === value ? nativeDateToString.call(this) : INVALID_DATE;
	    });
	}

	var dateToPrimitive = function (hint) {
	    if (hint !== 'string' && hint !== 'number' && hint !== 'default') {
	        throw TypeError('Incorrect hint');
	    }
	    return toPrimitive(anObject(this), hint !== 'number');
	};

	var TO_PRIMITIVE$1 = wellKnownSymbol('toPrimitive');
	var DatePrototype$2 = Date.prototype;
	if (!(TO_PRIMITIVE$1 in DatePrototype$2)) 
	    { hide(DatePrototype$2, TO_PRIMITIVE$1, dateToPrimitive); }

	setToStringTag(global_1.JSON, 'JSON', true);

	var nativePromiseConstructor = global_1.Promise;

	var redefineAll = function (target, src, options) {
	    for (var key in src) 
	        { redefine(target, key, src[key], options); }
	    return target;
	};

	var anInstance = function (it, Constructor, name) {
	    if (!(it instanceof Constructor)) {
	        throw TypeError('Incorrect ' + (name ? name + ' ' : '') + 'invocation');
	    }
	    return it;
	};

	var location = global_1.location;
	var set$1 = global_1.setImmediate;
	var clear = global_1.clearImmediate;
	var process = global_1.process;
	var MessageChannel = global_1.MessageChannel;
	var Dispatch = global_1.Dispatch;
	var counter = 0;
	var queue = {};
	var ONREADYSTATECHANGE = 'onreadystatechange';
	var defer, channel, port;
	var run = function (id) {
	    if (queue.hasOwnProperty(id)) {
	        var fn = queue[id];
	        delete queue[id];
	        fn();
	    }
	};
	var runner = function (id) {
	    return function () {
	        run(id);
	    };
	};
	var listener = function (event) {
	    run(event.data);
	};
	var post = function (id) {
	    global_1.postMessage(id + '', location.protocol + '//' + location.host);
	};
	if (!set$1 || !clear) {
	    set$1 = function setImmediate(fn) {
	        var arguments$1 = arguments;

	        var args = [];
	        var i = 1;
	        while (arguments.length > i) 
	            { args.push(arguments$1[i++]); }
	        queue[++counter] = function () {
	            (typeof fn == 'function' ? fn : Function(fn)).apply(undefined, args);
	        };
	        defer(counter);
	        return counter;
	    };
	    clear = function clearImmediate(id) {
	        delete queue[id];
	    };
	    if (classofRaw(process) == 'process') {
	        defer = function (id) {
	            process.nextTick(runner(id));
	        };
	    } else if (Dispatch && Dispatch.now) {
	        defer = function (id) {
	            Dispatch.now(runner(id));
	        };
	    } else if (MessageChannel) {
	        channel = new MessageChannel();
	        port = channel.port2;
	        channel.port1.onmessage = listener;
	        defer = bindContext(port.postMessage, port, 1);
	    } else if (global_1.addEventListener && typeof postMessage == 'function' && !global_1.importScripts && !fails(post)) {
	        defer = post;
	        global_1.addEventListener('message', listener, false);
	    } else if (ONREADYSTATECHANGE in documentCreateElement('script')) {
	        defer = function (id) {
	            html.appendChild(documentCreateElement('script'))[ONREADYSTATECHANGE] = function () {
	                html.removeChild(this);
	                run(id);
	            };
	        };
	    } else {
	        defer = function (id) {
	            setTimeout(runner(id), 0);
	        };
	    }
	}
	var task = {
	    set: set$1,
	    clear: clear
	};

	var getOwnPropertyDescriptor$5 = objectGetOwnPropertyDescriptor.f;
	var macrotask = task.set;
	var MutationObserver$1 = global_1.MutationObserver || global_1.WebKitMutationObserver;
	var process$1 = global_1.process;
	var Promise$1 = global_1.Promise;
	var IS_NODE = classofRaw(process$1) == 'process';
	var queueMicrotaskDescriptor = getOwnPropertyDescriptor$5(global_1, 'queueMicrotask');
	var queueMicrotask = queueMicrotaskDescriptor && queueMicrotaskDescriptor.value;
	var flush, head, last, notify, toggle, node, promise, then;
	if (!queueMicrotask) {
	    flush = function () {
	        var parent, fn;
	        if (IS_NODE && (parent = process$1.domain)) 
	            { parent.exit(); }
	        while (head) {
	            fn = head.fn;
	            head = head.next;
	            try {
	                fn();
	            } catch (error) {
	                if (head) 
	                    { notify(); }
	                 else 
	                    { last = undefined; }
	                throw error;
	            }
	        }
	        last = undefined;
	        if (parent) 
	            { parent.enter(); }
	    };
	    if (IS_NODE) {
	        notify = function () {
	            process$1.nextTick(flush);
	        };
	    } else if (MutationObserver$1 && !/(iphone|ipod|ipad).*applewebkit/i.test(userAgent)) {
	        toggle = true;
	        node = document.createTextNode('');
	        new MutationObserver$1(flush).observe(node, {
	            characterData: true
	        });
	        notify = function () {
	            node.data = (toggle = !toggle);
	        };
	    } else if (Promise$1 && Promise$1.resolve) {
	        promise = Promise$1.resolve(undefined);
	        then = promise.then;
	        notify = function () {
	            then.call(promise, flush);
	        };
	    } else {
	        notify = function () {
	            macrotask.call(global_1, flush);
	        };
	    }
	}
	var microtask = queueMicrotask || function (fn) {
	    var task = {
	        fn: fn,
	        next: undefined
	    };
	    if (last) 
	        { last.next = task; }
	    if (!head) {
	        head = task;
	        notify();
	    }
	    last = task;
	};

	var PromiseCapability = function (C) {
	    var resolve, reject;
	    this.promise = new C(function ($$resolve, $$reject) {
	        if (resolve !== undefined || reject !== undefined) 
	            { throw TypeError('Bad Promise constructor'); }
	        resolve = $$resolve;
	        reject = $$reject;
	    });
	    this.resolve = aFunction$1(resolve);
	    this.reject = aFunction$1(reject);
	};
	var f$7 = function (C) {
	    return new PromiseCapability(C);
	};
	var newPromiseCapability = {
	    f: f$7
	};

	var promiseResolve = function (C, x) {
	    anObject(C);
	    if (isObject(x) && x.constructor === C) 
	        { return x; }
	    var promiseCapability = newPromiseCapability.f(C);
	    var resolve = promiseCapability.resolve;
	    resolve(x);
	    return promiseCapability.promise;
	};

	var hostReportErrors = function (a, b) {
	    var console = global_1.console;
	    if (console && console.error) {
	        arguments.length === 1 ? console.error(a) : console.error(a, b);
	    }
	};

	var perform = function (exec) {
	    try {
	        return {
	            error: false,
	            value: exec()
	        };
	    } catch (error) {
	        return {
	            error: true,
	            value: error
	        };
	    }
	};

	var task$1 = task.set;
	var SPECIES$6 = wellKnownSymbol('species');
	var PROMISE = 'Promise';
	var getInternalState$4 = internalState.get;
	var setInternalState$4 = internalState.set;
	var getInternalPromiseState = internalState.getterFor(PROMISE);
	var PromiseConstructor = nativePromiseConstructor;
	var TypeError$1 = global_1.TypeError;
	var document$2 = global_1.document;
	var process$2 = global_1.process;
	var $fetch = global_1.fetch;
	var versions = process$2 && process$2.versions;
	var v8 = versions && versions.v8 || '';
	var newPromiseCapability$1 = newPromiseCapability.f;
	var newGenericPromiseCapability = newPromiseCapability$1;
	var IS_NODE$1 = classofRaw(process$2) == 'process';
	var DISPATCH_EVENT = !(!(document$2 && document$2.createEvent && global_1.dispatchEvent));
	var UNHANDLED_REJECTION = 'unhandledrejection';
	var REJECTION_HANDLED = 'rejectionhandled';
	var PENDING = 0;
	var FULFILLED = 1;
	var REJECTED = 2;
	var HANDLED = 1;
	var UNHANDLED = 2;
	var Internal, OwnPromiseCapability, PromiseWrapper, nativeThen;
	var FORCED$e = isForced_1(PROMISE, function () {
	    var promise = PromiseConstructor.resolve(1);
	    var empty = function () {};
	    var FakePromise = (promise.constructor = {})[SPECIES$6] = function (exec) {
	        exec(empty, empty);
	    };
	    return !((IS_NODE$1 || typeof PromiseRejectionEvent == 'function') && (!isPure || promise['finally']) && promise.then(empty) instanceof FakePromise && v8.indexOf('6.6') !== 0 && userAgent.indexOf('Chrome/66') === -1);
	});
	var INCORRECT_ITERATION$1 = FORCED$e || !checkCorrectnessOfIteration(function (iterable) {
	    PromiseConstructor.all(iterable)['catch'](function () {});
	});
	var isThenable = function (it) {
	    var then;
	    return isObject(it) && typeof (then = it.then) == 'function' ? then : false;
	};
	var notify$1 = function (promise, state, isReject) {
	    if (state.notified) 
	        { return; }
	    state.notified = true;
	    var chain = state.reactions;
	    microtask(function () {
	        var value = state.value;
	        var ok = state.state == FULFILLED;
	        var index = 0;
	        while (chain.length > index) {
	            var reaction = chain[index++];
	            var handler = ok ? reaction.ok : reaction.fail;
	            var resolve = reaction.resolve;
	            var reject = reaction.reject;
	            var domain = reaction.domain;
	            var result, then, exited;
	            try {
	                if (handler) {
	                    if (!ok) {
	                        if (state.rejection === UNHANDLED) 
	                            { onHandleUnhandled(promise, state); }
	                        state.rejection = HANDLED;
	                    }
	                    if (handler === true) 
	                        { result = value; }
	                     else {
	                        if (domain) 
	                            { domain.enter(); }
	                        result = handler(value);
	                        if (domain) {
	                            domain.exit();
	                            exited = true;
	                        }
	                    }
	                    if (result === reaction.promise) {
	                        reject(TypeError$1('Promise-chain cycle'));
	                    } else if (then = isThenable(result)) {
	                        then.call(result, resolve, reject);
	                    } else 
	                        { resolve(result); }
	                } else 
	                    { reject(value); }
	            } catch (error) {
	                if (domain && !exited) 
	                    { domain.exit(); }
	                reject(error);
	            }
	        }
	        state.reactions = [];
	        state.notified = false;
	        if (isReject && !state.rejection) 
	            { onUnhandled(promise, state); }
	    });
	};
	var dispatchEvent = function (name, promise, reason) {
	    var event, handler;
	    if (DISPATCH_EVENT) {
	        event = document$2.createEvent('Event');
	        event.promise = promise;
	        event.reason = reason;
	        event.initEvent(name, false, true);
	        global_1.dispatchEvent(event);
	    } else 
	        { event = {
	        promise: promise,
	        reason: reason
	    }; }
	    if (handler = global_1['on' + name]) 
	        { handler(event); }
	     else if (name === UNHANDLED_REJECTION) 
	        { hostReportErrors('Unhandled promise rejection', reason); }
	};
	var onUnhandled = function (promise, state) {
	    task$1.call(global_1, function () {
	        var value = state.value;
	        var IS_UNHANDLED = isUnhandled(state);
	        var result;
	        if (IS_UNHANDLED) {
	            result = perform(function () {
	                if (IS_NODE$1) {
	                    process$2.emit('unhandledRejection', value, promise);
	                } else 
	                    { dispatchEvent(UNHANDLED_REJECTION, promise, value); }
	            });
	            state.rejection = IS_NODE$1 || isUnhandled(state) ? UNHANDLED : HANDLED;
	            if (result.error) 
	                { throw result.value; }
	        }
	    });
	};
	var isUnhandled = function (state) {
	    return state.rejection !== HANDLED && !state.parent;
	};
	var onHandleUnhandled = function (promise, state) {
	    task$1.call(global_1, function () {
	        if (IS_NODE$1) {
	            process$2.emit('rejectionHandled', promise);
	        } else 
	            { dispatchEvent(REJECTION_HANDLED, promise, state.value); }
	    });
	};
	var bind = function (fn, promise, state, unwrap) {
	    return function (value) {
	        fn(promise, state, value, unwrap);
	    };
	};
	var internalReject = function (promise, state, value, unwrap) {
	    if (state.done) 
	        { return; }
	    state.done = true;
	    if (unwrap) 
	        { state = unwrap; }
	    state.value = value;
	    state.state = REJECTED;
	    notify$1(promise, state, true);
	};
	var internalResolve = function (promise, state, value, unwrap) {
	    if (state.done) 
	        { return; }
	    state.done = true;
	    if (unwrap) 
	        { state = unwrap; }
	    try {
	        if (promise === value) 
	            { throw TypeError$1("Promise can't be resolved itself"); }
	        var then = isThenable(value);
	        if (then) {
	            microtask(function () {
	                var wrapper = {
	                    done: false
	                };
	                try {
	                    then.call(value, bind(internalResolve, promise, wrapper, state), bind(internalReject, promise, wrapper, state));
	                } catch (error) {
	                    internalReject(promise, wrapper, error, state);
	                }
	            });
	        } else {
	            state.value = value;
	            state.state = FULFILLED;
	            notify$1(promise, state, false);
	        }
	    } catch (error) {
	        internalReject(promise, {
	            done: false
	        }, error, state);
	    }
	};
	if (FORCED$e) {
	    PromiseConstructor = function Promise(executor) {
	        anInstance(this, PromiseConstructor, PROMISE);
	        aFunction$1(executor);
	        Internal.call(this);
	        var state = getInternalState$4(this);
	        try {
	            executor(bind(internalResolve, this, state), bind(internalReject, this, state));
	        } catch (error) {
	            internalReject(this, state, error);
	        }
	    };
	    Internal = function Promise(executor) {
	        setInternalState$4(this, {
	            type: PROMISE,
	            done: false,
	            notified: false,
	            parent: false,
	            reactions: [],
	            rejection: false,
	            state: PENDING,
	            value: undefined
	        });
	    };
	    Internal.prototype = redefineAll(PromiseConstructor.prototype, {
	        then: function then(onFulfilled, onRejected) {
	            var state = getInternalPromiseState(this);
	            var reaction = newPromiseCapability$1(speciesConstructor(this, PromiseConstructor));
	            reaction.ok = typeof onFulfilled == 'function' ? onFulfilled : true;
	            reaction.fail = typeof onRejected == 'function' && onRejected;
	            reaction.domain = IS_NODE$1 ? process$2.domain : undefined;
	            state.parent = true;
	            state.reactions.push(reaction);
	            if (state.state != PENDING) 
	                { notify$1(this, state, false); }
	            return reaction.promise;
	        },
	        'catch': function (onRejected) {
	            return this.then(undefined, onRejected);
	        }
	    });
	    OwnPromiseCapability = function () {
	        var promise = new Internal();
	        var state = getInternalState$4(promise);
	        this.promise = promise;
	        this.resolve = bind(internalResolve, promise, state);
	        this.reject = bind(internalReject, promise, state);
	    };
	    newPromiseCapability.f = (newPromiseCapability$1 = function (C) {
	        return C === PromiseConstructor || C === PromiseWrapper ? new OwnPromiseCapability(C) : newGenericPromiseCapability(C);
	    });
	    if ( typeof nativePromiseConstructor == 'function') {
	        nativeThen = nativePromiseConstructor.prototype.then;
	        redefine(nativePromiseConstructor.prototype, 'then', function then(onFulfilled, onRejected) {
	            var that = this;
	            return new PromiseConstructor(function (resolve, reject) {
	                nativeThen.call(that, resolve, reject);
	            }).then(onFulfilled, onRejected);
	        });
	        if (typeof $fetch == 'function') 
	            { _export({
	            global: true,
	            enumerable: true,
	            forced: true
	        }, {
	            fetch: function fetch(input) {
	                return promiseResolve(PromiseConstructor, $fetch.apply(global_1, arguments));
	            }
	        }); }
	    }
	}
	_export({
	    global: true,
	    wrap: true,
	    forced: FORCED$e
	}, {
	    Promise: PromiseConstructor
	});
	setToStringTag(PromiseConstructor, PROMISE, false);
	setSpecies(PROMISE);
	PromiseWrapper = path[PROMISE];
	_export({
	    target: PROMISE,
	    stat: true,
	    forced: FORCED$e
	}, {
	    reject: function reject(r) {
	        var capability = newPromiseCapability$1(this);
	        capability.reject.call(undefined, r);
	        return capability.promise;
	    }
	});
	_export({
	    target: PROMISE,
	    stat: true,
	    forced:  FORCED$e
	}, {
	    resolve: function resolve(x) {
	        return promiseResolve( this, x);
	    }
	});
	_export({
	    target: PROMISE,
	    stat: true,
	    forced: INCORRECT_ITERATION$1
	}, {
	    all: function all(iterable) {
	        var C = this;
	        var capability = newPromiseCapability$1(C);
	        var resolve = capability.resolve;
	        var reject = capability.reject;
	        var result = perform(function () {
	            var $promiseResolve = aFunction$1(C.resolve);
	            var values = [];
	            var counter = 0;
	            var remaining = 1;
	            iterate_1(iterable, function (promise) {
	                var index = counter++;
	                var alreadyCalled = false;
	                values.push(undefined);
	                remaining++;
	                $promiseResolve.call(C, promise).then(function (value) {
	                    if (alreadyCalled) 
	                        { return; }
	                    alreadyCalled = true;
	                    values[index] = value;
	                    --remaining || resolve(values);
	                }, reject);
	            });
	            --remaining || resolve(values);
	        });
	        if (result.error) 
	            { reject(result.value); }
	        return capability.promise;
	    },
	    race: function race(iterable) {
	        var C = this;
	        var capability = newPromiseCapability$1(C);
	        var reject = capability.reject;
	        var result = perform(function () {
	            var $promiseResolve = aFunction$1(C.resolve);
	            iterate_1(iterable, function (promise) {
	                $promiseResolve.call(C, promise).then(capability.resolve, reject);
	            });
	        });
	        if (result.error) 
	            { reject(result.value); }
	        return capability.promise;
	    }
	});

	_export({
	    target: 'Promise',
	    stat: true
	}, {
	    allSettled: function allSettled(iterable) {
	        var C = this;
	        var capability = newPromiseCapability.f(C);
	        var resolve = capability.resolve;
	        var reject = capability.reject;
	        var result = perform(function () {
	            var promiseResolve = aFunction$1(C.resolve);
	            var values = [];
	            var counter = 0;
	            var remaining = 1;
	            iterate_1(iterable, function (promise) {
	                var index = counter++;
	                var alreadyCalled = false;
	                values.push(undefined);
	                remaining++;
	                promiseResolve.call(C, promise).then(function (value) {
	                    if (alreadyCalled) 
	                        { return; }
	                    alreadyCalled = true;
	                    values[index] = {
	                        status: 'fulfilled',
	                        value: value
	                    };
	                    --remaining || resolve(values);
	                }, function (e) {
	                    if (alreadyCalled) 
	                        { return; }
	                    alreadyCalled = true;
	                    values[index] = {
	                        status: 'rejected',
	                        reason: e
	                    };
	                    --remaining || resolve(values);
	                });
	            });
	            --remaining || resolve(values);
	        });
	        if (result.error) 
	            { reject(result.value); }
	        return capability.promise;
	    }
	});

	_export({
	    target: 'Promise',
	    proto: true,
	    real: true
	}, {
	    'finally': function (onFinally) {
	        var C = speciesConstructor(this, getBuiltIn('Promise'));
	        var isFunction = typeof onFinally == 'function';
	        return this.then(isFunction ? function (x) {
	            return promiseResolve(C, onFinally()).then(function () {
	                return x;
	            });
	        } : onFinally, isFunction ? function (e) {
	            return promiseResolve(C, onFinally()).then(function () {
	                throw e;
	            });
	        } : onFinally);
	    }
	});
	if ( typeof nativePromiseConstructor == 'function' && !nativePromiseConstructor.prototype['finally']) {
	    redefine(nativePromiseConstructor.prototype, 'finally', getBuiltIn('Promise').prototype['finally']);
	}

	var collection = function (CONSTRUCTOR_NAME, wrapper, common, IS_MAP, IS_WEAK) {
	    var NativeConstructor = global_1[CONSTRUCTOR_NAME];
	    var NativePrototype = NativeConstructor && NativeConstructor.prototype;
	    var Constructor = NativeConstructor;
	    var ADDER = IS_MAP ? 'set' : 'add';
	    var exported = {};
	    var fixMethod = function (KEY) {
	        var nativeMethod = NativePrototype[KEY];
	        redefine(NativePrototype, KEY, KEY == 'add' ? function add(value) {
	            nativeMethod.call(this, value === 0 ? 0 : value);
	            return this;
	        } : KEY == 'delete' ? function (key) {
	            return IS_WEAK && !isObject(key) ? false : nativeMethod.call(this, key === 0 ? 0 : key);
	        } : KEY == 'get' ? function get(key) {
	            return IS_WEAK && !isObject(key) ? undefined : nativeMethod.call(this, key === 0 ? 0 : key);
	        } : KEY == 'has' ? function has(key) {
	            return IS_WEAK && !isObject(key) ? false : nativeMethod.call(this, key === 0 ? 0 : key);
	        } : function set(key, value) {
	            nativeMethod.call(this, key === 0 ? 0 : key, value);
	            return this;
	        });
	    };
	    if (isForced_1(CONSTRUCTOR_NAME, typeof NativeConstructor != 'function' || !(IS_WEAK || NativePrototype.forEach && !fails(function () {
	        new NativeConstructor().entries().next();
	    })))) {
	        Constructor = common.getConstructor(wrapper, CONSTRUCTOR_NAME, IS_MAP, ADDER);
	        internalMetadata.REQUIRED = true;
	    } else if (isForced_1(CONSTRUCTOR_NAME, true)) {
	        var instance = new Constructor();
	        var HASNT_CHAINING = instance[ADDER](IS_WEAK ? {} : -0, 1) != instance;
	        var THROWS_ON_PRIMITIVES = fails(function () {
	            instance.has(1);
	        });
	        var ACCEPT_ITERABLES = checkCorrectnessOfIteration(function (iterable) {
	            new NativeConstructor(iterable);
	        });
	        var BUGGY_ZERO = !IS_WEAK && fails(function () {
	            var $instance = new NativeConstructor();
	            var index = 5;
	            while (index--) 
	                { $instance[ADDER](index, index); }
	            return !$instance.has(-0);
	        });
	        if (!ACCEPT_ITERABLES) {
	            Constructor = wrapper(function (dummy, iterable) {
	                anInstance(dummy, Constructor, CONSTRUCTOR_NAME);
	                var that = inheritIfRequired(new NativeConstructor(), dummy, Constructor);
	                if (iterable != undefined) 
	                    { iterate_1(iterable, that[ADDER], that, IS_MAP); }
	                return that;
	            });
	            Constructor.prototype = NativePrototype;
	            NativePrototype.constructor = Constructor;
	        }
	        if (THROWS_ON_PRIMITIVES || BUGGY_ZERO) {
	            fixMethod('delete');
	            fixMethod('has');
	            IS_MAP && fixMethod('get');
	        }
	        if (BUGGY_ZERO || HASNT_CHAINING) 
	            { fixMethod(ADDER); }
	        if (IS_WEAK && NativePrototype.clear) 
	            { delete NativePrototype.clear; }
	    }
	    exported[CONSTRUCTOR_NAME] = Constructor;
	    _export({
	        global: true,
	        forced: Constructor != NativeConstructor
	    }, exported);
	    setToStringTag(Constructor, CONSTRUCTOR_NAME);
	    if (!IS_WEAK) 
	        { common.setStrong(Constructor, CONSTRUCTOR_NAME, IS_MAP); }
	    return Constructor;
	};

	var defineProperty$6 = objectDefineProperty.f;
	var fastKey = internalMetadata.fastKey;
	var setInternalState$5 = internalState.set;
	var internalStateGetterFor = internalState.getterFor;
	var collectionStrong = {
	    getConstructor: function (wrapper, CONSTRUCTOR_NAME, IS_MAP, ADDER) {
	        var C = wrapper(function (that, iterable) {
	            anInstance(that, C, CONSTRUCTOR_NAME);
	            setInternalState$5(that, {
	                type: CONSTRUCTOR_NAME,
	                index: objectCreate(null),
	                first: undefined,
	                last: undefined,
	                size: 0
	            });
	            if (!descriptors) 
	                { that.size = 0; }
	            if (iterable != undefined) 
	                { iterate_1(iterable, that[ADDER], that, IS_MAP); }
	        });
	        var getInternalState = internalStateGetterFor(CONSTRUCTOR_NAME);
	        var define = function (that, key, value) {
	            var state = getInternalState(that);
	            var entry = getEntry(that, key);
	            var previous, index;
	            if (entry) {
	                entry.value = value;
	            } else {
	                state.last = (entry = {
	                    index: index = fastKey(key, true),
	                    key: key,
	                    value: value,
	                    previous: previous = state.last,
	                    next: undefined,
	                    removed: false
	                });
	                if (!state.first) 
	                    { state.first = entry; }
	                if (previous) 
	                    { previous.next = entry; }
	                if (descriptors) 
	                    { state.size++; }
	                 else 
	                    { that.size++; }
	                if (index !== 'F') 
	                    { state.index[index] = entry; }
	            }
	            return that;
	        };
	        var getEntry = function (that, key) {
	            var state = getInternalState(that);
	            var index = fastKey(key);
	            var entry;
	            if (index !== 'F') 
	                { return state.index[index]; }
	            for (entry = state.first; entry; entry = entry.next) {
	                if (entry.key == key) 
	                    { return entry; }
	            }
	        };
	        redefineAll(C.prototype, {
	            clear: function clear() {
	                var that = this;
	                var state = getInternalState(that);
	                var data = state.index;
	                var entry = state.first;
	                while (entry) {
	                    entry.removed = true;
	                    if (entry.previous) 
	                        { entry.previous = (entry.previous.next = undefined); }
	                    delete data[entry.index];
	                    entry = entry.next;
	                }
	                state.first = (state.last = undefined);
	                if (descriptors) 
	                    { state.size = 0; }
	                 else 
	                    { that.size = 0; }
	            },
	            'delete': function (key) {
	                var that = this;
	                var state = getInternalState(that);
	                var entry = getEntry(that, key);
	                if (entry) {
	                    var next = entry.next;
	                    var prev = entry.previous;
	                    delete state.index[entry.index];
	                    entry.removed = true;
	                    if (prev) 
	                        { prev.next = next; }
	                    if (next) 
	                        { next.previous = prev; }
	                    if (state.first == entry) 
	                        { state.first = next; }
	                    if (state.last == entry) 
	                        { state.last = prev; }
	                    if (descriptors) 
	                        { state.size--; }
	                     else 
	                        { that.size--; }
	                }
	                return !(!entry);
	            },
	            forEach: function forEach(callbackfn) {
	                var state = getInternalState(this);
	                var boundFunction = bindContext(callbackfn, arguments.length > 1 ? arguments[1] : undefined, 3);
	                var entry;
	                while (entry = entry ? entry.next : state.first) {
	                    boundFunction(entry.value, entry.key, this);
	                    while (entry && entry.removed) 
	                        { entry = entry.previous; }
	                }
	            },
	            has: function has(key) {
	                return !(!getEntry(this, key));
	            }
	        });
	        redefineAll(C.prototype, IS_MAP ? {
	            get: function get(key) {
	                var entry = getEntry(this, key);
	                return entry && entry.value;
	            },
	            set: function set(key, value) {
	                return define(this, key === 0 ? 0 : key, value);
	            }
	        } : {
	            add: function add(value) {
	                return define(this, value = value === 0 ? 0 : value, value);
	            }
	        });
	        if (descriptors) 
	            { defineProperty$6(C.prototype, 'size', {
	            get: function () {
	                return getInternalState(this).size;
	            }
	        }); }
	        return C;
	    },
	    setStrong: function (C, CONSTRUCTOR_NAME, IS_MAP) {
	        var ITERATOR_NAME = CONSTRUCTOR_NAME + ' Iterator';
	        var getInternalCollectionState = internalStateGetterFor(CONSTRUCTOR_NAME);
	        var getInternalIteratorState = internalStateGetterFor(ITERATOR_NAME);
	        defineIterator(C, CONSTRUCTOR_NAME, function (iterated, kind) {
	            setInternalState$5(this, {
	                type: ITERATOR_NAME,
	                target: iterated,
	                state: getInternalCollectionState(iterated),
	                kind: kind,
	                last: undefined
	            });
	        }, function () {
	            var state = getInternalIteratorState(this);
	            var kind = state.kind;
	            var entry = state.last;
	            while (entry && entry.removed) 
	                { entry = entry.previous; }
	            if (!state.target || !(state.last = (entry = entry ? entry.next : state.state.first))) {
	                state.target = undefined;
	                return {
	                    value: undefined,
	                    done: true
	                };
	            }
	            if (kind == 'keys') 
	                { return {
	                value: entry.key,
	                done: false
	            }; }
	            if (kind == 'values') 
	                { return {
	                value: entry.value,
	                done: false
	            }; }
	            return {
	                value: [entry.key,entry.value],
	                done: false
	            };
	        }, IS_MAP ? 'entries' : 'values', !IS_MAP, true);
	        setSpecies(CONSTRUCTOR_NAME);
	    }
	};

	var es_map = collection('Map', function (get) {
	    return function Map() {
	        return get(this, arguments.length ? arguments[0] : undefined);
	    };
	}, collectionStrong, true);

	var es_set = collection('Set', function (get) {
	    return function Set() {
	        return get(this, arguments.length ? arguments[0] : undefined);
	    };
	}, collectionStrong);

	var getWeakData = internalMetadata.getWeakData;
	var setInternalState$6 = internalState.set;
	var internalStateGetterFor$1 = internalState.getterFor;
	var find = arrayIteration.find;
	var findIndex = arrayIteration.findIndex;
	var id$1 = 0;
	var uncaughtFrozenStore = function (store) {
	    return store.frozen || (store.frozen = new UncaughtFrozenStore());
	};
	var UncaughtFrozenStore = function () {
	    this.entries = [];
	};
	var findUncaughtFrozen = function (store, key) {
	    return find(store.entries, function (it) {
	        return it[0] === key;
	    });
	};
	UncaughtFrozenStore.prototype = {
	    get: function (key) {
	        var entry = findUncaughtFrozen(this, key);
	        if (entry) 
	            { return entry[1]; }
	    },
	    has: function (key) {
	        return !(!findUncaughtFrozen(this, key));
	    },
	    set: function (key, value) {
	        var entry = findUncaughtFrozen(this, key);
	        if (entry) 
	            { entry[1] = value; }
	         else 
	            { this.entries.push([key,value]); }
	    },
	    'delete': function (key) {
	        var index = findIndex(this.entries, function (it) {
	            return it[0] === key;
	        });
	        if (~index) 
	            { this.entries.splice(index, 1); }
	        return !(!(~index));
	    }
	};
	var collectionWeak = {
	    getConstructor: function (wrapper, CONSTRUCTOR_NAME, IS_MAP, ADDER) {
	        var C = wrapper(function (that, iterable) {
	            anInstance(that, C, CONSTRUCTOR_NAME);
	            setInternalState$6(that, {
	                type: CONSTRUCTOR_NAME,
	                id: id$1++,
	                frozen: undefined
	            });
	            if (iterable != undefined) 
	                { iterate_1(iterable, that[ADDER], that, IS_MAP); }
	        });
	        var getInternalState = internalStateGetterFor$1(CONSTRUCTOR_NAME);
	        var define = function (that, key, value) {
	            var state = getInternalState(that);
	            var data = getWeakData(anObject(key), true);
	            if (data === true) 
	                { uncaughtFrozenStore(state).set(key, value); }
	             else 
	                { data[state.id] = value; }
	            return that;
	        };
	        redefineAll(C.prototype, {
	            'delete': function (key) {
	                var state = getInternalState(this);
	                if (!isObject(key)) 
	                    { return false; }
	                var data = getWeakData(key);
	                if (data === true) 
	                    { return uncaughtFrozenStore(state)['delete'](key); }
	                return data && has(data, state.id) && delete data[state.id];
	            },
	            has: function has$1(key) {
	                var state = getInternalState(this);
	                if (!isObject(key)) 
	                    { return false; }
	                var data = getWeakData(key);
	                if (data === true) 
	                    { return uncaughtFrozenStore(state).has(key); }
	                return data && has(data, state.id);
	            }
	        });
	        redefineAll(C.prototype, IS_MAP ? {
	            get: function get(key) {
	                var state = getInternalState(this);
	                if (isObject(key)) {
	                    var data = getWeakData(key);
	                    if (data === true) 
	                        { return uncaughtFrozenStore(state).get(key); }
	                    return data ? data[state.id] : undefined;
	                }
	            },
	            set: function set(key, value) {
	                return define(this, key, value);
	            }
	        } : {
	            add: function add(value) {
	                return define(this, value, true);
	            }
	        });
	        return C;
	    }
	};

	var es_weakMap = createCommonjsModule(function (module) {
	    var enforceIternalState = internalState.enforce;
	    var IS_IE11 = !global_1.ActiveXObject && 'ActiveXObject' in global_1;
	    var isExtensible = Object.isExtensible;
	    var InternalWeakMap;
	    var wrapper = function (get) {
	        return function WeakMap() {
	            return get(this, arguments.length ? arguments[0] : undefined);
	        };
	    };
	    var $WeakMap = module.exports = collection('WeakMap', wrapper, collectionWeak, true, true);
	    if (nativeWeakMap && IS_IE11) {
	        InternalWeakMap = collectionWeak.getConstructor(wrapper, 'WeakMap', true);
	        internalMetadata.REQUIRED = true;
	        var WeakMapPrototype = $WeakMap.prototype;
	        var nativeDelete = WeakMapPrototype['delete'];
	        var nativeHas = WeakMapPrototype.has;
	        var nativeGet = WeakMapPrototype.get;
	        var nativeSet = WeakMapPrototype.set;
	        redefineAll(WeakMapPrototype, {
	            'delete': function (key) {
	                if (isObject(key) && !isExtensible(key)) {
	                    var state = enforceIternalState(this);
	                    if (!state.frozen) 
	                        { state.frozen = new InternalWeakMap(); }
	                    return nativeDelete.call(this, key) || state.frozen['delete'](key);
	                }
	                return nativeDelete.call(this, key);
	            },
	            has: function has(key) {
	                if (isObject(key) && !isExtensible(key)) {
	                    var state = enforceIternalState(this);
	                    if (!state.frozen) 
	                        { state.frozen = new InternalWeakMap(); }
	                    return nativeHas.call(this, key) || state.frozen.has(key);
	                }
	                return nativeHas.call(this, key);
	            },
	            get: function get(key) {
	                if (isObject(key) && !isExtensible(key)) {
	                    var state = enforceIternalState(this);
	                    if (!state.frozen) 
	                        { state.frozen = new InternalWeakMap(); }
	                    return nativeHas.call(this, key) ? nativeGet.call(this, key) : state.frozen.get(key);
	                }
	                return nativeGet.call(this, key);
	            },
	            set: function set(key, value) {
	                if (isObject(key) && !isExtensible(key)) {
	                    var state = enforceIternalState(this);
	                    if (!state.frozen) 
	                        { state.frozen = new InternalWeakMap(); }
	                    nativeHas.call(this, key) ? nativeSet.call(this, key, value) : state.frozen.set(key, value);
	                } else 
	                    { nativeSet.call(this, key, value); }
	                return this;
	            }
	        });
	    }
	});

	collection('WeakSet', function (get) {
	    return function WeakSet() {
	        return get(this, arguments.length ? arguments[0] : undefined);
	    };
	}, collectionWeak, false, true);

	var defineProperty$7 = objectDefineProperty.f;
	var DataView = global_1.DataView;
	var DataViewPrototype = DataView && DataView.prototype;
	var Int8Array$1 = global_1.Int8Array;
	var Int8ArrayPrototype = Int8Array$1 && Int8Array$1.prototype;
	var Uint8ClampedArray = global_1.Uint8ClampedArray;
	var Uint8ClampedArrayPrototype = Uint8ClampedArray && Uint8ClampedArray.prototype;
	var TypedArray = Int8Array$1 && objectGetPrototypeOf(Int8Array$1);
	var TypedArrayPrototype = Int8ArrayPrototype && objectGetPrototypeOf(Int8ArrayPrototype);
	var ObjectPrototype$3 = Object.prototype;
	var isPrototypeOf = ObjectPrototype$3.isPrototypeOf;
	var TO_STRING_TAG$3 = wellKnownSymbol('toStringTag');
	var TYPED_ARRAY_TAG = uid('TYPED_ARRAY_TAG');
	var NATIVE_ARRAY_BUFFER = !(!(global_1.ArrayBuffer && DataView));
	var NATIVE_ARRAY_BUFFER_VIEWS = NATIVE_ARRAY_BUFFER && !(!objectSetPrototypeOf) && classof(global_1.opera) !== 'Opera';
	var TYPED_ARRAY_TAG_REQIRED = false;
	var NAME$1;
	var TypedArrayConstructorsList = {
	    Int8Array: 1,
	    Uint8Array: 1,
	    Uint8ClampedArray: 1,
	    Int16Array: 2,
	    Uint16Array: 2,
	    Int32Array: 4,
	    Uint32Array: 4,
	    Float32Array: 4,
	    Float64Array: 8
	};
	var isView = function isView(it) {
	    var klass = classof(it);
	    return klass === 'DataView' || has(TypedArrayConstructorsList, klass);
	};
	var isTypedArray = function (it) {
	    return isObject(it) && has(TypedArrayConstructorsList, classof(it));
	};
	var aTypedArray = function (it) {
	    if (isTypedArray(it)) 
	        { return it; }
	    throw TypeError('Target is not a typed array');
	};
	var aTypedArrayConstructor = function (C) {
	    if (objectSetPrototypeOf) {
	        if (isPrototypeOf.call(TypedArray, C)) 
	            { return C; }
	    } else 
	        { for (var ARRAY in TypedArrayConstructorsList) 
	        { if (has(TypedArrayConstructorsList, NAME$1)) {
	        var TypedArrayConstructor = global_1[ARRAY];
	        if (TypedArrayConstructor && (C === TypedArrayConstructor || isPrototypeOf.call(TypedArrayConstructor, C))) {
	            return C;
	        }
	    } } }
	    throw TypeError('Target is not a typed array constructor');
	};
	var exportProto = function (KEY, property, forced) {
	    if (!descriptors) 
	        { return; }
	    if (forced) 
	        { for (var ARRAY in TypedArrayConstructorsList) {
	        var TypedArrayConstructor = global_1[ARRAY];
	        if (TypedArrayConstructor && has(TypedArrayConstructor.prototype, KEY)) {
	            delete TypedArrayConstructor.prototype[KEY];
	        }
	    } }
	    if (!TypedArrayPrototype[KEY] || forced) {
	        redefine(TypedArrayPrototype, KEY, forced ? property : NATIVE_ARRAY_BUFFER_VIEWS && Int8ArrayPrototype[KEY] || property);
	    }
	};
	var exportStatic = function (KEY, property, forced) {
	    var ARRAY, TypedArrayConstructor;
	    if (!descriptors) 
	        { return; }
	    if (objectSetPrototypeOf) {
	        if (forced) 
	            { for (ARRAY in TypedArrayConstructorsList) {
	            TypedArrayConstructor = global_1[ARRAY];
	            if (TypedArrayConstructor && has(TypedArrayConstructor, KEY)) {
	                delete TypedArrayConstructor[KEY];
	            }
	        } }
	        if (!TypedArray[KEY] || forced) {
	            try {
	                return redefine(TypedArray, KEY, forced ? property : NATIVE_ARRAY_BUFFER_VIEWS && Int8Array$1[KEY] || property);
	            } catch (error) {}
	        } else 
	            { return; }
	    }
	    for (ARRAY in TypedArrayConstructorsList) {
	        TypedArrayConstructor = global_1[ARRAY];
	        if (TypedArrayConstructor && (!TypedArrayConstructor[KEY] || forced)) {
	            redefine(TypedArrayConstructor, KEY, property);
	        }
	    }
	};
	for (NAME$1 in TypedArrayConstructorsList) {
	    if (!global_1[NAME$1]) 
	        { NATIVE_ARRAY_BUFFER_VIEWS = false; }
	}
	if (!NATIVE_ARRAY_BUFFER_VIEWS || typeof TypedArray != 'function' || TypedArray === Function.prototype) {
	    TypedArray = function TypedArray() {
	        throw TypeError('Incorrect invocation');
	    };
	    if (NATIVE_ARRAY_BUFFER_VIEWS) 
	        { for (NAME$1 in TypedArrayConstructorsList) {
	        if (global_1[NAME$1]) 
	            { objectSetPrototypeOf(global_1[NAME$1], TypedArray); }
	    } }
	}
	if (!NATIVE_ARRAY_BUFFER_VIEWS || !TypedArrayPrototype || TypedArrayPrototype === ObjectPrototype$3) {
	    TypedArrayPrototype = TypedArray.prototype;
	    if (NATIVE_ARRAY_BUFFER_VIEWS) 
	        { for (NAME$1 in TypedArrayConstructorsList) {
	        if (global_1[NAME$1]) 
	            { objectSetPrototypeOf(global_1[NAME$1].prototype, TypedArrayPrototype); }
	    } }
	}
	if (NATIVE_ARRAY_BUFFER_VIEWS && objectGetPrototypeOf(Uint8ClampedArrayPrototype) !== TypedArrayPrototype) {
	    objectSetPrototypeOf(Uint8ClampedArrayPrototype, TypedArrayPrototype);
	}
	if (descriptors && !has(TypedArrayPrototype, TO_STRING_TAG$3)) {
	    TYPED_ARRAY_TAG_REQIRED = true;
	    defineProperty$7(TypedArrayPrototype, TO_STRING_TAG$3, {
	        get: function () {
	            return isObject(this) ? this[TYPED_ARRAY_TAG] : undefined;
	        }
	    });
	    for (NAME$1 in TypedArrayConstructorsList) 
	        { if (global_1[NAME$1]) {
	        hide(global_1[NAME$1], TYPED_ARRAY_TAG, NAME$1);
	    } }
	}
	if (NATIVE_ARRAY_BUFFER && objectSetPrototypeOf && objectGetPrototypeOf(DataViewPrototype) !== ObjectPrototype$3) {
	    objectSetPrototypeOf(DataViewPrototype, ObjectPrototype$3);
	}
	var arrayBufferViewCore = {
	    NATIVE_ARRAY_BUFFER: NATIVE_ARRAY_BUFFER,
	    NATIVE_ARRAY_BUFFER_VIEWS: NATIVE_ARRAY_BUFFER_VIEWS,
	    TYPED_ARRAY_TAG: TYPED_ARRAY_TAG_REQIRED && TYPED_ARRAY_TAG,
	    aTypedArray: aTypedArray,
	    aTypedArrayConstructor: aTypedArrayConstructor,
	    exportProto: exportProto,
	    exportStatic: exportStatic,
	    isView: isView,
	    isTypedArray: isTypedArray,
	    TypedArray: TypedArray,
	    TypedArrayPrototype: TypedArrayPrototype
	};

	var toIndex = function (it) {
	    if (it === undefined) 
	        { return 0; }
	    var number = toInteger(it);
	    var length = toLength(number);
	    if (number !== length) 
	        { throw RangeError('Wrong length or index'); }
	    return length;
	};

	var arrayBuffer = createCommonjsModule(function (module, exports) {
	    var NATIVE_ARRAY_BUFFER = arrayBufferViewCore.NATIVE_ARRAY_BUFFER;
	    var getOwnPropertyNames = objectGetOwnPropertyNames.f;
	    var defineProperty = objectDefineProperty.f;
	    var getInternalState = internalState.get;
	    var setInternalState = internalState.set;
	    var ARRAY_BUFFER = 'ArrayBuffer';
	    var DATA_VIEW = 'DataView';
	    var PROTOTYPE = 'prototype';
	    var WRONG_LENGTH = 'Wrong length';
	    var WRONG_INDEX = 'Wrong index';
	    var NativeArrayBuffer = global_1[ARRAY_BUFFER];
	    var $ArrayBuffer = NativeArrayBuffer;
	    var $DataView = global_1[DATA_VIEW];
	    var Math = global_1.Math;
	    var RangeError = global_1.RangeError;
	    var Infinity = 1 / 0;
	    var abs = Math.abs;
	    var pow = Math.pow;
	    var floor = Math.floor;
	    var log = Math.log;
	    var LN2 = Math.LN2;
	    var packIEEE754 = function (number, mantissaLength, bytes) {
	        var buffer = new Array(bytes);
	        var exponentLength = bytes * 8 - mantissaLength - 1;
	        var eMax = (1 << exponentLength) - 1;
	        var eBias = eMax >> 1;
	        var rt = mantissaLength === 23 ? pow(2, -24) - pow(2, -77) : 0;
	        var sign = number < 0 || number === 0 && 1 / number < 0 ? 1 : 0;
	        var index = 0;
	        var exponent, mantissa, c;
	        number = abs(number);
	        if (number != number || number === Infinity) {
	            mantissa = number != number ? 1 : 0;
	            exponent = eMax;
	        } else {
	            exponent = floor(log(number) / LN2);
	            if (number * (c = pow(2, -exponent)) < 1) {
	                exponent--;
	                c *= 2;
	            }
	            if (exponent + eBias >= 1) {
	                number += rt / c;
	            } else {
	                number += rt * pow(2, 1 - eBias);
	            }
	            if (number * c >= 2) {
	                exponent++;
	                c /= 2;
	            }
	            if (exponent + eBias >= eMax) {
	                mantissa = 0;
	                exponent = eMax;
	            } else if (exponent + eBias >= 1) {
	                mantissa = (number * c - 1) * pow(2, mantissaLength);
	                exponent = exponent + eBias;
	            } else {
	                mantissa = number * pow(2, eBias - 1) * pow(2, mantissaLength);
	                exponent = 0;
	            }
	        }
	        for (; mantissaLength >= 8; buffer[index++] = mantissa & 255, mantissa /= 256, mantissaLength -= 8) 
	            { }
	        exponent = exponent << mantissaLength | mantissa;
	        exponentLength += mantissaLength;
	        for (; exponentLength > 0; buffer[index++] = exponent & 255, exponent /= 256, exponentLength -= 8) 
	            { }
	        buffer[--index] |= sign * 128;
	        return buffer;
	    };
	    var unpackIEEE754 = function (buffer, mantissaLength) {
	        var bytes = buffer.length;
	        var exponentLength = bytes * 8 - mantissaLength - 1;
	        var eMax = (1 << exponentLength) - 1;
	        var eBias = eMax >> 1;
	        var nBits = exponentLength - 7;
	        var index = bytes - 1;
	        var sign = buffer[index--];
	        var exponent = sign & 127;
	        var mantissa;
	        sign >>= 7;
	        for (; nBits > 0; exponent = exponent * 256 + buffer[index], index--, nBits -= 8) 
	            { }
	        mantissa = exponent & (1 << -nBits) - 1;
	        exponent >>= -nBits;
	        nBits += mantissaLength;
	        for (; nBits > 0; mantissa = mantissa * 256 + buffer[index], index--, nBits -= 8) 
	            { }
	        if (exponent === 0) {
	            exponent = 1 - eBias;
	        } else if (exponent === eMax) {
	            return mantissa ? NaN : sign ? -Infinity : Infinity;
	        } else {
	            mantissa = mantissa + pow(2, mantissaLength);
	            exponent = exponent - eBias;
	        }
	        return (sign ? -1 : 1) * mantissa * pow(2, exponent - mantissaLength);
	    };
	    var unpackInt32 = function (buffer) {
	        return buffer[3] << 24 | buffer[2] << 16 | buffer[1] << 8 | buffer[0];
	    };
	    var packInt8 = function (number) {
	        return [number & 0xFF];
	    };
	    var packInt16 = function (number) {
	        return [number & 0xFF,number >> 8 & 0xFF];
	    };
	    var packInt32 = function (number) {
	        return [number & 0xFF,number >> 8 & 0xFF,number >> 16 & 0xFF,number >> 24 & 0xFF];
	    };
	    var packFloat32 = function (number) {
	        return packIEEE754(number, 23, 4);
	    };
	    var packFloat64 = function (number) {
	        return packIEEE754(number, 52, 8);
	    };
	    var addGetter = function (Constructor, key) {
	        defineProperty(Constructor[PROTOTYPE], key, {
	            get: function () {
	                return getInternalState(this)[key];
	            }
	        });
	    };
	    var get = function (view, count, index, isLittleEndian) {
	        var numIndex = +index;
	        var intIndex = toIndex(numIndex);
	        var store = getInternalState(view);
	        if (intIndex + count > store.byteLength) 
	            { throw RangeError(WRONG_INDEX); }
	        var bytes = getInternalState(store.buffer).bytes;
	        var start = intIndex + store.byteOffset;
	        var pack = bytes.slice(start, start + count);
	        return isLittleEndian ? pack : pack.reverse();
	    };
	    var set = function (view, count, index, conversion, value, isLittleEndian) {
	        var numIndex = +index;
	        var intIndex = toIndex(numIndex);
	        var store = getInternalState(view);
	        if (intIndex + count > store.byteLength) 
	            { throw RangeError(WRONG_INDEX); }
	        var bytes = getInternalState(store.buffer).bytes;
	        var start = intIndex + store.byteOffset;
	        var pack = conversion(+value);
	        for (var i = 0;i < count; i++) 
	            { bytes[start + i] = pack[isLittleEndian ? i : count - i - 1]; }
	    };
	    if (!NATIVE_ARRAY_BUFFER) {
	        $ArrayBuffer = function ArrayBuffer(length) {
	            anInstance(this, $ArrayBuffer, ARRAY_BUFFER);
	            var byteLength = toIndex(length);
	            setInternalState(this, {
	                bytes: arrayFill.call(new Array(byteLength), 0),
	                byteLength: byteLength
	            });
	            if (!descriptors) 
	                { this.byteLength = byteLength; }
	        };
	        $DataView = function DataView(buffer, byteOffset, byteLength) {
	            anInstance(this, $DataView, DATA_VIEW);
	            anInstance(buffer, $ArrayBuffer, DATA_VIEW);
	            var bufferLength = getInternalState(buffer).byteLength;
	            var offset = toInteger(byteOffset);
	            if (offset < 0 || offset > bufferLength) 
	                { throw RangeError('Wrong offset'); }
	            byteLength = byteLength === undefined ? bufferLength - offset : toLength(byteLength);
	            if (offset + byteLength > bufferLength) 
	                { throw RangeError(WRONG_LENGTH); }
	            setInternalState(this, {
	                buffer: buffer,
	                byteLength: byteLength,
	                byteOffset: offset
	            });
	            if (!descriptors) {
	                this.buffer = buffer;
	                this.byteLength = byteLength;
	                this.byteOffset = offset;
	            }
	        };
	        if (descriptors) {
	            addGetter($ArrayBuffer, 'byteLength');
	            addGetter($DataView, 'buffer');
	            addGetter($DataView, 'byteLength');
	            addGetter($DataView, 'byteOffset');
	        }
	        redefineAll($DataView[PROTOTYPE], {
	            getInt8: function getInt8(byteOffset) {
	                return get(this, 1, byteOffset)[0] << 24 >> 24;
	            },
	            getUint8: function getUint8(byteOffset) {
	                return get(this, 1, byteOffset)[0];
	            },
	            getInt16: function getInt16(byteOffset) {
	                var bytes = get(this, 2, byteOffset, arguments.length > 1 ? arguments[1] : undefined);
	                return (bytes[1] << 8 | bytes[0]) << 16 >> 16;
	            },
	            getUint16: function getUint16(byteOffset) {
	                var bytes = get(this, 2, byteOffset, arguments.length > 1 ? arguments[1] : undefined);
	                return bytes[1] << 8 | bytes[0];
	            },
	            getInt32: function getInt32(byteOffset) {
	                return unpackInt32(get(this, 4, byteOffset, arguments.length > 1 ? arguments[1] : undefined));
	            },
	            getUint32: function getUint32(byteOffset) {
	                return unpackInt32(get(this, 4, byteOffset, arguments.length > 1 ? arguments[1] : undefined)) >>> 0;
	            },
	            getFloat32: function getFloat32(byteOffset) {
	                return unpackIEEE754(get(this, 4, byteOffset, arguments.length > 1 ? arguments[1] : undefined), 23);
	            },
	            getFloat64: function getFloat64(byteOffset) {
	                return unpackIEEE754(get(this, 8, byteOffset, arguments.length > 1 ? arguments[1] : undefined), 52);
	            },
	            setInt8: function setInt8(byteOffset, value) {
	                set(this, 1, byteOffset, packInt8, value);
	            },
	            setUint8: function setUint8(byteOffset, value) {
	                set(this, 1, byteOffset, packInt8, value);
	            },
	            setInt16: function setInt16(byteOffset, value) {
	                set(this, 2, byteOffset, packInt16, value, arguments.length > 2 ? arguments[2] : undefined);
	            },
	            setUint16: function setUint16(byteOffset, value) {
	                set(this, 2, byteOffset, packInt16, value, arguments.length > 2 ? arguments[2] : undefined);
	            },
	            setInt32: function setInt32(byteOffset, value) {
	                set(this, 4, byteOffset, packInt32, value, arguments.length > 2 ? arguments[2] : undefined);
	            },
	            setUint32: function setUint32(byteOffset, value) {
	                set(this, 4, byteOffset, packInt32, value, arguments.length > 2 ? arguments[2] : undefined);
	            },
	            setFloat32: function setFloat32(byteOffset, value) {
	                set(this, 4, byteOffset, packFloat32, value, arguments.length > 2 ? arguments[2] : undefined);
	            },
	            setFloat64: function setFloat64(byteOffset, value) {
	                set(this, 8, byteOffset, packFloat64, value, arguments.length > 2 ? arguments[2] : undefined);
	            }
	        });
	    } else {
	        if (!fails(function () {
	            NativeArrayBuffer(1);
	        }) || !fails(function () {
	            new NativeArrayBuffer(-1);
	        }) || fails(function () {
	            new NativeArrayBuffer();
	            new NativeArrayBuffer(1.5);
	            new NativeArrayBuffer(NaN);
	            return NativeArrayBuffer.name != ARRAY_BUFFER;
	        })) {
	            $ArrayBuffer = function ArrayBuffer(length) {
	                anInstance(this, $ArrayBuffer);
	                return new NativeArrayBuffer(toIndex(length));
	            };
	            var ArrayBufferPrototype = $ArrayBuffer[PROTOTYPE] = NativeArrayBuffer[PROTOTYPE];
	            for (var keys = getOwnPropertyNames(NativeArrayBuffer), j = 0, key;keys.length > j; ) {
	                if (!((key = keys[j++]) in $ArrayBuffer)) 
	                    { hide($ArrayBuffer, key, NativeArrayBuffer[key]); }
	            }
	            ArrayBufferPrototype.constructor = $ArrayBuffer;
	        }
	        var testView = new $DataView(new $ArrayBuffer(2));
	        var nativeSetInt8 = $DataView[PROTOTYPE].setInt8;
	        testView.setInt8(0, 2147483648);
	        testView.setInt8(1, 2147483649);
	        if (testView.getInt8(0) || !testView.getInt8(1)) 
	            { redefineAll($DataView[PROTOTYPE], {
	            setInt8: function setInt8(byteOffset, value) {
	                nativeSetInt8.call(this, byteOffset, value << 24 >> 24);
	            },
	            setUint8: function setUint8(byteOffset, value) {
	                nativeSetInt8.call(this, byteOffset, value << 24 >> 24);
	            }
	        }, {
	            unsafe: true
	        }); }
	    }
	    setToStringTag($ArrayBuffer, ARRAY_BUFFER);
	    setToStringTag($DataView, DATA_VIEW);
	    exports[ARRAY_BUFFER] = $ArrayBuffer;
	    exports[DATA_VIEW] = $DataView;
	});

	var ARRAY_BUFFER = 'ArrayBuffer';
	var ArrayBuffer = arrayBuffer[ARRAY_BUFFER];
	var NativeArrayBuffer = global_1[ARRAY_BUFFER];
	_export({
	    global: true,
	    forced: NativeArrayBuffer !== ArrayBuffer
	}, {
	    ArrayBuffer: ArrayBuffer
	});
	setSpecies(ARRAY_BUFFER);

	var NATIVE_ARRAY_BUFFER_VIEWS$1 = arrayBufferViewCore.NATIVE_ARRAY_BUFFER_VIEWS;
	_export({
	    target: 'ArrayBuffer',
	    stat: true,
	    forced: !NATIVE_ARRAY_BUFFER_VIEWS$1
	}, {
	    isView: arrayBufferViewCore.isView
	});

	var ArrayBuffer$1 = arrayBuffer.ArrayBuffer;
	var DataView$1 = arrayBuffer.DataView;
	var nativeArrayBufferSlice = ArrayBuffer$1.prototype.slice;
	var INCORRECT_SLICE = fails(function () {
	    return !new ArrayBuffer$1(2).slice(1, undefined).byteLength;
	});
	_export({
	    target: 'ArrayBuffer',
	    proto: true,
	    unsafe: true,
	    forced: INCORRECT_SLICE
	}, {
	    slice: function slice(start, end) {
	        if (nativeArrayBufferSlice !== undefined && end === undefined) {
	            return nativeArrayBufferSlice.call(anObject(this), start);
	        }
	        var length = anObject(this).byteLength;
	        var first = toAbsoluteIndex(start, length);
	        var fin = toAbsoluteIndex(end === undefined ? length : end, length);
	        var result = new (speciesConstructor(this, ArrayBuffer$1))(toLength(fin - first));
	        var viewSource = new DataView$1(this);
	        var viewTarget = new DataView$1(result);
	        var index = 0;
	        while (first < fin) {
	            viewTarget.setUint8(index++, viewSource.getUint8(first++));
	        }
	        return result;
	    }
	});

	var NATIVE_ARRAY_BUFFER$1 = arrayBufferViewCore.NATIVE_ARRAY_BUFFER;
	_export({
	    global: true,
	    forced: !NATIVE_ARRAY_BUFFER$1
	}, {
	    DataView: arrayBuffer.DataView
	});

	var NATIVE_ARRAY_BUFFER_VIEWS$2 = arrayBufferViewCore.NATIVE_ARRAY_BUFFER_VIEWS;
	var ArrayBuffer$2 = global_1.ArrayBuffer;
	var Int8Array$2 = global_1.Int8Array;
	var typedArraysConstructorsRequiresWrappers = !NATIVE_ARRAY_BUFFER_VIEWS$2 || !fails(function () {
	    Int8Array$2(1);
	}) || !fails(function () {
	    new Int8Array$2(-1);
	}) || !checkCorrectnessOfIteration(function (iterable) {
	    new Int8Array$2();
	    new Int8Array$2(null);
	    new Int8Array$2(1.5);
	    new Int8Array$2(iterable);
	}, true) || fails(function () {
	    return new Int8Array$2(new ArrayBuffer$2(2), 1, undefined).length !== 1;
	});

	var toOffset = function (it, BYTES) {
	    var offset = toInteger(it);
	    if (offset < 0 || offset % BYTES) 
	        { throw RangeError('Wrong offset'); }
	    return offset;
	};

	var aTypedArrayConstructor$1 = arrayBufferViewCore.aTypedArrayConstructor;
	var typedArrayFrom = function from(source) {
	    var O = toObject(source);
	    var argumentsLength = arguments.length;
	    var mapfn = argumentsLength > 1 ? arguments[1] : undefined;
	    var mapping = mapfn !== undefined;
	    var iteratorMethod = getIteratorMethod(O);
	    var i, length, result, step, iterator;
	    if (iteratorMethod != undefined && !isArrayIteratorMethod(iteratorMethod)) {
	        iterator = iteratorMethod.call(O);
	        O = [];
	        while (!(step = iterator.next()).done) {
	            O.push(step.value);
	        }
	    }
	    if (mapping && argumentsLength > 2) {
	        mapfn = bindContext(mapfn, arguments[2], 2);
	    }
	    length = toLength(O.length);
	    result = new (aTypedArrayConstructor$1(this))(length);
	    for (i = 0; length > i; i++) {
	        result[i] = mapping ? mapfn(O[i], i) : O[i];
	    }
	    return result;
	};

	var typedArrayConstructor = createCommonjsModule(function (module) {
	    var getOwnPropertyNames = objectGetOwnPropertyNames.f;
	    var forEach = arrayIteration.forEach;
	    var getInternalState = internalState.get;
	    var setInternalState = internalState.set;
	    var nativeDefineProperty = objectDefineProperty.f;
	    var nativeGetOwnPropertyDescriptor = objectGetOwnPropertyDescriptor.f;
	    var round = Math.round;
	    var RangeError = global_1.RangeError;
	    var ArrayBuffer = arrayBuffer.ArrayBuffer;
	    var DataView = arrayBuffer.DataView;
	    var NATIVE_ARRAY_BUFFER_VIEWS = arrayBufferViewCore.NATIVE_ARRAY_BUFFER_VIEWS;
	    var TYPED_ARRAY_TAG = arrayBufferViewCore.TYPED_ARRAY_TAG;
	    var TypedArray = arrayBufferViewCore.TypedArray;
	    var TypedArrayPrototype = arrayBufferViewCore.TypedArrayPrototype;
	    var aTypedArrayConstructor = arrayBufferViewCore.aTypedArrayConstructor;
	    var isTypedArray = arrayBufferViewCore.isTypedArray;
	    var BYTES_PER_ELEMENT = 'BYTES_PER_ELEMENT';
	    var WRONG_LENGTH = 'Wrong length';
	    var fromList = function (C, list) {
	        var index = 0;
	        var length = list.length;
	        var result = new (aTypedArrayConstructor(C))(length);
	        while (length > index) 
	            { result[index] = list[index++]; }
	        return result;
	    };
	    var addGetter = function (it, key) {
	        nativeDefineProperty(it, key, {
	            get: function () {
	                return getInternalState(this)[key];
	            }
	        });
	    };
	    var isArrayBuffer = function (it) {
	        var klass;
	        return it instanceof ArrayBuffer || (klass = classof(it)) == 'ArrayBuffer' || klass == 'SharedArrayBuffer';
	    };
	    var isTypedArrayIndex = function (target, key) {
	        return isTypedArray(target) && typeof key != 'symbol' && key in target && String(+key) == String(key);
	    };
	    var wrappedGetOwnPropertyDescriptor = function getOwnPropertyDescriptor(target, key) {
	        return isTypedArrayIndex(target, key = toPrimitive(key, true)) ? createPropertyDescriptor(2, target[key]) : nativeGetOwnPropertyDescriptor(target, key);
	    };
	    var wrappedDefineProperty = function defineProperty(target, key, descriptor) {
	        if (isTypedArrayIndex(target, key = toPrimitive(key, true)) && isObject(descriptor) && has(descriptor, 'value') && !has(descriptor, 'get') && !has(descriptor, 'set') && !descriptor.configurable && (!has(descriptor, 'writable') || descriptor.writable) && (!has(descriptor, 'enumerable') || descriptor.enumerable)) {
	            target[key] = descriptor.value;
	            return target;
	        }
	        return nativeDefineProperty(target, key, descriptor);
	    };
	    if (descriptors) {
	        if (!NATIVE_ARRAY_BUFFER_VIEWS) {
	            objectGetOwnPropertyDescriptor.f = wrappedGetOwnPropertyDescriptor;
	            objectDefineProperty.f = wrappedDefineProperty;
	            addGetter(TypedArrayPrototype, 'buffer');
	            addGetter(TypedArrayPrototype, 'byteOffset');
	            addGetter(TypedArrayPrototype, 'byteLength');
	            addGetter(TypedArrayPrototype, 'length');
	        }
	        _export({
	            target: 'Object',
	            stat: true,
	            forced: !NATIVE_ARRAY_BUFFER_VIEWS
	        }, {
	            getOwnPropertyDescriptor: wrappedGetOwnPropertyDescriptor,
	            defineProperty: wrappedDefineProperty
	        });
	        module.exports = function (TYPE, BYTES, wrapper, CLAMPED) {
	            var CONSTRUCTOR_NAME = TYPE + (CLAMPED ? 'Clamped' : '') + 'Array';
	            var GETTER = 'get' + TYPE;
	            var SETTER = 'set' + TYPE;
	            var NativeTypedArrayConstructor = global_1[CONSTRUCTOR_NAME];
	            var TypedArrayConstructor = NativeTypedArrayConstructor;
	            var TypedArrayConstructorPrototype = TypedArrayConstructor && TypedArrayConstructor.prototype;
	            var exported = {};
	            var getter = function (that, index) {
	                var data = getInternalState(that);
	                return data.view[GETTER](index * BYTES + data.byteOffset, true);
	            };
	            var setter = function (that, index, value) {
	                var data = getInternalState(that);
	                if (CLAMPED) 
	                    { value = (value = round(value)) < 0 ? 0 : value > 0xFF ? 0xFF : value & 0xFF; }
	                data.view[SETTER](index * BYTES + data.byteOffset, value, true);
	            };
	            var addElement = function (that, index) {
	                nativeDefineProperty(that, index, {
	                    get: function () {
	                        return getter(this, index);
	                    },
	                    set: function (value) {
	                        return setter(this, index, value);
	                    },
	                    enumerable: true
	                });
	            };
	            if (!NATIVE_ARRAY_BUFFER_VIEWS) {
	                TypedArrayConstructor = wrapper(function (that, data, offset, $length) {
	                    anInstance(that, TypedArrayConstructor, CONSTRUCTOR_NAME);
	                    var index = 0;
	                    var byteOffset = 0;
	                    var buffer, byteLength, length;
	                    if (!isObject(data)) {
	                        length = toIndex(data);
	                        byteLength = length * BYTES;
	                        buffer = new ArrayBuffer(byteLength);
	                    } else if (isArrayBuffer(data)) {
	                        buffer = data;
	                        byteOffset = toOffset(offset, BYTES);
	                        var $len = data.byteLength;
	                        if ($length === undefined) {
	                            if ($len % BYTES) 
	                                { throw RangeError(WRONG_LENGTH); }
	                            byteLength = $len - byteOffset;
	                            if (byteLength < 0) 
	                                { throw RangeError(WRONG_LENGTH); }
	                        } else {
	                            byteLength = toLength($length) * BYTES;
	                            if (byteLength + byteOffset > $len) 
	                                { throw RangeError(WRONG_LENGTH); }
	                        }
	                        length = byteLength / BYTES;
	                    } else if (isTypedArray(data)) {
	                        return fromList(TypedArrayConstructor, data);
	                    } else {
	                        return typedArrayFrom.call(TypedArrayConstructor, data);
	                    }
	                    setInternalState(that, {
	                        buffer: buffer,
	                        byteOffset: byteOffset,
	                        byteLength: byteLength,
	                        length: length,
	                        view: new DataView(buffer)
	                    });
	                    while (index < length) 
	                        { addElement(that, index++); }
	                });
	                if (objectSetPrototypeOf) 
	                    { objectSetPrototypeOf(TypedArrayConstructor, TypedArray); }
	                TypedArrayConstructorPrototype = (TypedArrayConstructor.prototype = objectCreate(TypedArrayPrototype));
	            } else if (typedArraysConstructorsRequiresWrappers) {
	                TypedArrayConstructor = wrapper(function (dummy, data, typedArrayOffset, $length) {
	                    anInstance(dummy, TypedArrayConstructor, CONSTRUCTOR_NAME);
	                    if (!isObject(data)) 
	                        { return new NativeTypedArrayConstructor(toIndex(data)); }
	                    if (isArrayBuffer(data)) 
	                        { return $length !== undefined ? new NativeTypedArrayConstructor(data, toOffset(typedArrayOffset, BYTES), $length) : typedArrayOffset !== undefined ? new NativeTypedArrayConstructor(data, toOffset(typedArrayOffset, BYTES)) : new NativeTypedArrayConstructor(data); }
	                    if (isTypedArray(data)) 
	                        { return fromList(TypedArrayConstructor, data); }
	                    return typedArrayFrom.call(TypedArrayConstructor, data);
	                });
	                if (objectSetPrototypeOf) 
	                    { objectSetPrototypeOf(TypedArrayConstructor, TypedArray); }
	                forEach(getOwnPropertyNames(NativeTypedArrayConstructor), function (key) {
	                    if (!(key in TypedArrayConstructor)) 
	                        { hide(TypedArrayConstructor, key, NativeTypedArrayConstructor[key]); }
	                });
	                TypedArrayConstructor.prototype = TypedArrayConstructorPrototype;
	            }
	            if (TypedArrayConstructorPrototype.constructor !== TypedArrayConstructor) {
	                hide(TypedArrayConstructorPrototype, 'constructor', TypedArrayConstructor);
	            }
	            if (TYPED_ARRAY_TAG) 
	                { hide(TypedArrayConstructorPrototype, TYPED_ARRAY_TAG, CONSTRUCTOR_NAME); }
	            exported[CONSTRUCTOR_NAME] = TypedArrayConstructor;
	            _export({
	                global: true,
	                forced: TypedArrayConstructor != NativeTypedArrayConstructor,
	                sham: !NATIVE_ARRAY_BUFFER_VIEWS
	            }, exported);
	            if (!(BYTES_PER_ELEMENT in TypedArrayConstructor)) {
	                hide(TypedArrayConstructor, BYTES_PER_ELEMENT, BYTES);
	            }
	            if (!(BYTES_PER_ELEMENT in TypedArrayConstructorPrototype)) {
	                hide(TypedArrayConstructorPrototype, BYTES_PER_ELEMENT, BYTES);
	            }
	            setSpecies(CONSTRUCTOR_NAME);
	        };
	    } else 
	        { module.exports = function () {}; }
	});

	typedArrayConstructor('Int8', 1, function (init) {
	    return function Int8Array(data, byteOffset, length) {
	        return init(this, data, byteOffset, length);
	    };
	});

	typedArrayConstructor('Uint8', 1, function (init) {
	    return function Uint8Array(data, byteOffset, length) {
	        return init(this, data, byteOffset, length);
	    };
	});

	typedArrayConstructor('Uint8', 1, function (init) {
	    return function Uint8ClampedArray(data, byteOffset, length) {
	        return init(this, data, byteOffset, length);
	    };
	}, true);

	typedArrayConstructor('Int16', 2, function (init) {
	    return function Int16Array(data, byteOffset, length) {
	        return init(this, data, byteOffset, length);
	    };
	});

	typedArrayConstructor('Uint16', 2, function (init) {
	    return function Uint16Array(data, byteOffset, length) {
	        return init(this, data, byteOffset, length);
	    };
	});

	typedArrayConstructor('Int32', 4, function (init) {
	    return function Int32Array(data, byteOffset, length) {
	        return init(this, data, byteOffset, length);
	    };
	});

	typedArrayConstructor('Uint32', 4, function (init) {
	    return function Uint32Array(data, byteOffset, length) {
	        return init(this, data, byteOffset, length);
	    };
	});

	typedArrayConstructor('Float32', 4, function (init) {
	    return function Float32Array(data, byteOffset, length) {
	        return init(this, data, byteOffset, length);
	    };
	});

	typedArrayConstructor('Float64', 8, function (init) {
	    return function Float64Array(data, byteOffset, length) {
	        return init(this, data, byteOffset, length);
	    };
	});

	arrayBufferViewCore.exportStatic('from', typedArrayFrom, typedArraysConstructorsRequiresWrappers);

	var aTypedArrayConstructor$2 = arrayBufferViewCore.aTypedArrayConstructor;
	arrayBufferViewCore.exportStatic('of', function of() {
	    var arguments$1 = arguments;

	    var index = 0;
	    var length = arguments.length;
	    var result = new (aTypedArrayConstructor$2(this))(length);
	    while (length > index) 
	        { result[index] = arguments$1[index++]; }
	    return result;
	}, typedArraysConstructorsRequiresWrappers);

	var aTypedArray$1 = arrayBufferViewCore.aTypedArray;
	arrayBufferViewCore.exportProto('copyWithin', function copyWithin(target, start) {
	    return arrayCopyWithin.call(aTypedArray$1(this), target, start, arguments.length > 2 ? arguments[2] : undefined);
	});

	var $every$1 = arrayIteration.every;
	var aTypedArray$2 = arrayBufferViewCore.aTypedArray;
	arrayBufferViewCore.exportProto('every', function every(callbackfn) {
	    return $every$1(aTypedArray$2(this), callbackfn, arguments.length > 1 ? arguments[1] : undefined);
	});

	var aTypedArray$3 = arrayBufferViewCore.aTypedArray;
	arrayBufferViewCore.exportProto('fill', function fill(value) {
	    return arrayFill.apply(aTypedArray$3(this), arguments);
	});

	var $filter$1 = arrayIteration.filter;
	var aTypedArray$4 = arrayBufferViewCore.aTypedArray;
	var aTypedArrayConstructor$3 = arrayBufferViewCore.aTypedArrayConstructor;
	arrayBufferViewCore.exportProto('filter', function filter(callbackfn) {
	    var list = $filter$1(aTypedArray$4(this), callbackfn, arguments.length > 1 ? arguments[1] : undefined);
	    var C = speciesConstructor(this, this.constructor);
	    var index = 0;
	    var length = list.length;
	    var result = new (aTypedArrayConstructor$3(C))(length);
	    while (length > index) 
	        { result[index] = list[index++]; }
	    return result;
	});

	var $find$1 = arrayIteration.find;
	var aTypedArray$5 = arrayBufferViewCore.aTypedArray;
	arrayBufferViewCore.exportProto('find', function find(predicate) {
	    return $find$1(aTypedArray$5(this), predicate, arguments.length > 1 ? arguments[1] : undefined);
	});

	var $findIndex$1 = arrayIteration.findIndex;
	var aTypedArray$6 = arrayBufferViewCore.aTypedArray;
	arrayBufferViewCore.exportProto('findIndex', function findIndex(predicate) {
	    return $findIndex$1(aTypedArray$6(this), predicate, arguments.length > 1 ? arguments[1] : undefined);
	});

	var $forEach$2 = arrayIteration.forEach;
	var aTypedArray$7 = arrayBufferViewCore.aTypedArray;
	arrayBufferViewCore.exportProto('forEach', function forEach(callbackfn) {
	    $forEach$2(aTypedArray$7(this), callbackfn, arguments.length > 1 ? arguments[1] : undefined);
	});

	var $includes$1 = arrayIncludes.includes;
	var aTypedArray$8 = arrayBufferViewCore.aTypedArray;
	arrayBufferViewCore.exportProto('includes', function includes(searchElement) {
	    return $includes$1(aTypedArray$8(this), searchElement, arguments.length > 1 ? arguments[1] : undefined);
	});

	var $indexOf$1 = arrayIncludes.indexOf;
	var aTypedArray$9 = arrayBufferViewCore.aTypedArray;
	arrayBufferViewCore.exportProto('indexOf', function indexOf(searchElement) {
	    return $indexOf$1(aTypedArray$9(this), searchElement, arguments.length > 1 ? arguments[1] : undefined);
	});

	var ITERATOR$5 = wellKnownSymbol('iterator');
	var Uint8Array = global_1.Uint8Array;
	var arrayValues = es_array_iterator.values;
	var arrayKeys = es_array_iterator.keys;
	var arrayEntries = es_array_iterator.entries;
	var aTypedArray$a = arrayBufferViewCore.aTypedArray;
	var exportProto$1 = arrayBufferViewCore.exportProto;
	var nativeTypedArrayIterator = Uint8Array && Uint8Array.prototype[ITERATOR$5];
	var CORRECT_ITER_NAME = !(!nativeTypedArrayIterator) && (nativeTypedArrayIterator.name == 'values' || nativeTypedArrayIterator.name == undefined);
	var typedArrayValues = function values() {
	    return arrayValues.call(aTypedArray$a(this));
	};
	exportProto$1('entries', function entries() {
	    return arrayEntries.call(aTypedArray$a(this));
	});
	exportProto$1('keys', function keys() {
	    return arrayKeys.call(aTypedArray$a(this));
	});
	exportProto$1('values', typedArrayValues, !CORRECT_ITER_NAME);
	exportProto$1(ITERATOR$5, typedArrayValues, !CORRECT_ITER_NAME);

	var aTypedArray$b = arrayBufferViewCore.aTypedArray;
	var $join = [].join;
	arrayBufferViewCore.exportProto('join', function join(separator) {
	    return $join.apply(aTypedArray$b(this), arguments);
	});

	var aTypedArray$c = arrayBufferViewCore.aTypedArray;
	arrayBufferViewCore.exportProto('lastIndexOf', function lastIndexOf(searchElement) {
	    return arrayLastIndexOf.apply(aTypedArray$c(this), arguments);
	});

	var $map$1 = arrayIteration.map;
	var aTypedArray$d = arrayBufferViewCore.aTypedArray;
	var aTypedArrayConstructor$4 = arrayBufferViewCore.aTypedArrayConstructor;
	arrayBufferViewCore.exportProto('map', function map(mapfn) {
	    return $map$1(aTypedArray$d(this), mapfn, arguments.length > 1 ? arguments[1] : undefined, function (O, length) {
	        return new (aTypedArrayConstructor$4(speciesConstructor(O, O.constructor)))(length);
	    });
	});

	var $reduce$1 = arrayReduce.left;
	var aTypedArray$e = arrayBufferViewCore.aTypedArray;
	arrayBufferViewCore.exportProto('reduce', function reduce(callbackfn) {
	    return $reduce$1(aTypedArray$e(this), callbackfn, arguments.length, arguments.length > 1 ? arguments[1] : undefined);
	});

	var $reduceRight$1 = arrayReduce.right;
	var aTypedArray$f = arrayBufferViewCore.aTypedArray;
	arrayBufferViewCore.exportProto('reduceRight', function reduceRight(callbackfn) {
	    return $reduceRight$1(aTypedArray$f(this), callbackfn, arguments.length, arguments.length > 1 ? arguments[1] : undefined);
	});

	var aTypedArray$g = arrayBufferViewCore.aTypedArray;
	var floor$6 = Math.floor;
	arrayBufferViewCore.exportProto('reverse', function reverse() {
	    var that = this;
	    var length = aTypedArray$g(that).length;
	    var middle = floor$6(length / 2);
	    var index = 0;
	    var value;
	    while (index < middle) {
	        value = that[index];
	        that[index++] = that[--length];
	        that[length] = value;
	    }
	    return that;
	});

	var aTypedArray$h = arrayBufferViewCore.aTypedArray;
	var FORCED$f = fails(function () {
	    new Int8Array(1).set({});
	});
	arrayBufferViewCore.exportProto('set', function set(arrayLike) {
	    aTypedArray$h(this);
	    var offset = toOffset(arguments.length > 1 ? arguments[1] : undefined, 1);
	    var length = this.length;
	    var src = toObject(arrayLike);
	    var len = toLength(src.length);
	    var index = 0;
	    if (len + offset > length) 
	        { throw RangeError('Wrong length'); }
	    while (index < len) 
	        { this[offset + index] = src[index++]; }
	}, FORCED$f);

	var aTypedArray$i = arrayBufferViewCore.aTypedArray;
	var aTypedArrayConstructor$5 = arrayBufferViewCore.aTypedArrayConstructor;
	var $slice = [].slice;
	var FORCED$g = fails(function () {
	    new Int8Array(1).slice();
	});
	arrayBufferViewCore.exportProto('slice', function slice(start, end) {
	    var list = $slice.call(aTypedArray$i(this), start, end);
	    var C = speciesConstructor(this, this.constructor);
	    var index = 0;
	    var length = list.length;
	    var result = new (aTypedArrayConstructor$5(C))(length);
	    while (length > index) 
	        { result[index] = list[index++]; }
	    return result;
	}, FORCED$g);

	var $some$1 = arrayIteration.some;
	var aTypedArray$j = arrayBufferViewCore.aTypedArray;
	arrayBufferViewCore.exportProto('some', function some(callbackfn) {
	    return $some$1(aTypedArray$j(this), callbackfn, arguments.length > 1 ? arguments[1] : undefined);
	});

	var aTypedArray$k = arrayBufferViewCore.aTypedArray;
	var $sort = [].sort;
	arrayBufferViewCore.exportProto('sort', function sort(comparefn) {
	    return $sort.call(aTypedArray$k(this), comparefn);
	});

	var aTypedArray$l = arrayBufferViewCore.aTypedArray;
	arrayBufferViewCore.exportProto('subarray', function subarray(begin, end) {
	    var O = aTypedArray$l(this);
	    var length = O.length;
	    var beginIndex = toAbsoluteIndex(begin, length);
	    return new (speciesConstructor(O, O.constructor))(O.buffer, O.byteOffset + beginIndex * O.BYTES_PER_ELEMENT, toLength((end === undefined ? length : toAbsoluteIndex(end, length)) - beginIndex));
	});

	var Int8Array$3 = global_1.Int8Array;
	var aTypedArray$m = arrayBufferViewCore.aTypedArray;
	var $toLocaleString = [].toLocaleString;
	var $slice$1 = [].slice;
	var TO_LOCALE_STRING_BUG = !(!Int8Array$3) && fails(function () {
	    $toLocaleString.call(new Int8Array$3(1));
	});
	var FORCED$h = fails(function () {
	    return [1,2].toLocaleString() != new Int8Array$3([1,2]).toLocaleString();
	}) || !fails(function () {
	    Int8Array$3.prototype.toLocaleString.call([1,2]);
	});
	arrayBufferViewCore.exportProto('toLocaleString', function toLocaleString() {
	    return $toLocaleString.apply(TO_LOCALE_STRING_BUG ? $slice$1.call(aTypedArray$m(this)) : aTypedArray$m(this), arguments);
	}, FORCED$h);

	var Uint8Array$1 = global_1.Uint8Array;
	var Uint8ArrayPrototype = Uint8Array$1 && Uint8Array$1.prototype;
	var arrayToString = [].toString;
	var arrayJoin = [].join;
	if (fails(function () {
	    arrayToString.call({});
	})) {
	    arrayToString = function toString() {
	        return arrayJoin.call(this);
	    };
	}
	arrayBufferViewCore.exportProto('toString', arrayToString, (Uint8ArrayPrototype || {}).toString != arrayToString);

	var nativeApply = getBuiltIn('Reflect', 'apply');
	var functionApply = Function.apply;
	var OPTIONAL_ARGUMENTS_LIST = !fails(function () {
	    nativeApply(function () {});
	});
	_export({
	    target: 'Reflect',
	    stat: true,
	    forced: OPTIONAL_ARGUMENTS_LIST
	}, {
	    apply: function apply(target, thisArgument, argumentsList) {
	        aFunction$1(target);
	        anObject(argumentsList);
	        return nativeApply ? nativeApply(target, thisArgument, argumentsList) : functionApply.call(target, thisArgument, argumentsList);
	    }
	});

	var nativeConstruct = getBuiltIn('Reflect', 'construct');
	var NEW_TARGET_BUG = fails(function () {
	    function F() {}
	    
	    return !(nativeConstruct(function () {}, [], F) instanceof F);
	});
	var ARGS_BUG = !fails(function () {
	    nativeConstruct(function () {});
	});
	var FORCED$i = NEW_TARGET_BUG || ARGS_BUG;
	_export({
	    target: 'Reflect',
	    stat: true,
	    forced: FORCED$i,
	    sham: FORCED$i
	}, {
	    construct: function construct(Target, args) {
	        aFunction$1(Target);
	        anObject(args);
	        var newTarget = arguments.length < 3 ? Target : aFunction$1(arguments[2]);
	        if (ARGS_BUG && !NEW_TARGET_BUG) 
	            { return nativeConstruct(Target, args, newTarget); }
	        if (Target == newTarget) {
	            switch (args.length) {
	                case 0:
	                    return new Target();
	                case 1:
	                    return new Target(args[0]);
	                case 2:
	                    return new Target(args[0], args[1]);
	                case 3:
	                    return new Target(args[0], args[1], args[2]);
	                case 4:
	                    return new Target(args[0], args[1], args[2], args[3]);
	            }
	            var $args = [null];
	            $args.push.apply($args, args);
	            return new (functionBind.apply(Target, $args))();
	        }
	        var proto = newTarget.prototype;
	        var instance = objectCreate(isObject(proto) ? proto : Object.prototype);
	        var result = Function.apply.call(Target, instance, args);
	        return isObject(result) ? result : instance;
	    }
	});

	var ERROR_INSTEAD_OF_FALSE = fails(function () {
	    Reflect.defineProperty(objectDefineProperty.f({}, 1, {
	        value: 1
	    }), 1, {
	        value: 2
	    });
	});
	_export({
	    target: 'Reflect',
	    stat: true,
	    forced: ERROR_INSTEAD_OF_FALSE,
	    sham: !descriptors
	}, {
	    defineProperty: function defineProperty(target, propertyKey, attributes) {
	        anObject(target);
	        var key = toPrimitive(propertyKey, true);
	        anObject(attributes);
	        try {
	            objectDefineProperty.f(target, key, attributes);
	            return true;
	        } catch (error) {
	            return false;
	        }
	    }
	});

	var getOwnPropertyDescriptor$6 = objectGetOwnPropertyDescriptor.f;
	_export({
	    target: 'Reflect',
	    stat: true
	}, {
	    deleteProperty: function deleteProperty(target, propertyKey) {
	        var descriptor = getOwnPropertyDescriptor$6(anObject(target), propertyKey);
	        return descriptor && !descriptor.configurable ? false : delete target[propertyKey];
	    }
	});

	function get$1(target, propertyKey) {
	    var receiver = arguments.length < 3 ? target : arguments[2];
	    var descriptor, prototype;
	    if (anObject(target) === receiver) 
	        { return target[propertyKey]; }
	    if (descriptor = objectGetOwnPropertyDescriptor.f(target, propertyKey)) 
	        { return has(descriptor, 'value') ? descriptor.value : descriptor.get === undefined ? undefined : descriptor.get.call(receiver); }
	    if (isObject(prototype = objectGetPrototypeOf(target))) 
	        { return get$1(prototype, propertyKey, receiver); }
	}

	_export({
	    target: 'Reflect',
	    stat: true
	}, {
	    get: get$1
	});

	_export({
	    target: 'Reflect',
	    stat: true,
	    sham: !descriptors
	}, {
	    getOwnPropertyDescriptor: function getOwnPropertyDescriptor(target, propertyKey) {
	        return objectGetOwnPropertyDescriptor.f(anObject(target), propertyKey);
	    }
	});

	_export({
	    target: 'Reflect',
	    stat: true,
	    sham: !correctPrototypeGetter
	}, {
	    getPrototypeOf: function getPrototypeOf(target) {
	        return objectGetPrototypeOf(anObject(target));
	    }
	});

	_export({
	    target: 'Reflect',
	    stat: true
	}, {
	    has: function has(target, propertyKey) {
	        return propertyKey in target;
	    }
	});

	var objectIsExtensible = Object.isExtensible;
	_export({
	    target: 'Reflect',
	    stat: true
	}, {
	    isExtensible: function isExtensible(target) {
	        anObject(target);
	        return objectIsExtensible ? objectIsExtensible(target) : true;
	    }
	});

	_export({
	    target: 'Reflect',
	    stat: true
	}, {
	    ownKeys: ownKeys
	});

	_export({
	    target: 'Reflect',
	    stat: true,
	    sham: !freezing
	}, {
	    preventExtensions: function preventExtensions(target) {
	        anObject(target);
	        try {
	            var objectPreventExtensions = getBuiltIn('Object', 'preventExtensions');
	            if (objectPreventExtensions) 
	                { objectPreventExtensions(target); }
	            return true;
	        } catch (error) {
	            return false;
	        }
	    }
	});

	function set$2(target, propertyKey, V) {
	    var receiver = arguments.length < 4 ? target : arguments[3];
	    var ownDescriptor = objectGetOwnPropertyDescriptor.f(anObject(target), propertyKey);
	    var existingDescriptor, prototype;
	    if (!ownDescriptor) {
	        if (isObject(prototype = objectGetPrototypeOf(target))) {
	            return set$2(prototype, propertyKey, V, receiver);
	        }
	        ownDescriptor = createPropertyDescriptor(0);
	    }
	    if (has(ownDescriptor, 'value')) {
	        if (ownDescriptor.writable === false || !isObject(receiver)) 
	            { return false; }
	        if (existingDescriptor = objectGetOwnPropertyDescriptor.f(receiver, propertyKey)) {
	            if (existingDescriptor.get || existingDescriptor.set || existingDescriptor.writable === false) 
	                { return false; }
	            existingDescriptor.value = V;
	            objectDefineProperty.f(receiver, propertyKey, existingDescriptor);
	        } else 
	            { objectDefineProperty.f(receiver, propertyKey, createPropertyDescriptor(0, V)); }
	        return true;
	    }
	    return ownDescriptor.set === undefined ? false : (ownDescriptor.set.call(receiver, V), true);
	}

	_export({
	    target: 'Reflect',
	    stat: true
	}, {
	    set: set$2
	});

	if (objectSetPrototypeOf) 
	    { _export({
	    target: 'Reflect',
	    stat: true
	}, {
	    setPrototypeOf: function setPrototypeOf(target, proto) {
	        anObject(target);
	        aPossiblePrototype(proto);
	        try {
	            objectSetPrototypeOf(target, proto);
	            return true;
	        } catch (error) {
	            return false;
	        }
	    }
	}); }

	var metadata = shared('metadata');
	var store$2 = metadata.store || (metadata.store = new es_weakMap());
	var getOrCreateMetadataMap = function (target, targetKey, create) {
	    var targetMetadata = store$2.get(target);
	    if (!targetMetadata) {
	        if (!create) 
	            { return; }
	        store$2.set(target, targetMetadata = new es_map());
	    }
	    var keyMetadata = targetMetadata.get(targetKey);
	    if (!keyMetadata) {
	        if (!create) 
	            { return; }
	        targetMetadata.set(targetKey, keyMetadata = new es_map());
	    }
	    return keyMetadata;
	};
	var ordinaryHasOwnMetadata = function (MetadataKey, O, P) {
	    var metadataMap = getOrCreateMetadataMap(O, P, false);
	    return metadataMap === undefined ? false : metadataMap.has(MetadataKey);
	};
	var ordinaryGetOwnMetadata = function (MetadataKey, O, P) {
	    var metadataMap = getOrCreateMetadataMap(O, P, false);
	    return metadataMap === undefined ? undefined : metadataMap.get(MetadataKey);
	};
	var ordinaryDefineOwnMetadata = function (MetadataKey, MetadataValue, O, P) {
	    getOrCreateMetadataMap(O, P, true).set(MetadataKey, MetadataValue);
	};
	var ordinaryOwnMetadataKeys = function (target, targetKey) {
	    var metadataMap = getOrCreateMetadataMap(target, targetKey, false);
	    var keys = [];
	    if (metadataMap) 
	        { metadataMap.forEach(function (_, key) {
	        keys.push(key);
	    }); }
	    return keys;
	};
	var toMetadataKey = function (it) {
	    return it === undefined || typeof it == 'symbol' ? it : String(it);
	};
	var reflectMetadata = {
	    store: store$2,
	    getMap: getOrCreateMetadataMap,
	    has: ordinaryHasOwnMetadata,
	    get: ordinaryGetOwnMetadata,
	    set: ordinaryDefineOwnMetadata,
	    keys: ordinaryOwnMetadataKeys,
	    toKey: toMetadataKey
	};

	var toMetadataKey$1 = reflectMetadata.toKey;
	var ordinaryDefineOwnMetadata$1 = reflectMetadata.set;
	_export({
	    target: 'Reflect',
	    stat: true
	}, {
	    defineMetadata: function defineMetadata(metadataKey, metadataValue, target) {
	        var targetKey = arguments.length < 4 ? undefined : toMetadataKey$1(arguments[3]);
	        ordinaryDefineOwnMetadata$1(metadataKey, metadataValue, anObject(target), targetKey);
	    }
	});

	var toMetadataKey$2 = reflectMetadata.toKey;
	var getOrCreateMetadataMap$1 = reflectMetadata.getMap;
	var store$3 = reflectMetadata.store;
	_export({
	    target: 'Reflect',
	    stat: true
	}, {
	    deleteMetadata: function deleteMetadata(metadataKey, target) {
	        var targetKey = arguments.length < 3 ? undefined : toMetadataKey$2(arguments[2]);
	        var metadataMap = getOrCreateMetadataMap$1(anObject(target), targetKey, false);
	        if (metadataMap === undefined || !metadataMap['delete'](metadataKey)) 
	            { return false; }
	        if (metadataMap.size) 
	            { return true; }
	        var targetMetadata = store$3.get(target);
	        targetMetadata['delete'](targetKey);
	        return !(!targetMetadata.size) || store$3['delete'](target);
	    }
	});

	var ordinaryHasOwnMetadata$1 = reflectMetadata.has;
	var ordinaryGetOwnMetadata$1 = reflectMetadata.get;
	var toMetadataKey$3 = reflectMetadata.toKey;
	var ordinaryGetMetadata = function (MetadataKey, O, P) {
	    var hasOwn = ordinaryHasOwnMetadata$1(MetadataKey, O, P);
	    if (hasOwn) 
	        { return ordinaryGetOwnMetadata$1(MetadataKey, O, P); }
	    var parent = objectGetPrototypeOf(O);
	    return parent !== null ? ordinaryGetMetadata(MetadataKey, parent, P) : undefined;
	};
	_export({
	    target: 'Reflect',
	    stat: true
	}, {
	    getMetadata: function getMetadata(metadataKey, target) {
	        var targetKey = arguments.length < 3 ? undefined : toMetadataKey$3(arguments[2]);
	        return ordinaryGetMetadata(metadataKey, anObject(target), targetKey);
	    }
	});

	var ordinaryOwnMetadataKeys$1 = reflectMetadata.keys;
	var toMetadataKey$4 = reflectMetadata.toKey;
	var from = function (iter) {
	    var result = [];
	    iterate_1(iter, result.push, result);
	    return result;
	};
	var ordinaryMetadataKeys = function (O, P) {
	    var oKeys = ordinaryOwnMetadataKeys$1(O, P);
	    var parent = objectGetPrototypeOf(O);
	    if (parent === null) 
	        { return oKeys; }
	    var pKeys = ordinaryMetadataKeys(parent, P);
	    return pKeys.length ? oKeys.length ? from(new es_set(oKeys.concat(pKeys))) : pKeys : oKeys;
	};
	_export({
	    target: 'Reflect',
	    stat: true
	}, {
	    getMetadataKeys: function getMetadataKeys(target) {
	        var targetKey = arguments.length < 2 ? undefined : toMetadataKey$4(arguments[1]);
	        return ordinaryMetadataKeys(anObject(target), targetKey);
	    }
	});

	var ordinaryGetOwnMetadata$2 = reflectMetadata.get;
	var toMetadataKey$5 = reflectMetadata.toKey;
	_export({
	    target: 'Reflect',
	    stat: true
	}, {
	    getOwnMetadata: function getOwnMetadata(metadataKey, target) {
	        var targetKey = arguments.length < 3 ? undefined : toMetadataKey$5(arguments[2]);
	        return ordinaryGetOwnMetadata$2(metadataKey, anObject(target), targetKey);
	    }
	});

	var ordinaryOwnMetadataKeys$2 = reflectMetadata.keys;
	var toMetadataKey$6 = reflectMetadata.toKey;
	_export({
	    target: 'Reflect',
	    stat: true
	}, {
	    getOwnMetadataKeys: function getOwnMetadataKeys(target) {
	        var targetKey = arguments.length < 2 ? undefined : toMetadataKey$6(arguments[1]);
	        return ordinaryOwnMetadataKeys$2(anObject(target), targetKey);
	    }
	});

	var ordinaryHasOwnMetadata$2 = reflectMetadata.has;
	var toMetadataKey$7 = reflectMetadata.toKey;
	var ordinaryHasMetadata = function (MetadataKey, O, P) {
	    var hasOwn = ordinaryHasOwnMetadata$2(MetadataKey, O, P);
	    if (hasOwn) 
	        { return true; }
	    var parent = objectGetPrototypeOf(O);
	    return parent !== null ? ordinaryHasMetadata(MetadataKey, parent, P) : false;
	};
	_export({
	    target: 'Reflect',
	    stat: true
	}, {
	    hasMetadata: function hasMetadata(metadataKey, target) {
	        var targetKey = arguments.length < 3 ? undefined : toMetadataKey$7(arguments[2]);
	        return ordinaryHasMetadata(metadataKey, anObject(target), targetKey);
	    }
	});

	var ordinaryHasOwnMetadata$3 = reflectMetadata.has;
	var toMetadataKey$8 = reflectMetadata.toKey;
	_export({
	    target: 'Reflect',
	    stat: true
	}, {
	    hasOwnMetadata: function hasOwnMetadata(metadataKey, target) {
	        var targetKey = arguments.length < 3 ? undefined : toMetadataKey$8(arguments[2]);
	        return ordinaryHasOwnMetadata$3(metadataKey, anObject(target), targetKey);
	    }
	});

	var toMetadataKey$9 = reflectMetadata.toKey;
	var ordinaryDefineOwnMetadata$2 = reflectMetadata.set;
	_export({
	    target: 'Reflect',
	    stat: true
	}, {
	    metadata: function metadata(metadataKey, metadataValue) {
	        return function decorator(target, key) {
	            ordinaryDefineOwnMetadata$2(metadataKey, metadataValue, anObject(target), toMetadataKey$9(key));
	        };
	    }
	});

	_export({
	    target: 'Math',
	    stat: true
	}, {
	    iaddh: function iaddh(x0, x1, y0, y1) {
	        var $x0 = x0 >>> 0;
	        var $x1 = x1 >>> 0;
	        var $y0 = y0 >>> 0;
	        return $x1 + (y1 >>> 0) + (($x0 & $y0 | ($x0 | $y0) & ~($x0 + $y0 >>> 0)) >>> 31) | 0;
	    }
	});

	_export({
	    target: 'Math',
	    stat: true
	}, {
	    isubh: function isubh(x0, x1, y0, y1) {
	        var $x0 = x0 >>> 0;
	        var $x1 = x1 >>> 0;
	        var $y0 = y0 >>> 0;
	        return $x1 - (y1 >>> 0) - ((~$x0 & $y0 | ~($x0 ^ $y0) & $x0 - $y0 >>> 0) >>> 31) | 0;
	    }
	});

	_export({
	    target: 'Math',
	    stat: true
	}, {
	    imulh: function imulh(u, v) {
	        var UINT16 = 0xFFFF;
	        var $u = +u;
	        var $v = +v;
	        var u0 = $u & UINT16;
	        var v0 = $v & UINT16;
	        var u1 = $u >> 16;
	        var v1 = $v >> 16;
	        var t = (u1 * v0 >>> 0) + (u0 * v0 >>> 16);
	        return u1 * v1 + (t >> 16) + ((u0 * v1 >>> 0) + (t & UINT16) >> 16);
	    }
	});

	_export({
	    target: 'Math',
	    stat: true
	}, {
	    umulh: function umulh(u, v) {
	        var UINT16 = 0xFFFF;
	        var $u = +u;
	        var $v = +v;
	        var u0 = $u & UINT16;
	        var v0 = $v & UINT16;
	        var u1 = $u >>> 16;
	        var v1 = $v >>> 16;
	        var t = (u1 * v0 >>> 0) + (u0 * v0 >>> 16);
	        return u1 * v1 + (t >>> 16) + ((u0 * v1 >>> 0) + (t & UINT16) >>> 16);
	    }
	});

	var charAt$2 = stringMultibyte.charAt;
	_export({
	    target: 'String',
	    proto: true
	}, {
	    at: function at(pos) {
	        return charAt$2(this, pos);
	    }
	});

	var ITERATOR$6 = wellKnownSymbol('iterator');
	var nativeUrl = !fails(function () {
	    var url = new URL('b?e=1', 'http://a');
	    var searchParams = url.searchParams;
	    url.pathname = 'c%20d';
	    return isPure && !url.toJSON || !searchParams.sort || url.href !== 'http://a/c%20d?e=1' || searchParams.get('e') !== '1' || String(new URLSearchParams('?a=1')) !== 'a=1' || !searchParams[ITERATOR$6] || new URL('https://a@b').username !== 'a' || new URLSearchParams(new URLSearchParams('a=b')).get('a') !== 'b' || new URL('http://тест').host !== 'xn--e1aybc' || new URL('http://a#б').hash !== '#%D0%B1';
	});

	var maxInt = 2147483647;
	var base = 36;
	var tMin = 1;
	var tMax = 26;
	var skew = 38;
	var damp = 700;
	var initialBias = 72;
	var initialN = 128;
	var delimiter = '-';
	var regexNonASCII = /[^\0-\u007E]/;
	var regexSeparators = /[.\u3002\uFF0E\uFF61]/g;
	var OVERFLOW_ERROR = 'Overflow: input needs wider integers to process';
	var baseMinusTMin = base - tMin;
	var floor$7 = Math.floor;
	var stringFromCharCode = String.fromCharCode;
	var ucs2decode = function (string) {
	    var output = [];
	    var counter = 0;
	    var length = string.length;
	    while (counter < length) {
	        var value = string.charCodeAt(counter++);
	        if (value >= 0xD800 && value <= 0xDBFF && counter < length) {
	            var extra = string.charCodeAt(counter++);
	            if ((extra & 0xFC00) == 0xDC00) {
	                output.push(((value & 0x3FF) << 10) + (extra & 0x3FF) + 0x10000);
	            } else {
	                output.push(value);
	                counter--;
	            }
	        } else {
	            output.push(value);
	        }
	    }
	    return output;
	};
	var digitToBasic = function (digit) {
	    return digit + 22 + 75 * (digit < 26);
	};
	var adapt = function (delta, numPoints, firstTime) {
	    var k = 0;
	    delta = firstTime ? floor$7(delta / damp) : delta >> 1;
	    delta += floor$7(delta / numPoints);
	    for (; delta > baseMinusTMin * tMax >> 1; k += base) {
	        delta = floor$7(delta / baseMinusTMin);
	    }
	    return floor$7(k + (baseMinusTMin + 1) * delta / (delta + skew));
	};
	var encode = function (input) {
	    var output = [];
	    input = ucs2decode(input);
	    var inputLength = input.length;
	    var n = initialN;
	    var delta = 0;
	    var bias = initialBias;
	    var i, currentValue;
	    for (i = 0; i < input.length; i++) {
	        currentValue = input[i];
	        if (currentValue < 0x80) {
	            output.push(stringFromCharCode(currentValue));
	        }
	    }
	    var basicLength = output.length;
	    var handledCPCount = basicLength;
	    if (basicLength) {
	        output.push(delimiter);
	    }
	    while (handledCPCount < inputLength) {
	        var m = maxInt;
	        for (i = 0; i < input.length; i++) {
	            currentValue = input[i];
	            if (currentValue >= n && currentValue < m) {
	                m = currentValue;
	            }
	        }
	        var handledCPCountPlusOne = handledCPCount + 1;
	        if (m - n > floor$7((maxInt - delta) / handledCPCountPlusOne)) {
	            throw RangeError(OVERFLOW_ERROR);
	        }
	        delta += (m - n) * handledCPCountPlusOne;
	        n = m;
	        for (i = 0; i < input.length; i++) {
	            currentValue = input[i];
	            if (currentValue < n && ++delta > maxInt) {
	                throw RangeError(OVERFLOW_ERROR);
	            }
	            if (currentValue == n) {
	                var q = delta;
	                for (var k = base;; k += base) {
	                    var t = k <= bias ? tMin : k >= bias + tMax ? tMax : k - bias;
	                    if (q < t) 
	                        { break; }
	                    var qMinusT = q - t;
	                    var baseMinusT = base - t;
	                    output.push(stringFromCharCode(digitToBasic(t + qMinusT % baseMinusT)));
	                    q = floor$7(qMinusT / baseMinusT);
	                }
	                output.push(stringFromCharCode(digitToBasic(q)));
	                bias = adapt(delta, handledCPCountPlusOne, handledCPCount == basicLength);
	                delta = 0;
	                ++handledCPCount;
	            }
	        }
	        ++delta;
	        ++n;
	    }
	    return output.join('');
	};
	var punycodeToAscii = function (input) {
	    var encoded = [];
	    var labels = input.toLowerCase().replace(regexSeparators, '\u002E').split('.');
	    var i, label;
	    for (i = 0; i < labels.length; i++) {
	        label = labels[i];
	        encoded.push(regexNonASCII.test(label) ? 'xn--' + encode(label) : label);
	    }
	    return encoded.join('.');
	};

	var getIterator = function (it) {
	    var iteratorMethod = getIteratorMethod(it);
	    if (typeof iteratorMethod != 'function') {
	        throw TypeError(String(it) + ' is not iterable');
	    }
	    return anObject(iteratorMethod.call(it));
	};

	var ITERATOR$7 = wellKnownSymbol('iterator');
	var URL_SEARCH_PARAMS = 'URLSearchParams';
	var URL_SEARCH_PARAMS_ITERATOR = URL_SEARCH_PARAMS + 'Iterator';
	var setInternalState$7 = internalState.set;
	var getInternalParamsState = internalState.getterFor(URL_SEARCH_PARAMS);
	var getInternalIteratorState = internalState.getterFor(URL_SEARCH_PARAMS_ITERATOR);
	var plus = /\+/g;
	var sequences = Array(4);
	var percentSequence = function (bytes) {
	    return sequences[bytes - 1] || (sequences[bytes - 1] = RegExp('((?:%[\\da-f]{2}){' + bytes + '})', 'gi'));
	};
	var percentDecode = function (sequence) {
	    try {
	        return decodeURIComponent(sequence);
	    } catch (error) {
	        return sequence;
	    }
	};
	var deserialize = function (it) {
	    var result = it.replace(plus, ' ');
	    var bytes = 4;
	    try {
	        return decodeURIComponent(result);
	    } catch (error) {
	        while (bytes) {
	            result = result.replace(percentSequence(bytes--), percentDecode);
	        }
	        return result;
	    }
	};
	var find$1 = /[!'()~]|%20/g;
	var replace = {
	    '!': '%21',
	    "'": '%27',
	    '(': '%28',
	    ')': '%29',
	    '~': '%7E',
	    '%20': '+'
	};
	var replacer = function (match) {
	    return replace[match];
	};
	var serialize = function (it) {
	    return encodeURIComponent(it).replace(find$1, replacer);
	};
	var parseSearchParams = function (result, query) {
	    if (query) {
	        var attributes = query.split('&');
	        var index = 0;
	        var attribute, entry;
	        while (index < attributes.length) {
	            attribute = attributes[index++];
	            if (attribute.length) {
	                entry = attribute.split('=');
	                result.push({
	                    key: deserialize(entry.shift()),
	                    value: deserialize(entry.join('='))
	                });
	            }
	        }
	    }
	};
	var updateSearchParams = function (query) {
	    this.entries.length = 0;
	    parseSearchParams(this.entries, query);
	};
	var validateArgumentsLength = function (passed, required) {
	    if (passed < required) 
	        { throw TypeError('Not enough arguments'); }
	};
	var URLSearchParamsIterator = createIteratorConstructor(function Iterator(params, kind) {
	    setInternalState$7(this, {
	        type: URL_SEARCH_PARAMS_ITERATOR,
	        iterator: getIterator(getInternalParamsState(params).entries),
	        kind: kind
	    });
	}, 'Iterator', function next() {
	    var state = getInternalIteratorState(this);
	    var kind = state.kind;
	    var step = state.iterator.next();
	    var entry = step.value;
	    if (!step.done) {
	        step.value = kind === 'keys' ? entry.key : kind === 'values' ? entry.value : [entry.key,
	            entry.value];
	    }
	    return step;
	});
	var URLSearchParamsConstructor = function URLSearchParams() {
	    anInstance(this, URLSearchParamsConstructor, URL_SEARCH_PARAMS);
	    var init = arguments.length > 0 ? arguments[0] : undefined;
	    var that = this;
	    var entries = [];
	    var iteratorMethod, iterator, step, entryIterator, first, second, key;
	    setInternalState$7(that, {
	        type: URL_SEARCH_PARAMS,
	        entries: entries,
	        updateURL: function () {},
	        updateSearchParams: updateSearchParams
	    });
	    if (init !== undefined) {
	        if (isObject(init)) {
	            iteratorMethod = getIteratorMethod(init);
	            if (typeof iteratorMethod === 'function') {
	                iterator = iteratorMethod.call(init);
	                while (!(step = iterator.next()).done) {
	                    entryIterator = getIterator(anObject(step.value));
	                    if ((first = entryIterator.next()).done || (second = entryIterator.next()).done || !entryIterator.next().done) 
	                        { throw TypeError('Expected sequence with length 2'); }
	                    entries.push({
	                        key: first.value + '',
	                        value: second.value + ''
	                    });
	                }
	            } else 
	                { for (key in init) 
	                { if (has(init, key)) 
	                { entries.push({
	                key: key,
	                value: init[key] + ''
	            }); } } }
	        } else {
	            parseSearchParams(entries, typeof init === 'string' ? init.charAt(0) === '?' ? init.slice(1) : init : init + '');
	        }
	    }
	};
	var URLSearchParamsPrototype = URLSearchParamsConstructor.prototype;
	redefineAll(URLSearchParamsPrototype, {
	    append: function append(name, value) {
	        validateArgumentsLength(arguments.length, 2);
	        var state = getInternalParamsState(this);
	        state.entries.push({
	            key: name + '',
	            value: value + ''
	        });
	        state.updateURL();
	    },
	    'delete': function (name) {
	        validateArgumentsLength(arguments.length, 1);
	        var state = getInternalParamsState(this);
	        var entries = state.entries;
	        var key = name + '';
	        var index = 0;
	        while (index < entries.length) {
	            if (entries[index].key === key) 
	                { entries.splice(index, 1); }
	             else 
	                { index++; }
	        }
	        state.updateURL();
	    },
	    get: function get(name) {
	        validateArgumentsLength(arguments.length, 1);
	        var entries = getInternalParamsState(this).entries;
	        var key = name + '';
	        var index = 0;
	        for (; index < entries.length; index++) {
	            if (entries[index].key === key) 
	                { return entries[index].value; }
	        }
	        return null;
	    },
	    getAll: function getAll(name) {
	        validateArgumentsLength(arguments.length, 1);
	        var entries = getInternalParamsState(this).entries;
	        var key = name + '';
	        var result = [];
	        var index = 0;
	        for (; index < entries.length; index++) {
	            if (entries[index].key === key) 
	                { result.push(entries[index].value); }
	        }
	        return result;
	    },
	    has: function has(name) {
	        validateArgumentsLength(arguments.length, 1);
	        var entries = getInternalParamsState(this).entries;
	        var key = name + '';
	        var index = 0;
	        while (index < entries.length) {
	            if (entries[index++].key === key) 
	                { return true; }
	        }
	        return false;
	    },
	    set: function set(name, value) {
	        validateArgumentsLength(arguments.length, 1);
	        var state = getInternalParamsState(this);
	        var entries = state.entries;
	        var found = false;
	        var key = name + '';
	        var val = value + '';
	        var index = 0;
	        var entry;
	        for (; index < entries.length; index++) {
	            entry = entries[index];
	            if (entry.key === key) {
	                if (found) 
	                    { entries.splice(index--, 1); }
	                 else {
	                    found = true;
	                    entry.value = val;
	                }
	            }
	        }
	        if (!found) 
	            { entries.push({
	            key: key,
	            value: val
	        }); }
	        state.updateURL();
	    },
	    sort: function sort() {
	        var state = getInternalParamsState(this);
	        var entries = state.entries;
	        var slice = entries.slice();
	        var entry, entriesIndex, sliceIndex;
	        entries.length = 0;
	        for (sliceIndex = 0; sliceIndex < slice.length; sliceIndex++) {
	            entry = slice[sliceIndex];
	            for (entriesIndex = 0; entriesIndex < sliceIndex; entriesIndex++) {
	                if (entries[entriesIndex].key > entry.key) {
	                    entries.splice(entriesIndex, 0, entry);
	                    break;
	                }
	            }
	            if (entriesIndex === sliceIndex) 
	                { entries.push(entry); }
	        }
	        state.updateURL();
	    },
	    forEach: function forEach(callback) {
	        var entries = getInternalParamsState(this).entries;
	        var boundFunction = bindContext(callback, arguments.length > 1 ? arguments[1] : undefined, 3);
	        var index = 0;
	        var entry;
	        while (index < entries.length) {
	            entry = entries[index++];
	            boundFunction(entry.value, entry.key, this);
	        }
	    },
	    keys: function keys() {
	        return new URLSearchParamsIterator(this, 'keys');
	    },
	    values: function values() {
	        return new URLSearchParamsIterator(this, 'values');
	    },
	    entries: function entries() {
	        return new URLSearchParamsIterator(this, 'entries');
	    }
	}, {
	    enumerable: true
	});
	redefine(URLSearchParamsPrototype, ITERATOR$7, URLSearchParamsPrototype.entries);
	redefine(URLSearchParamsPrototype, 'toString', function toString() {
	    var entries = getInternalParamsState(this).entries;
	    var result = [];
	    var index = 0;
	    var entry;
	    while (index < entries.length) {
	        entry = entries[index++];
	        result.push(serialize(entry.key) + '=' + serialize(entry.value));
	    }
	    return result.join('&');
	}, {
	    enumerable: true
	});
	setToStringTag(URLSearchParamsConstructor, URL_SEARCH_PARAMS);
	_export({
	    global: true,
	    forced: !nativeUrl
	}, {
	    URLSearchParams: URLSearchParamsConstructor
	});
	var web_urlSearchParams = {
	    URLSearchParams: URLSearchParamsConstructor,
	    getState: getInternalParamsState
	};

	var codeAt$1 = stringMultibyte.codeAt;
	var NativeURL = global_1.URL;
	var URLSearchParams$1 = web_urlSearchParams.URLSearchParams;
	var getInternalSearchParamsState = web_urlSearchParams.getState;
	var setInternalState$8 = internalState.set;
	var getInternalURLState = internalState.getterFor('URL');
	var floor$8 = Math.floor;
	var pow$3 = Math.pow;
	var INVALID_AUTHORITY = 'Invalid authority';
	var INVALID_SCHEME = 'Invalid scheme';
	var INVALID_HOST = 'Invalid host';
	var INVALID_PORT = 'Invalid port';
	var ALPHA = /[A-Za-z]/;
	var ALPHANUMERIC = /[\d+\-.A-Za-z]/;
	var DIGIT = /\d/;
	var HEX_START = /^(0x|0X)/;
	var OCT = /^[0-7]+$/;
	var DEC = /^\d+$/;
	var HEX = /^[\dA-Fa-f]+$/;
	var FORBIDDEN_HOST_CODE_POINT = /[\u0000\u0009\u000A\u000D #%/:?@[\\]]/;
	var FORBIDDEN_HOST_CODE_POINT_EXCLUDING_PERCENT = /[\u0000\u0009\u000A\u000D #/:?@[\\]]/;
	var LEADING_AND_TRAILING_C0_CONTROL_OR_SPACE = /^[\u0000-\u001F ]+|[\u0000-\u001F ]+$/g;
	var TAB_AND_NEW_LINE = /[\u0009\u000A\u000D]/g;
	var EOF;
	var parseHost = function (url, input) {
	    var result, codePoints, index;
	    if (input.charAt(0) == '[') {
	        if (input.charAt(input.length - 1) != ']') 
	            { return INVALID_HOST; }
	        result = parseIPv6(input.slice(1, -1));
	        if (!result) 
	            { return INVALID_HOST; }
	        url.host = result;
	    } else if (!isSpecial(url)) {
	        if (FORBIDDEN_HOST_CODE_POINT_EXCLUDING_PERCENT.test(input)) 
	            { return INVALID_HOST; }
	        result = '';
	        codePoints = arrayFrom(input);
	        for (index = 0; index < codePoints.length; index++) {
	            result += percentEncode(codePoints[index], C0ControlPercentEncodeSet);
	        }
	        url.host = result;
	    } else {
	        input = punycodeToAscii(input);
	        if (FORBIDDEN_HOST_CODE_POINT.test(input)) 
	            { return INVALID_HOST; }
	        result = parseIPv4(input);
	        if (result === null) 
	            { return INVALID_HOST; }
	        url.host = result;
	    }
	};
	var parseIPv4 = function (input) {
	    var parts = input.split('.');
	    var partsLength, numbers, index, part, radix, number, ipv4;
	    if (parts.length && parts[parts.length - 1] == '') {
	        parts.pop();
	    }
	    partsLength = parts.length;
	    if (partsLength > 4) 
	        { return input; }
	    numbers = [];
	    for (index = 0; index < partsLength; index++) {
	        part = parts[index];
	        if (part == '') 
	            { return input; }
	        radix = 10;
	        if (part.length > 1 && part.charAt(0) == '0') {
	            radix = HEX_START.test(part) ? 16 : 8;
	            part = part.slice(radix == 8 ? 1 : 2);
	        }
	        if (part === '') {
	            number = 0;
	        } else {
	            if (!(radix == 10 ? DEC : radix == 8 ? OCT : HEX).test(part)) 
	                { return input; }
	            number = parseInt(part, radix);
	        }
	        numbers.push(number);
	    }
	    for (index = 0; index < partsLength; index++) {
	        number = numbers[index];
	        if (index == partsLength - 1) {
	            if (number >= pow$3(256, 5 - partsLength)) 
	                { return null; }
	        } else if (number > 255) 
	            { return null; }
	    }
	    ipv4 = numbers.pop();
	    for (index = 0; index < numbers.length; index++) {
	        ipv4 += numbers[index] * pow$3(256, 3 - index);
	    }
	    return ipv4;
	};
	var parseIPv6 = function (input) {
	    var address = [0,0,0,0,0,0,0,0];
	    var pieceIndex = 0;
	    var compress = null;
	    var pointer = 0;
	    var value, length, numbersSeen, ipv4Piece, number, swaps, swap;
	    var char = function () {
	        return input.charAt(pointer);
	    };
	    if (char() == ':') {
	        if (input.charAt(1) != ':') 
	            { return; }
	        pointer += 2;
	        pieceIndex++;
	        compress = pieceIndex;
	    }
	    while (char()) {
	        if (pieceIndex == 8) 
	            { return; }
	        if (char() == ':') {
	            if (compress !== null) 
	                { return; }
	            pointer++;
	            pieceIndex++;
	            compress = pieceIndex;
	            continue;
	        }
	        value = (length = 0);
	        while (length < 4 && HEX.test(char())) {
	            value = value * 16 + parseInt(char(), 16);
	            pointer++;
	            length++;
	        }
	        if (char() == '.') {
	            if (length == 0) 
	                { return; }
	            pointer -= length;
	            if (pieceIndex > 6) 
	                { return; }
	            numbersSeen = 0;
	            while (char()) {
	                ipv4Piece = null;
	                if (numbersSeen > 0) {
	                    if (char() == '.' && numbersSeen < 4) 
	                        { pointer++; }
	                     else 
	                        { return; }
	                }
	                if (!DIGIT.test(char())) 
	                    { return; }
	                while (DIGIT.test(char())) {
	                    number = parseInt(char(), 10);
	                    if (ipv4Piece === null) 
	                        { ipv4Piece = number; }
	                     else if (ipv4Piece == 0) 
	                        { return; }
	                     else 
	                        { ipv4Piece = ipv4Piece * 10 + number; }
	                    if (ipv4Piece > 255) 
	                        { return; }
	                    pointer++;
	                }
	                address[pieceIndex] = address[pieceIndex] * 256 + ipv4Piece;
	                numbersSeen++;
	                if (numbersSeen == 2 || numbersSeen == 4) 
	                    { pieceIndex++; }
	            }
	            if (numbersSeen != 4) 
	                { return; }
	            break;
	        } else if (char() == ':') {
	            pointer++;
	            if (!char()) 
	                { return; }
	        } else if (char()) 
	            { return; }
	        address[pieceIndex++] = value;
	    }
	    if (compress !== null) {
	        swaps = pieceIndex - compress;
	        pieceIndex = 7;
	        while (pieceIndex != 0 && swaps > 0) {
	            swap = address[pieceIndex];
	            address[pieceIndex--] = address[compress + swaps - 1];
	            address[compress + --swaps] = swap;
	        }
	    } else if (pieceIndex != 8) 
	        { return; }
	    return address;
	};
	var findLongestZeroSequence = function (ipv6) {
	    var maxIndex = null;
	    var maxLength = 1;
	    var currStart = null;
	    var currLength = 0;
	    var index = 0;
	    for (; index < 8; index++) {
	        if (ipv6[index] !== 0) {
	            if (currLength > maxLength) {
	                maxIndex = currStart;
	                maxLength = currLength;
	            }
	            currStart = null;
	            currLength = 0;
	        } else {
	            if (currStart === null) 
	                { currStart = index; }
	            ++currLength;
	        }
	    }
	    if (currLength > maxLength) {
	        maxIndex = currStart;
	        maxLength = currLength;
	    }
	    return maxIndex;
	};
	var serializeHost = function (host) {
	    var result, index, compress, ignore0;
	    if (typeof host == 'number') {
	        result = [];
	        for (index = 0; index < 4; index++) {
	            result.unshift(host % 256);
	            host = floor$8(host / 256);
	        }
	        return result.join('.');
	    } else if (typeof host == 'object') {
	        result = '';
	        compress = findLongestZeroSequence(host);
	        for (index = 0; index < 8; index++) {
	            if (ignore0 && host[index] === 0) 
	                { continue; }
	            if (ignore0) 
	                { ignore0 = false; }
	            if (compress === index) {
	                result += index ? ':' : '::';
	                ignore0 = true;
	            } else {
	                result += host[index].toString(16);
	                if (index < 7) 
	                    { result += ':'; }
	            }
	        }
	        return '[' + result + ']';
	    }
	    return host;
	};
	var C0ControlPercentEncodeSet = {};
	var fragmentPercentEncodeSet = objectAssign({}, C0ControlPercentEncodeSet, {
	    ' ': 1,
	    '"': 1,
	    '<': 1,
	    '>': 1,
	    '`': 1
	});
	var pathPercentEncodeSet = objectAssign({}, fragmentPercentEncodeSet, {
	    '#': 1,
	    '?': 1,
	    '{': 1,
	    '}': 1
	});
	var userinfoPercentEncodeSet = objectAssign({}, pathPercentEncodeSet, {
	    '/': 1,
	    ':': 1,
	    ';': 1,
	    '=': 1,
	    '@': 1,
	    '[': 1,
	    '\\': 1,
	    ']': 1,
	    '^': 1,
	    '|': 1
	});
	var percentEncode = function (char, set) {
	    var code = codeAt$1(char, 0);
	    return code > 0x20 && code < 0x7F && !has(set, char) ? char : encodeURIComponent(char);
	};
	var specialSchemes = {
	    ftp: 21,
	    file: null,
	    gopher: 70,
	    http: 80,
	    https: 443,
	    ws: 80,
	    wss: 443
	};
	var isSpecial = function (url) {
	    return has(specialSchemes, url.scheme);
	};
	var includesCredentials = function (url) {
	    return url.username != '' || url.password != '';
	};
	var cannotHaveUsernamePasswordPort = function (url) {
	    return !url.host || url.cannotBeABaseURL || url.scheme == 'file';
	};
	var isWindowsDriveLetter = function (string, normalized) {
	    var second;
	    return string.length == 2 && ALPHA.test(string.charAt(0)) && ((second = string.charAt(1)) == ':' || !normalized && second == '|');
	};
	var startsWithWindowsDriveLetter = function (string) {
	    var third;
	    return string.length > 1 && isWindowsDriveLetter(string.slice(0, 2)) && (string.length == 2 || ((third = string.charAt(2)) === '/' || third === '\\' || third === '?' || third === '#'));
	};
	var shortenURLsPath = function (url) {
	    var path = url.path;
	    var pathSize = path.length;
	    if (pathSize && (url.scheme != 'file' || pathSize != 1 || !isWindowsDriveLetter(path[0], true))) {
	        path.pop();
	    }
	};
	var isSingleDot = function (segment) {
	    return segment === '.' || segment.toLowerCase() === '%2e';
	};
	var isDoubleDot = function (segment) {
	    segment = segment.toLowerCase();
	    return segment === '..' || segment === '%2e.' || segment === '.%2e' || segment === '%2e%2e';
	};
	var SCHEME_START = {};
	var SCHEME = {};
	var NO_SCHEME = {};
	var SPECIAL_RELATIVE_OR_AUTHORITY = {};
	var PATH_OR_AUTHORITY = {};
	var RELATIVE = {};
	var RELATIVE_SLASH = {};
	var SPECIAL_AUTHORITY_SLASHES = {};
	var SPECIAL_AUTHORITY_IGNORE_SLASHES = {};
	var AUTHORITY = {};
	var HOST = {};
	var HOSTNAME = {};
	var PORT = {};
	var FILE = {};
	var FILE_SLASH = {};
	var FILE_HOST = {};
	var PATH_START = {};
	var PATH = {};
	var CANNOT_BE_A_BASE_URL_PATH = {};
	var QUERY = {};
	var FRAGMENT = {};
	var parseURL = function (url, input, stateOverride, base) {
	    var state = stateOverride || SCHEME_START;
	    var pointer = 0;
	    var buffer = '';
	    var seenAt = false;
	    var seenBracket = false;
	    var seenPasswordToken = false;
	    var codePoints, char, bufferCodePoints, failure;
	    if (!stateOverride) {
	        url.scheme = '';
	        url.username = '';
	        url.password = '';
	        url.host = null;
	        url.port = null;
	        url.path = [];
	        url.query = null;
	        url.fragment = null;
	        url.cannotBeABaseURL = false;
	        input = input.replace(LEADING_AND_TRAILING_C0_CONTROL_OR_SPACE, '');
	    }
	    input = input.replace(TAB_AND_NEW_LINE, '');
	    codePoints = arrayFrom(input);
	    while (pointer <= codePoints.length) {
	        char = codePoints[pointer];
	        switch (state) {
	            case SCHEME_START:
	                if (char && ALPHA.test(char)) {
	                    buffer += char.toLowerCase();
	                    state = SCHEME;
	                } else if (!stateOverride) {
	                    state = NO_SCHEME;
	                    continue;
	                } else 
	                    { return INVALID_SCHEME; }
	                break;
	            case SCHEME:
	                if (char && (ALPHANUMERIC.test(char) || char == '+' || char == '-' || char == '.')) {
	                    buffer += char.toLowerCase();
	                } else if (char == ':') {
	                    if (stateOverride && (isSpecial(url) != has(specialSchemes, buffer) || buffer == 'file' && (includesCredentials(url) || url.port !== null) || url.scheme == 'file' && !url.host)) 
	                        { return; }
	                    url.scheme = buffer;
	                    if (stateOverride) {
	                        if (isSpecial(url) && specialSchemes[url.scheme] == url.port) 
	                            { url.port = null; }
	                        return;
	                    }
	                    buffer = '';
	                    if (url.scheme == 'file') {
	                        state = FILE;
	                    } else if (isSpecial(url) && base && base.scheme == url.scheme) {
	                        state = SPECIAL_RELATIVE_OR_AUTHORITY;
	                    } else if (isSpecial(url)) {
	                        state = SPECIAL_AUTHORITY_SLASHES;
	                    } else if (codePoints[pointer + 1] == '/') {
	                        state = PATH_OR_AUTHORITY;
	                        pointer++;
	                    } else {
	                        url.cannotBeABaseURL = true;
	                        url.path.push('');
	                        state = CANNOT_BE_A_BASE_URL_PATH;
	                    }
	                } else if (!stateOverride) {
	                    buffer = '';
	                    state = NO_SCHEME;
	                    pointer = 0;
	                    continue;
	                } else 
	                    { return INVALID_SCHEME; }
	                break;
	            case NO_SCHEME:
	                if (!base || base.cannotBeABaseURL && char != '#') 
	                    { return INVALID_SCHEME; }
	                if (base.cannotBeABaseURL && char == '#') {
	                    url.scheme = base.scheme;
	                    url.path = base.path.slice();
	                    url.query = base.query;
	                    url.fragment = '';
	                    url.cannotBeABaseURL = true;
	                    state = FRAGMENT;
	                    break;
	                }
	                state = base.scheme == 'file' ? FILE : RELATIVE;
	                continue;
	            case SPECIAL_RELATIVE_OR_AUTHORITY:
	                if (char == '/' && codePoints[pointer + 1] == '/') {
	                    state = SPECIAL_AUTHORITY_IGNORE_SLASHES;
	                    pointer++;
	                } else {
	                    state = RELATIVE;
	                    continue;
	                }
	                break;
	            case PATH_OR_AUTHORITY:
	                if (char == '/') {
	                    state = AUTHORITY;
	                    break;
	                } else {
	                    state = PATH;
	                    continue;
	                }
	            case RELATIVE:
	                url.scheme = base.scheme;
	                if (char == EOF) {
	                    url.username = base.username;
	                    url.password = base.password;
	                    url.host = base.host;
	                    url.port = base.port;
	                    url.path = base.path.slice();
	                    url.query = base.query;
	                } else if (char == '/' || char == '\\' && isSpecial(url)) {
	                    state = RELATIVE_SLASH;
	                } else if (char == '?') {
	                    url.username = base.username;
	                    url.password = base.password;
	                    url.host = base.host;
	                    url.port = base.port;
	                    url.path = base.path.slice();
	                    url.query = '';
	                    state = QUERY;
	                } else if (char == '#') {
	                    url.username = base.username;
	                    url.password = base.password;
	                    url.host = base.host;
	                    url.port = base.port;
	                    url.path = base.path.slice();
	                    url.query = base.query;
	                    url.fragment = '';
	                    state = FRAGMENT;
	                } else {
	                    url.username = base.username;
	                    url.password = base.password;
	                    url.host = base.host;
	                    url.port = base.port;
	                    url.path = base.path.slice();
	                    url.path.pop();
	                    state = PATH;
	                    continue;
	                }
	                break;
	            case RELATIVE_SLASH:
	                if (isSpecial(url) && (char == '/' || char == '\\')) {
	                    state = SPECIAL_AUTHORITY_IGNORE_SLASHES;
	                } else if (char == '/') {
	                    state = AUTHORITY;
	                } else {
	                    url.username = base.username;
	                    url.password = base.password;
	                    url.host = base.host;
	                    url.port = base.port;
	                    state = PATH;
	                    continue;
	                }
	                break;
	            case SPECIAL_AUTHORITY_SLASHES:
	                state = SPECIAL_AUTHORITY_IGNORE_SLASHES;
	                if (char != '/' || buffer.charAt(pointer + 1) != '/') 
	                    { continue; }
	                pointer++;
	                break;
	            case SPECIAL_AUTHORITY_IGNORE_SLASHES:
	                if (char != '/' && char != '\\') {
	                    state = AUTHORITY;
	                    continue;
	                }
	                break;
	            case AUTHORITY:
	                if (char == '@') {
	                    if (seenAt) 
	                        { buffer = '%40' + buffer; }
	                    seenAt = true;
	                    bufferCodePoints = arrayFrom(buffer);
	                    for (var i = 0;i < bufferCodePoints.length; i++) {
	                        var codePoint = bufferCodePoints[i];
	                        if (codePoint == ':' && !seenPasswordToken) {
	                            seenPasswordToken = true;
	                            continue;
	                        }
	                        var encodedCodePoints = percentEncode(codePoint, userinfoPercentEncodeSet);
	                        if (seenPasswordToken) 
	                            { url.password += encodedCodePoints; }
	                         else 
	                            { url.username += encodedCodePoints; }
	                    }
	                    buffer = '';
	                } else if (char == EOF || char == '/' || char == '?' || char == '#' || char == '\\' && isSpecial(url)) {
	                    if (seenAt && buffer == '') 
	                        { return INVALID_AUTHORITY; }
	                    pointer -= arrayFrom(buffer).length + 1;
	                    buffer = '';
	                    state = HOST;
	                } else 
	                    { buffer += char; }
	                break;
	            case HOST:
	            case HOSTNAME:
	                if (stateOverride && url.scheme == 'file') {
	                    state = FILE_HOST;
	                    continue;
	                } else if (char == ':' && !seenBracket) {
	                    if (buffer == '') 
	                        { return INVALID_HOST; }
	                    failure = parseHost(url, buffer);
	                    if (failure) 
	                        { return failure; }
	                    buffer = '';
	                    state = PORT;
	                    if (stateOverride == HOSTNAME) 
	                        { return; }
	                } else if (char == EOF || char == '/' || char == '?' || char == '#' || char == '\\' && isSpecial(url)) {
	                    if (isSpecial(url) && buffer == '') 
	                        { return INVALID_HOST; }
	                    if (stateOverride && buffer == '' && (includesCredentials(url) || url.port !== null)) 
	                        { return; }
	                    failure = parseHost(url, buffer);
	                    if (failure) 
	                        { return failure; }
	                    buffer = '';
	                    state = PATH_START;
	                    if (stateOverride) 
	                        { return; }
	                    continue;
	                } else {
	                    if (char == '[') 
	                        { seenBracket = true; }
	                     else if (char == ']') 
	                        { seenBracket = false; }
	                    buffer += char;
	                }
	                break;
	            case PORT:
	                if (DIGIT.test(char)) {
	                    buffer += char;
	                } else if (char == EOF || char == '/' || char == '?' || char == '#' || char == '\\' && isSpecial(url) || stateOverride) {
	                    if (buffer != '') {
	                        var port = parseInt(buffer, 10);
	                        if (port > 0xFFFF) 
	                            { return INVALID_PORT; }
	                        url.port = isSpecial(url) && port === specialSchemes[url.scheme] ? null : port;
	                        buffer = '';
	                    }
	                    if (stateOverride) 
	                        { return; }
	                    state = PATH_START;
	                    continue;
	                } else 
	                    { return INVALID_PORT; }
	                break;
	            case FILE:
	                url.scheme = 'file';
	                if (char == '/' || char == '\\') 
	                    { state = FILE_SLASH; }
	                 else if (base && base.scheme == 'file') {
	                    if (char == EOF) {
	                        url.host = base.host;
	                        url.path = base.path.slice();
	                        url.query = base.query;
	                    } else if (char == '?') {
	                        url.host = base.host;
	                        url.path = base.path.slice();
	                        url.query = '';
	                        state = QUERY;
	                    } else if (char == '#') {
	                        url.host = base.host;
	                        url.path = base.path.slice();
	                        url.query = base.query;
	                        url.fragment = '';
	                        state = FRAGMENT;
	                    } else {
	                        if (!startsWithWindowsDriveLetter(codePoints.slice(pointer).join(''))) {
	                            url.host = base.host;
	                            url.path = base.path.slice();
	                            shortenURLsPath(url);
	                        }
	                        state = PATH;
	                        continue;
	                    }
	                } else {
	                    state = PATH;
	                    continue;
	                }
	                break;
	            case FILE_SLASH:
	                if (char == '/' || char == '\\') {
	                    state = FILE_HOST;
	                    break;
	                }
	                if (base && base.scheme == 'file' && !startsWithWindowsDriveLetter(codePoints.slice(pointer).join(''))) {
	                    if (isWindowsDriveLetter(base.path[0], true)) 
	                        { url.path.push(base.path[0]); }
	                     else 
	                        { url.host = base.host; }
	                }
	                state = PATH;
	                continue;
	            case FILE_HOST:
	                if (char == EOF || char == '/' || char == '\\' || char == '?' || char == '#') {
	                    if (!stateOverride && isWindowsDriveLetter(buffer)) {
	                        state = PATH;
	                    } else if (buffer == '') {
	                        url.host = '';
	                        if (stateOverride) 
	                            { return; }
	                        state = PATH_START;
	                    } else {
	                        failure = parseHost(url, buffer);
	                        if (failure) 
	                            { return failure; }
	                        if (url.host == 'localhost') 
	                            { url.host = ''; }
	                        if (stateOverride) 
	                            { return; }
	                        buffer = '';
	                        state = PATH_START;
	                    }
	                    continue;
	                } else 
	                    { buffer += char; }
	                break;
	            case PATH_START:
	                if (isSpecial(url)) {
	                    state = PATH;
	                    if (char != '/' && char != '\\') 
	                        { continue; }
	                } else if (!stateOverride && char == '?') {
	                    url.query = '';
	                    state = QUERY;
	                } else if (!stateOverride && char == '#') {
	                    url.fragment = '';
	                    state = FRAGMENT;
	                } else if (char != EOF) {
	                    state = PATH;
	                    if (char != '/') 
	                        { continue; }
	                }
	                break;
	            case PATH:
	                if (char == EOF || char == '/' || char == '\\' && isSpecial(url) || !stateOverride && (char == '?' || char == '#')) {
	                    if (isDoubleDot(buffer)) {
	                        shortenURLsPath(url);
	                        if (char != '/' && !(char == '\\' && isSpecial(url))) {
	                            url.path.push('');
	                        }
	                    } else if (isSingleDot(buffer)) {
	                        if (char != '/' && !(char == '\\' && isSpecial(url))) {
	                            url.path.push('');
	                        }
	                    } else {
	                        if (url.scheme == 'file' && !url.path.length && isWindowsDriveLetter(buffer)) {
	                            if (url.host) 
	                                { url.host = ''; }
	                            buffer = buffer.charAt(0) + ':';
	                        }
	                        url.path.push(buffer);
	                    }
	                    buffer = '';
	                    if (url.scheme == 'file' && (char == EOF || char == '?' || char == '#')) {
	                        while (url.path.length > 1 && url.path[0] === '') {
	                            url.path.shift();
	                        }
	                    }
	                    if (char == '?') {
	                        url.query = '';
	                        state = QUERY;
	                    } else if (char == '#') {
	                        url.fragment = '';
	                        state = FRAGMENT;
	                    }
	                } else {
	                    buffer += percentEncode(char, pathPercentEncodeSet);
	                }
	                break;
	            case CANNOT_BE_A_BASE_URL_PATH:
	                if (char == '?') {
	                    url.query = '';
	                    state = QUERY;
	                } else if (char == '#') {
	                    url.fragment = '';
	                    state = FRAGMENT;
	                } else if (char != EOF) {
	                    url.path[0] += percentEncode(char, C0ControlPercentEncodeSet);
	                }
	                break;
	            case QUERY:
	                if (!stateOverride && char == '#') {
	                    url.fragment = '';
	                    state = FRAGMENT;
	                } else if (char != EOF) {
	                    if (char == "'" && isSpecial(url)) 
	                        { url.query += '%27'; }
	                     else if (char == '#') 
	                        { url.query += '%23'; }
	                     else 
	                        { url.query += percentEncode(char, C0ControlPercentEncodeSet); }
	                }
	                break;
	            case FRAGMENT:
	                if (char != EOF) 
	                    { url.fragment += percentEncode(char, fragmentPercentEncodeSet); }
	                break;
	        }
	        pointer++;
	    }
	};
	var URLConstructor = function URL(url) {
	    var that = anInstance(this, URLConstructor, 'URL');
	    var base = arguments.length > 1 ? arguments[1] : undefined;
	    var urlString = String(url);
	    var state = setInternalState$8(that, {
	        type: 'URL'
	    });
	    var baseState, failure;
	    if (base !== undefined) {
	        if (base instanceof URLConstructor) 
	            { baseState = getInternalURLState(base); }
	         else {
	            failure = parseURL(baseState = {}, String(base));
	            if (failure) 
	                { throw TypeError(failure); }
	        }
	    }
	    failure = parseURL(state, urlString, null, baseState);
	    if (failure) 
	        { throw TypeError(failure); }
	    var searchParams = state.searchParams = new URLSearchParams$1();
	    var searchParamsState = getInternalSearchParamsState(searchParams);
	    searchParamsState.updateSearchParams(state.query);
	    searchParamsState.updateURL = function () {
	        state.query = String(searchParams) || null;
	    };
	    if (!descriptors) {
	        that.href = serializeURL.call(that);
	        that.origin = getOrigin.call(that);
	        that.protocol = getProtocol.call(that);
	        that.username = getUsername.call(that);
	        that.password = getPassword.call(that);
	        that.host = getHost.call(that);
	        that.hostname = getHostname.call(that);
	        that.port = getPort.call(that);
	        that.pathname = getPathname.call(that);
	        that.search = getSearch.call(that);
	        that.searchParams = getSearchParams.call(that);
	        that.hash = getHash.call(that);
	    }
	};
	var URLPrototype = URLConstructor.prototype;
	var serializeURL = function () {
	    var url = getInternalURLState(this);
	    var scheme = url.scheme;
	    var username = url.username;
	    var password = url.password;
	    var host = url.host;
	    var port = url.port;
	    var path = url.path;
	    var query = url.query;
	    var fragment = url.fragment;
	    var output = scheme + ':';
	    if (host !== null) {
	        output += '//';
	        if (includesCredentials(url)) {
	            output += username + (password ? ':' + password : '') + '@';
	        }
	        output += serializeHost(host);
	        if (port !== null) 
	            { output += ':' + port; }
	    } else if (scheme == 'file') 
	        { output += '//'; }
	    output += url.cannotBeABaseURL ? path[0] : path.length ? '/' + path.join('/') : '';
	    if (query !== null) 
	        { output += '?' + query; }
	    if (fragment !== null) 
	        { output += '#' + fragment; }
	    return output;
	};
	var getOrigin = function () {
	    var url = getInternalURLState(this);
	    var scheme = url.scheme;
	    var port = url.port;
	    if (scheme == 'blob') {
	        try {
	            return new URL(scheme.path[0]).origin;
	        } catch (error) {
	            return 'null';
	        }
	    }
	    if (scheme == 'file' || !isSpecial(url)) 
	        { return 'null'; }
	    return scheme + '://' + serializeHost(url.host) + (port !== null ? ':' + port : '');
	};
	var getProtocol = function () {
	    return getInternalURLState(this).scheme + ':';
	};
	var getUsername = function () {
	    return getInternalURLState(this).username;
	};
	var getPassword = function () {
	    return getInternalURLState(this).password;
	};
	var getHost = function () {
	    var url = getInternalURLState(this);
	    var host = url.host;
	    var port = url.port;
	    return host === null ? '' : port === null ? serializeHost(host) : serializeHost(host) + ':' + port;
	};
	var getHostname = function () {
	    var host = getInternalURLState(this).host;
	    return host === null ? '' : serializeHost(host);
	};
	var getPort = function () {
	    var port = getInternalURLState(this).port;
	    return port === null ? '' : String(port);
	};
	var getPathname = function () {
	    var url = getInternalURLState(this);
	    var path = url.path;
	    return url.cannotBeABaseURL ? path[0] : path.length ? '/' + path.join('/') : '';
	};
	var getSearch = function () {
	    var query = getInternalURLState(this).query;
	    return query ? '?' + query : '';
	};
	var getSearchParams = function () {
	    return getInternalURLState(this).searchParams;
	};
	var getHash = function () {
	    var fragment = getInternalURLState(this).fragment;
	    return fragment ? '#' + fragment : '';
	};
	var accessorDescriptor = function (getter, setter) {
	    return {
	        get: getter,
	        set: setter,
	        configurable: true,
	        enumerable: true
	    };
	};
	if (descriptors) {
	    objectDefineProperties(URLPrototype, {
	        href: accessorDescriptor(serializeURL, function (href) {
	            var url = getInternalURLState(this);
	            var urlString = String(href);
	            var failure = parseURL(url, urlString);
	            if (failure) 
	                { throw TypeError(failure); }
	            getInternalSearchParamsState(url.searchParams).updateSearchParams(url.query);
	        }),
	        origin: accessorDescriptor(getOrigin),
	        protocol: accessorDescriptor(getProtocol, function (protocol) {
	            var url = getInternalURLState(this);
	            parseURL(url, String(protocol) + ':', SCHEME_START);
	        }),
	        username: accessorDescriptor(getUsername, function (username) {
	            var url = getInternalURLState(this);
	            var codePoints = arrayFrom(String(username));
	            if (cannotHaveUsernamePasswordPort(url)) 
	                { return; }
	            url.username = '';
	            for (var i = 0;i < codePoints.length; i++) {
	                url.username += percentEncode(codePoints[i], userinfoPercentEncodeSet);
	            }
	        }),
	        password: accessorDescriptor(getPassword, function (password) {
	            var url = getInternalURLState(this);
	            var codePoints = arrayFrom(String(password));
	            if (cannotHaveUsernamePasswordPort(url)) 
	                { return; }
	            url.password = '';
	            for (var i = 0;i < codePoints.length; i++) {
	                url.password += percentEncode(codePoints[i], userinfoPercentEncodeSet);
	            }
	        }),
	        host: accessorDescriptor(getHost, function (host) {
	            var url = getInternalURLState(this);
	            if (url.cannotBeABaseURL) 
	                { return; }
	            parseURL(url, String(host), HOST);
	        }),
	        hostname: accessorDescriptor(getHostname, function (hostname) {
	            var url = getInternalURLState(this);
	            if (url.cannotBeABaseURL) 
	                { return; }
	            parseURL(url, String(hostname), HOSTNAME);
	        }),
	        port: accessorDescriptor(getPort, function (port) {
	            var url = getInternalURLState(this);
	            if (cannotHaveUsernamePasswordPort(url)) 
	                { return; }
	            port = String(port);
	            if (port == '') 
	                { url.port = null; }
	             else 
	                { parseURL(url, port, PORT); }
	        }),
	        pathname: accessorDescriptor(getPathname, function (pathname) {
	            var url = getInternalURLState(this);
	            if (url.cannotBeABaseURL) 
	                { return; }
	            url.path = [];
	            parseURL(url, pathname + '', PATH_START);
	        }),
	        search: accessorDescriptor(getSearch, function (search) {
	            var url = getInternalURLState(this);
	            search = String(search);
	            if (search == '') {
	                url.query = null;
	            } else {
	                if ('?' == search.charAt(0)) 
	                    { search = search.slice(1); }
	                url.query = '';
	                parseURL(url, search, QUERY);
	            }
	            getInternalSearchParamsState(url.searchParams).updateSearchParams(url.query);
	        }),
	        searchParams: accessorDescriptor(getSearchParams),
	        hash: accessorDescriptor(getHash, function (hash) {
	            var url = getInternalURLState(this);
	            hash = String(hash);
	            if (hash == '') {
	                url.fragment = null;
	                return;
	            }
	            if ('#' == hash.charAt(0)) 
	                { hash = hash.slice(1); }
	            url.fragment = '';
	            parseURL(url, hash, FRAGMENT);
	        })
	    });
	}
	redefine(URLPrototype, 'toJSON', function toJSON() {
	    return serializeURL.call(this);
	}, {
	    enumerable: true
	});
	redefine(URLPrototype, 'toString', function toString() {
	    return serializeURL.call(this);
	}, {
	    enumerable: true
	});
	if (NativeURL) {
	    var nativeCreateObjectURL = NativeURL.createObjectURL;
	    var nativeRevokeObjectURL = NativeURL.revokeObjectURL;
	    if (nativeCreateObjectURL) 
	        { redefine(URLConstructor, 'createObjectURL', function createObjectURL(blob) {
	        return nativeCreateObjectURL.apply(NativeURL, arguments);
	    }); }
	    if (nativeRevokeObjectURL) 
	        { redefine(URLConstructor, 'revokeObjectURL', function revokeObjectURL(url) {
	        return nativeRevokeObjectURL.apply(NativeURL, arguments);
	    }); }
	}
	setToStringTag(URLConstructor, 'URL');
	_export({
	    global: true,
	    forced: !nativeUrl,
	    sham: !descriptors
	}, {
	    URL: URLConstructor
	});

	_export({
	    target: 'URL',
	    proto: true,
	    enumerable: true
	}, {
	    toJSON: function toJSON() {
	        return URL.prototype.toString.call(this);
	    }
	});

	var defineProperty$8 = objectDefineProperty.f;
	if (descriptors && !('lastIndex' in [])) {
	    defineProperty$8(Array.prototype, 'lastIndex', {
	        configurable: true,
	        get: function lastIndex() {
	            var O = toObject(this);
	            var len = toLength(O.length);
	            return len == 0 ? 0 : len - 1;
	        }
	    });
	    addToUnscopables('lastIndex');
	}

	var defineProperty$9 = objectDefineProperty.f;
	if (descriptors && !('lastItem' in [])) {
	    defineProperty$9(Array.prototype, 'lastItem', {
	        configurable: true,
	        get: function lastItem() {
	            var O = toObject(this);
	            var len = toLength(O.length);
	            return len == 0 ? undefined : O[len - 1];
	        },
	        set: function lastItem(value) {
	            var O = toObject(this);
	            var len = toLength(O.length);
	            return O[len == 0 ? 0 : len - 1] = value;
	        }
	    });
	    addToUnscopables('lastItem');
	}

	_export({
	    target: 'Map',
	    stat: true
	}, {
	    groupBy: function groupBy(iterable, keyDerivative) {
	        var newMap = new this();
	        aFunction$1(keyDerivative);
	        var has = aFunction$1(newMap.has);
	        var get = aFunction$1(newMap.get);
	        var set = aFunction$1(newMap.set);
	        iterate_1(iterable, function (element) {
	            var derivedKey = keyDerivative(element);
	            if (!has.call(newMap, derivedKey)) 
	                { set.call(newMap, derivedKey, [element]); }
	             else 
	                { get.call(newMap, derivedKey).push(element); }
	        });
	        return newMap;
	    }
	});

	_export({
	    target: 'Map',
	    stat: true
	}, {
	    keyBy: function keyBy(iterable, keyDerivative) {
	        var newMap = new this();
	        aFunction$1(keyDerivative);
	        var setter = aFunction$1(newMap.set);
	        iterate_1(iterable, function (element) {
	            setter.call(newMap, keyDerivative(element), element);
	        });
	        return newMap;
	    }
	});

	var collectionDeleteAll = function () {
	    var arguments$1 = arguments;

	    var collection = anObject(this);
	    var remover = aFunction$1(collection['delete']);
	    var allDeleted = true;
	    var wasDeleted;
	    for (var k = 0, len = arguments.length;k < len; k++) {
	        wasDeleted = remover.call(collection, arguments$1[k]);
	        allDeleted = allDeleted && wasDeleted;
	    }
	    return !(!allDeleted);
	};

	_export({
	    target: 'Map',
	    proto: true,
	    real: true,
	    forced: isPure
	}, {
	    deleteAll: function deleteAll() {
	        return collectionDeleteAll.apply(this, arguments);
	    }
	});

	var getMapIterator =  function (it) {
	    return Map.prototype.entries.call(it);
	};

	_export({
	    target: 'Map',
	    proto: true,
	    real: true,
	    forced: isPure
	}, {
	    every: function every(callbackfn) {
	        var map = anObject(this);
	        var iterator = getMapIterator(map);
	        var boundFunction = bindContext(callbackfn, arguments.length > 1 ? arguments[1] : undefined, 3);
	        return !iterate_1(iterator, function (key, value) {
	            if (!boundFunction(value, key, map)) 
	                { return iterate_1.stop(); }
	        }, undefined, true, true).stopped;
	    }
	});

	_export({
	    target: 'Map',
	    proto: true,
	    real: true,
	    forced: isPure
	}, {
	    filter: function filter(callbackfn) {
	        var map = anObject(this);
	        var iterator = getMapIterator(map);
	        var boundFunction = bindContext(callbackfn, arguments.length > 1 ? arguments[1] : undefined, 3);
	        var newMap = new (speciesConstructor(map, getBuiltIn('Map')))();
	        var setter = aFunction$1(newMap.set);
	        iterate_1(iterator, function (key, value) {
	            if (boundFunction(value, key, map)) 
	                { setter.call(newMap, key, value); }
	        }, undefined, true, true);
	        return newMap;
	    }
	});

	_export({
	    target: 'Map',
	    proto: true,
	    real: true,
	    forced: isPure
	}, {
	    find: function find(callbackfn) {
	        var map = anObject(this);
	        var iterator = getMapIterator(map);
	        var boundFunction = bindContext(callbackfn, arguments.length > 1 ? arguments[1] : undefined, 3);
	        return iterate_1(iterator, function (key, value) {
	            if (boundFunction(value, key, map)) 
	                { return iterate_1.stop(value); }
	        }, undefined, true, true).result;
	    }
	});

	_export({
	    target: 'Map',
	    proto: true,
	    real: true,
	    forced: isPure
	}, {
	    findKey: function findKey(callbackfn) {
	        var map = anObject(this);
	        var iterator = getMapIterator(map);
	        var boundFunction = bindContext(callbackfn, arguments.length > 1 ? arguments[1] : undefined, 3);
	        return iterate_1(iterator, function (key, value) {
	            if (boundFunction(value, key, map)) 
	                { return iterate_1.stop(key); }
	        }, undefined, true, true).result;
	    }
	});

	var sameValueZero = function (x, y) {
	    return x === y || x != x && y != y;
	};

	_export({
	    target: 'Map',
	    proto: true,
	    real: true,
	    forced: isPure
	}, {
	    includes: function includes(searchElement) {
	        return iterate_1(getMapIterator(anObject(this)), function (key, value) {
	            if (sameValueZero(value, searchElement)) 
	                { return iterate_1.stop(); }
	        }, undefined, true, true).stopped;
	    }
	});

	_export({
	    target: 'Map',
	    proto: true,
	    real: true,
	    forced: isPure
	}, {
	    keyOf: function keyOf(searchElement) {
	        return iterate_1(getMapIterator(anObject(this)), function (key, value) {
	            if (value === searchElement) 
	                { return iterate_1.stop(key); }
	        }, undefined, true, true).result;
	    }
	});

	_export({
	    target: 'Map',
	    proto: true,
	    real: true,
	    forced: isPure
	}, {
	    mapKeys: function mapKeys(callbackfn) {
	        var map = anObject(this);
	        var iterator = getMapIterator(map);
	        var boundFunction = bindContext(callbackfn, arguments.length > 1 ? arguments[1] : undefined, 3);
	        var newMap = new (speciesConstructor(map, getBuiltIn('Map')))();
	        var setter = aFunction$1(newMap.set);
	        iterate_1(iterator, function (key, value) {
	            setter.call(newMap, boundFunction(value, key, map), value);
	        }, undefined, true, true);
	        return newMap;
	    }
	});

	_export({
	    target: 'Map',
	    proto: true,
	    real: true,
	    forced: isPure
	}, {
	    mapValues: function mapValues(callbackfn) {
	        var map = anObject(this);
	        var iterator = getMapIterator(map);
	        var boundFunction = bindContext(callbackfn, arguments.length > 1 ? arguments[1] : undefined, 3);
	        var newMap = new (speciesConstructor(map, getBuiltIn('Map')))();
	        var setter = aFunction$1(newMap.set);
	        iterate_1(iterator, function (key, value) {
	            setter.call(newMap, key, boundFunction(value, key, map));
	        }, undefined, true, true);
	        return newMap;
	    }
	});

	_export({
	    target: 'Map',
	    proto: true,
	    real: true,
	    forced: isPure
	}, {
	    merge: function merge(iterable) {
	        var arguments$1 = arguments;

	        var map = anObject(this);
	        var setter = aFunction$1(map.set);
	        var i = 0;
	        while (i < arguments.length) {
	            iterate_1(arguments$1[i++], setter, map, true);
	        }
	        return map;
	    }
	});

	_export({
	    target: 'Map',
	    proto: true,
	    real: true,
	    forced: isPure
	}, {
	    reduce: function reduce(callbackfn) {
	        var map = anObject(this);
	        var iterator = getMapIterator(map);
	        var accumulator, step;
	        aFunction$1(callbackfn);
	        if (arguments.length > 1) 
	            { accumulator = arguments[1]; }
	         else {
	            step = iterator.next();
	            if (step.done) 
	                { throw TypeError('Reduce of empty map with no initial value'); }
	            accumulator = step.value[1];
	        }
	        iterate_1(iterator, function (key, value) {
	            accumulator = callbackfn(accumulator, value, key, map);
	        }, undefined, true, true);
	        return accumulator;
	    }
	});

	_export({
	    target: 'Map',
	    proto: true,
	    real: true,
	    forced: isPure
	}, {
	    some: function some(callbackfn) {
	        var map = anObject(this);
	        var iterator = getMapIterator(map);
	        var boundFunction = bindContext(callbackfn, arguments.length > 1 ? arguments[1] : undefined, 3);
	        return iterate_1(iterator, function (key, value) {
	            if (boundFunction(value, key, map)) 
	                { return iterate_1.stop(); }
	        }, undefined, true, true).stopped;
	    }
	});

	_export({
	    target: 'Map',
	    proto: true,
	    real: true,
	    forced: isPure
	}, {
	    update: function update(key, callback) {
	        var map = anObject(this);
	        var length = arguments.length;
	        aFunction$1(callback);
	        var isPresentInMap = map.has(key);
	        if (!isPresentInMap && length < 3) {
	            throw TypeError('Updating absent value');
	        }
	        var value = isPresentInMap ? map.get(key) : aFunction$1(length > 2 ? arguments[2] : undefined)(key, map);
	        map.set(key, callback(value, key, map));
	        return map;
	    }
	});

	var collectionAddAll = function () {
	    var arguments$1 = arguments;

	    var set = anObject(this);
	    var adder = aFunction$1(set.add);
	    for (var k = 0, len = arguments.length;k < len; k++) {
	        adder.call(set, arguments$1[k]);
	    }
	    return set;
	};

	_export({
	    target: 'Set',
	    proto: true,
	    real: true,
	    forced: isPure
	}, {
	    addAll: function addAll() {
	        return collectionAddAll.apply(this, arguments);
	    }
	});

	_export({
	    target: 'Set',
	    proto: true,
	    real: true,
	    forced: isPure
	}, {
	    deleteAll: function deleteAll() {
	        return collectionDeleteAll.apply(this, arguments);
	    }
	});

	var getSetIterator =  function (it) {
	    return Set.prototype.values.call(it);
	};

	_export({
	    target: 'Set',
	    proto: true,
	    real: true,
	    forced: isPure
	}, {
	    every: function every(callbackfn) {
	        var set = anObject(this);
	        var iterator = getSetIterator(set);
	        var boundFunction = bindContext(callbackfn, arguments.length > 1 ? arguments[1] : undefined, 3);
	        return !iterate_1(iterator, function (value) {
	            if (!boundFunction(value, value, set)) 
	                { return iterate_1.stop(); }
	        }, undefined, false, true).stopped;
	    }
	});

	_export({
	    target: 'Set',
	    proto: true,
	    real: true,
	    forced: isPure
	}, {
	    filter: function filter(callbackfn) {
	        var set = anObject(this);
	        var iterator = getSetIterator(set);
	        var boundFunction = bindContext(callbackfn, arguments.length > 1 ? arguments[1] : undefined, 3);
	        var newSet = new (speciesConstructor(set, getBuiltIn('Set')))();
	        var adder = aFunction$1(newSet.add);
	        iterate_1(iterator, function (value) {
	            if (boundFunction(value, value, set)) 
	                { adder.call(newSet, value); }
	        }, undefined, false, true);
	        return newSet;
	    }
	});

	_export({
	    target: 'Set',
	    proto: true,
	    real: true,
	    forced: isPure
	}, {
	    find: function find(callbackfn) {
	        var set = anObject(this);
	        var iterator = getSetIterator(set);
	        var boundFunction = bindContext(callbackfn, arguments.length > 1 ? arguments[1] : undefined, 3);
	        return iterate_1(iterator, function (value) {
	            if (boundFunction(value, value, set)) 
	                { return iterate_1.stop(value); }
	        }, undefined, false, true).result;
	    }
	});

	_export({
	    target: 'Set',
	    proto: true,
	    real: true,
	    forced: isPure
	}, {
	    join: function join(separator) {
	        var set = anObject(this);
	        var iterator = getSetIterator(set);
	        var sep = separator === undefined ? ',' : String(separator);
	        var result = [];
	        iterate_1(iterator, result.push, result, false, true);
	        return result.join(sep);
	    }
	});

	_export({
	    target: 'Set',
	    proto: true,
	    real: true,
	    forced: isPure
	}, {
	    map: function map(callbackfn) {
	        var set = anObject(this);
	        var iterator = getSetIterator(set);
	        var boundFunction = bindContext(callbackfn, arguments.length > 1 ? arguments[1] : undefined, 3);
	        var newSet = new (speciesConstructor(set, getBuiltIn('Set')))();
	        var adder = aFunction$1(newSet.add);
	        iterate_1(iterator, function (value) {
	            adder.call(newSet, boundFunction(value, value, set));
	        }, undefined, false, true);
	        return newSet;
	    }
	});

	_export({
	    target: 'Set',
	    proto: true,
	    real: true,
	    forced: isPure
	}, {
	    reduce: function reduce(callbackfn) {
	        var set = anObject(this);
	        var iterator = getSetIterator(set);
	        var accumulator, step;
	        aFunction$1(callbackfn);
	        if (arguments.length > 1) 
	            { accumulator = arguments[1]; }
	         else {
	            step = iterator.next();
	            if (step.done) 
	                { throw TypeError('Reduce of empty set with no initial value'); }
	            accumulator = step.value;
	        }
	        iterate_1(iterator, function (value) {
	            accumulator = callbackfn(accumulator, value, value, set);
	        }, undefined, false, true);
	        return accumulator;
	    }
	});

	_export({
	    target: 'Set',
	    proto: true,
	    real: true,
	    forced: isPure
	}, {
	    some: function some(callbackfn) {
	        var set = anObject(this);
	        var iterator = getSetIterator(set);
	        var boundFunction = bindContext(callbackfn, arguments.length > 1 ? arguments[1] : undefined, 3);
	        return iterate_1(iterator, function (value) {
	            if (boundFunction(value, value, set)) 
	                { return iterate_1.stop(); }
	        }, undefined, false, true).stopped;
	    }
	});

	_export({
	    target: 'WeakMap',
	    proto: true,
	    real: true,
	    forced: isPure
	}, {
	    deleteAll: function deleteAll() {
	        return collectionDeleteAll.apply(this, arguments);
	    }
	});

	_export({
	    target: 'WeakSet',
	    proto: true,
	    real: true,
	    forced: isPure
	}, {
	    addAll: function addAll() {
	        return collectionAddAll.apply(this, arguments);
	    }
	});

	_export({
	    target: 'WeakSet',
	    proto: true,
	    real: true,
	    forced: isPure
	}, {
	    deleteAll: function deleteAll() {
	        return collectionDeleteAll.apply(this, arguments);
	    }
	});

	var collectionFrom = function from(source) {
	    var length = arguments.length;
	    var mapFn = length > 1 ? arguments[1] : undefined;
	    var mapping, A, n, boundFunction;
	    aFunction$1(this);
	    mapping = mapFn !== undefined;
	    if (mapping) 
	        { aFunction$1(mapFn); }
	    if (source == undefined) 
	        { return new this(); }
	    A = [];
	    if (mapping) {
	        n = 0;
	        boundFunction = bindContext(mapFn, length > 2 ? arguments[2] : undefined, 2);
	        iterate_1(source, function (nextItem) {
	            A.push(boundFunction(nextItem, n++));
	        });
	    } else {
	        iterate_1(source, A.push, A);
	    }
	    return new this(A);
	};

	_export({
	    target: 'Map',
	    stat: true
	}, {
	    from: collectionFrom
	});

	var collectionOf = function of() {
	    var arguments$1 = arguments;

	    var length = arguments.length;
	    var A = new Array(length);
	    while (length--) 
	        { A[length] = arguments$1[length]; }
	    return new this(A);
	};

	_export({
	    target: 'Map',
	    stat: true
	}, {
	    of: collectionOf
	});

	_export({
	    target: 'Set',
	    stat: true
	}, {
	    from: collectionFrom
	});

	_export({
	    target: 'Set',
	    stat: true
	}, {
	    of: collectionOf
	});

	_export({
	    target: 'WeakMap',
	    stat: true
	}, {
	    from: collectionFrom
	});

	_export({
	    target: 'WeakMap',
	    stat: true
	}, {
	    of: collectionOf
	});

	_export({
	    target: 'WeakSet',
	    stat: true
	}, {
	    from: collectionFrom
	});

	_export({
	    target: 'WeakSet',
	    stat: true
	}, {
	    of: collectionOf
	});

	var Node = function () {
	    this.object = null;
	    this.symbol = null;
	    this.primitives = null;
	    this.objectsByIndex = objectCreate(null);
	};
	Node.prototype.get = function (key, initializer) {
	    return this[key] || (this[key] = initializer());
	};
	Node.prototype.next = function (i, it, IS_OBJECT) {
	    var store = IS_OBJECT ? this.objectsByIndex[i] || (this.objectsByIndex[i] = new es_weakMap()) : this.primitives || (this.primitives = new es_map());
	    var entry = store.get(it);
	    if (!entry) 
	        { store.set(it, entry = new Node()); }
	    return entry;
	};
	var root = new Node();
	var compositeKey = function () {
	    var arguments$1 = arguments;

	    var active = root;
	    var length = arguments.length;
	    var i, it;
	    for (i = 0; i < length; i++) {
	        if (isObject(it = arguments$1[i])) 
	            { active = active.next(i, it, true); }
	    }
	    if (this === Object && active === root) 
	        { throw TypeError('Composite keys must contain a non-primitive component'); }
	    for (i = 0; i < length; i++) {
	        if (!isObject(it = arguments$1[i])) 
	            { active = active.next(i, it, false); }
	    }
	    return active;
	};

	var initializer = function () {
	    var freeze = getBuiltIn('Object', 'freeze');
	    return freeze ? freeze(objectCreate(null)) : objectCreate(null);
	};
	_export({
	    global: true
	}, {
	    compositeKey: function compositeKey$1() {
	        return compositeKey.apply(Object, arguments).get('object', initializer);
	    }
	});

	_export({
	    global: true
	}, {
	    compositeSymbol: function compositeSymbol() {
	        if (arguments.length === 1 && typeof arguments[0] === 'string') 
	            { return getBuiltIn('Symbol')['for'](arguments[0]); }
	        return compositeKey.apply(null, arguments).get('symbol', getBuiltIn('Symbol'));
	    }
	});

	_export({
	    target: 'Map',
	    proto: true,
	    real: true,
	    forced: isPure
	}, {
	    updateOrInsert: function updateOrInsert(key, onUpdate, onInsert) {
	        var map = anObject(this);
	        aFunction$1(onUpdate);
	        aFunction$1(onInsert);
	        var value = map.has(key) ? onUpdate(map.get(key)) : onInsert();
	        map.set(key, value);
	        return value;
	    }
	});

	var min$9 = Math.min;
	var max$4 = Math.max;
	_export({
	    target: 'Math',
	    stat: true
	}, {
	    clamp: function clamp(x, lower, upper) {
	        return min$9(upper, max$4(lower, x));
	    }
	});

	_export({
	    target: 'Math',
	    stat: true
	}, {
	    DEG_PER_RAD: Math.PI / 180
	});

	var RAD_PER_DEG = 180 / Math.PI;
	_export({
	    target: 'Math',
	    stat: true
	}, {
	    degrees: function degrees(radians) {
	        return radians * RAD_PER_DEG;
	    }
	});

	var mathScale = Math.scale || function scale(x, inLow, inHigh, outLow, outHigh) {
	    if (arguments.length === 0 || x != x || inLow != inLow || inHigh != inHigh || outLow != outLow || outHigh != outHigh) 
	        { return NaN; }
	    if (x === Infinity || x === -Infinity) 
	        { return x; }
	    return (x - inLow) * (outHigh - outLow) / (inHigh - inLow) + outLow;
	};

	_export({
	    target: 'Math',
	    stat: true
	}, {
	    fscale: function fscale(x, inLow, inHigh, outLow, outHigh) {
	        return mathFround(mathScale(x, inLow, inHigh, outLow, outHigh));
	    }
	});

	_export({
	    target: 'Math',
	    stat: true
	}, {
	    RAD_PER_DEG: 180 / Math.PI
	});

	var DEG_PER_RAD = Math.PI / 180;
	_export({
	    target: 'Math',
	    stat: true
	}, {
	    radians: function radians(degrees) {
	        return degrees * DEG_PER_RAD;
	    }
	});

	_export({
	    target: 'Math',
	    stat: true
	}, {
	    scale: mathScale
	});

	_export({
	    target: 'Math',
	    stat: true
	}, {
	    signbit: function signbit(x) {
	        return (x = +x) != x ? x : x == 0 ? 1 / x == Infinity : x > 0;
	    }
	});

	var INVALID_NUMBER_REPRESENTATION = 'Invalid number representation';
	var INVALID_RADIX = 'Invalid radix';
	var valid = /^[\da-z]+$/;
	_export({
	    target: 'Number',
	    stat: true
	}, {
	    fromString: function fromString(string, radix) {
	        var sign = 1;
	        var R, mathNum;
	        if (typeof string != 'string') 
	            { throw TypeError(INVALID_NUMBER_REPRESENTATION); }
	        if (!string.length) 
	            { throw SyntaxError(INVALID_NUMBER_REPRESENTATION); }
	        if (string.charAt(0) == '-') {
	            sign = -1;
	            string = string.slice(1);
	            if (!string.length) 
	                { throw SyntaxError(INVALID_NUMBER_REPRESENTATION); }
	        }
	        R = radix === undefined ? 10 : toInteger(radix);
	        if (R < 2 || R > 36) 
	            { throw RangeError(INVALID_RADIX); }
	        if (!valid.test(string) || (mathNum = _parseInt(string, R)).toString(R) !== string) {
	            throw SyntaxError(INVALID_NUMBER_REPRESENTATION);
	        }
	        return sign * mathNum;
	    }
	});

	var defineProperty$a = objectDefineProperty.f;
	var OBSERVABLE = wellKnownSymbol('observable');
	var getInternalState$5 = internalState.get;
	var setInternalState$9 = internalState.set;
	var getMethod = function (fn) {
	    return fn == null ? undefined : aFunction$1(fn);
	};
	var cleanupSubscription = function (subscriptionState) {
	    var cleanup = subscriptionState.cleanup;
	    if (cleanup) {
	        subscriptionState.cleanup = undefined;
	        try {
	            cleanup();
	        } catch (error) {
	            hostReportErrors(error);
	        }
	    }
	};
	var subscriptionClosed = function (subscriptionState) {
	    return subscriptionState.observer === undefined;
	};
	var close = function (subscription, subscriptionState) {
	    if (!descriptors) {
	        subscription.closed = true;
	        var subscriptionObserver = subscriptionState.subscriptionObserver;
	        if (subscriptionObserver) 
	            { subscriptionObserver.closed = true; }
	    }
	    subscriptionState.observer = undefined;
	};
	var Subscription = function (observer, subscriber) {
	    var subscriptionState = setInternalState$9(this, {
	        cleanup: undefined,
	        observer: anObject(observer),
	        subscriptionObserver: undefined
	    });
	    var start;
	    if (!descriptors) 
	        { this.closed = false; }
	    try {
	        if (start = getMethod(observer.start)) 
	            { start.call(observer, this); }
	    } catch (error) {
	        hostReportErrors(error);
	    }
	    if (subscriptionClosed(subscriptionState)) 
	        { return; }
	    var subscriptionObserver = subscriptionState.subscriptionObserver = new SubscriptionObserver(this);
	    try {
	        var cleanup = subscriber(subscriptionObserver);
	        var subscription = cleanup;
	        if (cleanup != null) 
	            { subscriptionState.cleanup = typeof cleanup.unsubscribe === 'function' ? function () {
	            subscription.unsubscribe();
	        } : aFunction$1(cleanup); }
	    } catch (error) {
	        subscriptionObserver.error(error);
	        return;
	    }
	    if (subscriptionClosed(subscriptionState)) 
	        { cleanupSubscription(subscriptionState); }
	};
	Subscription.prototype = redefineAll({}, {
	    unsubscribe: function unsubscribe() {
	        var subscriptionState = getInternalState$5(this);
	        if (!subscriptionClosed(subscriptionState)) {
	            close(this, subscriptionState);
	            cleanupSubscription(subscriptionState);
	        }
	    }
	});
	if (descriptors) 
	    { defineProperty$a(Subscription.prototype, 'closed', {
	    configurable: true,
	    get: function () {
	        return subscriptionClosed(getInternalState$5(this));
	    }
	}); }
	var SubscriptionObserver = function (subscription) {
	    setInternalState$9(this, {
	        subscription: subscription
	    });
	    if (!descriptors) 
	        { this.closed = false; }
	};
	SubscriptionObserver.prototype = redefineAll({}, {
	    next: function next(value) {
	        var subscriptionState = getInternalState$5(getInternalState$5(this).subscription);
	        if (!subscriptionClosed(subscriptionState)) {
	            var observer = subscriptionState.observer;
	            try {
	                var nextMethod = getMethod(observer.next);
	                if (nextMethod) 
	                    { nextMethod.call(observer, value); }
	            } catch (error) {
	                hostReportErrors(error);
	            }
	        }
	    },
	    error: function error(value) {
	        var subscription = getInternalState$5(this).subscription;
	        var subscriptionState = getInternalState$5(subscription);
	        if (!subscriptionClosed(subscriptionState)) {
	            var observer = subscriptionState.observer;
	            close(subscription, subscriptionState);
	            try {
	                var errorMethod = getMethod(observer.error);
	                if (errorMethod) 
	                    { errorMethod.call(observer, value); }
	                 else 
	                    { hostReportErrors(value); }
	            } catch (err) {
	                hostReportErrors(err);
	            }
	            cleanupSubscription(subscriptionState);
	        }
	    },
	    complete: function complete() {
	        var subscription = getInternalState$5(this).subscription;
	        var subscriptionState = getInternalState$5(subscription);
	        if (!subscriptionClosed(subscriptionState)) {
	            var observer = subscriptionState.observer;
	            close(subscription, subscriptionState);
	            try {
	                var completeMethod = getMethod(observer.complete);
	                if (completeMethod) 
	                    { completeMethod.call(observer); }
	            } catch (error) {
	                hostReportErrors(error);
	            }
	            cleanupSubscription(subscriptionState);
	        }
	    }
	});
	if (descriptors) 
	    { defineProperty$a(SubscriptionObserver.prototype, 'closed', {
	    configurable: true,
	    get: function () {
	        return subscriptionClosed(getInternalState$5(getInternalState$5(this).subscription));
	    }
	}); }
	var $Observable = function Observable(subscriber) {
	    anInstance(this, $Observable, 'Observable');
	    setInternalState$9(this, {
	        subscriber: aFunction$1(subscriber)
	    });
	};
	redefineAll($Observable.prototype, {
	    subscribe: function subscribe(observer) {
	        var length = arguments.length;
	        return new Subscription(typeof observer === 'function' ? {
	            next: observer,
	            error: length > 1 ? arguments[1] : undefined,
	            complete: length > 2 ? arguments[2] : undefined
	        } : isObject(observer) ? observer : {}, getInternalState$5(this).subscriber);
	    }
	});
	redefineAll($Observable, {
	    from: function from(x) {
	        var C = typeof this === 'function' ? this : $Observable;
	        var observableMethod = getMethod(anObject(x)[OBSERVABLE]);
	        if (observableMethod) {
	            var observable = anObject(observableMethod.call(x));
	            return observable.constructor === C ? observable : new C(function (observer) {
	                return observable.subscribe(observer);
	            });
	        }
	        var iterator = getIterator(x);
	        return new C(function (observer) {
	            iterate_1(iterator, function (it) {
	                observer.next(it);
	                if (observer.closed) 
	                    { return iterate_1.stop(); }
	            }, undefined, false, true);
	            observer.complete();
	        });
	    },
	    of: function of() {
	        var arguments$1 = arguments;

	        var C = typeof this === 'function' ? this : $Observable;
	        var length = arguments.length;
	        var items = new Array(length);
	        var index = 0;
	        while (index < length) 
	            { items[index] = arguments$1[index++]; }
	        return new C(function (observer) {
	            for (var i = 0;i < length; i++) {
	                observer.next(items[i]);
	                if (observer.closed) 
	                    { return; }
	            }
	            observer.complete();
	        });
	    }
	});
	hide($Observable.prototype, OBSERVABLE, function () {
	    return this;
	});
	_export({
	    global: true
	}, {
	    Observable: $Observable
	});
	setSpecies('Observable');

	defineWellKnownSymbol('observable');

	defineWellKnownSymbol('patternMatch');

	_export({
	    target: 'Promise',
	    stat: true
	}, {
	    'try': function (callbackfn) {
	        var promiseCapability = newPromiseCapability.f(this);
	        var result = perform(callbackfn);
	        (result.error ? promiseCapability.reject : promiseCapability.resolve)(result.value);
	        return promiseCapability.promise;
	    }
	});

	var SEEDED_RANDOM = 'Seeded Random';
	var SEEDED_RANDOM_GENERATOR = SEEDED_RANDOM + ' Generator';
	var setInternalState$a = internalState.set;
	var getInternalState$6 = internalState.getterFor(SEEDED_RANDOM_GENERATOR);
	var SEED_TYPE_ERROR = 'Math.seededPRNG() argument should have a "seed" field with a finite value.';
	var $SeededRandomGenerator = createIteratorConstructor(function SeededRandomGenerator(seed) {
	    setInternalState$a(this, {
	        type: SEEDED_RANDOM_GENERATOR,
	        seed: seed % 2147483647
	    });
	}, SEEDED_RANDOM, function next() {
	    var state = getInternalState$6(this);
	    var seed = state.seed = (state.seed * 1103515245 + 12345) % 2147483647;
	    return {
	        value: (seed & 1073741823) / 1073741823,
	        done: false
	    };
	});
	_export({
	    target: 'Math',
	    stat: true,
	    forced: true
	}, {
	    seededPRNG: function seededPRNG(it) {
	        var seed = anObject(it).seed;
	        if (!numberIsFinite(seed)) 
	            { throw TypeError(SEED_TYPE_ERROR); }
	        return new $SeededRandomGenerator(seed);
	    }
	});

	var codeAt$2 = stringMultibyte.codeAt;
	var charAt$3 = stringMultibyte.charAt;
	var STRING_ITERATOR$1 = 'String Iterator';
	var setInternalState$b = internalState.set;
	var getInternalState$7 = internalState.getterFor(STRING_ITERATOR$1);
	var $StringIterator = createIteratorConstructor(function StringIterator(string) {
	    setInternalState$b(this, {
	        type: STRING_ITERATOR$1,
	        string: string,
	        index: 0
	    });
	}, 'String', function next() {
	    var state = getInternalState$7(this);
	    var string = state.string;
	    var index = state.index;
	    var point;
	    if (index >= string.length) 
	        { return {
	        value: undefined,
	        done: true
	    }; }
	    point = charAt$3(string, index);
	    state.index += point.length;
	    return {
	        value: {
	            codePoint: codeAt$2(point, 0),
	            position: index
	        },
	        done: false
	    };
	});
	_export({
	    target: 'String',
	    proto: true
	}, {
	    codePoints: function codePoints() {
	        return new $StringIterator(String(requireObjectCoercible(this)));
	    }
	});

	var isFrozen = Object.isFrozen;
	var isFrozenStringArray = function (array, allowUndefined) {
	    if (!isFrozen || !isArray(array) || !isFrozen(array)) 
	        { return false; }
	    var index = 0;
	    var length = array.length;
	    var element;
	    while (index < length) {
	        element = array[index++];
	        if (!(typeof element === 'string' || allowUndefined && typeof element === 'undefined')) {
	            return false;
	        }
	    }
	    return length !== 0;
	};
	_export({
	    target: 'Array',
	    stat: true
	}, {
	    isTemplateObject: function isTemplateObject(value) {
	        if (!isFrozenStringArray(value, true)) 
	            { return false; }
	        var raw = value.raw;
	        if (raw.length !== value.length || !isFrozenStringArray(raw, false)) 
	            { return false; }
	        return true;
	    }
	});

	var $AggregateError = function AggregateError(errors, message) {
	    var that = this;
	    if (!(that instanceof $AggregateError)) 
	        { return new $AggregateError(errors, message); }
	    if (objectSetPrototypeOf) {
	        that = objectSetPrototypeOf(new Error(message), objectGetPrototypeOf(that));
	    }
	    var errorsArray = [];
	    iterate_1(errors, errorsArray.push, errorsArray);
	    hide(that, 'errors', errorsArray);
	    if (message !== undefined) 
	        { hide(that, 'message', String(message)); }
	    return that;
	};
	$AggregateError.prototype = objectCreate(Error.prototype, {
	    constructor: createPropertyDescriptor(5, $AggregateError),
	    name: createPropertyDescriptor(5, 'AggregateError')
	});
	_export({
	    global: true
	}, {
	    AggregateError: $AggregateError
	});

	var PROMISE_ANY_ERROR = 'No one promise resolved';
	_export({
	    target: 'Promise',
	    stat: true
	}, {
	    any: function any(iterable) {
	        var C = this;
	        var capability = newPromiseCapability.f(C);
	        var resolve = capability.resolve;
	        var reject = capability.reject;
	        var result = perform(function () {
	            var promiseResolve = aFunction$1(C.resolve);
	            var errors = [];
	            var counter = 0;
	            var remaining = 1;
	            var alreadyResolved = false;
	            iterate_1(iterable, function (promise) {
	                var index = counter++;
	                var alreadyRejected = false;
	                errors.push(undefined);
	                remaining++;
	                promiseResolve.call(C, promise).then(function (value) {
	                    if (alreadyRejected || alreadyResolved) 
	                        { return; }
	                    alreadyResolved = true;
	                    resolve(value);
	                }, function (e) {
	                    if (alreadyRejected || alreadyResolved) 
	                        { return; }
	                    alreadyRejected = true;
	                    errors[index] = e;
	                    --remaining || reject(new (getBuiltIn('AggregateError'))(errors, PROMISE_ANY_ERROR));
	                });
	            });
	            --remaining || reject(new (getBuiltIn('AggregateError'))(errors, PROMISE_ANY_ERROR));
	        });
	        if (result.error) 
	            { reject(result.value); }
	        return capability.promise;
	    }
	});

	_export({
	    target: 'Set',
	    proto: true,
	    real: true,
	    forced: isPure
	}, {
	    difference: function difference(iterable) {
	        var set = anObject(this);
	        var newSet = new (speciesConstructor(set, getBuiltIn('Set')))(set);
	        var remover = aFunction$1(newSet['delete']);
	        iterate_1(iterable, function (value) {
	            remover.call(newSet, value);
	        });
	        return newSet;
	    }
	});

	_export({
	    target: 'Set',
	    proto: true,
	    real: true,
	    forced: isPure
	}, {
	    intersection: function intersection(iterable) {
	        var set = anObject(this);
	        var newSet = new (speciesConstructor(set, getBuiltIn('Set')))();
	        var hasCheck = aFunction$1(set.has);
	        var adder = aFunction$1(newSet.add);
	        iterate_1(iterable, function (value) {
	            if (hasCheck.call(set, value)) 
	                { adder.call(newSet, value); }
	        });
	        return newSet;
	    }
	});

	_export({
	    target: 'Set',
	    proto: true,
	    real: true,
	    forced: isPure
	}, {
	    isDisjointFrom: function isDisjointFrom(iterable) {
	        var set = anObject(this);
	        var hasCheck = aFunction$1(set.has);
	        return !iterate_1(iterable, function (value) {
	            if (hasCheck.call(set, value) === true) 
	                { return iterate_1.stop(); }
	        }).stopped;
	    }
	});

	_export({
	    target: 'Set',
	    proto: true,
	    real: true,
	    forced: isPure
	}, {
	    isSubsetOf: function isSubsetOf(iterable) {
	        var iterator = getIterator(this);
	        var otherSet = anObject(iterable);
	        var hasCheck = otherSet.has;
	        if (typeof hasCheck != 'function') {
	            otherSet = new (getBuiltIn('Set'))(iterable);
	            hasCheck = aFunction$1(otherSet.has);
	        }
	        return !iterate_1(iterator, function (value) {
	            if (hasCheck.call(otherSet, value) === false) 
	                { return iterate_1.stop(); }
	        }, undefined, false, true).stopped;
	    }
	});

	_export({
	    target: 'Set',
	    proto: true,
	    real: true,
	    forced: isPure
	}, {
	    isSupersetOf: function isSupersetOf(iterable) {
	        var set = anObject(this);
	        var hasCheck = aFunction$1(set.has);
	        return !iterate_1(iterable, function (value) {
	            if (hasCheck.call(set, value) === false) 
	                { return iterate_1.stop(); }
	        }).stopped;
	    }
	});

	_export({
	    target: 'Set',
	    proto: true,
	    real: true,
	    forced: isPure
	}, {
	    union: function union(iterable) {
	        var set = anObject(this);
	        var newSet = new (speciesConstructor(set, getBuiltIn('Set')))(set);
	        iterate_1(iterable, aFunction$1(newSet.add), newSet);
	        return newSet;
	    }
	});

	_export({
	    target: 'Set',
	    proto: true,
	    real: true,
	    forced: isPure
	}, {
	    symmetricDifference: function symmetricDifference(iterable) {
	        var set = anObject(this);
	        var newSet = new (speciesConstructor(set, getBuiltIn('Set')))(set);
	        var remover = aFunction$1(newSet['delete']);
	        var adder = aFunction$1(newSet.add);
	        iterate_1(iterable, function (value) {
	            remover.call(newSet, value) || adder.call(newSet, value);
	        });
	        return newSet;
	    }
	});

	var REPLACE_ALL = wellKnownSymbol('replaceAll');
	var RegExpPrototype$3 = RegExp.prototype;
	var $replaceAll = function (string, replaceValue) {
	    var rx = anObject(this);
	    var flags = String('flags' in RegExpPrototype$3 ? rx.flags : regexpFlags.call(rx));
	    if (!(~flags.indexOf('g'))) {
	        rx = new (speciesConstructor(rx, RegExp))(rx.source, flags + 'g');
	    }
	    return String(string).replace(rx, replaceValue);
	};
	_export({
	    target: 'String',
	    proto: true
	}, {
	    replaceAll: function replaceAll(searchValue, replaceValue) {
	        var O = requireObjectCoercible(this);
	        var replacer, string, searchString, template, result, index;
	        if (searchValue != null) {
	            replacer = searchValue[REPLACE_ALL];
	            if (replacer !== undefined) {
	                return replacer.call(searchValue, O, replaceValue);
	            }
	        }
	        string = String(O);
	        searchString = String(searchValue);
	        template = string.split(searchString);
	        if (typeof replaceValue !== 'function') {
	            return template.join(String(replaceValue));
	        }
	        result = template[0];
	        for (index = 1; index < template.length; index++) {
	            result += String(replaceValue(searchString, index - 1, string));
	            result += template[index];
	        }
	        return result;
	    }
	});
	 REPLACE_ALL in RegExpPrototype$3 || hide(RegExpPrototype$3, REPLACE_ALL, $replaceAll);

	defineWellKnownSymbol('replaceAll');

	defineWellKnownSymbol('asyncDispose');

	defineWellKnownSymbol('dispose');

	_export({
	    global: true
	}, {
	    globalThis: global_1
	});

	var domIterables = {
	    CSSRuleList: 0,
	    CSSStyleDeclaration: 0,
	    CSSValueList: 0,
	    ClientRectList: 0,
	    DOMRectList: 0,
	    DOMStringList: 0,
	    DOMTokenList: 1,
	    DataTransferItemList: 0,
	    FileList: 0,
	    HTMLAllCollection: 0,
	    HTMLCollection: 0,
	    HTMLFormElement: 0,
	    HTMLSelectElement: 0,
	    MediaList: 0,
	    MimeTypeArray: 0,
	    NamedNodeMap: 0,
	    NodeList: 1,
	    PaintRequestList: 0,
	    Plugin: 0,
	    PluginArray: 0,
	    SVGLengthList: 0,
	    SVGNumberList: 0,
	    SVGPathSegList: 0,
	    SVGPointList: 0,
	    SVGStringList: 0,
	    SVGTransformList: 0,
	    SourceBufferList: 0,
	    StyleSheetList: 0,
	    TextTrackCueList: 0,
	    TextTrackList: 0,
	    TouchList: 0
	};

	for (var COLLECTION_NAME in domIterables) {
	    var Collection = global_1[COLLECTION_NAME];
	    var CollectionPrototype = Collection && Collection.prototype;
	    if (CollectionPrototype && CollectionPrototype.forEach !== arrayForEach) {
	        try {
	            hide(CollectionPrototype, 'forEach', arrayForEach);
	        } catch (error) {
	            CollectionPrototype.forEach = arrayForEach;
	        }
	    }
	}

	var ITERATOR$8 = wellKnownSymbol('iterator');
	var TO_STRING_TAG$4 = wellKnownSymbol('toStringTag');
	var ArrayValues = es_array_iterator.values;
	for (var COLLECTION_NAME$1 in domIterables) {
	    var Collection$1 = global_1[COLLECTION_NAME$1];
	    var CollectionPrototype$1 = Collection$1 && Collection$1.prototype;
	    if (CollectionPrototype$1) {
	        if (CollectionPrototype$1[ITERATOR$8] !== ArrayValues) {
	            try {
	                hide(CollectionPrototype$1, ITERATOR$8, ArrayValues);
	            } catch (error) {
	                CollectionPrototype$1[ITERATOR$8] = ArrayValues;
	            }
	        }
	        if (!CollectionPrototype$1[TO_STRING_TAG$4]) 
	            { hide(CollectionPrototype$1, TO_STRING_TAG$4, COLLECTION_NAME$1); }
	        if (domIterables[COLLECTION_NAME$1]) 
	            { for (var METHOD_NAME in es_array_iterator) {
	            if (CollectionPrototype$1[METHOD_NAME] !== es_array_iterator[METHOD_NAME]) {
	                try {
	                    hide(CollectionPrototype$1, METHOD_NAME, es_array_iterator[METHOD_NAME]);
	                } catch (error) {
	                    CollectionPrototype$1[METHOD_NAME] = es_array_iterator[METHOD_NAME];
	                }
	            }
	        } }
	    }
	}

	var FORCED$j = !global_1.setImmediate || !global_1.clearImmediate;
	_export({
	    global: true,
	    bind: true,
	    enumerable: true,
	    forced: FORCED$j
	}, {
	    setImmediate: task.set,
	    clearImmediate: task.clear
	});

	var process$3 = global_1.process;
	var isNode = classofRaw(process$3) == 'process';
	_export({
	    global: true,
	    enumerable: true,
	    noTargetGet: true
	}, {
	    queueMicrotask: function queueMicrotask(fn) {
	        var domain = isNode && process$3.domain;
	        microtask(domain ? domain.bind(fn) : fn);
	    }
	});

	var slice$1 = [].slice;
	var MSIE = /MSIE .\./.test(userAgent);
	var wrap$1 = function (scheduler) {
	    return function (handler, timeout) {
	        var boundArgs = arguments.length > 2;
	        var args = boundArgs ? slice$1.call(arguments, 2) : undefined;
	        return scheduler(boundArgs ? function () {
	            (typeof handler == 'function' ? handler : Function(handler)).apply(this, args);
	        } : handler, timeout);
	    };
	};
	_export({
	    global: true,
	    bind: true,
	    forced: MSIE
	}, {
	    setTimeout: wrap$1(global_1.setTimeout),
	    setInterval: wrap$1(global_1.setInterval)
	});

	var global$1 = typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {};

	var emptyObject = Object.freeze({});
	function isUndef(v) {
	    return v === undefined || v === null;
	}

	function isDef(v) {
	    return v !== undefined && v !== null;
	}

	function isTrue(v) {
	    return v === true;
	}

	function isFalse(v) {
	    return v === false;
	}

	function isPrimitive(value) {
	    return typeof value === 'string' || typeof value === 'number' || typeof value === 'symbol' || typeof value === 'boolean';
	}

	function isObject$1(obj) {
	    return obj !== null && typeof obj === 'object';
	}

	var _toString = Object.prototype.toString;
	function toRawType(value) {
	    return _toString.call(value).slice(8, -1);
	}

	function isPlainObject(obj) {
	    return _toString.call(obj) === '[object Object]';
	}

	function isRegExp(v) {
	    return _toString.call(v) === '[object RegExp]';
	}

	function isValidArrayIndex(val) {
	    var n = parseFloat(String(val));
	    return n >= 0 && Math.floor(n) === n && isFinite(val);
	}

	function isPromise(val) {
	    return isDef(val) && typeof val.then === 'function' && typeof val.catch === 'function';
	}

	function toString$2(val) {
	    return val == null ? '' : Array.isArray(val) || isPlainObject(val) && val.toString === _toString ? JSON.stringify(val, null, 2) : String(val);
	}

	function toNumber$1(val) {
	    var n = parseFloat(val);
	    return isNaN(n) ? val : n;
	}

	function makeMap(str, expectsLowerCase) {
	    var map = Object.create(null);
	    var list = str.split(',');
	    for (var i = 0;i < list.length; i++) {
	        map[list[i]] = true;
	    }
	    return expectsLowerCase ? function (val) {
	        return map[val.toLowerCase()];
	    } : function (val) {
	        return map[val];
	    };
	}

	var isBuiltInTag = makeMap('slot,component', true);
	var isReservedAttribute = makeMap('key,ref,slot,slot-scope,is');
	function remove(arr, item) {
	    if (arr.length) {
	        var index = arr.indexOf(item);
	        if (index > -1) {
	            return arr.splice(index, 1);
	        }
	    }
	}

	var hasOwnProperty$1 = Object.prototype.hasOwnProperty;
	function hasOwn(obj, key) {
	    return hasOwnProperty$1.call(obj, key);
	}

	function cached(fn) {
	    var cache = Object.create(null);
	    return function cachedFn(str) {
	        var hit = cache[str];
	        return hit || (cache[str] = fn(str));
	    };
	}

	var camelizeRE = /-(\w)/g;
	var camelize = cached(function (str) {
	    return str.replace(camelizeRE, function (_, c) {
	        return c ? c.toUpperCase() : '';
	    });
	});
	var capitalize = cached(function (str) {
	    return str.charAt(0).toUpperCase() + str.slice(1);
	});
	var hyphenateRE = /\B([A-Z])/g;
	var hyphenate = cached(function (str) {
	    return str.replace(hyphenateRE, '-$1').toLowerCase();
	});
	function polyfillBind(fn, ctx) {
	    function boundFn(a) {
	        var l = arguments.length;
	        return l ? l > 1 ? fn.apply(ctx, arguments) : fn.call(ctx, a) : fn.call(ctx);
	    }
	    
	    boundFn._length = fn.length;
	    return boundFn;
	}

	function nativeBind(fn, ctx) {
	    return fn.bind(ctx);
	}

	var bind$1 = Function.prototype.bind ? nativeBind : polyfillBind;
	function toArray(list, start) {
	    start = start || 0;
	    var i = list.length - start;
	    var ret = new Array(i);
	    while (i--) {
	        ret[i] = list[i + start];
	    }
	    return ret;
	}

	function extend(to, _from) {
	    for (var key in _from) {
	        to[key] = _from[key];
	    }
	    return to;
	}

	function toObject$1(arr) {
	    var res = {};
	    for (var i = 0;i < arr.length; i++) {
	        if (arr[i]) {
	            extend(res, arr[i]);
	        }
	    }
	    return res;
	}

	function noop(a, b, c) {}

	var no = function (a, b, c) {
	    return false;
	};
	var identity = function (_) {
	    return _;
	};
	function looseEqual(a, b) {
	    if (a === b) {
	        return true;
	    }
	    var isObjectA = isObject$1(a);
	    var isObjectB = isObject$1(b);
	    if (isObjectA && isObjectB) {
	        try {
	            var isArrayA = Array.isArray(a);
	            var isArrayB = Array.isArray(b);
	            if (isArrayA && isArrayB) {
	                return a.length === b.length && a.every(function (e, i) {
	                    return looseEqual(e, b[i]);
	                });
	            } else if (a instanceof Date && b instanceof Date) {
	                return a.getTime() === b.getTime();
	            } else if (!isArrayA && !isArrayB) {
	                var keysA = Object.keys(a);
	                var keysB = Object.keys(b);
	                return keysA.length === keysB.length && keysA.every(function (key) {
	                    return looseEqual(a[key], b[key]);
	                });
	            } else {
	                return false;
	            }
	        } catch (e) {
	            return false;
	        }
	    } else if (!isObjectA && !isObjectB) {
	        return String(a) === String(b);
	    } else {
	        return false;
	    }
	}

	function looseIndexOf(arr, val) {
	    for (var i = 0;i < arr.length; i++) {
	        if (looseEqual(arr[i], val)) {
	            return i;
	        }
	    }
	    return -1;
	}

	function once(fn) {
	    var called = false;
	    return function () {
	        if (!called) {
	            called = true;
	            fn.apply(this, arguments);
	        }
	    };
	}

	var SSR_ATTR = 'data-server-rendered';
	var ASSET_TYPES = ['component','directive','filter'];
	var LIFECYCLE_HOOKS = ['beforeCreate','created','beforeMount','mounted','beforeUpdate',
	    'updated','beforeDestroy','destroyed','activated','deactivated','errorCaptured',
	    'serverPrefetch'];
	var config = {
	    optionMergeStrategies: Object.create(null),
	    silent: false,
	    productionTip: "production" !== 'production',
	    devtools: "production" !== 'production',
	    performance: false,
	    errorHandler: null,
	    warnHandler: null,
	    ignoredElements: [],
	    keyCodes: Object.create(null),
	    isReservedTag: no,
	    isReservedAttr: no,
	    isUnknownElement: no,
	    getTagNamespace: noop,
	    parsePlatformTagName: identity,
	    mustUseProp: no,
	    async: true,
	    _lifecycleHooks: LIFECYCLE_HOOKS
	};
	var unicodeRegExp = /a-zA-Z\u00B7\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u037D\u037F-\u1FFF\u200C-\u200D\u203F-\u2040\u2070-\u218F\u2C00-\u2FEF\u3001-\uD7FF\uF900-\uFDCF\uFDF0-\uFFFD/;
	function isReserved(str) {
	    var c = (str + '').charCodeAt(0);
	    return c === 0x24 || c === 0x5F;
	}

	function def(obj, key, val, enumerable) {
	    Object.defineProperty(obj, key, {
	        value: val,
	        enumerable: !(!enumerable),
	        writable: true,
	        configurable: true
	    });
	}

	var bailRE = new RegExp("[^" + unicodeRegExp.source + ".$_\\d]");
	function parsePath(path) {
	    if (bailRE.test(path)) {
	        return;
	    }
	    var segments = path.split('.');
	    return function (obj) {
	        for (var i = 0;i < segments.length; i++) {
	            if (!obj) {
	                return;
	            }
	            obj = obj[segments[i]];
	        }
	        return obj;
	    };
	}

	var hasProto = '__proto__' in {};
	var inBrowser = typeof window !== 'undefined';
	var inWeex = typeof WXEnvironment !== 'undefined' && !(!WXEnvironment.platform);
	var weexPlatform = inWeex && WXEnvironment.platform.toLowerCase();
	var UA = inBrowser && window.navigator.userAgent.toLowerCase();
	var isIE = UA && /msie|trident/.test(UA);
	var isIE9 = UA && UA.indexOf('msie 9.0') > 0;
	var isEdge = UA && UA.indexOf('edge/') > 0;
	var isAndroid = UA && UA.indexOf('android') > 0 || weexPlatform === 'android';
	var isIOS = UA && /iphone|ipad|ipod|ios/.test(UA) || weexPlatform === 'ios';
	var isChrome = UA && /chrome\/\d+/.test(UA) && !isEdge;
	var isPhantomJS = UA && /phantomjs/.test(UA);
	var isFF = UA && UA.match(/firefox\/(\d+)/);
	var nativeWatch = ({}).watch;
	var supportsPassive = false;
	if (inBrowser) {
	    try {
	        var opts = {};
	        Object.defineProperty(opts, 'passive', {
	            get: function get() {
	                supportsPassive = true;
	            }
	        });
	        window.addEventListener('test-passive', null, opts);
	    } catch (e) {}
	}
	var _isServer;
	var isServerRendering = function () {
	    if (_isServer === undefined) {
	        if (!inBrowser && !inWeex && typeof global$1 !== 'undefined') {
	            _isServer = global$1['process'] && global$1['process'].env.VUE_ENV === 'server';
	        } else {
	            _isServer = false;
	        }
	    }
	    return _isServer;
	};
	var devtools = inBrowser && window.__VUE_DEVTOOLS_GLOBAL_HOOK__;
	function isNative(Ctor) {
	    return typeof Ctor === 'function' && /native code/.test(Ctor.toString());
	}

	var hasSymbol = typeof Symbol !== 'undefined' && isNative(Symbol) && typeof Reflect !== 'undefined' && isNative(Reflect.ownKeys);
	var _Set;
	if (typeof Set !== 'undefined' && isNative(Set)) {
	    _Set = Set;
	} else {
	    _Set = (function () {
	        function Set() {
	            this.set = Object.create(null);
	        }
	        
	        Set.prototype.has = function has(key) {
	            return this.set[key] === true;
	        };
	        Set.prototype.add = function add(key) {
	            this.set[key] = true;
	        };
	        Set.prototype.clear = function clear() {
	            this.set = Object.create(null);
	        };
	        return Set;
	    })();
	}
	var warn = noop;
	var uid$1 = 0;
	var Dep = function Dep() {
	    this.id = uid$1++;
	    this.subs = [];
	};
	Dep.prototype.addSub = function addSub(sub) {
	    this.subs.push(sub);
	};
	Dep.prototype.removeSub = function removeSub(sub) {
	    remove(this.subs, sub);
	};
	Dep.prototype.depend = function depend() {
	    if (Dep.target) {
	        Dep.target.addDep(this);
	    }
	};
	Dep.prototype.notify = function notify() {
	    var subs = this.subs.slice();
	    for (var i = 0, l = subs.length;i < l; i++) {
	        subs[i].update();
	    }
	};
	Dep.target = null;
	var targetStack = [];
	function pushTarget(target) {
	    targetStack.push(target);
	    Dep.target = target;
	}

	function popTarget() {
	    targetStack.pop();
	    Dep.target = targetStack[targetStack.length - 1];
	}

	var VNode = function VNode(tag, data, children, text, elm, context, componentOptions, asyncFactory) {
	    this.tag = tag;
	    this.data = data;
	    this.children = children;
	    this.text = text;
	    this.elm = elm;
	    this.ns = undefined;
	    this.context = context;
	    this.fnContext = undefined;
	    this.fnOptions = undefined;
	    this.fnScopeId = undefined;
	    this.key = data && data.key;
	    this.componentOptions = componentOptions;
	    this.componentInstance = undefined;
	    this.parent = undefined;
	    this.raw = false;
	    this.isStatic = false;
	    this.isRootInsert = true;
	    this.isComment = false;
	    this.isCloned = false;
	    this.isOnce = false;
	    this.asyncFactory = asyncFactory;
	    this.asyncMeta = undefined;
	    this.isAsyncPlaceholder = false;
	};
	var prototypeAccessors = {
	    child: {
	        configurable: true
	    }
	};
	prototypeAccessors.child.get = function () {
	    return this.componentInstance;
	};
	Object.defineProperties(VNode.prototype, prototypeAccessors);
	var createEmptyVNode = function (text) {
	    if (text === void 0) 
	        { text = ''; }
	    var node = new VNode();
	    node.text = text;
	    node.isComment = true;
	    return node;
	};
	function createTextVNode(val) {
	    return new VNode(undefined, undefined, undefined, String(val));
	}

	function cloneVNode(vnode) {
	    var cloned = new VNode(vnode.tag, vnode.data, vnode.children && vnode.children.slice(), vnode.text, vnode.elm, vnode.context, vnode.componentOptions, vnode.asyncFactory);
	    cloned.ns = vnode.ns;
	    cloned.isStatic = vnode.isStatic;
	    cloned.key = vnode.key;
	    cloned.isComment = vnode.isComment;
	    cloned.fnContext = vnode.fnContext;
	    cloned.fnOptions = vnode.fnOptions;
	    cloned.fnScopeId = vnode.fnScopeId;
	    cloned.asyncMeta = vnode.asyncMeta;
	    cloned.isCloned = true;
	    return cloned;
	}

	var arrayProto = Array.prototype;
	var arrayMethods = Object.create(arrayProto);
	var methodsToPatch = ['push','pop','shift','unshift','splice','sort','reverse'];
	methodsToPatch.forEach(function (method) {
	    var original = arrayProto[method];
	    def(arrayMethods, method, function mutator() {
	        var arguments$1 = arguments;

	        var args = [], len = arguments.length;
	        while (len--) 
	            { args[len] = arguments$1[len]; }
	        var result = original.apply(this, args);
	        var ob = this.__ob__;
	        var inserted;
	        switch (method) {
	            case 'push':
	            case 'unshift':
	                inserted = args;
	                break;
	            case 'splice':
	                inserted = args.slice(2);
	                break;
	        }
	        if (inserted) {
	            ob.observeArray(inserted);
	        }
	        ob.dep.notify();
	        return result;
	    });
	});
	var arrayKeys$1 = Object.getOwnPropertyNames(arrayMethods);
	var shouldObserve = true;
	function toggleObserving(value) {
	    shouldObserve = value;
	}

	var Observer = function Observer(value) {
	    this.value = value;
	    this.dep = new Dep();
	    this.vmCount = 0;
	    def(value, '__ob__', this);
	    if (Array.isArray(value)) {
	        if (hasProto) {
	            protoAugment(value, arrayMethods);
	        } else {
	            copyAugment(value, arrayMethods, arrayKeys$1);
	        }
	        this.observeArray(value);
	    } else {
	        this.walk(value);
	    }
	};
	Observer.prototype.walk = function walk(obj) {
	    var keys = Object.keys(obj);
	    for (var i = 0;i < keys.length; i++) {
	        defineReactive$$1(obj, keys[i]);
	    }
	};
	Observer.prototype.observeArray = function observeArray(items) {
	    for (var i = 0, l = items.length;i < l; i++) {
	        observe(items[i]);
	    }
	};
	function protoAugment(target, src) {
	    target.__proto__ = src;
	}

	function copyAugment(target, src, keys) {
	    for (var i = 0, l = keys.length;i < l; i++) {
	        var key = keys[i];
	        def(target, key, src[key]);
	    }
	}

	function observe(value, asRootData) {
	    if (!isObject$1(value) || value instanceof VNode) {
	        return;
	    }
	    var ob;
	    if (hasOwn(value, '__ob__') && value.__ob__ instanceof Observer) {
	        ob = value.__ob__;
	    } else if (shouldObserve && !isServerRendering() && (Array.isArray(value) || isPlainObject(value)) && Object.isExtensible(value) && !value._isVue) {
	        ob = new Observer(value);
	    }
	    if (asRootData && ob) {
	        ob.vmCount++;
	    }
	    return ob;
	}

	function defineReactive$$1(obj, key, val, customSetter, shallow) {
	    var dep = new Dep();
	    var property = Object.getOwnPropertyDescriptor(obj, key);
	    if (property && property.configurable === false) {
	        return;
	    }
	    var getter = property && property.get;
	    var setter = property && property.set;
	    if ((!getter || setter) && arguments.length === 2) {
	        val = obj[key];
	    }
	    var childOb = !shallow && observe(val);
	    Object.defineProperty(obj, key, {
	        enumerable: true,
	        configurable: true,
	        get: function reactiveGetter() {
	            var value = getter ? getter.call(obj) : val;
	            if (Dep.target) {
	                dep.depend();
	                if (childOb) {
	                    childOb.dep.depend();
	                    if (Array.isArray(value)) {
	                        dependArray(value);
	                    }
	                }
	            }
	            return value;
	        },
	        set: function reactiveSetter(newVal) {
	            var value = getter ? getter.call(obj) : val;
	            if (newVal === value || newVal !== newVal && value !== value) {
	                return;
	            }
	            if (getter && !setter) {
	                return;
	            }
	            if (setter) {
	                setter.call(obj, newVal);
	            } else {
	                val = newVal;
	            }
	            childOb = !shallow && observe(newVal);
	            dep.notify();
	        }
	    });
	}

	function set$3(target, key, val) {
	    if (Array.isArray(target) && isValidArrayIndex(key)) {
	        target.length = Math.max(target.length, key);
	        target.splice(key, 1, val);
	        return val;
	    }
	    if (key in target && !(key in Object.prototype)) {
	        target[key] = val;
	        return val;
	    }
	    var ob = target.__ob__;
	    if (target._isVue || ob && ob.vmCount) {
	        return val;
	    }
	    if (!ob) {
	        target[key] = val;
	        return val;
	    }
	    defineReactive$$1(ob.value, key, val);
	    ob.dep.notify();
	    return val;
	}

	function del(target, key) {
	    if (Array.isArray(target) && isValidArrayIndex(key)) {
	        target.splice(key, 1);
	        return;
	    }
	    var ob = target.__ob__;
	    if (target._isVue || ob && ob.vmCount) {
	        return;
	    }
	    if (!hasOwn(target, key)) {
	        return;
	    }
	    delete target[key];
	    if (!ob) {
	        return;
	    }
	    ob.dep.notify();
	}

	function dependArray(value) {
	    for (var e = void 0, i = 0, l = value.length;i < l; i++) {
	        e = value[i];
	        e && e.__ob__ && e.__ob__.dep.depend();
	        if (Array.isArray(e)) {
	            dependArray(e);
	        }
	    }
	}

	var strats = config.optionMergeStrategies;
	function mergeData(to, from) {
	    if (!from) {
	        return to;
	    }
	    var key, toVal, fromVal;
	    var keys = hasSymbol ? Reflect.ownKeys(from) : Object.keys(from);
	    for (var i = 0;i < keys.length; i++) {
	        key = keys[i];
	        if (key === '__ob__') {
	            continue;
	        }
	        toVal = to[key];
	        fromVal = from[key];
	        if (!hasOwn(to, key)) {
	            set$3(to, key, fromVal);
	        } else if (toVal !== fromVal && isPlainObject(toVal) && isPlainObject(fromVal)) {
	            mergeData(toVal, fromVal);
	        }
	    }
	    return to;
	}

	function mergeDataOrFn(parentVal, childVal, vm) {
	    if (!vm) {
	        if (!childVal) {
	            return parentVal;
	        }
	        if (!parentVal) {
	            return childVal;
	        }
	        return function mergedDataFn() {
	            return mergeData(typeof childVal === 'function' ? childVal.call(this, this) : childVal, typeof parentVal === 'function' ? parentVal.call(this, this) : parentVal);
	        };
	    } else {
	        return function mergedInstanceDataFn() {
	            var instanceData = typeof childVal === 'function' ? childVal.call(vm, vm) : childVal;
	            var defaultData = typeof parentVal === 'function' ? parentVal.call(vm, vm) : parentVal;
	            if (instanceData) {
	                return mergeData(instanceData, defaultData);
	            } else {
	                return defaultData;
	            }
	        };
	    }
	}

	strats.data = function (parentVal, childVal, vm) {
	    if (!vm) {
	        if (childVal && typeof childVal !== 'function') {
	            return parentVal;
	        }
	        return mergeDataOrFn(parentVal, childVal);
	    }
	    return mergeDataOrFn(parentVal, childVal, vm);
	};
	function mergeHook(parentVal, childVal) {
	    var res = childVal ? parentVal ? parentVal.concat(childVal) : Array.isArray(childVal) ? childVal : [childVal] : parentVal;
	    return res ? dedupeHooks(res) : res;
	}

	function dedupeHooks(hooks) {
	    var res = [];
	    for (var i = 0;i < hooks.length; i++) {
	        if (res.indexOf(hooks[i]) === -1) {
	            res.push(hooks[i]);
	        }
	    }
	    return res;
	}

	LIFECYCLE_HOOKS.forEach(function (hook) {
	    strats[hook] = mergeHook;
	});
	function mergeAssets(parentVal, childVal, vm, key) {
	    var res = Object.create(parentVal || null);
	    if (childVal) {
	        return extend(res, childVal);
	    } else {
	        return res;
	    }
	}

	ASSET_TYPES.forEach(function (type) {
	    strats[type + 's'] = mergeAssets;
	});
	strats.watch = function (parentVal, childVal, vm, key) {
	    if (parentVal === nativeWatch) {
	        parentVal = undefined;
	    }
	    if (childVal === nativeWatch) {
	        childVal = undefined;
	    }
	    if (!childVal) {
	        return Object.create(parentVal || null);
	    }
	    if (!parentVal) {
	        return childVal;
	    }
	    var ret = {};
	    extend(ret, parentVal);
	    for (var key$1 in childVal) {
	        var parent = ret[key$1];
	        var child = childVal[key$1];
	        if (parent && !Array.isArray(parent)) {
	            parent = [parent];
	        }
	        ret[key$1] = parent ? parent.concat(child) : Array.isArray(child) ? child : [child];
	    }
	    return ret;
	};
	strats.props = (strats.methods = (strats.inject = (strats.computed = function (parentVal, childVal, vm, key) {
	    if (childVal && "production" !== 'production') {
	        assertObjectType(key, childVal, vm);
	    }
	    if (!parentVal) {
	        return childVal;
	    }
	    var ret = Object.create(null);
	    extend(ret, parentVal);
	    if (childVal) {
	        extend(ret, childVal);
	    }
	    return ret;
	})));
	strats.provide = mergeDataOrFn;
	var defaultStrat = function (parentVal, childVal) {
	    return childVal === undefined ? parentVal : childVal;
	};

	function normalizeProps(options, vm) {
	    var props = options.props;
	    if (!props) {
	        return;
	    }
	    var res = {};
	    var i, val, name;
	    if (Array.isArray(props)) {
	        i = props.length;
	        while (i--) {
	            val = props[i];
	            if (typeof val === 'string') {
	                name = camelize(val);
	                res[name] = {
	                    type: null
	                };
	            }
	        }
	    } else if (isPlainObject(props)) {
	        for (var key in props) {
	            val = props[key];
	            name = camelize(key);
	            res[name] = isPlainObject(val) ? val : {
	                type: val
	            };
	        }
	    }
	    options.props = res;
	}

	function normalizeInject(options, vm) {
	    var inject = options.inject;
	    if (!inject) {
	        return;
	    }
	    var normalized = options.inject = {};
	    if (Array.isArray(inject)) {
	        for (var i = 0;i < inject.length; i++) {
	            normalized[inject[i]] = {
	                from: inject[i]
	            };
	        }
	    } else if (isPlainObject(inject)) {
	        for (var key in inject) {
	            var val = inject[key];
	            normalized[key] = isPlainObject(val) ? extend({
	                from: key
	            }, val) : {
	                from: val
	            };
	        }
	    }
	}

	function normalizeDirectives(options) {
	    var dirs = options.directives;
	    if (dirs) {
	        for (var key in dirs) {
	            var def$$1 = dirs[key];
	            if (typeof def$$1 === 'function') {
	                dirs[key] = {
	                    bind: def$$1,
	                    update: def$$1
	                };
	            }
	        }
	    }
	}

	function assertObjectType(name, value, vm) {
	    if (!isPlainObject(value)) {
	        warn("Invalid value for option \"" + name + "\": expected an Object, " + "but got " + toRawType(value) + ".", vm);
	    }
	}

	function mergeOptions(parent, child, vm) {
	    if (typeof child === 'function') {
	        child = child.options;
	    }
	    normalizeProps(child);
	    normalizeInject(child);
	    normalizeDirectives(child);
	    if (!child._base) {
	        if (child.extends) {
	            parent = mergeOptions(parent, child.extends, vm);
	        }
	        if (child.mixins) {
	            for (var i = 0, l = child.mixins.length;i < l; i++) {
	                parent = mergeOptions(parent, child.mixins[i], vm);
	            }
	        }
	    }
	    var options = {};
	    var key;
	    for (key in parent) {
	        mergeField(key);
	    }
	    for (key in child) {
	        if (!hasOwn(parent, key)) {
	            mergeField(key);
	        }
	    }
	    function mergeField(key) {
	        var strat = strats[key] || defaultStrat;
	        options[key] = strat(parent[key], child[key], vm, key);
	    }
	    
	    return options;
	}

	function resolveAsset(options, type, id, warnMissing) {
	    if (typeof id !== 'string') {
	        return;
	    }
	    var assets = options[type];
	    if (hasOwn(assets, id)) {
	        return assets[id];
	    }
	    var camelizedId = camelize(id);
	    if (hasOwn(assets, camelizedId)) {
	        return assets[camelizedId];
	    }
	    var PascalCaseId = capitalize(camelizedId);
	    if (hasOwn(assets, PascalCaseId)) {
	        return assets[PascalCaseId];
	    }
	    var res = assets[id] || assets[camelizedId] || assets[PascalCaseId];
	    return res;
	}

	function validateProp(key, propOptions, propsData, vm) {
	    var prop = propOptions[key];
	    var absent = !hasOwn(propsData, key);
	    var value = propsData[key];
	    var booleanIndex = getTypeIndex(Boolean, prop.type);
	    if (booleanIndex > -1) {
	        if (absent && !hasOwn(prop, 'default')) {
	            value = false;
	        } else if (value === '' || value === hyphenate(key)) {
	            var stringIndex = getTypeIndex(String, prop.type);
	            if (stringIndex < 0 || booleanIndex < stringIndex) {
	                value = true;
	            }
	        }
	    }
	    if (value === undefined) {
	        value = getPropDefaultValue(vm, prop, key);
	        var prevShouldObserve = shouldObserve;
	        toggleObserving(true);
	        observe(value);
	        toggleObserving(prevShouldObserve);
	    }
	    return value;
	}

	function getPropDefaultValue(vm, prop, key) {
	    if (!hasOwn(prop, 'default')) {
	        return undefined;
	    }
	    var def = prop.default;
	    if (vm && vm.$options.propsData && vm.$options.propsData[key] === undefined && vm._props[key] !== undefined) {
	        return vm._props[key];
	    }
	    return typeof def === 'function' && getType(prop.type) !== 'Function' ? def.call(vm) : def;
	}

	function getType(fn) {
	    var match = fn && fn.toString().match(/^\s*function (\w+)/);
	    return match ? match[1] : '';
	}

	function isSameType(a, b) {
	    return getType(a) === getType(b);
	}

	function getTypeIndex(type, expectedTypes) {
	    if (!Array.isArray(expectedTypes)) {
	        return isSameType(expectedTypes, type) ? 0 : -1;
	    }
	    for (var i = 0, len = expectedTypes.length;i < len; i++) {
	        if (isSameType(expectedTypes[i], type)) {
	            return i;
	        }
	    }
	    return -1;
	}

	function handleError(err, vm, info) {
	    pushTarget();
	    try {
	        if (vm) {
	            var cur = vm;
	            while (cur = cur.$parent) {
	                var hooks = cur.$options.errorCaptured;
	                if (hooks) {
	                    for (var i = 0;i < hooks.length; i++) {
	                        try {
	                            var capture = hooks[i].call(cur, err, vm, info) === false;
	                            if (capture) {
	                                return;
	                            }
	                        } catch (e) {
	                            globalHandleError(e, cur, 'errorCaptured hook');
	                        }
	                    }
	                }
	            }
	        }
	        globalHandleError(err, vm, info);
	    } finally {
	        popTarget();
	    }
	}

	function invokeWithErrorHandling(handler, context, args, vm, info) {
	    var res;
	    try {
	        res = args ? handler.apply(context, args) : handler.call(context);
	        if (res && !res._isVue && isPromise(res) && !res._handled) {
	            res.catch(function (e) {
	                return handleError(e, vm, info + " (Promise/async)");
	            });
	            res._handled = true;
	        }
	    } catch (e) {
	        handleError(e, vm, info);
	    }
	    return res;
	}

	function globalHandleError(err, vm, info) {
	    if (config.errorHandler) {
	        try {
	            return config.errorHandler.call(null, err, vm, info);
	        } catch (e) {
	            if (e !== err) {
	                logError(e);
	            }
	        }
	    }
	    logError(err);
	}

	function logError(err, vm, info) {
	    if ((inBrowser || inWeex) && typeof console !== 'undefined') {
	        console.error(err);
	    } else {
	        throw err;
	    }
	}

	var isUsingMicroTask = false;
	var callbacks = [];
	var pending = false;
	function flushCallbacks() {
	    pending = false;
	    var copies = callbacks.slice(0);
	    callbacks.length = 0;
	    for (var i = 0;i < copies.length; i++) {
	        copies[i]();
	    }
	}

	var timerFunc;
	if (typeof Promise !== 'undefined' && isNative(Promise)) {
	    var p = Promise.resolve();
	    timerFunc = function () {
	        p.then(flushCallbacks);
	        if (isIOS) {
	            setTimeout(noop);
	        }
	    };
	    isUsingMicroTask = true;
	} else if (!isIE && typeof MutationObserver !== 'undefined' && (isNative(MutationObserver) || MutationObserver.toString() === '[object MutationObserverConstructor]')) {
	    var counter$1 = 1;
	    var observer = new MutationObserver(flushCallbacks);
	    var textNode = document.createTextNode(String(counter$1));
	    observer.observe(textNode, {
	        characterData: true
	    });
	    timerFunc = function () {
	        counter$1 = (counter$1 + 1) % 2;
	        textNode.data = String(counter$1);
	    };
	    isUsingMicroTask = true;
	} else if (typeof setImmediate !== 'undefined' && isNative(setImmediate)) {
	    timerFunc = function () {
	        setImmediate(flushCallbacks);
	    };
	} else {
	    timerFunc = function () {
	        setTimeout(flushCallbacks, 0);
	    };
	}
	function nextTick(cb, ctx) {
	    var _resolve;
	    callbacks.push(function () {
	        if (cb) {
	            try {
	                cb.call(ctx);
	            } catch (e) {
	                handleError(e, ctx, 'nextTick');
	            }
	        } else if (_resolve) {
	            _resolve(ctx);
	        }
	    });
	    if (!pending) {
	        pending = true;
	        timerFunc();
	    }
	    if (!cb && typeof Promise !== 'undefined') {
	        return new Promise(function (resolve) {
	            _resolve = resolve;
	        });
	    }
	}
	var seenObjects = new _Set();
	function traverse(val) {
	    _traverse(val, seenObjects);
	    seenObjects.clear();
	}

	function _traverse(val, seen) {
	    var i, keys;
	    var isA = Array.isArray(val);
	    if (!isA && !isObject$1(val) || Object.isFrozen(val) || val instanceof VNode) {
	        return;
	    }
	    if (val.__ob__) {
	        var depId = val.__ob__.dep.id;
	        if (seen.has(depId)) {
	            return;
	        }
	        seen.add(depId);
	    }
	    if (isA) {
	        i = val.length;
	        while (i--) {
	            _traverse(val[i], seen);
	        }
	    } else {
	        keys = Object.keys(val);
	        i = keys.length;
	        while (i--) {
	            _traverse(val[keys[i]], seen);
	        }
	    }
	}
	var normalizeEvent = cached(function (name) {
	    var passive = name.charAt(0) === '&';
	    name = passive ? name.slice(1) : name;
	    var once$$1 = name.charAt(0) === '~';
	    name = once$$1 ? name.slice(1) : name;
	    var capture = name.charAt(0) === '!';
	    name = capture ? name.slice(1) : name;
	    return {
	        name: name,
	        once: once$$1,
	        capture: capture,
	        passive: passive
	    };
	});
	function createFnInvoker(fns, vm) {
	    function invoker() {
	        var arguments$1 = arguments;
	        var fns = invoker.fns;
	        if (Array.isArray(fns)) {
	            var cloned = fns.slice();
	            for (var i = 0;i < cloned.length; i++) {
	                invokeWithErrorHandling(cloned[i], null, arguments$1, vm, "v-on handler");
	            }
	        } else {
	            return invokeWithErrorHandling(fns, null, arguments, vm, "v-on handler");
	        }
	    }
	    
	    invoker.fns = fns;
	    return invoker;
	}

	function updateListeners(on, oldOn, add, remove$$1, createOnceHandler, vm) {
	    var name, def$$1, cur, old, event;
	    for (name in on) {
	        def$$1 = (cur = on[name]);
	        old = oldOn[name];
	        event = normalizeEvent(name);
	        if (isUndef(cur)) ; else if (isUndef(old)) {
	            if (isUndef(cur.fns)) {
	                cur = (on[name] = createFnInvoker(cur, vm));
	            }
	            if (isTrue(event.once)) {
	                cur = (on[name] = createOnceHandler(event.name, cur, event.capture));
	            }
	            add(event.name, cur, event.capture, event.passive, event.params);
	        } else if (cur !== old) {
	            old.fns = cur;
	            on[name] = old;
	        }
	    }
	    for (name in oldOn) {
	        if (isUndef(on[name])) {
	            event = normalizeEvent(name);
	            remove$$1(event.name, oldOn[name], event.capture);
	        }
	    }
	}

	function mergeVNodeHook(def, hookKey, hook) {
	    if (def instanceof VNode) {
	        def = def.data.hook || (def.data.hook = {});
	    }
	    var invoker;
	    var oldHook = def[hookKey];
	    function wrappedHook() {
	        hook.apply(this, arguments);
	        remove(invoker.fns, wrappedHook);
	    }
	    
	    if (isUndef(oldHook)) {
	        invoker = createFnInvoker([wrappedHook]);
	    } else {
	        if (isDef(oldHook.fns) && isTrue(oldHook.merged)) {
	            invoker = oldHook;
	            invoker.fns.push(wrappedHook);
	        } else {
	            invoker = createFnInvoker([oldHook,wrappedHook]);
	        }
	    }
	    invoker.merged = true;
	    def[hookKey] = invoker;
	}

	function extractPropsFromVNodeData(data, Ctor, tag) {
	    var propOptions = Ctor.options.props;
	    if (isUndef(propOptions)) {
	        return;
	    }
	    var res = {};
	    var attrs = data.attrs;
	    var props = data.props;
	    if (isDef(attrs) || isDef(props)) {
	        for (var key in propOptions) {
	            var altKey = hyphenate(key);
	            checkProp(res, props, key, altKey, true) || checkProp(res, attrs, key, altKey, false);
	        }
	    }
	    return res;
	}

	function checkProp(res, hash, key, altKey, preserve) {
	    if (isDef(hash)) {
	        if (hasOwn(hash, key)) {
	            res[key] = hash[key];
	            if (!preserve) {
	                delete hash[key];
	            }
	            return true;
	        } else if (hasOwn(hash, altKey)) {
	            res[key] = hash[altKey];
	            if (!preserve) {
	                delete hash[altKey];
	            }
	            return true;
	        }
	    }
	    return false;
	}

	function simpleNormalizeChildren(children) {
	    for (var i = 0;i < children.length; i++) {
	        if (Array.isArray(children[i])) {
	            return Array.prototype.concat.apply([], children);
	        }
	    }
	    return children;
	}

	function normalizeChildren(children) {
	    return isPrimitive(children) ? [createTextVNode(children)] : Array.isArray(children) ? normalizeArrayChildren(children) : undefined;
	}

	function isTextNode(node) {
	    return isDef(node) && isDef(node.text) && isFalse(node.isComment);
	}

	function normalizeArrayChildren(children, nestedIndex) {
	    var res = [];
	    var i, c, lastIndex, last;
	    for (i = 0; i < children.length; i++) {
	        c = children[i];
	        if (isUndef(c) || typeof c === 'boolean') {
	            continue;
	        }
	        lastIndex = res.length - 1;
	        last = res[lastIndex];
	        if (Array.isArray(c)) {
	            if (c.length > 0) {
	                c = normalizeArrayChildren(c, (nestedIndex || '') + "_" + i);
	                if (isTextNode(c[0]) && isTextNode(last)) {
	                    res[lastIndex] = createTextVNode(last.text + c[0].text);
	                    c.shift();
	                }
	                res.push.apply(res, c);
	            }
	        } else if (isPrimitive(c)) {
	            if (isTextNode(last)) {
	                res[lastIndex] = createTextVNode(last.text + c);
	            } else if (c !== '') {
	                res.push(createTextVNode(c));
	            }
	        } else {
	            if (isTextNode(c) && isTextNode(last)) {
	                res[lastIndex] = createTextVNode(last.text + c.text);
	            } else {
	                if (isTrue(children._isVList) && isDef(c.tag) && isUndef(c.key) && isDef(nestedIndex)) {
	                    c.key = "__vlist" + nestedIndex + "_" + i + "__";
	                }
	                res.push(c);
	            }
	        }
	    }
	    return res;
	}

	function initProvide(vm) {
	    var provide = vm.$options.provide;
	    if (provide) {
	        vm._provided = typeof provide === 'function' ? provide.call(vm) : provide;
	    }
	}

	function initInjections(vm) {
	    var result = resolveInject(vm.$options.inject, vm);
	    if (result) {
	        toggleObserving(false);
	        Object.keys(result).forEach(function (key) {
	            {
	                defineReactive$$1(vm, key, result[key]);
	            }
	        });
	        toggleObserving(true);
	    }
	}

	function resolveInject(inject, vm) {
	    if (inject) {
	        var result = Object.create(null);
	        var keys = hasSymbol ? Reflect.ownKeys(inject) : Object.keys(inject);
	        for (var i = 0;i < keys.length; i++) {
	            var key = keys[i];
	            if (key === '__ob__') {
	                continue;
	            }
	            var provideKey = inject[key].from;
	            var source = vm;
	            while (source) {
	                if (source._provided && hasOwn(source._provided, provideKey)) {
	                    result[key] = source._provided[provideKey];
	                    break;
	                }
	                source = source.$parent;
	            }
	            if (!source) {
	                if ('default' in inject[key]) {
	                    var provideDefault = inject[key].default;
	                    result[key] = typeof provideDefault === 'function' ? provideDefault.call(vm) : provideDefault;
	                }
	            }
	        }
	        return result;
	    }
	}

	function resolveSlots(children, context) {
	    if (!children || !children.length) {
	        return {};
	    }
	    var slots = {};
	    for (var i = 0, l = children.length;i < l; i++) {
	        var child = children[i];
	        var data = child.data;
	        if (data && data.attrs && data.attrs.slot) {
	            delete data.attrs.slot;
	        }
	        if ((child.context === context || child.fnContext === context) && data && data.slot != null) {
	            var name = data.slot;
	            var slot = slots[name] || (slots[name] = []);
	            if (child.tag === 'template') {
	                slot.push.apply(slot, child.children || []);
	            } else {
	                slot.push(child);
	            }
	        } else {
	            (slots.default || (slots.default = [])).push(child);
	        }
	    }
	    for (var name$1 in slots) {
	        if (slots[name$1].every(isWhitespace)) {
	            delete slots[name$1];
	        }
	    }
	    return slots;
	}

	function isWhitespace(node) {
	    return node.isComment && !node.asyncFactory || node.text === ' ';
	}

	function normalizeScopedSlots(slots, normalSlots, prevSlots) {
	    var res;
	    var hasNormalSlots = Object.keys(normalSlots).length > 0;
	    var isStable = slots ? !(!slots.$stable) : !hasNormalSlots;
	    var key = slots && slots.$key;
	    if (!slots) {
	        res = {};
	    } else if (slots._normalized) {
	        return slots._normalized;
	    } else if (isStable && prevSlots && prevSlots !== emptyObject && key === prevSlots.$key && !hasNormalSlots && !prevSlots.$hasNormal) {
	        return prevSlots;
	    } else {
	        res = {};
	        for (var key$1 in slots) {
	            if (slots[key$1] && key$1[0] !== '$') {
	                res[key$1] = normalizeScopedSlot(normalSlots, key$1, slots[key$1]);
	            }
	        }
	    }
	    for (var key$2 in normalSlots) {
	        if (!(key$2 in res)) {
	            res[key$2] = proxyNormalSlot(normalSlots, key$2);
	        }
	    }
	    if (slots && Object.isExtensible(slots)) {
	        slots._normalized = res;
	    }
	    def(res, '$stable', isStable);
	    def(res, '$key', key);
	    def(res, '$hasNormal', hasNormalSlots);
	    return res;
	}

	function normalizeScopedSlot(normalSlots, key, fn) {
	    var normalized = function () {
	        var res = arguments.length ? fn.apply(null, arguments) : fn({});
	        res = res && typeof res === 'object' && !Array.isArray(res) ? [res] : normalizeChildren(res);
	        return res && (res.length === 0 || res.length === 1 && res[0].isComment) ? undefined : res;
	    };
	    if (fn.proxy) {
	        Object.defineProperty(normalSlots, key, {
	            get: normalized,
	            enumerable: true,
	            configurable: true
	        });
	    }
	    return normalized;
	}

	function proxyNormalSlot(slots, key) {
	    return function () {
	        return slots[key];
	    };
	}

	function renderList(val, render) {
	    var ret, i, l, keys, key;
	    if (Array.isArray(val) || typeof val === 'string') {
	        ret = new Array(val.length);
	        for (i = 0, l = val.length; i < l; i++) {
	            ret[i] = render(val[i], i);
	        }
	    } else if (typeof val === 'number') {
	        ret = new Array(val);
	        for (i = 0; i < val; i++) {
	            ret[i] = render(i + 1, i);
	        }
	    } else if (isObject$1(val)) {
	        if (hasSymbol && val[Symbol.iterator]) {
	            ret = [];
	            var iterator = val[Symbol.iterator]();
	            var result = iterator.next();
	            while (!result.done) {
	                ret.push(render(result.value, ret.length));
	                result = iterator.next();
	            }
	        } else {
	            keys = Object.keys(val);
	            ret = new Array(keys.length);
	            for (i = 0, l = keys.length; i < l; i++) {
	                key = keys[i];
	                ret[i] = render(val[key], key, i);
	            }
	        }
	    }
	    if (!isDef(ret)) {
	        ret = [];
	    }
	    ret._isVList = true;
	    return ret;
	}

	function renderSlot(name, fallback, props, bindObject) {
	    var scopedSlotFn = this.$scopedSlots[name];
	    var nodes;
	    if (scopedSlotFn) {
	        props = props || {};
	        if (bindObject) {
	            props = extend(extend({}, bindObject), props);
	        }
	        nodes = scopedSlotFn(props) || fallback;
	    } else {
	        nodes = this.$slots[name] || fallback;
	    }
	    var target = props && props.slot;
	    if (target) {
	        return this.$createElement('template', {
	            slot: target
	        }, nodes);
	    } else {
	        return nodes;
	    }
	}

	function resolveFilter(id) {
	    return resolveAsset(this.$options, 'filters', id) || identity;
	}

	function isKeyNotMatch(expect, actual) {
	    if (Array.isArray(expect)) {
	        return expect.indexOf(actual) === -1;
	    } else {
	        return expect !== actual;
	    }
	}

	function checkKeyCodes(eventKeyCode, key, builtInKeyCode, eventKeyName, builtInKeyName) {
	    var mappedKeyCode = config.keyCodes[key] || builtInKeyCode;
	    if (builtInKeyName && eventKeyName && !config.keyCodes[key]) {
	        return isKeyNotMatch(builtInKeyName, eventKeyName);
	    } else if (mappedKeyCode) {
	        return isKeyNotMatch(mappedKeyCode, eventKeyCode);
	    } else if (eventKeyName) {
	        return hyphenate(eventKeyName) !== key;
	    }
	}

	function bindObjectProps(data, tag, value, asProp, isSync) {
	    if (value) {
	        if (!isObject$1(value)) ; else {
	            if (Array.isArray(value)) {
	                value = toObject$1(value);
	            }
	            var hash;
	            var loop = function (key) {
	                if (key === 'class' || key === 'style' || isReservedAttribute(key)) {
	                    hash = data;
	                } else {
	                    var type = data.attrs && data.attrs.type;
	                    hash = asProp || config.mustUseProp(tag, type, key) ? data.domProps || (data.domProps = {}) : data.attrs || (data.attrs = {});
	                }
	                var camelizedKey = camelize(key);
	                var hyphenatedKey = hyphenate(key);
	                if (!(camelizedKey in hash) && !(hyphenatedKey in hash)) {
	                    hash[key] = value[key];
	                    if (isSync) {
	                        var on = data.on || (data.on = {});
	                        on["update:" + key] = function ($event) {
	                            value[key] = $event;
	                        };
	                    }
	                }
	            };
	            for (var key in value) 
	                { loop(key); }
	        }
	    }
	    return data;
	}

	function renderStatic(index, isInFor) {
	    var cached = this._staticTrees || (this._staticTrees = []);
	    var tree = cached[index];
	    if (tree && !isInFor) {
	        return tree;
	    }
	    tree = (cached[index] = this.$options.staticRenderFns[index].call(this._renderProxy, null, this));
	    markStatic(tree, "__static__" + index, false);
	    return tree;
	}

	function markOnce(tree, index, key) {
	    markStatic(tree, "__once__" + index + (key ? "_" + key : ""), true);
	    return tree;
	}

	function markStatic(tree, key, isOnce) {
	    if (Array.isArray(tree)) {
	        for (var i = 0;i < tree.length; i++) {
	            if (tree[i] && typeof tree[i] !== 'string') {
	                markStaticNode(tree[i], key + "_" + i, isOnce);
	            }
	        }
	    } else {
	        markStaticNode(tree, key, isOnce);
	    }
	}

	function markStaticNode(node, key, isOnce) {
	    node.isStatic = true;
	    node.key = key;
	    node.isOnce = isOnce;
	}

	function bindObjectListeners(data, value) {
	    if (value) {
	        if (!isPlainObject(value)) ; else {
	            var on = data.on = data.on ? extend({}, data.on) : {};
	            for (var key in value) {
	                var existing = on[key];
	                var ours = value[key];
	                on[key] = existing ? [].concat(existing, ours) : ours;
	            }
	        }
	    }
	    return data;
	}

	function resolveScopedSlots(fns, res, hasDynamicKeys, contentHashKey) {
	    res = res || {
	        $stable: !hasDynamicKeys
	    };
	    for (var i = 0;i < fns.length; i++) {
	        var slot = fns[i];
	        if (Array.isArray(slot)) {
	            resolveScopedSlots(slot, res, hasDynamicKeys);
	        } else if (slot) {
	            if (slot.proxy) {
	                slot.fn.proxy = true;
	            }
	            res[slot.key] = slot.fn;
	        }
	    }
	    if (contentHashKey) {
	        res.$key = contentHashKey;
	    }
	    return res;
	}

	function bindDynamicKeys(baseObj, values) {
	    for (var i = 0;i < values.length; i += 2) {
	        var key = values[i];
	        if (typeof key === 'string' && key) {
	            baseObj[values[i]] = values[i + 1];
	        }
	    }
	    return baseObj;
	}

	function prependModifier(value, symbol) {
	    return typeof value === 'string' ? symbol + value : value;
	}

	function installRenderHelpers(target) {
	    target._o = markOnce;
	    target._n = toNumber$1;
	    target._s = toString$2;
	    target._l = renderList;
	    target._t = renderSlot;
	    target._q = looseEqual;
	    target._i = looseIndexOf;
	    target._m = renderStatic;
	    target._f = resolveFilter;
	    target._k = checkKeyCodes;
	    target._b = bindObjectProps;
	    target._v = createTextVNode;
	    target._e = createEmptyVNode;
	    target._u = resolveScopedSlots;
	    target._g = bindObjectListeners;
	    target._d = bindDynamicKeys;
	    target._p = prependModifier;
	}

	function FunctionalRenderContext(data, props, children, parent, Ctor) {
	    var this$1 = this;
	    var options = Ctor.options;
	    var contextVm;
	    if (hasOwn(parent, '_uid')) {
	        contextVm = Object.create(parent);
	        contextVm._original = parent;
	    } else {
	        contextVm = parent;
	        parent = parent._original;
	    }
	    var isCompiled = isTrue(options._compiled);
	    var needNormalization = !isCompiled;
	    this.data = data;
	    this.props = props;
	    this.children = children;
	    this.parent = parent;
	    this.listeners = data.on || emptyObject;
	    this.injections = resolveInject(options.inject, parent);
	    this.slots = function () {
	        if (!this$1.$slots) {
	            normalizeScopedSlots(data.scopedSlots, this$1.$slots = resolveSlots(children, parent));
	        }
	        return this$1.$slots;
	    };
	    Object.defineProperty(this, 'scopedSlots', {
	        enumerable: true,
	        get: function get() {
	            return normalizeScopedSlots(data.scopedSlots, this.slots());
	        }
	    });
	    if (isCompiled) {
	        this.$options = options;
	        this.$slots = this.slots();
	        this.$scopedSlots = normalizeScopedSlots(data.scopedSlots, this.$slots);
	    }
	    if (options._scopeId) {
	        this._c = function (a, b, c, d) {
	            var vnode = createElement(contextVm, a, b, c, d, needNormalization);
	            if (vnode && !Array.isArray(vnode)) {
	                vnode.fnScopeId = options._scopeId;
	                vnode.fnContext = parent;
	            }
	            return vnode;
	        };
	    } else {
	        this._c = function (a, b, c, d) {
	            return createElement(contextVm, a, b, c, d, needNormalization);
	        };
	    }
	}

	installRenderHelpers(FunctionalRenderContext.prototype);
	function createFunctionalComponent(Ctor, propsData, data, contextVm, children) {
	    var options = Ctor.options;
	    var props = {};
	    var propOptions = options.props;
	    if (isDef(propOptions)) {
	        for (var key in propOptions) {
	            props[key] = validateProp(key, propOptions, propsData || emptyObject);
	        }
	    } else {
	        if (isDef(data.attrs)) {
	            mergeProps(props, data.attrs);
	        }
	        if (isDef(data.props)) {
	            mergeProps(props, data.props);
	        }
	    }
	    var renderContext = new FunctionalRenderContext(data, props, children, contextVm, Ctor);
	    var vnode = options.render.call(null, renderContext._c, renderContext);
	    if (vnode instanceof VNode) {
	        return cloneAndMarkFunctionalResult(vnode, data, renderContext.parent, options);
	    } else if (Array.isArray(vnode)) {
	        var vnodes = normalizeChildren(vnode) || [];
	        var res = new Array(vnodes.length);
	        for (var i = 0;i < vnodes.length; i++) {
	            res[i] = cloneAndMarkFunctionalResult(vnodes[i], data, renderContext.parent, options);
	        }
	        return res;
	    }
	}

	function cloneAndMarkFunctionalResult(vnode, data, contextVm, options, renderContext) {
	    var clone = cloneVNode(vnode);
	    clone.fnContext = contextVm;
	    clone.fnOptions = options;
	    if (data.slot) {
	        (clone.data || (clone.data = {})).slot = data.slot;
	    }
	    return clone;
	}

	function mergeProps(to, from) {
	    for (var key in from) {
	        to[camelize(key)] = from[key];
	    }
	}

	var componentVNodeHooks = {
	    init: function init(vnode, hydrating) {
	        if (vnode.componentInstance && !vnode.componentInstance._isDestroyed && vnode.data.keepAlive) {
	            var mountedNode = vnode;
	            componentVNodeHooks.prepatch(mountedNode, mountedNode);
	        } else {
	            var child = vnode.componentInstance = createComponentInstanceForVnode(vnode, activeInstance);
	            child.$mount(hydrating ? vnode.elm : undefined, hydrating);
	        }
	    },
	    prepatch: function prepatch(oldVnode, vnode) {
	        var options = vnode.componentOptions;
	        var child = vnode.componentInstance = oldVnode.componentInstance;
	        updateChildComponent(child, options.propsData, options.listeners, vnode, options.children);
	    },
	    insert: function insert(vnode) {
	        var context = vnode.context;
	        var componentInstance = vnode.componentInstance;
	        if (!componentInstance._isMounted) {
	            componentInstance._isMounted = true;
	            callHook(componentInstance, 'mounted');
	        }
	        if (vnode.data.keepAlive) {
	            if (context._isMounted) {
	                queueActivatedComponent(componentInstance);
	            } else {
	                activateChildComponent(componentInstance, true);
	            }
	        }
	    },
	    destroy: function destroy(vnode) {
	        var componentInstance = vnode.componentInstance;
	        if (!componentInstance._isDestroyed) {
	            if (!vnode.data.keepAlive) {
	                componentInstance.$destroy();
	            } else {
	                deactivateChildComponent(componentInstance, true);
	            }
	        }
	    }
	};
	var hooksToMerge = Object.keys(componentVNodeHooks);
	function createComponent(Ctor, data, context, children, tag) {
	    if (isUndef(Ctor)) {
	        return;
	    }
	    var baseCtor = context.$options._base;
	    if (isObject$1(Ctor)) {
	        Ctor = baseCtor.extend(Ctor);
	    }
	    if (typeof Ctor !== 'function') {
	        return;
	    }
	    var asyncFactory;
	    if (isUndef(Ctor.cid)) {
	        asyncFactory = Ctor;
	        Ctor = resolveAsyncComponent(asyncFactory, baseCtor);
	        if (Ctor === undefined) {
	            return createAsyncPlaceholder(asyncFactory, data, context, children, tag);
	        }
	    }
	    data = data || {};
	    resolveConstructorOptions(Ctor);
	    if (isDef(data.model)) {
	        transformModel(Ctor.options, data);
	    }
	    var propsData = extractPropsFromVNodeData(data, Ctor);
	    if (isTrue(Ctor.options.functional)) {
	        return createFunctionalComponent(Ctor, propsData, data, context, children);
	    }
	    var listeners = data.on;
	    data.on = data.nativeOn;
	    if (isTrue(Ctor.options.abstract)) {
	        var slot = data.slot;
	        data = {};
	        if (slot) {
	            data.slot = slot;
	        }
	    }
	    installComponentHooks(data);
	    var name = Ctor.options.name || tag;
	    var vnode = new VNode("vue-component-" + Ctor.cid + (name ? "-" + name : ''), data, undefined, undefined, undefined, context, {
	        Ctor: Ctor,
	        propsData: propsData,
	        listeners: listeners,
	        tag: tag,
	        children: children
	    }, asyncFactory);
	    return vnode;
	}

	function createComponentInstanceForVnode(vnode, parent) {
	    var options = {
	        _isComponent: true,
	        _parentVnode: vnode,
	        parent: parent
	    };
	    var inlineTemplate = vnode.data.inlineTemplate;
	    if (isDef(inlineTemplate)) {
	        options.render = inlineTemplate.render;
	        options.staticRenderFns = inlineTemplate.staticRenderFns;
	    }
	    return new vnode.componentOptions.Ctor(options);
	}

	function installComponentHooks(data) {
	    var hooks = data.hook || (data.hook = {});
	    for (var i = 0;i < hooksToMerge.length; i++) {
	        var key = hooksToMerge[i];
	        var existing = hooks[key];
	        var toMerge = componentVNodeHooks[key];
	        if (existing !== toMerge && !(existing && existing._merged)) {
	            hooks[key] = existing ? mergeHook$1(toMerge, existing) : toMerge;
	        }
	    }
	}

	function mergeHook$1(f1, f2) {
	    var merged = function (a, b) {
	        f1(a, b);
	        f2(a, b);
	    };
	    merged._merged = true;
	    return merged;
	}

	function transformModel(options, data) {
	    var prop = options.model && options.model.prop || 'value';
	    var event = options.model && options.model.event || 'input';
	    (data.attrs || (data.attrs = {}))[prop] = data.model.value;
	    var on = data.on || (data.on = {});
	    var existing = on[event];
	    var callback = data.model.callback;
	    if (isDef(existing)) {
	        if (Array.isArray(existing) ? existing.indexOf(callback) === -1 : existing !== callback) {
	            on[event] = [callback].concat(existing);
	        }
	    } else {
	        on[event] = callback;
	    }
	}

	var SIMPLE_NORMALIZE = 1;
	var ALWAYS_NORMALIZE = 2;
	function createElement(context, tag, data, children, normalizationType, alwaysNormalize) {
	    if (Array.isArray(data) || isPrimitive(data)) {
	        normalizationType = children;
	        children = data;
	        data = undefined;
	    }
	    if (isTrue(alwaysNormalize)) {
	        normalizationType = ALWAYS_NORMALIZE;
	    }
	    return _createElement(context, tag, data, children, normalizationType);
	}

	function _createElement(context, tag, data, children, normalizationType) {
	    if (isDef(data) && isDef(data.__ob__)) {
	        return createEmptyVNode();
	    }
	    if (isDef(data) && isDef(data.is)) {
	        tag = data.is;
	    }
	    if (!tag) {
	        return createEmptyVNode();
	    }
	    if (Array.isArray(children) && typeof children[0] === 'function') {
	        data = data || {};
	        data.scopedSlots = {
	            default: children[0]
	        };
	        children.length = 0;
	    }
	    if (normalizationType === ALWAYS_NORMALIZE) {
	        children = normalizeChildren(children);
	    } else if (normalizationType === SIMPLE_NORMALIZE) {
	        children = simpleNormalizeChildren(children);
	    }
	    var vnode, ns;
	    if (typeof tag === 'string') {
	        var Ctor;
	        ns = context.$vnode && context.$vnode.ns || config.getTagNamespace(tag);
	        if (config.isReservedTag(tag)) {
	            vnode = new VNode(config.parsePlatformTagName(tag), data, children, undefined, undefined, context);
	        } else if ((!data || !data.pre) && isDef(Ctor = resolveAsset(context.$options, 'components', tag))) {
	            vnode = createComponent(Ctor, data, context, children, tag);
	        } else {
	            vnode = new VNode(tag, data, children, undefined, undefined, context);
	        }
	    } else {
	        vnode = createComponent(tag, data, context, children);
	    }
	    if (Array.isArray(vnode)) {
	        return vnode;
	    } else if (isDef(vnode)) {
	        if (isDef(ns)) {
	            applyNS(vnode, ns);
	        }
	        if (isDef(data)) {
	            registerDeepBindings(data);
	        }
	        return vnode;
	    } else {
	        return createEmptyVNode();
	    }
	}

	function applyNS(vnode, ns, force) {
	    vnode.ns = ns;
	    if (vnode.tag === 'foreignObject') {
	        ns = undefined;
	        force = true;
	    }
	    if (isDef(vnode.children)) {
	        for (var i = 0, l = vnode.children.length;i < l; i++) {
	            var child = vnode.children[i];
	            if (isDef(child.tag) && (isUndef(child.ns) || isTrue(force) && child.tag !== 'svg')) {
	                applyNS(child, ns, force);
	            }
	        }
	    }
	}

	function registerDeepBindings(data) {
	    if (isObject$1(data.style)) {
	        traverse(data.style);
	    }
	    if (isObject$1(data.class)) {
	        traverse(data.class);
	    }
	}

	function initRender(vm) {
	    vm._vnode = null;
	    vm._staticTrees = null;
	    var options = vm.$options;
	    var parentVnode = vm.$vnode = options._parentVnode;
	    var renderContext = parentVnode && parentVnode.context;
	    vm.$slots = resolveSlots(options._renderChildren, renderContext);
	    vm.$scopedSlots = emptyObject;
	    vm._c = function (a, b, c, d) {
	        return createElement(vm, a, b, c, d, false);
	    };
	    vm.$createElement = function (a, b, c, d) {
	        return createElement(vm, a, b, c, d, true);
	    };
	    var parentData = parentVnode && parentVnode.data;
	    {
	        defineReactive$$1(vm, '$attrs', parentData && parentData.attrs || emptyObject, null, true);
	        defineReactive$$1(vm, '$listeners', options._parentListeners || emptyObject, null, true);
	    }
	}

	var currentRenderingInstance = null;
	function renderMixin(Vue) {
	    installRenderHelpers(Vue.prototype);
	    Vue.prototype.$nextTick = function (fn) {
	        return nextTick(fn, this);
	    };
	    Vue.prototype._render = function () {
	        var vm = this;
	        var ref = vm.$options;
	        var render = ref.render;
	        var _parentVnode = ref._parentVnode;
	        if (_parentVnode) {
	            vm.$scopedSlots = normalizeScopedSlots(_parentVnode.data.scopedSlots, vm.$slots, vm.$scopedSlots);
	        }
	        vm.$vnode = _parentVnode;
	        var vnode;
	        try {
	            currentRenderingInstance = vm;
	            vnode = render.call(vm._renderProxy, vm.$createElement);
	        } catch (e) {
	            handleError(e, vm, "render");
	            {
	                vnode = vm._vnode;
	            }
	        } finally {
	            currentRenderingInstance = null;
	        }
	        if (Array.isArray(vnode) && vnode.length === 1) {
	            vnode = vnode[0];
	        }
	        if (!(vnode instanceof VNode)) {
	            vnode = createEmptyVNode();
	        }
	        vnode.parent = _parentVnode;
	        return vnode;
	    };
	}

	function ensureCtor(comp, base) {
	    if (comp.__esModule || hasSymbol && comp[Symbol.toStringTag] === 'Module') {
	        comp = comp.default;
	    }
	    return isObject$1(comp) ? base.extend(comp) : comp;
	}

	function createAsyncPlaceholder(factory, data, context, children, tag) {
	    var node = createEmptyVNode();
	    node.asyncFactory = factory;
	    node.asyncMeta = {
	        data: data,
	        context: context,
	        children: children,
	        tag: tag
	    };
	    return node;
	}

	function resolveAsyncComponent(factory, baseCtor) {
	    if (isTrue(factory.error) && isDef(factory.errorComp)) {
	        return factory.errorComp;
	    }
	    if (isDef(factory.resolved)) {
	        return factory.resolved;
	    }
	    var owner = currentRenderingInstance;
	    if (owner && isDef(factory.owners) && factory.owners.indexOf(owner) === -1) {
	        factory.owners.push(owner);
	    }
	    if (isTrue(factory.loading) && isDef(factory.loadingComp)) {
	        return factory.loadingComp;
	    }
	    if (owner && !isDef(factory.owners)) {
	        var owners = factory.owners = [owner];
	        var sync = true;
	        var timerLoading = null;
	        var timerTimeout = null;
	        owner.$on('hook:destroyed', function () {
	            return remove(owners, owner);
	        });
	        var forceRender = function (renderCompleted) {
	            for (var i = 0, l = owners.length;i < l; i++) {
	                owners[i].$forceUpdate();
	            }
	            if (renderCompleted) {
	                owners.length = 0;
	                if (timerLoading !== null) {
	                    clearTimeout(timerLoading);
	                    timerLoading = null;
	                }
	                if (timerTimeout !== null) {
	                    clearTimeout(timerTimeout);
	                    timerTimeout = null;
	                }
	            }
	        };
	        var resolve = once(function (res) {
	            factory.resolved = ensureCtor(res, baseCtor);
	            if (!sync) {
	                forceRender(true);
	            } else {
	                owners.length = 0;
	            }
	        });
	        var reject = once(function (reason) {
	            if (isDef(factory.errorComp)) {
	                factory.error = true;
	                forceRender(true);
	            }
	        });
	        var res = factory(resolve, reject);
	        if (isObject$1(res)) {
	            if (isPromise(res)) {
	                if (isUndef(factory.resolved)) {
	                    res.then(resolve, reject);
	                }
	            } else if (isPromise(res.component)) {
	                res.component.then(resolve, reject);
	                if (isDef(res.error)) {
	                    factory.errorComp = ensureCtor(res.error, baseCtor);
	                }
	                if (isDef(res.loading)) {
	                    factory.loadingComp = ensureCtor(res.loading, baseCtor);
	                    if (res.delay === 0) {
	                        factory.loading = true;
	                    } else {
	                        timerLoading = setTimeout(function () {
	                            timerLoading = null;
	                            if (isUndef(factory.resolved) && isUndef(factory.error)) {
	                                factory.loading = true;
	                                forceRender(false);
	                            }
	                        }, res.delay || 200);
	                    }
	                }
	                if (isDef(res.timeout)) {
	                    timerTimeout = setTimeout(function () {
	                        timerTimeout = null;
	                        if (isUndef(factory.resolved)) {
	                            reject( null);
	                        }
	                    }, res.timeout);
	                }
	            }
	        }
	        sync = false;
	        return factory.loading ? factory.loadingComp : factory.resolved;
	    }
	}

	function isAsyncPlaceholder(node) {
	    return node.isComment && node.asyncFactory;
	}

	function getFirstComponentChild(children) {
	    if (Array.isArray(children)) {
	        for (var i = 0;i < children.length; i++) {
	            var c = children[i];
	            if (isDef(c) && (isDef(c.componentOptions) || isAsyncPlaceholder(c))) {
	                return c;
	            }
	        }
	    }
	}

	function initEvents(vm) {
	    vm._events = Object.create(null);
	    vm._hasHookEvent = false;
	    var listeners = vm.$options._parentListeners;
	    if (listeners) {
	        updateComponentListeners(vm, listeners);
	    }
	}

	var target;
	function add(event, fn) {
	    target.$on(event, fn);
	}

	function remove$1(event, fn) {
	    target.$off(event, fn);
	}

	function createOnceHandler(event, fn) {
	    var _target = target;
	    return function onceHandler() {
	        var res = fn.apply(null, arguments);
	        if (res !== null) {
	            _target.$off(event, onceHandler);
	        }
	    };
	}

	function updateComponentListeners(vm, listeners, oldListeners) {
	    target = vm;
	    updateListeners(listeners, oldListeners || {}, add, remove$1, createOnceHandler, vm);
	    target = undefined;
	}

	function eventsMixin(Vue) {
	    var hookRE = /^hook:/;
	    Vue.prototype.$on = function (event, fn) {
	        var vm = this;
	        if (Array.isArray(event)) {
	            for (var i = 0, l = event.length;i < l; i++) {
	                vm.$on(event[i], fn);
	            }
	        } else {
	            (vm._events[event] || (vm._events[event] = [])).push(fn);
	            if (hookRE.test(event)) {
	                vm._hasHookEvent = true;
	            }
	        }
	        return vm;
	    };
	    Vue.prototype.$once = function (event, fn) {
	        var vm = this;
	        function on() {
	            vm.$off(event, on);
	            fn.apply(vm, arguments);
	        }
	        
	        on.fn = fn;
	        vm.$on(event, on);
	        return vm;
	    };
	    Vue.prototype.$off = function (event, fn) {
	        var vm = this;
	        if (!arguments.length) {
	            vm._events = Object.create(null);
	            return vm;
	        }
	        if (Array.isArray(event)) {
	            for (var i$1 = 0, l = event.length;i$1 < l; i$1++) {
	                vm.$off(event[i$1], fn);
	            }
	            return vm;
	        }
	        var cbs = vm._events[event];
	        if (!cbs) {
	            return vm;
	        }
	        if (!fn) {
	            vm._events[event] = null;
	            return vm;
	        }
	        var cb;
	        var i = cbs.length;
	        while (i--) {
	            cb = cbs[i];
	            if (cb === fn || cb.fn === fn) {
	                cbs.splice(i, 1);
	                break;
	            }
	        }
	        return vm;
	    };
	    Vue.prototype.$emit = function (event) {
	        var vm = this;
	        var cbs = vm._events[event];
	        if (cbs) {
	            cbs = cbs.length > 1 ? toArray(cbs) : cbs;
	            var args = toArray(arguments, 1);
	            var info = "event handler for \"" + event + "\"";
	            for (var i = 0, l = cbs.length;i < l; i++) {
	                invokeWithErrorHandling(cbs[i], vm, args, vm, info);
	            }
	        }
	        return vm;
	    };
	}

	var activeInstance = null;
	function setActiveInstance(vm) {
	    var prevActiveInstance = activeInstance;
	    activeInstance = vm;
	    return function () {
	        activeInstance = prevActiveInstance;
	    };
	}

	function initLifecycle(vm) {
	    var options = vm.$options;
	    var parent = options.parent;
	    if (parent && !options.abstract) {
	        while (parent.$options.abstract && parent.$parent) {
	            parent = parent.$parent;
	        }
	        parent.$children.push(vm);
	    }
	    vm.$parent = parent;
	    vm.$root = parent ? parent.$root : vm;
	    vm.$children = [];
	    vm.$refs = {};
	    vm._watcher = null;
	    vm._inactive = null;
	    vm._directInactive = false;
	    vm._isMounted = false;
	    vm._isDestroyed = false;
	    vm._isBeingDestroyed = false;
	}

	function lifecycleMixin(Vue) {
	    Vue.prototype._update = function (vnode, hydrating) {
	        var vm = this;
	        var prevEl = vm.$el;
	        var prevVnode = vm._vnode;
	        var restoreActiveInstance = setActiveInstance(vm);
	        vm._vnode = vnode;
	        if (!prevVnode) {
	            vm.$el = vm.__patch__(vm.$el, vnode, hydrating, false);
	        } else {
	            vm.$el = vm.__patch__(prevVnode, vnode);
	        }
	        restoreActiveInstance();
	        if (prevEl) {
	            prevEl.__vue__ = null;
	        }
	        if (vm.$el) {
	            vm.$el.__vue__ = vm;
	        }
	        if (vm.$vnode && vm.$parent && vm.$vnode === vm.$parent._vnode) {
	            vm.$parent.$el = vm.$el;
	        }
	    };
	    Vue.prototype.$forceUpdate = function () {
	        var vm = this;
	        if (vm._watcher) {
	            vm._watcher.update();
	        }
	    };
	    Vue.prototype.$destroy = function () {
	        var vm = this;
	        if (vm._isBeingDestroyed) {
	            return;
	        }
	        callHook(vm, 'beforeDestroy');
	        vm._isBeingDestroyed = true;
	        var parent = vm.$parent;
	        if (parent && !parent._isBeingDestroyed && !vm.$options.abstract) {
	            remove(parent.$children, vm);
	        }
	        if (vm._watcher) {
	            vm._watcher.teardown();
	        }
	        var i = vm._watchers.length;
	        while (i--) {
	            vm._watchers[i].teardown();
	        }
	        if (vm._data.__ob__) {
	            vm._data.__ob__.vmCount--;
	        }
	        vm._isDestroyed = true;
	        vm.__patch__(vm._vnode, null);
	        callHook(vm, 'destroyed');
	        vm.$off();
	        if (vm.$el) {
	            vm.$el.__vue__ = null;
	        }
	        if (vm.$vnode) {
	            vm.$vnode.parent = null;
	        }
	    };
	}

	function mountComponent(vm, el, hydrating) {
	    vm.$el = el;
	    if (!vm.$options.render) {
	        vm.$options.render = createEmptyVNode;
	    }
	    callHook(vm, 'beforeMount');
	    var updateComponent;
	    {
	        updateComponent = function () {
	            vm._update(vm._render(), hydrating);
	        };
	    }
	    new Watcher(vm, updateComponent, noop, {
	        before: function before() {
	            if (vm._isMounted && !vm._isDestroyed) {
	                callHook(vm, 'beforeUpdate');
	            }
	        }
	    }, true);
	    hydrating = false;
	    if (vm.$vnode == null) {
	        vm._isMounted = true;
	        callHook(vm, 'mounted');
	    }
	    return vm;
	}

	function updateChildComponent(vm, propsData, listeners, parentVnode, renderChildren) {
	    var newScopedSlots = parentVnode.data.scopedSlots;
	    var oldScopedSlots = vm.$scopedSlots;
	    var hasDynamicScopedSlot = !(!(newScopedSlots && !newScopedSlots.$stable || oldScopedSlots !== emptyObject && !oldScopedSlots.$stable || newScopedSlots && vm.$scopedSlots.$key !== newScopedSlots.$key));
	    var needsForceUpdate = !(!(renderChildren || vm.$options._renderChildren || hasDynamicScopedSlot));
	    vm.$options._parentVnode = parentVnode;
	    vm.$vnode = parentVnode;
	    if (vm._vnode) {
	        vm._vnode.parent = parentVnode;
	    }
	    vm.$options._renderChildren = renderChildren;
	    vm.$attrs = parentVnode.data.attrs || emptyObject;
	    vm.$listeners = listeners || emptyObject;
	    if (propsData && vm.$options.props) {
	        toggleObserving(false);
	        var props = vm._props;
	        var propKeys = vm.$options._propKeys || [];
	        for (var i = 0;i < propKeys.length; i++) {
	            var key = propKeys[i];
	            var propOptions = vm.$options.props;
	            props[key] = validateProp(key, propOptions, propsData, vm);
	        }
	        toggleObserving(true);
	        vm.$options.propsData = propsData;
	    }
	    listeners = listeners || emptyObject;
	    var oldListeners = vm.$options._parentListeners;
	    vm.$options._parentListeners = listeners;
	    updateComponentListeners(vm, listeners, oldListeners);
	    if (needsForceUpdate) {
	        vm.$slots = resolveSlots(renderChildren, parentVnode.context);
	        vm.$forceUpdate();
	    }
	}

	function isInInactiveTree(vm) {
	    while (vm && (vm = vm.$parent)) {
	        if (vm._inactive) {
	            return true;
	        }
	    }
	    return false;
	}

	function activateChildComponent(vm, direct) {
	    if (direct) {
	        vm._directInactive = false;
	        if (isInInactiveTree(vm)) {
	            return;
	        }
	    } else if (vm._directInactive) {
	        return;
	    }
	    if (vm._inactive || vm._inactive === null) {
	        vm._inactive = false;
	        for (var i = 0;i < vm.$children.length; i++) {
	            activateChildComponent(vm.$children[i]);
	        }
	        callHook(vm, 'activated');
	    }
	}

	function deactivateChildComponent(vm, direct) {
	    if (direct) {
	        vm._directInactive = true;
	        if (isInInactiveTree(vm)) {
	            return;
	        }
	    }
	    if (!vm._inactive) {
	        vm._inactive = true;
	        for (var i = 0;i < vm.$children.length; i++) {
	            deactivateChildComponent(vm.$children[i]);
	        }
	        callHook(vm, 'deactivated');
	    }
	}

	function callHook(vm, hook) {
	    pushTarget();
	    var handlers = vm.$options[hook];
	    var info = hook + " hook";
	    if (handlers) {
	        for (var i = 0, j = handlers.length;i < j; i++) {
	            invokeWithErrorHandling(handlers[i], vm, null, vm, info);
	        }
	    }
	    if (vm._hasHookEvent) {
	        vm.$emit('hook:' + hook);
	    }
	    popTarget();
	}
	var queue$1 = [];
	var activatedChildren = [];
	var has$2 = {};
	var waiting = false;
	var flushing = false;
	var index$1 = 0;
	function resetSchedulerState() {
	    index$1 = (queue$1.length = (activatedChildren.length = 0));
	    has$2 = {};
	    waiting = (flushing = false);
	}

	var currentFlushTimestamp = 0;
	var getNow = Date.now;
	if (inBrowser && !isIE) {
	    var performance = window.performance;
	    if (performance && typeof performance.now === 'function' && getNow() > document.createEvent('Event').timeStamp) {
	        getNow = function () {
	            return performance.now();
	        };
	    }
	}
	function flushSchedulerQueue() {
	    currentFlushTimestamp = getNow();
	    flushing = true;
	    var watcher, id;
	    queue$1.sort(function (a, b) {
	        return a.id - b.id;
	    });
	    for (index$1 = 0; index$1 < queue$1.length; index$1++) {
	        watcher = queue$1[index$1];
	        if (watcher.before) {
	            watcher.before();
	        }
	        id = watcher.id;
	        has$2[id] = null;
	        watcher.run();
	    }
	    var activatedQueue = activatedChildren.slice();
	    var updatedQueue = queue$1.slice();
	    resetSchedulerState();
	    callActivatedHooks(activatedQueue);
	    callUpdatedHooks(updatedQueue);
	    if (devtools && config.devtools) {
	        devtools.emit('flush');
	    }
	}

	function callUpdatedHooks(queue) {
	    var i = queue.length;
	    while (i--) {
	        var watcher = queue[i];
	        var vm = watcher.vm;
	        if (vm._watcher === watcher && vm._isMounted && !vm._isDestroyed) {
	            callHook(vm, 'updated');
	        }
	    }
	}

	function queueActivatedComponent(vm) {
	    vm._inactive = false;
	    activatedChildren.push(vm);
	}

	function callActivatedHooks(queue) {
	    for (var i = 0;i < queue.length; i++) {
	        queue[i]._inactive = true;
	        activateChildComponent(queue[i], true);
	    }
	}

	function queueWatcher(watcher) {
	    var id = watcher.id;
	    if (has$2[id] == null) {
	        has$2[id] = true;
	        if (!flushing) {
	            queue$1.push(watcher);
	        } else {
	            var i = queue$1.length - 1;
	            while (i > index$1 && queue$1[i].id > watcher.id) {
	                i--;
	            }
	            queue$1.splice(i + 1, 0, watcher);
	        }
	        if (!waiting) {
	            waiting = true;
	            nextTick(flushSchedulerQueue);
	        }
	    }
	}

	var uid$2 = 0;
	var Watcher = function Watcher(vm, expOrFn, cb, options, isRenderWatcher) {
	    this.vm = vm;
	    if (isRenderWatcher) {
	        vm._watcher = this;
	    }
	    vm._watchers.push(this);
	    if (options) {
	        this.deep = !(!options.deep);
	        this.user = !(!options.user);
	        this.lazy = !(!options.lazy);
	        this.sync = !(!options.sync);
	        this.before = options.before;
	    } else {
	        this.deep = (this.user = (this.lazy = (this.sync = false)));
	    }
	    this.cb = cb;
	    this.id = ++uid$2;
	    this.active = true;
	    this.dirty = this.lazy;
	    this.deps = [];
	    this.newDeps = [];
	    this.depIds = new _Set();
	    this.newDepIds = new _Set();
	    this.expression =  '';
	    if (typeof expOrFn === 'function') {
	        this.getter = expOrFn;
	    } else {
	        this.getter = parsePath(expOrFn);
	        if (!this.getter) {
	            this.getter = noop;
	        }
	    }
	    this.value = this.lazy ? undefined : this.get();
	};
	Watcher.prototype.get = function get() {
	    pushTarget(this);
	    var value;
	    var vm = this.vm;
	    try {
	        value = this.getter.call(vm, vm);
	    } catch (e) {
	        if (this.user) {
	            handleError(e, vm, "getter for watcher \"" + this.expression + "\"");
	        } else {
	            throw e;
	        }
	    } finally {
	        if (this.deep) {
	            traverse(value);
	        }
	        popTarget();
	        this.cleanupDeps();
	    }
	    return value;
	};
	Watcher.prototype.addDep = function addDep(dep) {
	    var id = dep.id;
	    if (!this.newDepIds.has(id)) {
	        this.newDepIds.add(id);
	        this.newDeps.push(dep);
	        if (!this.depIds.has(id)) {
	            dep.addSub(this);
	        }
	    }
	};
	Watcher.prototype.cleanupDeps = function cleanupDeps() {
	    var i = this.deps.length;
	    while (i--) {
	        var dep = this.deps[i];
	        if (!this.newDepIds.has(dep.id)) {
	            dep.removeSub(this);
	        }
	    }
	    var tmp = this.depIds;
	    this.depIds = this.newDepIds;
	    this.newDepIds = tmp;
	    this.newDepIds.clear();
	    tmp = this.deps;
	    this.deps = this.newDeps;
	    this.newDeps = tmp;
	    this.newDeps.length = 0;
	};
	Watcher.prototype.update = function update() {
	    if (this.lazy) {
	        this.dirty = true;
	    } else if (this.sync) {
	        this.run();
	    } else {
	        queueWatcher(this);
	    }
	};
	Watcher.prototype.run = function run() {
	    if (this.active) {
	        var value = this.get();
	        if (value !== this.value || isObject$1(value) || this.deep) {
	            var oldValue = this.value;
	            this.value = value;
	            if (this.user) {
	                try {
	                    this.cb.call(this.vm, value, oldValue);
	                } catch (e) {
	                    handleError(e, this.vm, "callback for watcher \"" + this.expression + "\"");
	                }
	            } else {
	                this.cb.call(this.vm, value, oldValue);
	            }
	        }
	    }
	};
	Watcher.prototype.evaluate = function evaluate() {
	    this.value = this.get();
	    this.dirty = false;
	};
	Watcher.prototype.depend = function depend() {
	    var i = this.deps.length;
	    while (i--) {
	        this.deps[i].depend();
	    }
	};
	Watcher.prototype.teardown = function teardown() {
	    if (this.active) {
	        if (!this.vm._isBeingDestroyed) {
	            remove(this.vm._watchers, this);
	        }
	        var i = this.deps.length;
	        while (i--) {
	            this.deps[i].removeSub(this);
	        }
	        this.active = false;
	    }
	};
	var sharedPropertyDefinition = {
	    enumerable: true,
	    configurable: true,
	    get: noop,
	    set: noop
	};
	function proxy$1(target, sourceKey, key) {
	    sharedPropertyDefinition.get = function proxyGetter() {
	        return this[sourceKey][key];
	    };
	    sharedPropertyDefinition.set = function proxySetter(val) {
	        this[sourceKey][key] = val;
	    };
	    Object.defineProperty(target, key, sharedPropertyDefinition);
	}

	function initState(vm) {
	    vm._watchers = [];
	    var opts = vm.$options;
	    if (opts.props) {
	        initProps(vm, opts.props);
	    }
	    if (opts.methods) {
	        initMethods(vm, opts.methods);
	    }
	    if (opts.data) {
	        initData(vm);
	    } else {
	        observe(vm._data = {}, true);
	    }
	    if (opts.computed) {
	        initComputed(vm, opts.computed);
	    }
	    if (opts.watch && opts.watch !== nativeWatch) {
	        initWatch(vm, opts.watch);
	    }
	}

	function initProps(vm, propsOptions) {
	    var propsData = vm.$options.propsData || {};
	    var props = vm._props = {};
	    var keys = vm.$options._propKeys = [];
	    var isRoot = !vm.$parent;
	    if (!isRoot) {
	        toggleObserving(false);
	    }
	    var loop = function (key) {
	        keys.push(key);
	        var value = validateProp(key, propsOptions, propsData, vm);
	        {
	            defineReactive$$1(props, key, value);
	        }
	        if (!(key in vm)) {
	            proxy$1(vm, "_props", key);
	        }
	    };
	    for (var key in propsOptions) 
	        { loop(key); }
	    toggleObserving(true);
	}

	function initData(vm) {
	    var data = vm.$options.data;
	    data = (vm._data = typeof data === 'function' ? getData(data, vm) : data || {});
	    if (!isPlainObject(data)) {
	        data = {};
	    }
	    var keys = Object.keys(data);
	    var props = vm.$options.props;
	    var methods = vm.$options.methods;
	    var i = keys.length;
	    while (i--) {
	        var key = keys[i];
	        if (props && hasOwn(props, key)) ; else if (!isReserved(key)) {
	            proxy$1(vm, "_data", key);
	        }
	    }
	    observe(data, true);
	}

	function getData(data, vm) {
	    pushTarget();
	    try {
	        return data.call(vm, vm);
	    } catch (e) {
	        handleError(e, vm, "data()");
	        return {};
	    } finally {
	        popTarget();
	    }
	}

	var computedWatcherOptions = {
	    lazy: true
	};
	function initComputed(vm, computed) {
	    var watchers = vm._computedWatchers = Object.create(null);
	    var isSSR = isServerRendering();
	    for (var key in computed) {
	        var userDef = computed[key];
	        var getter = typeof userDef === 'function' ? userDef : userDef.get;
	        if (!isSSR) {
	            watchers[key] = new Watcher(vm, getter || noop, noop, computedWatcherOptions);
	        }
	        if (!(key in vm)) {
	            defineComputed(vm, key, userDef);
	        }
	    }
	}

	function defineComputed(target, key, userDef) {
	    var shouldCache = !isServerRendering();
	    if (typeof userDef === 'function') {
	        sharedPropertyDefinition.get = shouldCache ? createComputedGetter(key) : createGetterInvoker(userDef);
	        sharedPropertyDefinition.set = noop;
	    } else {
	        sharedPropertyDefinition.get = userDef.get ? shouldCache && userDef.cache !== false ? createComputedGetter(key) : createGetterInvoker(userDef.get) : noop;
	        sharedPropertyDefinition.set = userDef.set || noop;
	    }
	    Object.defineProperty(target, key, sharedPropertyDefinition);
	}

	function createComputedGetter(key) {
	    return function computedGetter() {
	        var watcher = this._computedWatchers && this._computedWatchers[key];
	        if (watcher) {
	            if (watcher.dirty) {
	                watcher.evaluate();
	            }
	            if (Dep.target) {
	                watcher.depend();
	            }
	            return watcher.value;
	        }
	    };
	}

	function createGetterInvoker(fn) {
	    return function computedGetter() {
	        return fn.call(this, this);
	    };
	}

	function initMethods(vm, methods) {
	    var props = vm.$options.props;
	    for (var key in methods) {
	        vm[key] = typeof methods[key] !== 'function' ? noop : bind$1(methods[key], vm);
	    }
	}

	function initWatch(vm, watch) {
	    for (var key in watch) {
	        var handler = watch[key];
	        if (Array.isArray(handler)) {
	            for (var i = 0;i < handler.length; i++) {
	                createWatcher(vm, key, handler[i]);
	            }
	        } else {
	            createWatcher(vm, key, handler);
	        }
	    }
	}

	function createWatcher(vm, expOrFn, handler, options) {
	    if (isPlainObject(handler)) {
	        options = handler;
	        handler = handler.handler;
	    }
	    if (typeof handler === 'string') {
	        handler = vm[handler];
	    }
	    return vm.$watch(expOrFn, handler, options);
	}

	function stateMixin(Vue) {
	    var dataDef = {};
	    dataDef.get = function () {
	        return this._data;
	    };
	    var propsDef = {};
	    propsDef.get = function () {
	        return this._props;
	    };
	    Object.defineProperty(Vue.prototype, '$data', dataDef);
	    Object.defineProperty(Vue.prototype, '$props', propsDef);
	    Vue.prototype.$set = set$3;
	    Vue.prototype.$delete = del;
	    Vue.prototype.$watch = function (expOrFn, cb, options) {
	        var vm = this;
	        if (isPlainObject(cb)) {
	            return createWatcher(vm, expOrFn, cb, options);
	        }
	        options = options || {};
	        options.user = true;
	        var watcher = new Watcher(vm, expOrFn, cb, options);
	        if (options.immediate) {
	            try {
	                cb.call(vm, watcher.value);
	            } catch (error) {
	                handleError(error, vm, "callback for immediate watcher \"" + watcher.expression + "\"");
	            }
	        }
	        return function unwatchFn() {
	            watcher.teardown();
	        };
	    };
	}

	var uid$3 = 0;
	function initMixin(Vue) {
	    Vue.prototype._init = function (options) {
	        var vm = this;
	        vm._uid = uid$3++;
	        vm._isVue = true;
	        if (options && options._isComponent) {
	            initInternalComponent(vm, options);
	        } else {
	            vm.$options = mergeOptions(resolveConstructorOptions(vm.constructor), options || {}, vm);
	        }
	        {
	            vm._renderProxy = vm;
	        }
	        vm._self = vm;
	        initLifecycle(vm);
	        initEvents(vm);
	        initRender(vm);
	        callHook(vm, 'beforeCreate');
	        initInjections(vm);
	        initState(vm);
	        initProvide(vm);
	        callHook(vm, 'created');
	        if (vm.$options.el) {
	            vm.$mount(vm.$options.el);
	        }
	    };
	}

	function initInternalComponent(vm, options) {
	    var opts = vm.$options = Object.create(vm.constructor.options);
	    var parentVnode = options._parentVnode;
	    opts.parent = options.parent;
	    opts._parentVnode = parentVnode;
	    var vnodeComponentOptions = parentVnode.componentOptions;
	    opts.propsData = vnodeComponentOptions.propsData;
	    opts._parentListeners = vnodeComponentOptions.listeners;
	    opts._renderChildren = vnodeComponentOptions.children;
	    opts._componentTag = vnodeComponentOptions.tag;
	    if (options.render) {
	        opts.render = options.render;
	        opts.staticRenderFns = options.staticRenderFns;
	    }
	}

	function resolveConstructorOptions(Ctor) {
	    var options = Ctor.options;
	    if (Ctor.super) {
	        var superOptions = resolveConstructorOptions(Ctor.super);
	        var cachedSuperOptions = Ctor.superOptions;
	        if (superOptions !== cachedSuperOptions) {
	            Ctor.superOptions = superOptions;
	            var modifiedOptions = resolveModifiedOptions(Ctor);
	            if (modifiedOptions) {
	                extend(Ctor.extendOptions, modifiedOptions);
	            }
	            options = (Ctor.options = mergeOptions(superOptions, Ctor.extendOptions));
	            if (options.name) {
	                options.components[options.name] = Ctor;
	            }
	        }
	    }
	    return options;
	}

	function resolveModifiedOptions(Ctor) {
	    var modified;
	    var latest = Ctor.options;
	    var sealed = Ctor.sealedOptions;
	    for (var key in latest) {
	        if (latest[key] !== sealed[key]) {
	            if (!modified) {
	                modified = {};
	            }
	            modified[key] = latest[key];
	        }
	    }
	    return modified;
	}

	function Vue(options) {
	    this._init(options);
	}

	initMixin(Vue);
	stateMixin(Vue);
	eventsMixin(Vue);
	lifecycleMixin(Vue);
	renderMixin(Vue);
	function initUse(Vue) {
	    Vue.use = function (plugin) {
	        var installedPlugins = this._installedPlugins || (this._installedPlugins = []);
	        if (installedPlugins.indexOf(plugin) > -1) {
	            return this;
	        }
	        var args = toArray(arguments, 1);
	        args.unshift(this);
	        if (typeof plugin.install === 'function') {
	            plugin.install.apply(plugin, args);
	        } else if (typeof plugin === 'function') {
	            plugin.apply(null, args);
	        }
	        installedPlugins.push(plugin);
	        return this;
	    };
	}

	function initMixin$1(Vue) {
	    Vue.mixin = function (mixin) {
	        this.options = mergeOptions(this.options, mixin);
	        return this;
	    };
	}

	function initExtend(Vue) {
	    Vue.cid = 0;
	    var cid = 1;
	    Vue.extend = function (extendOptions) {
	        extendOptions = extendOptions || {};
	        var Super = this;
	        var SuperId = Super.cid;
	        var cachedCtors = extendOptions._Ctor || (extendOptions._Ctor = {});
	        if (cachedCtors[SuperId]) {
	            return cachedCtors[SuperId];
	        }
	        var name = extendOptions.name || Super.options.name;
	        var Sub = function VueComponent(options) {
	            this._init(options);
	        };
	        Sub.prototype = Object.create(Super.prototype);
	        Sub.prototype.constructor = Sub;
	        Sub.cid = cid++;
	        Sub.options = mergeOptions(Super.options, extendOptions);
	        Sub['super'] = Super;
	        if (Sub.options.props) {
	            initProps$1(Sub);
	        }
	        if (Sub.options.computed) {
	            initComputed$1(Sub);
	        }
	        Sub.extend = Super.extend;
	        Sub.mixin = Super.mixin;
	        Sub.use = Super.use;
	        ASSET_TYPES.forEach(function (type) {
	            Sub[type] = Super[type];
	        });
	        if (name) {
	            Sub.options.components[name] = Sub;
	        }
	        Sub.superOptions = Super.options;
	        Sub.extendOptions = extendOptions;
	        Sub.sealedOptions = extend({}, Sub.options);
	        cachedCtors[SuperId] = Sub;
	        return Sub;
	    };
	}

	function initProps$1(Comp) {
	    var props = Comp.options.props;
	    for (var key in props) {
	        proxy$1(Comp.prototype, "_props", key);
	    }
	}

	function initComputed$1(Comp) {
	    var computed = Comp.options.computed;
	    for (var key in computed) {
	        defineComputed(Comp.prototype, key, computed[key]);
	    }
	}

	function initAssetRegisters(Vue) {
	    ASSET_TYPES.forEach(function (type) {
	        Vue[type] = function (id, definition) {
	            if (!definition) {
	                return this.options[type + 's'][id];
	            } else {
	                if (type === 'component' && isPlainObject(definition)) {
	                    definition.name = definition.name || id;
	                    definition = this.options._base.extend(definition);
	                }
	                if (type === 'directive' && typeof definition === 'function') {
	                    definition = {
	                        bind: definition,
	                        update: definition
	                    };
	                }
	                this.options[type + 's'][id] = definition;
	                return definition;
	            }
	        };
	    });
	}

	function getComponentName(opts) {
	    return opts && (opts.Ctor.options.name || opts.tag);
	}

	function matches(pattern, name) {
	    if (Array.isArray(pattern)) {
	        return pattern.indexOf(name) > -1;
	    } else if (typeof pattern === 'string') {
	        return pattern.split(',').indexOf(name) > -1;
	    } else if (isRegExp(pattern)) {
	        return pattern.test(name);
	    }
	    return false;
	}

	function pruneCache(keepAliveInstance, filter) {
	    var cache = keepAliveInstance.cache;
	    var keys = keepAliveInstance.keys;
	    var _vnode = keepAliveInstance._vnode;
	    for (var key in cache) {
	        var cachedNode = cache[key];
	        if (cachedNode) {
	            var name = getComponentName(cachedNode.componentOptions);
	            if (name && !filter(name)) {
	                pruneCacheEntry(cache, key, keys, _vnode);
	            }
	        }
	    }
	}

	function pruneCacheEntry(cache, key, keys, current) {
	    var cached$$1 = cache[key];
	    if (cached$$1 && (!current || cached$$1.tag !== current.tag)) {
	        cached$$1.componentInstance.$destroy();
	    }
	    cache[key] = null;
	    remove(keys, key);
	}

	var patternTypes = [String,RegExp,Array];
	var KeepAlive = {
	    name: 'keep-alive',
	    abstract: true,
	    props: {
	        include: patternTypes,
	        exclude: patternTypes,
	        max: [String,Number]
	    },
	    created: function created() {
	        this.cache = Object.create(null);
	        this.keys = [];
	    },
	    destroyed: function destroyed() {
	        for (var key in this.cache) {
	            pruneCacheEntry(this.cache, key, this.keys);
	        }
	    },
	    mounted: function mounted() {
	        var this$1 = this;
	        this.$watch('include', function (val) {
	            pruneCache(this$1, function (name) {
	                return matches(val, name);
	            });
	        });
	        this.$watch('exclude', function (val) {
	            pruneCache(this$1, function (name) {
	                return !matches(val, name);
	            });
	        });
	    },
	    render: function render() {
	        var slot = this.$slots.default;
	        var vnode = getFirstComponentChild(slot);
	        var componentOptions = vnode && vnode.componentOptions;
	        if (componentOptions) {
	            var name = getComponentName(componentOptions);
	            var ref = this;
	            var include = ref.include;
	            var exclude = ref.exclude;
	            if (include && (!name || !matches(include, name)) || exclude && name && matches(exclude, name)) {
	                return vnode;
	            }
	            var ref$1 = this;
	            var cache = ref$1.cache;
	            var keys = ref$1.keys;
	            var key = vnode.key == null ? componentOptions.Ctor.cid + (componentOptions.tag ? "::" + componentOptions.tag : '') : vnode.key;
	            if (cache[key]) {
	                vnode.componentInstance = cache[key].componentInstance;
	                remove(keys, key);
	                keys.push(key);
	            } else {
	                cache[key] = vnode;
	                keys.push(key);
	                if (this.max && keys.length > parseInt(this.max)) {
	                    pruneCacheEntry(cache, keys[0], keys, this._vnode);
	                }
	            }
	            vnode.data.keepAlive = true;
	        }
	        return vnode || slot && slot[0];
	    }
	};
	var builtInComponents = {
	    KeepAlive: KeepAlive
	};
	function initGlobalAPI(Vue) {
	    var configDef = {};
	    configDef.get = function () {
	        return config;
	    };
	    Object.defineProperty(Vue, 'config', configDef);
	    Vue.util = {
	        warn: warn,
	        extend: extend,
	        mergeOptions: mergeOptions,
	        defineReactive: defineReactive$$1
	    };
	    Vue.set = set$3;
	    Vue.delete = del;
	    Vue.nextTick = nextTick;
	    Vue.observable = function (obj) {
	        observe(obj);
	        return obj;
	    };
	    Vue.options = Object.create(null);
	    ASSET_TYPES.forEach(function (type) {
	        Vue.options[type + 's'] = Object.create(null);
	    });
	    Vue.options._base = Vue;
	    extend(Vue.options.components, builtInComponents);
	    initUse(Vue);
	    initMixin$1(Vue);
	    initExtend(Vue);
	    initAssetRegisters(Vue);
	}

	initGlobalAPI(Vue);
	Object.defineProperty(Vue.prototype, '$isServer', {
	    get: isServerRendering
	});
	Object.defineProperty(Vue.prototype, '$ssrContext', {
	    get: function get() {
	        return this.$vnode && this.$vnode.ssrContext;
	    }
	});
	Object.defineProperty(Vue, 'FunctionalRenderContext', {
	    value: FunctionalRenderContext
	});
	Vue.version = '2.6.10';
	var isReservedAttr = makeMap('style,class');
	var acceptValue = makeMap('input,textarea,option,select,progress');
	var mustUseProp = function (tag, type, attr) {
	    return attr === 'value' && acceptValue(tag) && type !== 'button' || attr === 'selected' && tag === 'option' || attr === 'checked' && tag === 'input' || attr === 'muted' && tag === 'video';
	};
	var isEnumeratedAttr = makeMap('contenteditable,draggable,spellcheck');
	var isValidContentEditableValue = makeMap('events,caret,typing,plaintext-only');
	var convertEnumeratedValue = function (key, value) {
	    return isFalsyAttrValue(value) || value === 'false' ? 'false' : key === 'contenteditable' && isValidContentEditableValue(value) ? value : 'true';
	};
	var isBooleanAttr = makeMap('allowfullscreen,async,autofocus,autoplay,checked,compact,controls,declare,' + 'default,defaultchecked,defaultmuted,defaultselected,defer,disabled,' + 'enabled,formnovalidate,hidden,indeterminate,inert,ismap,itemscope,loop,multiple,' + 'muted,nohref,noresize,noshade,novalidate,nowrap,open,pauseonexit,readonly,' + 'required,reversed,scoped,seamless,selected,sortable,translate,' + 'truespeed,typemustmatch,visible');
	var xlinkNS = 'http://www.w3.org/1999/xlink';
	var isXlink = function (name) {
	    return name.charAt(5) === ':' && name.slice(0, 5) === 'xlink';
	};
	var getXlinkProp = function (name) {
	    return isXlink(name) ? name.slice(6, name.length) : '';
	};
	var isFalsyAttrValue = function (val) {
	    return val == null || val === false;
	};
	function genClassForVnode(vnode) {
	    var data = vnode.data;
	    var parentNode = vnode;
	    var childNode = vnode;
	    while (isDef(childNode.componentInstance)) {
	        childNode = childNode.componentInstance._vnode;
	        if (childNode && childNode.data) {
	            data = mergeClassData(childNode.data, data);
	        }
	    }
	    while (isDef(parentNode = parentNode.parent)) {
	        if (parentNode && parentNode.data) {
	            data = mergeClassData(data, parentNode.data);
	        }
	    }
	    return renderClass(data.staticClass, data.class);
	}

	function mergeClassData(child, parent) {
	    return {
	        staticClass: concat(child.staticClass, parent.staticClass),
	        class: isDef(child.class) ? [child.class,parent.class] : parent.class
	    };
	}

	function renderClass(staticClass, dynamicClass) {
	    if (isDef(staticClass) || isDef(dynamicClass)) {
	        return concat(staticClass, stringifyClass(dynamicClass));
	    }
	    return '';
	}

	function concat(a, b) {
	    return a ? b ? a + ' ' + b : a : b || '';
	}

	function stringifyClass(value) {
	    if (Array.isArray(value)) {
	        return stringifyArray(value);
	    }
	    if (isObject$1(value)) {
	        return stringifyObject(value);
	    }
	    if (typeof value === 'string') {
	        return value;
	    }
	    return '';
	}

	function stringifyArray(value) {
	    var res = '';
	    var stringified;
	    for (var i = 0, l = value.length;i < l; i++) {
	        if (isDef(stringified = stringifyClass(value[i])) && stringified !== '') {
	            if (res) {
	                res += ' ';
	            }
	            res += stringified;
	        }
	    }
	    return res;
	}

	function stringifyObject(value) {
	    var res = '';
	    for (var key in value) {
	        if (value[key]) {
	            if (res) {
	                res += ' ';
	            }
	            res += key;
	        }
	    }
	    return res;
	}

	var namespaceMap = {
	    svg: 'http://www.w3.org/2000/svg',
	    math: 'http://www.w3.org/1998/Math/MathML'
	};
	var isHTMLTag = makeMap('html,body,base,head,link,meta,style,title,' + 'address,article,aside,footer,header,h1,h2,h3,h4,h5,h6,hgroup,nav,section,' + 'div,dd,dl,dt,figcaption,figure,picture,hr,img,li,main,ol,p,pre,ul,' + 'a,b,abbr,bdi,bdo,br,cite,code,data,dfn,em,i,kbd,mark,q,rp,rt,rtc,ruby,' + 's,samp,small,span,strong,sub,sup,time,u,var,wbr,area,audio,map,track,video,' + 'embed,object,param,source,canvas,script,noscript,del,ins,' + 'caption,col,colgroup,table,thead,tbody,td,th,tr,' + 'button,datalist,fieldset,form,input,label,legend,meter,optgroup,option,' + 'output,progress,select,textarea,' + 'details,dialog,menu,menuitem,summary,' + 'content,element,shadow,template,blockquote,iframe,tfoot');
	var isSVG = makeMap('svg,animate,circle,clippath,cursor,defs,desc,ellipse,filter,font-face,' + 'foreignObject,g,glyph,image,line,marker,mask,missing-glyph,path,pattern,' + 'polygon,polyline,rect,switch,symbol,text,textpath,tspan,use,view', true);
	var isReservedTag = function (tag) {
	    return isHTMLTag(tag) || isSVG(tag);
	};
	function getTagNamespace(tag) {
	    if (isSVG(tag)) {
	        return 'svg';
	    }
	    if (tag === 'math') {
	        return 'math';
	    }
	}

	var unknownElementCache = Object.create(null);
	function isUnknownElement(tag) {
	    if (!inBrowser) {
	        return true;
	    }
	    if (isReservedTag(tag)) {
	        return false;
	    }
	    tag = tag.toLowerCase();
	    if (unknownElementCache[tag] != null) {
	        return unknownElementCache[tag];
	    }
	    var el = document.createElement(tag);
	    if (tag.indexOf('-') > -1) {
	        return unknownElementCache[tag] = el.constructor === window.HTMLUnknownElement || el.constructor === window.HTMLElement;
	    } else {
	        return unknownElementCache[tag] = /HTMLUnknownElement/.test(el.toString());
	    }
	}

	var isTextInputType = makeMap('text,number,password,search,email,tel,url');
	function query(el) {
	    if (typeof el === 'string') {
	        var selected = document.querySelector(el);
	        if (!selected) {
	            return document.createElement('div');
	        }
	        return selected;
	    } else {
	        return el;
	    }
	}

	function createElement$1(tagName, vnode) {
	    var elm = document.createElement(tagName);
	    if (tagName !== 'select') {
	        return elm;
	    }
	    if (vnode.data && vnode.data.attrs && vnode.data.attrs.multiple !== undefined) {
	        elm.setAttribute('multiple', 'multiple');
	    }
	    return elm;
	}

	function createElementNS(namespace, tagName) {
	    return document.createElementNS(namespaceMap[namespace], tagName);
	}

	function createTextNode(text) {
	    return document.createTextNode(text);
	}

	function createComment(text) {
	    return document.createComment(text);
	}

	function insertBefore(parentNode, newNode, referenceNode) {
	    parentNode.insertBefore(newNode, referenceNode);
	}

	function removeChild(node, child) {
	    node.removeChild(child);
	}

	function appendChild(node, child) {
	    node.appendChild(child);
	}

	function parentNode(node) {
	    return node.parentNode;
	}

	function nextSibling(node) {
	    return node.nextSibling;
	}

	function tagName(node) {
	    return node.tagName;
	}

	function setTextContent(node, text) {
	    node.textContent = text;
	}

	function setStyleScope(node, scopeId) {
	    node.setAttribute(scopeId, '');
	}

	var nodeOps = Object.freeze({
	    createElement: createElement$1,
	    createElementNS: createElementNS,
	    createTextNode: createTextNode,
	    createComment: createComment,
	    insertBefore: insertBefore,
	    removeChild: removeChild,
	    appendChild: appendChild,
	    parentNode: parentNode,
	    nextSibling: nextSibling,
	    tagName: tagName,
	    setTextContent: setTextContent,
	    setStyleScope: setStyleScope
	});
	var ref = {
	    create: function create(_, vnode) {
	        registerRef(vnode);
	    },
	    update: function update(oldVnode, vnode) {
	        if (oldVnode.data.ref !== vnode.data.ref) {
	            registerRef(oldVnode, true);
	            registerRef(vnode);
	        }
	    },
	    destroy: function destroy(vnode) {
	        registerRef(vnode, true);
	    }
	};
	function registerRef(vnode, isRemoval) {
	    var key = vnode.data.ref;
	    if (!isDef(key)) {
	        return;
	    }
	    var vm = vnode.context;
	    var ref = vnode.componentInstance || vnode.elm;
	    var refs = vm.$refs;
	    if (isRemoval) {
	        if (Array.isArray(refs[key])) {
	            remove(refs[key], ref);
	        } else if (refs[key] === ref) {
	            refs[key] = undefined;
	        }
	    } else {
	        if (vnode.data.refInFor) {
	            if (!Array.isArray(refs[key])) {
	                refs[key] = [ref];
	            } else if (refs[key].indexOf(ref) < 0) {
	                refs[key].push(ref);
	            }
	        } else {
	            refs[key] = ref;
	        }
	    }
	}

	var emptyNode = new VNode('', {}, []);
	var hooks = ['create','activate','update','remove','destroy'];
	function sameVnode(a, b) {
	    return a.key === b.key && (a.tag === b.tag && a.isComment === b.isComment && isDef(a.data) === isDef(b.data) && sameInputType(a, b) || isTrue(a.isAsyncPlaceholder) && a.asyncFactory === b.asyncFactory && isUndef(b.asyncFactory.error));
	}

	function sameInputType(a, b) {
	    if (a.tag !== 'input') {
	        return true;
	    }
	    var i;
	    var typeA = isDef(i = a.data) && isDef(i = i.attrs) && i.type;
	    var typeB = isDef(i = b.data) && isDef(i = i.attrs) && i.type;
	    return typeA === typeB || isTextInputType(typeA) && isTextInputType(typeB);
	}

	function createKeyToOldIdx(children, beginIdx, endIdx) {
	    var i, key;
	    var map = {};
	    for (i = beginIdx; i <= endIdx; ++i) {
	        key = children[i].key;
	        if (isDef(key)) {
	            map[key] = i;
	        }
	    }
	    return map;
	}

	function createPatchFunction(backend) {
	    var i, j;
	    var cbs = {};
	    var modules = backend.modules;
	    var nodeOps = backend.nodeOps;
	    for (i = 0; i < hooks.length; ++i) {
	        cbs[hooks[i]] = [];
	        for (j = 0; j < modules.length; ++j) {
	            if (isDef(modules[j][hooks[i]])) {
	                cbs[hooks[i]].push(modules[j][hooks[i]]);
	            }
	        }
	    }
	    function emptyNodeAt(elm) {
	        return new VNode(nodeOps.tagName(elm).toLowerCase(), {}, [], undefined, elm);
	    }
	    
	    function createRmCb(childElm, listeners) {
	        function remove$$1() {
	            if (--remove$$1.listeners === 0) {
	                removeNode(childElm);
	            }
	        }
	        
	        remove$$1.listeners = listeners;
	        return remove$$1;
	    }
	    
	    function removeNode(el) {
	        var parent = nodeOps.parentNode(el);
	        if (isDef(parent)) {
	            nodeOps.removeChild(parent, el);
	        }
	    }
	    function createElm(vnode, insertedVnodeQueue, parentElm, refElm, nested, ownerArray, index) {
	        if (isDef(vnode.elm) && isDef(ownerArray)) {
	            vnode = (ownerArray[index] = cloneVNode(vnode));
	        }
	        vnode.isRootInsert = !nested;
	        if (createComponent(vnode, insertedVnodeQueue, parentElm, refElm)) {
	            return;
	        }
	        var data = vnode.data;
	        var children = vnode.children;
	        var tag = vnode.tag;
	        if (isDef(tag)) {
	            vnode.elm = vnode.ns ? nodeOps.createElementNS(vnode.ns, tag) : nodeOps.createElement(tag, vnode);
	            setScope(vnode);
	            createChildren(vnode, children, insertedVnodeQueue);
	            if (isDef(data)) {
	                invokeCreateHooks(vnode, insertedVnodeQueue);
	            }
	            insert(parentElm, vnode.elm, refElm);
	        } else if (isTrue(vnode.isComment)) {
	            vnode.elm = nodeOps.createComment(vnode.text);
	            insert(parentElm, vnode.elm, refElm);
	        } else {
	            vnode.elm = nodeOps.createTextNode(vnode.text);
	            insert(parentElm, vnode.elm, refElm);
	        }
	    }
	    
	    function createComponent(vnode, insertedVnodeQueue, parentElm, refElm) {
	        var i = vnode.data;
	        if (isDef(i)) {
	            var isReactivated = isDef(vnode.componentInstance) && i.keepAlive;
	            if (isDef(i = i.hook) && isDef(i = i.init)) {
	                i(vnode, false);
	            }
	            if (isDef(vnode.componentInstance)) {
	                initComponent(vnode, insertedVnodeQueue);
	                insert(parentElm, vnode.elm, refElm);
	                if (isTrue(isReactivated)) {
	                    reactivateComponent(vnode, insertedVnodeQueue, parentElm, refElm);
	                }
	                return true;
	            }
	        }
	    }
	    
	    function initComponent(vnode, insertedVnodeQueue) {
	        if (isDef(vnode.data.pendingInsert)) {
	            insertedVnodeQueue.push.apply(insertedVnodeQueue, vnode.data.pendingInsert);
	            vnode.data.pendingInsert = null;
	        }
	        vnode.elm = vnode.componentInstance.$el;
	        if (isPatchable(vnode)) {
	            invokeCreateHooks(vnode, insertedVnodeQueue);
	            setScope(vnode);
	        } else {
	            registerRef(vnode);
	            insertedVnodeQueue.push(vnode);
	        }
	    }
	    
	    function reactivateComponent(vnode, insertedVnodeQueue, parentElm, refElm) {
	        var i;
	        var innerNode = vnode;
	        while (innerNode.componentInstance) {
	            innerNode = innerNode.componentInstance._vnode;
	            if (isDef(i = innerNode.data) && isDef(i = i.transition)) {
	                for (i = 0; i < cbs.activate.length; ++i) {
	                    cbs.activate[i](emptyNode, innerNode);
	                }
	                insertedVnodeQueue.push(innerNode);
	                break;
	            }
	        }
	        insert(parentElm, vnode.elm, refElm);
	    }
	    
	    function insert(parent, elm, ref$$1) {
	        if (isDef(parent)) {
	            if (isDef(ref$$1)) {
	                if (nodeOps.parentNode(ref$$1) === parent) {
	                    nodeOps.insertBefore(parent, elm, ref$$1);
	                }
	            } else {
	                nodeOps.appendChild(parent, elm);
	            }
	        }
	    }
	    
	    function createChildren(vnode, children, insertedVnodeQueue) {
	        if (Array.isArray(children)) {
	            for (var i = 0;i < children.length; ++i) {
	                createElm(children[i], insertedVnodeQueue, vnode.elm, null, true, children, i);
	            }
	        } else if (isPrimitive(vnode.text)) {
	            nodeOps.appendChild(vnode.elm, nodeOps.createTextNode(String(vnode.text)));
	        }
	    }
	    
	    function isPatchable(vnode) {
	        while (vnode.componentInstance) {
	            vnode = vnode.componentInstance._vnode;
	        }
	        return isDef(vnode.tag);
	    }
	    
	    function invokeCreateHooks(vnode, insertedVnodeQueue) {
	        for (var i$1 = 0;i$1 < cbs.create.length; ++i$1) {
	            cbs.create[i$1](emptyNode, vnode);
	        }
	        i = vnode.data.hook;
	        if (isDef(i)) {
	            if (isDef(i.create)) {
	                i.create(emptyNode, vnode);
	            }
	            if (isDef(i.insert)) {
	                insertedVnodeQueue.push(vnode);
	            }
	        }
	    }
	    
	    function setScope(vnode) {
	        var i;
	        if (isDef(i = vnode.fnScopeId)) {
	            nodeOps.setStyleScope(vnode.elm, i);
	        } else {
	            var ancestor = vnode;
	            while (ancestor) {
	                if (isDef(i = ancestor.context) && isDef(i = i.$options._scopeId)) {
	                    nodeOps.setStyleScope(vnode.elm, i);
	                }
	                ancestor = ancestor.parent;
	            }
	        }
	        if (isDef(i = activeInstance) && i !== vnode.context && i !== vnode.fnContext && isDef(i = i.$options._scopeId)) {
	            nodeOps.setStyleScope(vnode.elm, i);
	        }
	    }
	    
	    function addVnodes(parentElm, refElm, vnodes, startIdx, endIdx, insertedVnodeQueue) {
	        for (; startIdx <= endIdx; ++startIdx) {
	            createElm(vnodes[startIdx], insertedVnodeQueue, parentElm, refElm, false, vnodes, startIdx);
	        }
	    }
	    
	    function invokeDestroyHook(vnode) {
	        var i, j;
	        var data = vnode.data;
	        if (isDef(data)) {
	            if (isDef(i = data.hook) && isDef(i = i.destroy)) {
	                i(vnode);
	            }
	            for (i = 0; i < cbs.destroy.length; ++i) {
	                cbs.destroy[i](vnode);
	            }
	        }
	        if (isDef(i = vnode.children)) {
	            for (j = 0; j < vnode.children.length; ++j) {
	                invokeDestroyHook(vnode.children[j]);
	            }
	        }
	    }
	    
	    function removeVnodes(parentElm, vnodes, startIdx, endIdx) {
	        for (; startIdx <= endIdx; ++startIdx) {
	            var ch = vnodes[startIdx];
	            if (isDef(ch)) {
	                if (isDef(ch.tag)) {
	                    removeAndInvokeRemoveHook(ch);
	                    invokeDestroyHook(ch);
	                } else {
	                    removeNode(ch.elm);
	                }
	            }
	        }
	    }
	    
	    function removeAndInvokeRemoveHook(vnode, rm) {
	        if (isDef(rm) || isDef(vnode.data)) {
	            var i;
	            var listeners = cbs.remove.length + 1;
	            if (isDef(rm)) {
	                rm.listeners += listeners;
	            } else {
	                rm = createRmCb(vnode.elm, listeners);
	            }
	            if (isDef(i = vnode.componentInstance) && isDef(i = i._vnode) && isDef(i.data)) {
	                removeAndInvokeRemoveHook(i, rm);
	            }
	            for (i = 0; i < cbs.remove.length; ++i) {
	                cbs.remove[i](vnode, rm);
	            }
	            if (isDef(i = vnode.data.hook) && isDef(i = i.remove)) {
	                i(vnode, rm);
	            } else {
	                rm();
	            }
	        } else {
	            removeNode(vnode.elm);
	        }
	    }
	    
	    function updateChildren(parentElm, oldCh, newCh, insertedVnodeQueue, removeOnly) {
	        var oldStartIdx = 0;
	        var newStartIdx = 0;
	        var oldEndIdx = oldCh.length - 1;
	        var oldStartVnode = oldCh[0];
	        var oldEndVnode = oldCh[oldEndIdx];
	        var newEndIdx = newCh.length - 1;
	        var newStartVnode = newCh[0];
	        var newEndVnode = newCh[newEndIdx];
	        var oldKeyToIdx, idxInOld, vnodeToMove, refElm;
	        var canMove = !removeOnly;
	        while (oldStartIdx <= oldEndIdx && newStartIdx <= newEndIdx) {
	            if (isUndef(oldStartVnode)) {
	                oldStartVnode = oldCh[++oldStartIdx];
	            } else if (isUndef(oldEndVnode)) {
	                oldEndVnode = oldCh[--oldEndIdx];
	            } else if (sameVnode(oldStartVnode, newStartVnode)) {
	                patchVnode(oldStartVnode, newStartVnode, insertedVnodeQueue, newCh, newStartIdx);
	                oldStartVnode = oldCh[++oldStartIdx];
	                newStartVnode = newCh[++newStartIdx];
	            } else if (sameVnode(oldEndVnode, newEndVnode)) {
	                patchVnode(oldEndVnode, newEndVnode, insertedVnodeQueue, newCh, newEndIdx);
	                oldEndVnode = oldCh[--oldEndIdx];
	                newEndVnode = newCh[--newEndIdx];
	            } else if (sameVnode(oldStartVnode, newEndVnode)) {
	                patchVnode(oldStartVnode, newEndVnode, insertedVnodeQueue, newCh, newEndIdx);
	                canMove && nodeOps.insertBefore(parentElm, oldStartVnode.elm, nodeOps.nextSibling(oldEndVnode.elm));
	                oldStartVnode = oldCh[++oldStartIdx];
	                newEndVnode = newCh[--newEndIdx];
	            } else if (sameVnode(oldEndVnode, newStartVnode)) {
	                patchVnode(oldEndVnode, newStartVnode, insertedVnodeQueue, newCh, newStartIdx);
	                canMove && nodeOps.insertBefore(parentElm, oldEndVnode.elm, oldStartVnode.elm);
	                oldEndVnode = oldCh[--oldEndIdx];
	                newStartVnode = newCh[++newStartIdx];
	            } else {
	                if (isUndef(oldKeyToIdx)) {
	                    oldKeyToIdx = createKeyToOldIdx(oldCh, oldStartIdx, oldEndIdx);
	                }
	                idxInOld = isDef(newStartVnode.key) ? oldKeyToIdx[newStartVnode.key] : findIdxInOld(newStartVnode, oldCh, oldStartIdx, oldEndIdx);
	                if (isUndef(idxInOld)) {
	                    createElm(newStartVnode, insertedVnodeQueue, parentElm, oldStartVnode.elm, false, newCh, newStartIdx);
	                } else {
	                    vnodeToMove = oldCh[idxInOld];
	                    if (sameVnode(vnodeToMove, newStartVnode)) {
	                        patchVnode(vnodeToMove, newStartVnode, insertedVnodeQueue, newCh, newStartIdx);
	                        oldCh[idxInOld] = undefined;
	                        canMove && nodeOps.insertBefore(parentElm, vnodeToMove.elm, oldStartVnode.elm);
	                    } else {
	                        createElm(newStartVnode, insertedVnodeQueue, parentElm, oldStartVnode.elm, false, newCh, newStartIdx);
	                    }
	                }
	                newStartVnode = newCh[++newStartIdx];
	            }
	        }
	        if (oldStartIdx > oldEndIdx) {
	            refElm = isUndef(newCh[newEndIdx + 1]) ? null : newCh[newEndIdx + 1].elm;
	            addVnodes(parentElm, refElm, newCh, newStartIdx, newEndIdx, insertedVnodeQueue);
	        } else if (newStartIdx > newEndIdx) {
	            removeVnodes(parentElm, oldCh, oldStartIdx, oldEndIdx);
	        }
	    }
	    
	    function findIdxInOld(node, oldCh, start, end) {
	        for (var i = start;i < end; i++) {
	            var c = oldCh[i];
	            if (isDef(c) && sameVnode(node, c)) {
	                return i;
	            }
	        }
	    }
	    
	    function patchVnode(oldVnode, vnode, insertedVnodeQueue, ownerArray, index, removeOnly) {
	        if (oldVnode === vnode) {
	            return;
	        }
	        if (isDef(vnode.elm) && isDef(ownerArray)) {
	            vnode = (ownerArray[index] = cloneVNode(vnode));
	        }
	        var elm = vnode.elm = oldVnode.elm;
	        if (isTrue(oldVnode.isAsyncPlaceholder)) {
	            if (isDef(vnode.asyncFactory.resolved)) {
	                hydrate(oldVnode.elm, vnode, insertedVnodeQueue);
	            } else {
	                vnode.isAsyncPlaceholder = true;
	            }
	            return;
	        }
	        if (isTrue(vnode.isStatic) && isTrue(oldVnode.isStatic) && vnode.key === oldVnode.key && (isTrue(vnode.isCloned) || isTrue(vnode.isOnce))) {
	            vnode.componentInstance = oldVnode.componentInstance;
	            return;
	        }
	        var i;
	        var data = vnode.data;
	        if (isDef(data) && isDef(i = data.hook) && isDef(i = i.prepatch)) {
	            i(oldVnode, vnode);
	        }
	        var oldCh = oldVnode.children;
	        var ch = vnode.children;
	        if (isDef(data) && isPatchable(vnode)) {
	            for (i = 0; i < cbs.update.length; ++i) {
	                cbs.update[i](oldVnode, vnode);
	            }
	            if (isDef(i = data.hook) && isDef(i = i.update)) {
	                i(oldVnode, vnode);
	            }
	        }
	        if (isUndef(vnode.text)) {
	            if (isDef(oldCh) && isDef(ch)) {
	                if (oldCh !== ch) {
	                    updateChildren(elm, oldCh, ch, insertedVnodeQueue, removeOnly);
	                }
	            } else if (isDef(ch)) {
	                if (isDef(oldVnode.text)) {
	                    nodeOps.setTextContent(elm, '');
	                }
	                addVnodes(elm, null, ch, 0, ch.length - 1, insertedVnodeQueue);
	            } else if (isDef(oldCh)) {
	                removeVnodes(elm, oldCh, 0, oldCh.length - 1);
	            } else if (isDef(oldVnode.text)) {
	                nodeOps.setTextContent(elm, '');
	            }
	        } else if (oldVnode.text !== vnode.text) {
	            nodeOps.setTextContent(elm, vnode.text);
	        }
	        if (isDef(data)) {
	            if (isDef(i = data.hook) && isDef(i = i.postpatch)) {
	                i(oldVnode, vnode);
	            }
	        }
	    }
	    
	    function invokeInsertHook(vnode, queue, initial) {
	        if (isTrue(initial) && isDef(vnode.parent)) {
	            vnode.parent.data.pendingInsert = queue;
	        } else {
	            for (var i = 0;i < queue.length; ++i) {
	                queue[i].data.hook.insert(queue[i]);
	            }
	        }
	    }
	    var isRenderedModule = makeMap('attrs,class,staticClass,staticStyle,key');
	    function hydrate(elm, vnode, insertedVnodeQueue, inVPre) {
	        var i;
	        var tag = vnode.tag;
	        var data = vnode.data;
	        var children = vnode.children;
	        inVPre = inVPre || data && data.pre;
	        vnode.elm = elm;
	        if (isTrue(vnode.isComment) && isDef(vnode.asyncFactory)) {
	            vnode.isAsyncPlaceholder = true;
	            return true;
	        }
	        if (isDef(data)) {
	            if (isDef(i = data.hook) && isDef(i = i.init)) {
	                i(vnode, true);
	            }
	            if (isDef(i = vnode.componentInstance)) {
	                initComponent(vnode, insertedVnodeQueue);
	                return true;
	            }
	        }
	        if (isDef(tag)) {
	            if (isDef(children)) {
	                if (!elm.hasChildNodes()) {
	                    createChildren(vnode, children, insertedVnodeQueue);
	                } else {
	                    if (isDef(i = data) && isDef(i = i.domProps) && isDef(i = i.innerHTML)) {
	                        if (i !== elm.innerHTML) {
	                            return false;
	                        }
	                    } else {
	                        var childrenMatch = true;
	                        var childNode = elm.firstChild;
	                        for (var i$1 = 0;i$1 < children.length; i$1++) {
	                            if (!childNode || !hydrate(childNode, children[i$1], insertedVnodeQueue, inVPre)) {
	                                childrenMatch = false;
	                                break;
	                            }
	                            childNode = childNode.nextSibling;
	                        }
	                        if (!childrenMatch || childNode) {
	                            return false;
	                        }
	                    }
	                }
	            }
	            if (isDef(data)) {
	                var fullInvoke = false;
	                for (var key in data) {
	                    if (!isRenderedModule(key)) {
	                        fullInvoke = true;
	                        invokeCreateHooks(vnode, insertedVnodeQueue);
	                        break;
	                    }
	                }
	                if (!fullInvoke && data['class']) {
	                    traverse(data['class']);
	                }
	            }
	        } else if (elm.data !== vnode.text) {
	            elm.data = vnode.text;
	        }
	        return true;
	    }
	    
	    return function patch(oldVnode, vnode, hydrating, removeOnly) {
	        if (isUndef(vnode)) {
	            if (isDef(oldVnode)) {
	                invokeDestroyHook(oldVnode);
	            }
	            return;
	        }
	        var isInitialPatch = false;
	        var insertedVnodeQueue = [];
	        if (isUndef(oldVnode)) {
	            isInitialPatch = true;
	            createElm(vnode, insertedVnodeQueue);
	        } else {
	            var isRealElement = isDef(oldVnode.nodeType);
	            if (!isRealElement && sameVnode(oldVnode, vnode)) {
	                patchVnode(oldVnode, vnode, insertedVnodeQueue, null, null, removeOnly);
	            } else {
	                if (isRealElement) {
	                    if (oldVnode.nodeType === 1 && oldVnode.hasAttribute(SSR_ATTR)) {
	                        oldVnode.removeAttribute(SSR_ATTR);
	                        hydrating = true;
	                    }
	                    if (isTrue(hydrating)) {
	                        if (hydrate(oldVnode, vnode, insertedVnodeQueue)) {
	                            invokeInsertHook(vnode, insertedVnodeQueue, true);
	                            return oldVnode;
	                        }
	                    }
	                    oldVnode = emptyNodeAt(oldVnode);
	                }
	                var oldElm = oldVnode.elm;
	                var parentElm = nodeOps.parentNode(oldElm);
	                createElm(vnode, insertedVnodeQueue, oldElm._leaveCb ? null : parentElm, nodeOps.nextSibling(oldElm));
	                if (isDef(vnode.parent)) {
	                    var ancestor = vnode.parent;
	                    var patchable = isPatchable(vnode);
	                    while (ancestor) {
	                        for (var i = 0;i < cbs.destroy.length; ++i) {
	                            cbs.destroy[i](ancestor);
	                        }
	                        ancestor.elm = vnode.elm;
	                        if (patchable) {
	                            for (var i$1 = 0;i$1 < cbs.create.length; ++i$1) {
	                                cbs.create[i$1](emptyNode, ancestor);
	                            }
	                            var insert = ancestor.data.hook.insert;
	                            if (insert.merged) {
	                                for (var i$2 = 1;i$2 < insert.fns.length; i$2++) {
	                                    insert.fns[i$2]();
	                                }
	                            }
	                        } else {
	                            registerRef(ancestor);
	                        }
	                        ancestor = ancestor.parent;
	                    }
	                }
	                if (isDef(parentElm)) {
	                    removeVnodes(parentElm, [oldVnode], 0, 0);
	                } else if (isDef(oldVnode.tag)) {
	                    invokeDestroyHook(oldVnode);
	                }
	            }
	        }
	        invokeInsertHook(vnode, insertedVnodeQueue, isInitialPatch);
	        return vnode.elm;
	    };
	}

	var directives = {
	    create: updateDirectives,
	    update: updateDirectives,
	    destroy: function unbindDirectives(vnode) {
	        updateDirectives(vnode, emptyNode);
	    }
	};
	function updateDirectives(oldVnode, vnode) {
	    if (oldVnode.data.directives || vnode.data.directives) {
	        _update(oldVnode, vnode);
	    }
	}

	function _update(oldVnode, vnode) {
	    var isCreate = oldVnode === emptyNode;
	    var isDestroy = vnode === emptyNode;
	    var oldDirs = normalizeDirectives$1(oldVnode.data.directives, oldVnode.context);
	    var newDirs = normalizeDirectives$1(vnode.data.directives, vnode.context);
	    var dirsWithInsert = [];
	    var dirsWithPostpatch = [];
	    var key, oldDir, dir;
	    for (key in newDirs) {
	        oldDir = oldDirs[key];
	        dir = newDirs[key];
	        if (!oldDir) {
	            callHook$1(dir, 'bind', vnode, oldVnode);
	            if (dir.def && dir.def.inserted) {
	                dirsWithInsert.push(dir);
	            }
	        } else {
	            dir.oldValue = oldDir.value;
	            dir.oldArg = oldDir.arg;
	            callHook$1(dir, 'update', vnode, oldVnode);
	            if (dir.def && dir.def.componentUpdated) {
	                dirsWithPostpatch.push(dir);
	            }
	        }
	    }
	    if (dirsWithInsert.length) {
	        var callInsert = function () {
	            for (var i = 0;i < dirsWithInsert.length; i++) {
	                callHook$1(dirsWithInsert[i], 'inserted', vnode, oldVnode);
	            }
	        };
	        if (isCreate) {
	            mergeVNodeHook(vnode, 'insert', callInsert);
	        } else {
	            callInsert();
	        }
	    }
	    if (dirsWithPostpatch.length) {
	        mergeVNodeHook(vnode, 'postpatch', function () {
	            for (var i = 0;i < dirsWithPostpatch.length; i++) {
	                callHook$1(dirsWithPostpatch[i], 'componentUpdated', vnode, oldVnode);
	            }
	        });
	    }
	    if (!isCreate) {
	        for (key in oldDirs) {
	            if (!newDirs[key]) {
	                callHook$1(oldDirs[key], 'unbind', oldVnode, oldVnode, isDestroy);
	            }
	        }
	    }
	}

	var emptyModifiers = Object.create(null);
	function normalizeDirectives$1(dirs, vm) {
	    var res = Object.create(null);
	    if (!dirs) {
	        return res;
	    }
	    var i, dir;
	    for (i = 0; i < dirs.length; i++) {
	        dir = dirs[i];
	        if (!dir.modifiers) {
	            dir.modifiers = emptyModifiers;
	        }
	        res[getRawDirName(dir)] = dir;
	        dir.def = resolveAsset(vm.$options, 'directives', dir.name);
	    }
	    return res;
	}

	function getRawDirName(dir) {
	    return dir.rawName || dir.name + "." + Object.keys(dir.modifiers || {}).join('.');
	}

	function callHook$1(dir, hook, vnode, oldVnode, isDestroy) {
	    var fn = dir.def && dir.def[hook];
	    if (fn) {
	        try {
	            fn(vnode.elm, dir, vnode, oldVnode, isDestroy);
	        } catch (e) {
	            handleError(e, vnode.context, "directive " + dir.name + " " + hook + " hook");
	        }
	    }
	}

	var baseModules = [ref,directives];
	function updateAttrs(oldVnode, vnode) {
	    var opts = vnode.componentOptions;
	    if (isDef(opts) && opts.Ctor.options.inheritAttrs === false) {
	        return;
	    }
	    if (isUndef(oldVnode.data.attrs) && isUndef(vnode.data.attrs)) {
	        return;
	    }
	    var key, cur, old;
	    var elm = vnode.elm;
	    var oldAttrs = oldVnode.data.attrs || {};
	    var attrs = vnode.data.attrs || {};
	    if (isDef(attrs.__ob__)) {
	        attrs = (vnode.data.attrs = extend({}, attrs));
	    }
	    for (key in attrs) {
	        cur = attrs[key];
	        old = oldAttrs[key];
	        if (old !== cur) {
	            setAttr(elm, key, cur);
	        }
	    }
	    if ((isIE || isEdge) && attrs.value !== oldAttrs.value) {
	        setAttr(elm, 'value', attrs.value);
	    }
	    for (key in oldAttrs) {
	        if (isUndef(attrs[key])) {
	            if (isXlink(key)) {
	                elm.removeAttributeNS(xlinkNS, getXlinkProp(key));
	            } else if (!isEnumeratedAttr(key)) {
	                elm.removeAttribute(key);
	            }
	        }
	    }
	}

	function setAttr(el, key, value) {
	    if (el.tagName.indexOf('-') > -1) {
	        baseSetAttr(el, key, value);
	    } else if (isBooleanAttr(key)) {
	        if (isFalsyAttrValue(value)) {
	            el.removeAttribute(key);
	        } else {
	            value = key === 'allowfullscreen' && el.tagName === 'EMBED' ? 'true' : key;
	            el.setAttribute(key, value);
	        }
	    } else if (isEnumeratedAttr(key)) {
	        el.setAttribute(key, convertEnumeratedValue(key, value));
	    } else if (isXlink(key)) {
	        if (isFalsyAttrValue(value)) {
	            el.removeAttributeNS(xlinkNS, getXlinkProp(key));
	        } else {
	            el.setAttributeNS(xlinkNS, key, value);
	        }
	    } else {
	        baseSetAttr(el, key, value);
	    }
	}

	function baseSetAttr(el, key, value) {
	    if (isFalsyAttrValue(value)) {
	        el.removeAttribute(key);
	    } else {
	        if (isIE && !isIE9 && el.tagName === 'TEXTAREA' && key === 'placeholder' && value !== '' && !el.__ieph) {
	            var blocker = function (e) {
	                e.stopImmediatePropagation();
	                el.removeEventListener('input', blocker);
	            };
	            el.addEventListener('input', blocker);
	            el.__ieph = true;
	        }
	        el.setAttribute(key, value);
	    }
	}

	var attrs = {
	    create: updateAttrs,
	    update: updateAttrs
	};
	function updateClass(oldVnode, vnode) {
	    var el = vnode.elm;
	    var data = vnode.data;
	    var oldData = oldVnode.data;
	    if (isUndef(data.staticClass) && isUndef(data.class) && (isUndef(oldData) || isUndef(oldData.staticClass) && isUndef(oldData.class))) {
	        return;
	    }
	    var cls = genClassForVnode(vnode);
	    var transitionClass = el._transitionClasses;
	    if (isDef(transitionClass)) {
	        cls = concat(cls, stringifyClass(transitionClass));
	    }
	    if (cls !== el._prevClass) {
	        el.setAttribute('class', cls);
	        el._prevClass = cls;
	    }
	}

	var klass = {
	    create: updateClass,
	    update: updateClass
	};
	var RANGE_TOKEN = '__r';
	var CHECKBOX_RADIO_TOKEN = '__c';
	function normalizeEvents(on) {
	    if (isDef(on[RANGE_TOKEN])) {
	        var event = isIE ? 'change' : 'input';
	        on[event] = [].concat(on[RANGE_TOKEN], on[event] || []);
	        delete on[RANGE_TOKEN];
	    }
	    if (isDef(on[CHECKBOX_RADIO_TOKEN])) {
	        on.change = [].concat(on[CHECKBOX_RADIO_TOKEN], on.change || []);
	        delete on[CHECKBOX_RADIO_TOKEN];
	    }
	}

	var target$1;
	function createOnceHandler$1(event, handler, capture) {
	    var _target = target$1;
	    return function onceHandler() {
	        var res = handler.apply(null, arguments);
	        if (res !== null) {
	            remove$2(event, onceHandler, capture, _target);
	        }
	    };
	}

	var useMicrotaskFix = isUsingMicroTask && !(isFF && Number(isFF[1]) <= 53);
	function add$1(name, handler, capture, passive) {
	    if (useMicrotaskFix) {
	        var attachedTimestamp = currentFlushTimestamp;
	        var original = handler;
	        handler = (original._wrapper = function (e) {
	            if (e.target === e.currentTarget || e.timeStamp >= attachedTimestamp || e.timeStamp <= 0 || e.target.ownerDocument !== document) {
	                return original.apply(this, arguments);
	            }
	        });
	    }
	    target$1.addEventListener(name, handler, supportsPassive ? {
	        capture: capture,
	        passive: passive
	    } : capture);
	}

	function remove$2(name, handler, capture, _target) {
	    (_target || target$1).removeEventListener(name, handler._wrapper || handler, capture);
	}

	function updateDOMListeners(oldVnode, vnode) {
	    if (isUndef(oldVnode.data.on) && isUndef(vnode.data.on)) {
	        return;
	    }
	    var on = vnode.data.on || {};
	    var oldOn = oldVnode.data.on || {};
	    target$1 = vnode.elm;
	    normalizeEvents(on);
	    updateListeners(on, oldOn, add$1, remove$2, createOnceHandler$1, vnode.context);
	    target$1 = undefined;
	}

	var events = {
	    create: updateDOMListeners,
	    update: updateDOMListeners
	};
	var svgContainer;
	function updateDOMProps(oldVnode, vnode) {
	    if (isUndef(oldVnode.data.domProps) && isUndef(vnode.data.domProps)) {
	        return;
	    }
	    var key, cur;
	    var elm = vnode.elm;
	    var oldProps = oldVnode.data.domProps || {};
	    var props = vnode.data.domProps || {};
	    if (isDef(props.__ob__)) {
	        props = (vnode.data.domProps = extend({}, props));
	    }
	    for (key in oldProps) {
	        if (!(key in props)) {
	            elm[key] = '';
	        }
	    }
	    for (key in props) {
	        cur = props[key];
	        if (key === 'textContent' || key === 'innerHTML') {
	            if (vnode.children) {
	                vnode.children.length = 0;
	            }
	            if (cur === oldProps[key]) {
	                continue;
	            }
	            if (elm.childNodes.length === 1) {
	                elm.removeChild(elm.childNodes[0]);
	            }
	        }
	        if (key === 'value' && elm.tagName !== 'PROGRESS') {
	            elm._value = cur;
	            var strCur = isUndef(cur) ? '' : String(cur);
	            if (shouldUpdateValue(elm, strCur)) {
	                elm.value = strCur;
	            }
	        } else if (key === 'innerHTML' && isSVG(elm.tagName) && isUndef(elm.innerHTML)) {
	            svgContainer = svgContainer || document.createElement('div');
	            svgContainer.innerHTML = "<svg>" + cur + "</svg>";
	            var svg = svgContainer.firstChild;
	            while (elm.firstChild) {
	                elm.removeChild(elm.firstChild);
	            }
	            while (svg.firstChild) {
	                elm.appendChild(svg.firstChild);
	            }
	        } else if (cur !== oldProps[key]) {
	            try {
	                elm[key] = cur;
	            } catch (e) {}
	        }
	    }
	}

	function shouldUpdateValue(elm, checkVal) {
	    return !elm.composing && (elm.tagName === 'OPTION' || isNotInFocusAndDirty(elm, checkVal) || isDirtyWithModifiers(elm, checkVal));
	}

	function isNotInFocusAndDirty(elm, checkVal) {
	    var notInFocus = true;
	    try {
	        notInFocus = document.activeElement !== elm;
	    } catch (e) {}
	    return notInFocus && elm.value !== checkVal;
	}

	function isDirtyWithModifiers(elm, newVal) {
	    var value = elm.value;
	    var modifiers = elm._vModifiers;
	    if (isDef(modifiers)) {
	        if (modifiers.number) {
	            return toNumber$1(value) !== toNumber$1(newVal);
	        }
	        if (modifiers.trim) {
	            return value.trim() !== newVal.trim();
	        }
	    }
	    return value !== newVal;
	}

	var domProps = {
	    create: updateDOMProps,
	    update: updateDOMProps
	};
	var parseStyleText = cached(function (cssText) {
	    var res = {};
	    var listDelimiter = /;(?![^(]*\))/g;
	    var propertyDelimiter = /:(.+)/;
	    cssText.split(listDelimiter).forEach(function (item) {
	        if (item) {
	            var tmp = item.split(propertyDelimiter);
	            tmp.length > 1 && (res[tmp[0].trim()] = tmp[1].trim());
	        }
	    });
	    return res;
	});
	function normalizeStyleData(data) {
	    var style = normalizeStyleBinding(data.style);
	    return data.staticStyle ? extend(data.staticStyle, style) : style;
	}

	function normalizeStyleBinding(bindingStyle) {
	    if (Array.isArray(bindingStyle)) {
	        return toObject$1(bindingStyle);
	    }
	    if (typeof bindingStyle === 'string') {
	        return parseStyleText(bindingStyle);
	    }
	    return bindingStyle;
	}

	function getStyle(vnode, checkChild) {
	    var res = {};
	    var styleData;
	    if (checkChild) {
	        var childNode = vnode;
	        while (childNode.componentInstance) {
	            childNode = childNode.componentInstance._vnode;
	            if (childNode && childNode.data && (styleData = normalizeStyleData(childNode.data))) {
	                extend(res, styleData);
	            }
	        }
	    }
	    if (styleData = normalizeStyleData(vnode.data)) {
	        extend(res, styleData);
	    }
	    var parentNode = vnode;
	    while (parentNode = parentNode.parent) {
	        if (parentNode.data && (styleData = normalizeStyleData(parentNode.data))) {
	            extend(res, styleData);
	        }
	    }
	    return res;
	}

	var cssVarRE = /^--/;
	var importantRE = /\s*!important$/;
	var setProp = function (el, name, val) {
	    if (cssVarRE.test(name)) {
	        el.style.setProperty(name, val);
	    } else if (importantRE.test(val)) {
	        el.style.setProperty(hyphenate(name), val.replace(importantRE, ''), 'important');
	    } else {
	        var normalizedName = normalize$1(name);
	        if (Array.isArray(val)) {
	            for (var i = 0, len = val.length;i < len; i++) {
	                el.style[normalizedName] = val[i];
	            }
	        } else {
	            el.style[normalizedName] = val;
	        }
	    }
	};
	var vendorNames = ['Webkit','Moz','ms'];
	var emptyStyle;
	var normalize$1 = cached(function (prop) {
	    emptyStyle = emptyStyle || document.createElement('div').style;
	    prop = camelize(prop);
	    if (prop !== 'filter' && prop in emptyStyle) {
	        return prop;
	    }
	    var capName = prop.charAt(0).toUpperCase() + prop.slice(1);
	    for (var i = 0;i < vendorNames.length; i++) {
	        var name = vendorNames[i] + capName;
	        if (name in emptyStyle) {
	            return name;
	        }
	    }
	});
	function updateStyle(oldVnode, vnode) {
	    var data = vnode.data;
	    var oldData = oldVnode.data;
	    if (isUndef(data.staticStyle) && isUndef(data.style) && isUndef(oldData.staticStyle) && isUndef(oldData.style)) {
	        return;
	    }
	    var cur, name;
	    var el = vnode.elm;
	    var oldStaticStyle = oldData.staticStyle;
	    var oldStyleBinding = oldData.normalizedStyle || oldData.style || {};
	    var oldStyle = oldStaticStyle || oldStyleBinding;
	    var style = normalizeStyleBinding(vnode.data.style) || {};
	    vnode.data.normalizedStyle = isDef(style.__ob__) ? extend({}, style) : style;
	    var newStyle = getStyle(vnode, true);
	    for (name in oldStyle) {
	        if (isUndef(newStyle[name])) {
	            setProp(el, name, '');
	        }
	    }
	    for (name in newStyle) {
	        cur = newStyle[name];
	        if (cur !== oldStyle[name]) {
	            setProp(el, name, cur == null ? '' : cur);
	        }
	    }
	}

	var style = {
	    create: updateStyle,
	    update: updateStyle
	};
	var whitespaceRE = /\s+/;
	function addClass(el, cls) {
	    if (!cls || !(cls = cls.trim())) {
	        return;
	    }
	    if (el.classList) {
	        if (cls.indexOf(' ') > -1) {
	            cls.split(whitespaceRE).forEach(function (c) {
	                return el.classList.add(c);
	            });
	        } else {
	            el.classList.add(cls);
	        }
	    } else {
	        var cur = " " + (el.getAttribute('class') || '') + " ";
	        if (cur.indexOf(' ' + cls + ' ') < 0) {
	            el.setAttribute('class', (cur + cls).trim());
	        }
	    }
	}

	function removeClass(el, cls) {
	    if (!cls || !(cls = cls.trim())) {
	        return;
	    }
	    if (el.classList) {
	        if (cls.indexOf(' ') > -1) {
	            cls.split(whitespaceRE).forEach(function (c) {
	                return el.classList.remove(c);
	            });
	        } else {
	            el.classList.remove(cls);
	        }
	        if (!el.classList.length) {
	            el.removeAttribute('class');
	        }
	    } else {
	        var cur = " " + (el.getAttribute('class') || '') + " ";
	        var tar = ' ' + cls + ' ';
	        while (cur.indexOf(tar) >= 0) {
	            cur = cur.replace(tar, ' ');
	        }
	        cur = cur.trim();
	        if (cur) {
	            el.setAttribute('class', cur);
	        } else {
	            el.removeAttribute('class');
	        }
	    }
	}

	function resolveTransition(def$$1) {
	    if (!def$$1) {
	        return;
	    }
	    if (typeof def$$1 === 'object') {
	        var res = {};
	        if (def$$1.css !== false) {
	            extend(res, autoCssTransition(def$$1.name || 'v'));
	        }
	        extend(res, def$$1);
	        return res;
	    } else if (typeof def$$1 === 'string') {
	        return autoCssTransition(def$$1);
	    }
	}

	var autoCssTransition = cached(function (name) {
	    return {
	        enterClass: name + "-enter",
	        enterToClass: name + "-enter-to",
	        enterActiveClass: name + "-enter-active",
	        leaveClass: name + "-leave",
	        leaveToClass: name + "-leave-to",
	        leaveActiveClass: name + "-leave-active"
	    };
	});
	var hasTransition = inBrowser && !isIE9;
	var TRANSITION = 'transition';
	var ANIMATION = 'animation';
	var transitionProp = 'transition';
	var transitionEndEvent = 'transitionend';
	var animationProp = 'animation';
	var animationEndEvent = 'animationend';
	if (hasTransition) {
	    if (window.ontransitionend === undefined && window.onwebkittransitionend !== undefined) {
	        transitionProp = 'WebkitTransition';
	        transitionEndEvent = 'webkitTransitionEnd';
	    }
	    if (window.onanimationend === undefined && window.onwebkitanimationend !== undefined) {
	        animationProp = 'WebkitAnimation';
	        animationEndEvent = 'webkitAnimationEnd';
	    }
	}
	var raf = inBrowser ? window.requestAnimationFrame ? window.requestAnimationFrame.bind(window) : setTimeout : function (fn) {
	    return fn();
	};
	function nextFrame(fn) {
	    raf(function () {
	        raf(fn);
	    });
	}

	function addTransitionClass(el, cls) {
	    var transitionClasses = el._transitionClasses || (el._transitionClasses = []);
	    if (transitionClasses.indexOf(cls) < 0) {
	        transitionClasses.push(cls);
	        addClass(el, cls);
	    }
	}

	function removeTransitionClass(el, cls) {
	    if (el._transitionClasses) {
	        remove(el._transitionClasses, cls);
	    }
	    removeClass(el, cls);
	}

	function whenTransitionEnds(el, expectedType, cb) {
	    var ref = getTransitionInfo(el, expectedType);
	    var type = ref.type;
	    var timeout = ref.timeout;
	    var propCount = ref.propCount;
	    if (!type) {
	        return cb();
	    }
	    var event = type === TRANSITION ? transitionEndEvent : animationEndEvent;
	    var ended = 0;
	    var end = function () {
	        el.removeEventListener(event, onEnd);
	        cb();
	    };
	    var onEnd = function (e) {
	        if (e.target === el) {
	            if (++ended >= propCount) {
	                end();
	            }
	        }
	    };
	    setTimeout(function () {
	        if (ended < propCount) {
	            end();
	        }
	    }, timeout + 1);
	    el.addEventListener(event, onEnd);
	}

	var transformRE = /\b(transform|all)(,|$)/;
	function getTransitionInfo(el, expectedType) {
	    var styles = window.getComputedStyle(el);
	    var transitionDelays = (styles[transitionProp + 'Delay'] || '').split(', ');
	    var transitionDurations = (styles[transitionProp + 'Duration'] || '').split(', ');
	    var transitionTimeout = getTimeout(transitionDelays, transitionDurations);
	    var animationDelays = (styles[animationProp + 'Delay'] || '').split(', ');
	    var animationDurations = (styles[animationProp + 'Duration'] || '').split(', ');
	    var animationTimeout = getTimeout(animationDelays, animationDurations);
	    var type;
	    var timeout = 0;
	    var propCount = 0;
	    if (expectedType === TRANSITION) {
	        if (transitionTimeout > 0) {
	            type = TRANSITION;
	            timeout = transitionTimeout;
	            propCount = transitionDurations.length;
	        }
	    } else if (expectedType === ANIMATION) {
	        if (animationTimeout > 0) {
	            type = ANIMATION;
	            timeout = animationTimeout;
	            propCount = animationDurations.length;
	        }
	    } else {
	        timeout = Math.max(transitionTimeout, animationTimeout);
	        type = timeout > 0 ? transitionTimeout > animationTimeout ? TRANSITION : ANIMATION : null;
	        propCount = type ? type === TRANSITION ? transitionDurations.length : animationDurations.length : 0;
	    }
	    var hasTransform = type === TRANSITION && transformRE.test(styles[transitionProp + 'Property']);
	    return {
	        type: type,
	        timeout: timeout,
	        propCount: propCount,
	        hasTransform: hasTransform
	    };
	}

	function getTimeout(delays, durations) {
	    while (delays.length < durations.length) {
	        delays = delays.concat(delays);
	    }
	    return Math.max.apply(null, durations.map(function (d, i) {
	        return toMs(d) + toMs(delays[i]);
	    }));
	}

	function toMs(s) {
	    return Number(s.slice(0, -1).replace(',', '.')) * 1000;
	}

	function enter(vnode, toggleDisplay) {
	    var el = vnode.elm;
	    if (isDef(el._leaveCb)) {
	        el._leaveCb.cancelled = true;
	        el._leaveCb();
	    }
	    var data = resolveTransition(vnode.data.transition);
	    if (isUndef(data)) {
	        return;
	    }
	    if (isDef(el._enterCb) || el.nodeType !== 1) {
	        return;
	    }
	    var css = data.css;
	    var type = data.type;
	    var enterClass = data.enterClass;
	    var enterToClass = data.enterToClass;
	    var enterActiveClass = data.enterActiveClass;
	    var appearClass = data.appearClass;
	    var appearToClass = data.appearToClass;
	    var appearActiveClass = data.appearActiveClass;
	    var beforeEnter = data.beforeEnter;
	    var enter = data.enter;
	    var afterEnter = data.afterEnter;
	    var enterCancelled = data.enterCancelled;
	    var beforeAppear = data.beforeAppear;
	    var appear = data.appear;
	    var afterAppear = data.afterAppear;
	    var appearCancelled = data.appearCancelled;
	    var duration = data.duration;
	    var context = activeInstance;
	    var transitionNode = activeInstance.$vnode;
	    while (transitionNode && transitionNode.parent) {
	        context = transitionNode.context;
	        transitionNode = transitionNode.parent;
	    }
	    var isAppear = !context._isMounted || !vnode.isRootInsert;
	    if (isAppear && !appear && appear !== '') {
	        return;
	    }
	    var startClass = isAppear && appearClass ? appearClass : enterClass;
	    var activeClass = isAppear && appearActiveClass ? appearActiveClass : enterActiveClass;
	    var toClass = isAppear && appearToClass ? appearToClass : enterToClass;
	    var beforeEnterHook = isAppear ? beforeAppear || beforeEnter : beforeEnter;
	    var enterHook = isAppear ? typeof appear === 'function' ? appear : enter : enter;
	    var afterEnterHook = isAppear ? afterAppear || afterEnter : afterEnter;
	    var enterCancelledHook = isAppear ? appearCancelled || enterCancelled : enterCancelled;
	    var explicitEnterDuration = toNumber$1(isObject$1(duration) ? duration.enter : duration);
	    var expectsCSS = css !== false && !isIE9;
	    var userWantsControl = getHookArgumentsLength(enterHook);
	    var cb = el._enterCb = once(function () {
	        if (expectsCSS) {
	            removeTransitionClass(el, toClass);
	            removeTransitionClass(el, activeClass);
	        }
	        if (cb.cancelled) {
	            if (expectsCSS) {
	                removeTransitionClass(el, startClass);
	            }
	            enterCancelledHook && enterCancelledHook(el);
	        } else {
	            afterEnterHook && afterEnterHook(el);
	        }
	        el._enterCb = null;
	    });
	    if (!vnode.data.show) {
	        mergeVNodeHook(vnode, 'insert', function () {
	            var parent = el.parentNode;
	            var pendingNode = parent && parent._pending && parent._pending[vnode.key];
	            if (pendingNode && pendingNode.tag === vnode.tag && pendingNode.elm._leaveCb) {
	                pendingNode.elm._leaveCb();
	            }
	            enterHook && enterHook(el, cb);
	        });
	    }
	    beforeEnterHook && beforeEnterHook(el);
	    if (expectsCSS) {
	        addTransitionClass(el, startClass);
	        addTransitionClass(el, activeClass);
	        nextFrame(function () {
	            removeTransitionClass(el, startClass);
	            if (!cb.cancelled) {
	                addTransitionClass(el, toClass);
	                if (!userWantsControl) {
	                    if (isValidDuration(explicitEnterDuration)) {
	                        setTimeout(cb, explicitEnterDuration);
	                    } else {
	                        whenTransitionEnds(el, type, cb);
	                    }
	                }
	            }
	        });
	    }
	    if (vnode.data.show) {
	        toggleDisplay && toggleDisplay();
	        enterHook && enterHook(el, cb);
	    }
	    if (!expectsCSS && !userWantsControl) {
	        cb();
	    }
	}

	function leave(vnode, rm) {
	    var el = vnode.elm;
	    if (isDef(el._enterCb)) {
	        el._enterCb.cancelled = true;
	        el._enterCb();
	    }
	    var data = resolveTransition(vnode.data.transition);
	    if (isUndef(data) || el.nodeType !== 1) {
	        return rm();
	    }
	    if (isDef(el._leaveCb)) {
	        return;
	    }
	    var css = data.css;
	    var type = data.type;
	    var leaveClass = data.leaveClass;
	    var leaveToClass = data.leaveToClass;
	    var leaveActiveClass = data.leaveActiveClass;
	    var beforeLeave = data.beforeLeave;
	    var leave = data.leave;
	    var afterLeave = data.afterLeave;
	    var leaveCancelled = data.leaveCancelled;
	    var delayLeave = data.delayLeave;
	    var duration = data.duration;
	    var expectsCSS = css !== false && !isIE9;
	    var userWantsControl = getHookArgumentsLength(leave);
	    var explicitLeaveDuration = toNumber$1(isObject$1(duration) ? duration.leave : duration);
	    var cb = el._leaveCb = once(function () {
	        if (el.parentNode && el.parentNode._pending) {
	            el.parentNode._pending[vnode.key] = null;
	        }
	        if (expectsCSS) {
	            removeTransitionClass(el, leaveToClass);
	            removeTransitionClass(el, leaveActiveClass);
	        }
	        if (cb.cancelled) {
	            if (expectsCSS) {
	                removeTransitionClass(el, leaveClass);
	            }
	            leaveCancelled && leaveCancelled(el);
	        } else {
	            rm();
	            afterLeave && afterLeave(el);
	        }
	        el._leaveCb = null;
	    });
	    if (delayLeave) {
	        delayLeave(performLeave);
	    } else {
	        performLeave();
	    }
	    function performLeave() {
	        if (cb.cancelled) {
	            return;
	        }
	        if (!vnode.data.show && el.parentNode) {
	            (el.parentNode._pending || (el.parentNode._pending = {}))[vnode.key] = vnode;
	        }
	        beforeLeave && beforeLeave(el);
	        if (expectsCSS) {
	            addTransitionClass(el, leaveClass);
	            addTransitionClass(el, leaveActiveClass);
	            nextFrame(function () {
	                removeTransitionClass(el, leaveClass);
	                if (!cb.cancelled) {
	                    addTransitionClass(el, leaveToClass);
	                    if (!userWantsControl) {
	                        if (isValidDuration(explicitLeaveDuration)) {
	                            setTimeout(cb, explicitLeaveDuration);
	                        } else {
	                            whenTransitionEnds(el, type, cb);
	                        }
	                    }
	                }
	            });
	        }
	        leave && leave(el, cb);
	        if (!expectsCSS && !userWantsControl) {
	            cb();
	        }
	    }
	    
	}

	function isValidDuration(val) {
	    return typeof val === 'number' && !isNaN(val);
	}

	function getHookArgumentsLength(fn) {
	    if (isUndef(fn)) {
	        return false;
	    }
	    var invokerFns = fn.fns;
	    if (isDef(invokerFns)) {
	        return getHookArgumentsLength(Array.isArray(invokerFns) ? invokerFns[0] : invokerFns);
	    } else {
	        return (fn._length || fn.length) > 1;
	    }
	}

	function _enter(_, vnode) {
	    if (vnode.data.show !== true) {
	        enter(vnode);
	    }
	}

	var transition = inBrowser ? {
	    create: _enter,
	    activate: _enter,
	    remove: function remove$$1(vnode, rm) {
	        if (vnode.data.show !== true) {
	            leave(vnode, rm);
	        } else {
	            rm();
	        }
	    }
	} : {};
	var platformModules = [attrs,klass,events,domProps,style,transition];
	var modules = platformModules.concat(baseModules);
	var patch = createPatchFunction({
	    nodeOps: nodeOps,
	    modules: modules
	});
	if (isIE9) {
	    document.addEventListener('selectionchange', function () {
	        var el = document.activeElement;
	        if (el && el.vmodel) {
	            trigger(el, 'input');
	        }
	    });
	}
	var directive = {
	    inserted: function inserted(el, binding, vnode, oldVnode) {
	        if (vnode.tag === 'select') {
	            if (oldVnode.elm && !oldVnode.elm._vOptions) {
	                mergeVNodeHook(vnode, 'postpatch', function () {
	                    directive.componentUpdated(el, binding, vnode);
	                });
	            } else {
	                setSelected(el, binding, vnode.context);
	            }
	            el._vOptions = [].map.call(el.options, getValue);
	        } else if (vnode.tag === 'textarea' || isTextInputType(el.type)) {
	            el._vModifiers = binding.modifiers;
	            if (!binding.modifiers.lazy) {
	                el.addEventListener('compositionstart', onCompositionStart);
	                el.addEventListener('compositionend', onCompositionEnd);
	                el.addEventListener('change', onCompositionEnd);
	                if (isIE9) {
	                    el.vmodel = true;
	                }
	            }
	        }
	    },
	    componentUpdated: function componentUpdated(el, binding, vnode) {
	        if (vnode.tag === 'select') {
	            setSelected(el, binding, vnode.context);
	            var prevOptions = el._vOptions;
	            var curOptions = el._vOptions = [].map.call(el.options, getValue);
	            if (curOptions.some(function (o, i) {
	                return !looseEqual(o, prevOptions[i]);
	            })) {
	                var needReset = el.multiple ? binding.value.some(function (v) {
	                    return hasNoMatchingOption(v, curOptions);
	                }) : binding.value !== binding.oldValue && hasNoMatchingOption(binding.value, curOptions);
	                if (needReset) {
	                    trigger(el, 'change');
	                }
	            }
	        }
	    }
	};
	function setSelected(el, binding, vm) {
	    actuallySetSelected(el, binding);
	    if (isIE || isEdge) {
	        setTimeout(function () {
	            actuallySetSelected(el, binding);
	        }, 0);
	    }
	}

	function actuallySetSelected(el, binding, vm) {
	    var value = binding.value;
	    var isMultiple = el.multiple;
	    if (isMultiple && !Array.isArray(value)) {
	        return;
	    }
	    var selected, option;
	    for (var i = 0, l = el.options.length;i < l; i++) {
	        option = el.options[i];
	        if (isMultiple) {
	            selected = looseIndexOf(value, getValue(option)) > -1;
	            if (option.selected !== selected) {
	                option.selected = selected;
	            }
	        } else {
	            if (looseEqual(getValue(option), value)) {
	                if (el.selectedIndex !== i) {
	                    el.selectedIndex = i;
	                }
	                return;
	            }
	        }
	    }
	    if (!isMultiple) {
	        el.selectedIndex = -1;
	    }
	}

	function hasNoMatchingOption(value, options) {
	    return options.every(function (o) {
	        return !looseEqual(o, value);
	    });
	}

	function getValue(option) {
	    return '_value' in option ? option._value : option.value;
	}

	function onCompositionStart(e) {
	    e.target.composing = true;
	}

	function onCompositionEnd(e) {
	    if (!e.target.composing) {
	        return;
	    }
	    e.target.composing = false;
	    trigger(e.target, 'input');
	}

	function trigger(el, type) {
	    var e = document.createEvent('HTMLEvents');
	    e.initEvent(type, true, true);
	    el.dispatchEvent(e);
	}

	function locateNode(vnode) {
	    return vnode.componentInstance && (!vnode.data || !vnode.data.transition) ? locateNode(vnode.componentInstance._vnode) : vnode;
	}

	var show = {
	    bind: function bind(el, ref, vnode) {
	        var value = ref.value;
	        vnode = locateNode(vnode);
	        var transition$$1 = vnode.data && vnode.data.transition;
	        var originalDisplay = el.__vOriginalDisplay = el.style.display === 'none' ? '' : el.style.display;
	        if (value && transition$$1) {
	            vnode.data.show = true;
	            enter(vnode, function () {
	                el.style.display = originalDisplay;
	            });
	        } else {
	            el.style.display = value ? originalDisplay : 'none';
	        }
	    },
	    update: function update(el, ref, vnode) {
	        var value = ref.value;
	        var oldValue = ref.oldValue;
	        if (!value === !oldValue) {
	            return;
	        }
	        vnode = locateNode(vnode);
	        var transition$$1 = vnode.data && vnode.data.transition;
	        if (transition$$1) {
	            vnode.data.show = true;
	            if (value) {
	                enter(vnode, function () {
	                    el.style.display = el.__vOriginalDisplay;
	                });
	            } else {
	                leave(vnode, function () {
	                    el.style.display = 'none';
	                });
	            }
	        } else {
	            el.style.display = value ? el.__vOriginalDisplay : 'none';
	        }
	    },
	    unbind: function unbind(el, binding, vnode, oldVnode, isDestroy) {
	        if (!isDestroy) {
	            el.style.display = el.__vOriginalDisplay;
	        }
	    }
	};
	var platformDirectives = {
	    model: directive,
	    show: show
	};
	var transitionProps = {
	    name: String,
	    appear: Boolean,
	    css: Boolean,
	    mode: String,
	    type: String,
	    enterClass: String,
	    leaveClass: String,
	    enterToClass: String,
	    leaveToClass: String,
	    enterActiveClass: String,
	    leaveActiveClass: String,
	    appearClass: String,
	    appearActiveClass: String,
	    appearToClass: String,
	    duration: [Number,String,Object]
	};
	function getRealChild(vnode) {
	    var compOptions = vnode && vnode.componentOptions;
	    if (compOptions && compOptions.Ctor.options.abstract) {
	        return getRealChild(getFirstComponentChild(compOptions.children));
	    } else {
	        return vnode;
	    }
	}

	function extractTransitionData(comp) {
	    var data = {};
	    var options = comp.$options;
	    for (var key in options.propsData) {
	        data[key] = comp[key];
	    }
	    var listeners = options._parentListeners;
	    for (var key$1 in listeners) {
	        data[camelize(key$1)] = listeners[key$1];
	    }
	    return data;
	}

	function placeholder(h, rawChild) {
	    if (/\d-keep-alive$/.test(rawChild.tag)) {
	        return h('keep-alive', {
	            props: rawChild.componentOptions.propsData
	        });
	    }
	}

	function hasParentTransition(vnode) {
	    while (vnode = vnode.parent) {
	        if (vnode.data.transition) {
	            return true;
	        }
	    }
	}

	function isSameChild(child, oldChild) {
	    return oldChild.key === child.key && oldChild.tag === child.tag;
	}

	var isNotTextNode = function (c) {
	    return c.tag || isAsyncPlaceholder(c);
	};
	var isVShowDirective = function (d) {
	    return d.name === 'show';
	};
	var Transition = {
	    name: 'transition',
	    props: transitionProps,
	    abstract: true,
	    render: function render(h) {
	        var this$1 = this;
	        var children = this.$slots.default;
	        if (!children) {
	            return;
	        }
	        children = children.filter(isNotTextNode);
	        if (!children.length) {
	            return;
	        }
	        var mode = this.mode;
	        var rawChild = children[0];
	        if (hasParentTransition(this.$vnode)) {
	            return rawChild;
	        }
	        var child = getRealChild(rawChild);
	        if (!child) {
	            return rawChild;
	        }
	        if (this._leaving) {
	            return placeholder(h, rawChild);
	        }
	        var id = "__transition-" + this._uid + "-";
	        child.key = child.key == null ? child.isComment ? id + 'comment' : id + child.tag : isPrimitive(child.key) ? String(child.key).indexOf(id) === 0 ? child.key : id + child.key : child.key;
	        var data = (child.data || (child.data = {})).transition = extractTransitionData(this);
	        var oldRawChild = this._vnode;
	        var oldChild = getRealChild(oldRawChild);
	        if (child.data.directives && child.data.directives.some(isVShowDirective)) {
	            child.data.show = true;
	        }
	        if (oldChild && oldChild.data && !isSameChild(child, oldChild) && !isAsyncPlaceholder(oldChild) && !(oldChild.componentInstance && oldChild.componentInstance._vnode.isComment)) {
	            var oldData = oldChild.data.transition = extend({}, data);
	            if (mode === 'out-in') {
	                this._leaving = true;
	                mergeVNodeHook(oldData, 'afterLeave', function () {
	                    this$1._leaving = false;
	                    this$1.$forceUpdate();
	                });
	                return placeholder(h, rawChild);
	            } else if (mode === 'in-out') {
	                if (isAsyncPlaceholder(child)) {
	                    return oldRawChild;
	                }
	                var delayedLeave;
	                var performLeave = function () {
	                    delayedLeave();
	                };
	                mergeVNodeHook(data, 'afterEnter', performLeave);
	                mergeVNodeHook(data, 'enterCancelled', performLeave);
	                mergeVNodeHook(oldData, 'delayLeave', function (leave) {
	                    delayedLeave = leave;
	                });
	            }
	        }
	        return rawChild;
	    }
	};
	var props = extend({
	    tag: String,
	    moveClass: String
	}, transitionProps);
	delete props.mode;
	var TransitionGroup = {
	    props: props,
	    beforeMount: function beforeMount() {
	        var this$1 = this;
	        var update = this._update;
	        this._update = function (vnode, hydrating) {
	            var restoreActiveInstance = setActiveInstance(this$1);
	            this$1.__patch__(this$1._vnode, this$1.kept, false, true);
	            this$1._vnode = this$1.kept;
	            restoreActiveInstance();
	            update.call(this$1, vnode, hydrating);
	        };
	    },
	    render: function render(h) {
	        var tag = this.tag || this.$vnode.data.tag || 'span';
	        var map = Object.create(null);
	        var prevChildren = this.prevChildren = this.children;
	        var rawChildren = this.$slots.default || [];
	        var children = this.children = [];
	        var transitionData = extractTransitionData(this);
	        for (var i = 0;i < rawChildren.length; i++) {
	            var c = rawChildren[i];
	            if (c.tag) {
	                if (c.key != null && String(c.key).indexOf('__vlist') !== 0) {
	                    children.push(c);
	                    map[c.key] = c;
	                    (c.data || (c.data = {})).transition = transitionData;
	                }
	            }
	        }
	        if (prevChildren) {
	            var kept = [];
	            var removed = [];
	            for (var i$1 = 0;i$1 < prevChildren.length; i$1++) {
	                var c$1 = prevChildren[i$1];
	                c$1.data.transition = transitionData;
	                c$1.data.pos = c$1.elm.getBoundingClientRect();
	                if (map[c$1.key]) {
	                    kept.push(c$1);
	                } else {
	                    removed.push(c$1);
	                }
	            }
	            this.kept = h(tag, null, kept);
	            this.removed = removed;
	        }
	        return h(tag, null, children);
	    },
	    updated: function updated() {
	        var children = this.prevChildren;
	        var moveClass = this.moveClass || (this.name || 'v') + '-move';
	        if (!children.length || !this.hasMove(children[0].elm, moveClass)) {
	            return;
	        }
	        children.forEach(callPendingCbs);
	        children.forEach(recordPosition);
	        children.forEach(applyTranslation);
	        this._reflow = document.body.offsetHeight;
	        children.forEach(function (c) {
	            if (c.data.moved) {
	                var el = c.elm;
	                var s = el.style;
	                addTransitionClass(el, moveClass);
	                s.transform = (s.WebkitTransform = (s.transitionDuration = ''));
	                el.addEventListener(transitionEndEvent, el._moveCb = function cb(e) {
	                    if (e && e.target !== el) {
	                        return;
	                    }
	                    if (!e || /transform$/.test(e.propertyName)) {
	                        el.removeEventListener(transitionEndEvent, cb);
	                        el._moveCb = null;
	                        removeTransitionClass(el, moveClass);
	                    }
	                });
	            }
	        });
	    },
	    methods: {
	        hasMove: function hasMove(el, moveClass) {
	            if (!hasTransition) {
	                return false;
	            }
	            if (this._hasMove) {
	                return this._hasMove;
	            }
	            var clone = el.cloneNode();
	            if (el._transitionClasses) {
	                el._transitionClasses.forEach(function (cls) {
	                    removeClass(clone, cls);
	                });
	            }
	            addClass(clone, moveClass);
	            clone.style.display = 'none';
	            this.$el.appendChild(clone);
	            var info = getTransitionInfo(clone);
	            this.$el.removeChild(clone);
	            return this._hasMove = info.hasTransform;
	        }
	    }
	};
	function callPendingCbs(c) {
	    if (c.elm._moveCb) {
	        c.elm._moveCb();
	    }
	    if (c.elm._enterCb) {
	        c.elm._enterCb();
	    }
	}

	function recordPosition(c) {
	    c.data.newPos = c.elm.getBoundingClientRect();
	}

	function applyTranslation(c) {
	    var oldPos = c.data.pos;
	    var newPos = c.data.newPos;
	    var dx = oldPos.left - newPos.left;
	    var dy = oldPos.top - newPos.top;
	    if (dx || dy) {
	        c.data.moved = true;
	        var s = c.elm.style;
	        s.transform = (s.WebkitTransform = "translate(" + dx + "px," + dy + "px)");
	        s.transitionDuration = '0s';
	    }
	}

	var platformComponents = {
	    Transition: Transition,
	    TransitionGroup: TransitionGroup
	};
	Vue.config.mustUseProp = mustUseProp;
	Vue.config.isReservedTag = isReservedTag;
	Vue.config.isReservedAttr = isReservedAttr;
	Vue.config.getTagNamespace = getTagNamespace;
	Vue.config.isUnknownElement = isUnknownElement;
	extend(Vue.options.directives, platformDirectives);
	extend(Vue.options.components, platformComponents);
	Vue.prototype.__patch__ = inBrowser ? patch : noop;
	Vue.prototype.$mount = function (el, hydrating) {
	    el = el && inBrowser ? query(el) : undefined;
	    return mountComponent(this, el, hydrating);
	};
	if (inBrowser) {
	    setTimeout(function () {
	        if (config.devtools) {
	            if (devtools) {
	                devtools.emit('init', Vue);
	            }
	        }
	    }, 0);
	}

	exports.Vue = Vue;

}(this.window = this.window || {}));
